.greentechDashboard {
    margin-left: 10px;
    margin-bottom: 30px;

    .dashboard-heading {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        padding-top: 25px;
    }

    .name-heading {
        color: rgb(33, 33, 33);
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
    }

    .dashboard-cards {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-direction: row;
        gap: 0 20px;

        .dashboard-card {
            flex: 1;
            border: 1px solid rgb(213, 216, 217);
            border-radius: 6px;

            .card-header {
                padding: 16px;
                height: 60px;
                color: rgb(33, 33, 33);
                font-size: 24px;
                font-weight: normal;
                letter-spacing: 0.23px;
                line-height: 28px;
                white-space: nowrap;
            }

            .card-body {
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                height: 295px;

                .card-field {
                    font-size: 14px;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    margin-bottom: 8px;

                    &:not(:first-child) {
                        margin-top: 8px;
                    }

                    .card-field-label {
                        color: rgb(33, 33, 33);
                        font-weight: bold;
                    }

                    a {
                        word-break: break-all;
                    }
                }

                &.loan-body {
                    display: flex;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    flex-direction: column;

                    .loan-record {
                        flex: 1;

                        &:not(:first-child) {
                            padding-top: 15px;
                            border-top: 1px solid rgb(213, 216, 217);
                        }

                        .card-field {
                            margin-bottom: 4px;
                            margin-top: 4px;

                            &:last-child {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }

            .card-footer {
                border-top: 1px solid rgb(213, 216, 217);
                padding: 16px;
                height: 48px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;
            }
        }
    }

    .heading {
        color: rgb(33, 33, 33);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15px;
        line-height: 24px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }
    }

    hr {
        padding-bottom: 5px;
    }

    .name {
        padding-left: 10px;
        color: rgb(106, 107, 109);
    }

    .dashboard-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .dashboard-content {
        width: 100%;
    }

    .find-loan,
    .new-service-order,
    .pay-invoices {
        display: inline-block;
        margin: 5px;
        white-space: nowrap;
        text-align: center;
    }

    .status-pill {
        display: inline-block;
        border-radius: 35px;
        color: rgb(33, 33, 33);
        font-size: 14px;
        font-weight: normal;
        padding: 7px 10px;
        line-height: 20px;

        &.status-pill-green {
            border: 1px solid rgb(88, 152, 21);
            background: rgba(88, 152, 21, 0.15);

            .icon i {
                color: rgb(88, 152, 21);
                font-size: 16px;
            }
        }

        &.status-pill-yellow {
            border: 1px solid rgb(191, 144, 0);
            background: rgba(191, 144, 0, 0.15);

            .icon i {
                color: rgb(191, 144, 0);
                font-size: 16px;
            }
        }

        &.status-pill-red {
            border: 1px solid rgb(182, 79, 53);
            background: rgba(182, 79, 53, 0.15);

            .icon i {
                color: rgb(182, 79, 53);
                font-size: 16px;
            }
        }

        &.status-pill-grey {
            border: 1px solid rgb(213, 216, 217);
            background: rgba(213, 216, 217, 0.15);

            .icon i {
                color: rgb(106, 107, 109);
                font-size: 16px;
            }
        }
    }

    .billing-header {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        align-items: center;

        margin-top: 40px;
        margin-bottom: 16px;

        > div:first-child {
            flex: 1;
            color: rgb(33, 33, 33);
            font-size: 24px;
            font-weight: normal;
            letter-spacing: 0.23px;
            line-height: 28px;
        }

        > div:last-child {
            flex: 0;
        }
    }

    ul#orders-pagination {
		padding-left: 0;
		list-style-type: none;
		
		li {
			margin-left: 0;
			display: inline-block;
			vertical-align: top;

			a {
				display: block;
			}
		}
	}

    .showing {
        color: rgb(33, 33, 33);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.13px;
        margin-bottom: 16px;
        display: none;
    }
    .salesOrderShowing {
        display: block;
    }

    .nav-tabs {
        margin-top: 4px;
    }

    .tab-content {
        margin-top: 24px;
    }

    .invoices-table, #adminOrdersTable {
        thead {
            tr {
                th {
                    background: rgb(248, 249, 249);
                    border-radius: 0px;
                    border: 1px solid rgb(213, 216, 217);
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    padding-left: 15px;
                }
            }
        }

        tbody {
            tr {
                border: none;

                td {
                    border-radius: 0px;
                    border: 1px solid rgb(213, 216, 217);
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    padding-left: 15px;

                    &.invoice-button-column {
                        padding-right: 0px;
                        padding-left: 0px;
                    }

                    &.noOrdersMessage {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                    }

                    .order-invoice-order-order-link-help-mobile {
                        font-weight: bold;
                    }

                    .related-orders-show-project {
                        font-size: 12px;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                }

                td.sub-row-toggle-row {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    font-size: 12px;
                }
            }

            tr.sub-row {
                td {
                    font-size: 12px;
                    padding-left: 29px;

                    .small-text {
                        font-size: 12px;
                    }
                }
            }

            tr.poRow, tr.jobRow {
                display: none;
            }

            tr.shown {
                border-bottom-width: 0;
            }

            tr.hasRelated {
                border-bottom: none;
            }

            tr.related-order-row-toggle {
                td {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            tr.related-order-row-toggle.open {
                border-bottom: none;
            }

            tr.child-row.first-child-row {	
                td {
                    > div {
                        border-top: 1px solid rgb(213, 216, 217);
                    }
                }

                td.first-col {
                    border-top: 0;

                    > div {
                        border-top: 1px solid rgb(213, 216, 217);	
                        padding-left: 0;			
                    }
                }

                td.last-col {
                    border-top: 0;

                    > div {
                        border-top: 1px solid rgb(213, 216, 217);
                    }
                }
            }

            tr.child-row.last-child-row {
                border-bottom: 1px solid rgb(213, 216, 217);

                td {
                    > div {
                        border-bottom: none;
                    }
                }

                td.first-col {
                    > div {
                        border-bottom: none;
                        margin-bottom: 0;
                    }
                }

                td.last-col {
                    > div {
                        border-bottom: none;
                        margin-bottom: 0;
                    }
                }
            }

            tr.related-order-row-toggle.open {
                td {
                    border-bottom: none;
                }
            }

            tr.child-row.open {
                display: table-row;
            }

            tr.child-row {
                border-bottom: none;
                display: none;

                td {
                    padding: 0;
                    vertical-align: top;
                    border-right: none;
                    border-left: none;
                    border-top: none;
                    border-bottom: none;

                    > div {
                        border-bottom: 1px solid rgb(213, 216, 217);
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 15px;
                    }

                    a {
                        font-weight: 100;
                        margin-left: 0;
                    }
                }

                td.first-col {
                    border-bottom: none;
                    padding: 0;
                    border-left: 1px solid rgb(213, 216, 217);

                    > div {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        margin-left: 30px;				    
                        border-bottom: 1px solid rgb(213, 216, 217);
                    }
                }

                td.last-col {
                    padding: 0;
                    border-bottom: none;
                    border-right: 1px solid rgb(213, 216, 217);

                    > div {
                        margin-right: 15px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(213, 216, 217);
                        margin-bottom: 0px;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $greentech-tablet-max-width) and (min-width: $greentech-tablet-min-width) {
    .greentechDashboard {
        margin-left: 10px;

        .dashboard-cards {
            flex-direction: column;
            gap: 24px 0;
    
            .dashboard-card {
                width: 100%;

                .card-body {
                    height: auto;

                    &.loan-body {
                        flex-direction: row;
    
                        .loan-record {
                            flex: 1;
    
                            &:not(:first-child) {
                                padding-top: 0;
                                padding-left: 15px;
                                border-top: none;
                                border-left: 1px solid rgb(213, 216, 217);
                            }
                        }
                    }
                }
            }
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .dashboard-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }

        #adminOrdersTable {
			thead {
				th#poHeader, th#jobHeader {
					display: none;
				}
			}

			tbody {
				tr {
					td {
						.related-orders-show {
							margin-left: 42px;
							padding-top: 5px;
						}
					}                    
				}

                tr.open {
                    td.sub-row-toggle-row {
                        border-bottom-width: 0;
                    }
                }

				td.webOrderColumn, td.poColumn, td.jobColumn, td.relatedPo, td.relatedJob {
					display: none;
				}

				tr.poRow, tr.jobRow {
					display: none;
				}

				tr.poRow.open, tr.jobRow.open {
					display: table-row;

                    td {
                        border-top-width: 0;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
				}

                tr.color8-bg:not(.open) {
                    background-color: #fff;
                }

                tr.related-order-row-toggle.open {
                    td {
                        border-bottom: none;
                    }
                }

                tr.sub-row, tr.main-row.open {
					border-bottom-width: 0;
				}
            }
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    .greentechDashboard {
        .dashboard-cards {
            flex-direction: column;
            gap: 24px 0;
    
            .dashboard-card {
                width: 100%;

                .card-body {
                    height: auto;
                }
            }
        }

        .billing-header {
            flex-direction: column;
            gap: 24px 0;
    
            margin-top: 40px;
            margin-bottom: 16px;
    
            > div:first-child {
                flex: 1;
                width: 100%;
            }
    
            > div:last-child {
                display: block;
                flex: 1;
                width: 100%;

                a.pay-invoices {
                    display: block;
                }
            }
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        h1, .order-icons {
            padding-left: 15px;
        }

        #adminOrdersTable {
            thead {
                th {
                    display: none;
                }
            }

            tbody {
                border-top: 1px solid rgb(213, 216, 217);

                tr {
                    td {
                        border: none;
                    }
                }

                tr.main-row {
                    padding-top: 15px;
                    padding-bottom: 20px;
                    display: block;
                    border-bottom: 1px solid rgb(213, 216, 217);

                    td {
                        border: none;
                        position: relative;
                        padding-left: 140px;
                        display: block;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }

                    td:before {
                        position: absolute;
                        left: 15px;	
                        font-size: 13px;
                        line-height: 1.5;	
                        top: 0px;				
                    }

                    td:nth-of-type(1):before { content: "Order #"; }
                    td:nth-of-type(2):before { content: "Web Order #"; }
                    td:nth-of-type(3):before { content: "Customer PO"; }
                    td:nth-of-type(4):before { content: "Account #"; }
                    td:nth-of-type(5):before { content: "Order Date"; }
                    td:nth-of-type(6):before { 
                        content: "Status"; 
                    }
                }

                tr.main-row.show-prices {
                    td:nth-of-type(1):before { content: "Order #"; }
                    td:nth-of-type(2):before { content: "Web Order #"; }
                    td:nth-of-type(3):before { content: "Customer PO"; }
                    td:nth-of-type(4):before { content: "Account #"; }
                    td:nth-of-type(5):before { content: "Order Date"; }
                    td:nth-of-type(6):before { content: "Total"; }
                    td:nth-of-type(7):before { 
                        content: "Status"; 
                        top: 4px;
                    }
                }

                tr.main-row.hasRelated {
                    padding-bottom: 10px;
                    border-bottom: none;
                }

                .related-order-row-toggle {
                    padding-bottom: 10px;
                    display: block;
                }    
                
                tr.related-order-row-toggle:not(.open) {
                    border-bottom: 1px solid rgb(213, 216, 217);
                }

                .related-orders-show {
                    margin-left: 0;
                }

                tr.child-row.open {
                    display: block;

                    td {
                        display: block;
                        padding: 0px;
                        position: relative;	

                        br {
                            display: none;
                        }

                        div {
                            margin-left: 15px;
                            padding: 0;
                            border: none;

                            span {
                                padding-left: 125px;

                                a {
                                    font-weight: bold;
                                }
                            }

                            div {
                                padding-left: 125px;
                                margin-left: 0;
                            }
                        }
                                                                    
                    }

                    td.first-col {
                        div {	
                            padding: 10px 0 0 0;						
                            margin-right: 15px;
                            border-top: 1px solid rgb(213, 216, 217);
                            border-bottom: none;
                        }
                    }

                    td.last-col {
                        > div {
                            padding: 0 0 10px 0;
                            border-bottom: none;
                        }
                    }

                    td.last-col:before {
                        bottom: 10px;
                    }

                    td:before {
                        position: absolute;
                        left: 15px;
                        font-size: 13px;
                        line-height: 1.5;	
                        bottom: 0px;	
                    }

                    td:nth-of-type(1):before { content: "Order #"; }
                    td:nth-of-type(4):before { content: "Order Date"; }
                    td:nth-of-type(6):before { content: "Status"; }
                }

                tr.child-row.open.noPrice {
                    td:nth-of-type(5):before { content: "Status"; }
                }

                tr.child-row.first-child-row {
                    td {
                        border: none;
                    }

                    td.last-col {
                        > div {
                            border-top: none;
                        }
                    }
                }
            }
        }

        #orders-pagination {
            .page-selector {
                width: auto;
            }
        }

        .invoices-table {			
            tbody {
                tr {
                    td {
                        a {
                            margin-left: 0;
                        }
                    }
                }

                a.pod-button-file-path {
                    display: inline-block;
                    width: 52%;
                }

                .footable-row-detail {
                    .footable-row-detail-cell {
                        .footable-row-detail-inner {
                            .footable-row-detail-row {
                                .button-small {
                                    margin-top: 10px;
                                }
                            }
                            .footable-row-detail-row:last-of-type {
                                .footable-row-detail-name {
                                    display: none;
                                }
                                .footable-row-detail-value {
                                    position: relative;
                                    height: 50px;

                                    button {
                                        position: absolute;
                                        top: 5px;
                                        width: 140px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}