@import "../variables";
@import "../mixins";

.orderingPage {
	margin-top: 40px;
	margin-left: 40px;
	margin-right: 40px;

	.header{
	    display: flex;

		& > div{
			width: 50%;
			flex: 1;
		}

		.notes{
			font-size: 16px;
			padding-right: 30px;
		}

		.page-logo{
			display: block;
			max-width: 100%;
			margin: 10px 0;
		}

		.filter {
			margin-top: 16px;
			position: relative;

			i {
				position: absolute;
			    top: 15px;
			    left: 15px;
			    opacity: .5;
			}
			input[type="text"] {
				width: 404px;
				padding-left: 30px;
				opacity: .5;
			}
		}
	}

	

	.fixtureArea {
		@include flexbox;
		margin-top: 16px;
	    margin-left: -15px;
	    margin-right: -15px;

		.fixtures {
			width: 100%;
			display: flex;
			@include flexbox;
			@include flexwrap(wrap);

			

			.fixtureRow {
				padding: 30px 15px 0px 15px;
				cursor: pointer;
				max-width: 25%;
				position: relative;
				flex: 1 1 25%;

				&.viewing-products:after{
					content: '';
				    position: absolute;
				    top: 100%;
				    left: 50%;
				    margin-left: -15px;
				    margin-top: 17px;
				    width: 0;
				    height: 0;
				    border-left: solid 15px transparent;
				    border-right: solid 15px transparent;
				    z-index: 1;
				}

				.fixtureData{
					border: solid 1px #b9bbc0;
					height: 100%;
					width: 100%;
					padding: 30px 20px;
				}

				.image {
				    height: 200px;
					margin: auto;
				    margin-bottom: 10px;
					text-align: center;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.desc {

					.title {
						font-weight: 600;
						font-size: 16px;
						margin-bottom: 8px;
						display: inline-block;
					}

					.mfr{
					    word-break: break-word;
					}

					.pdf-link{
						margin-left: 10px;
					}

					.view-products-button {
						font-size: 13px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.54;
						letter-spacing: normal;
					}

					.fixtureSelect{
						margin-top: 20px;
						display: block;
					}
				}

				.custom-line.line-item{
				    padding: 0;
					border-bottom: none;

					.uom{
						font-size: 15px;
						line-height: 1.67;
					}

					.button-emphasis{
						vertical-align: top;
					}

					.list-item-price{
						display: block;
						margin-right: 15px;
						margin-bottom: 20px;

						.search-price{
							height: 45px;
							vertical-align: middle;
							display: table-cell;
						}
					}

					.quantity-column{
						display: inline-block;
						vertical-align: top;
					}
				}

				.buttons {
					width: 210px;
					text-align: right;

					button:focus {
						outline: none;
					}
				}
			}

			
		}

		.items.selected {
			display: block;
		}

		.items {
			
			width: 100%;
			position: relative;
			display: none;
			border-radius: 5px;
			background-color: #f4f4f4;
		    margin: 30px 15px 0 15px;
		    overflow: hidden;

			.items-head{
				width: 50%;
				height: 34px;
				font-size: 16px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #ffffff;
				padding: 7px;
			}

			.items-close{
				text-align: right;
			}
			

			.itemsPanel{
				display: flex;
				@include flexwrap(wrap);
				margin-bottom: -1px;
			}

			#mobile-cancel {
				display: none;
			}

			.header {
				font-size: 16px;
				font-weight: bold;
				line-height: 1.5;
				padding: 10px 2px 10px 15px;
				box-shadow: inset 0 1px 0 0 #b9bbc0;
				background-color: #f4f4f4;
			}

			#itemsLoading {
				text-align: center;
				padding-top: 16px;
				padding-bottom: 16px;
				display: none;
				z-index: 1;
				margin-top: 34px;
			}

			.itemRow.line-item:first-child {
				margin-top: 0;
			}

			.line-item{
				
				border-bottom: none;
				margin-top: 0;
				padding-bottom: 0px
			}



			.itemRow {
				width: 50%;
				display: inline-block;
				

				& > div{
					padding: 20px 26px;
					margin: 0 15px;
					border-bottom: solid 1px #b9bbc0;
					height: 100%;
				}

				.top {
					@include flexbox;
					

					.image {
						max-width: 90px;
						text-align: center;
						flex: 1 0;

						img {
							max-width: 100%;
						}
					}

					.desc {
						@include flex(2);
						margin-left: 8px;

						.title {
							
							font-weight: 600;
							font-size: 16px;
							
						}

						.productDetails {
							margin-top: 10px;
							font-size: 12px;
							word-break: break-word;
						}
					}
				}

				.price-per {
							font-size: 24px;
							font-weight: 600;
						}

				.bottom {
					display: none;
					margin-top: 8px;

					.price {
						@include flex(1);

						.price-per {
							font-size: 24px;
							font-weight: 600;
						}
					}

					.cart {
						width: 110px;
						text-align: right;						
					}

					.button {
						width: 150px;
						text-align: right;

						button.line-item-button {
							margin-left: 8px;
						}
					}
				}

				.bottom.row {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	#extra-image-view{
		.modal-body{
			padding-top: 0;

			img{
				max-width:100%;
				margin: auto;
				margin-top: 20px;
				display: block;
			}
		}

		.modal-footer{
			text-align: center;

			.img-wrapper{

			    width: 100px;
				height: 100px;
				padding: 5px;
				display: inline-block;
				position: relative;
				text-align: left;

				&.highlight{
					border: thin solid black;
				}

				img{
			        max-width: 89px;
					max-height: 90px;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					
				}
			}
		}
	}
}

@media only screen and (min-width: $desktop-min-width) and (max-width: 1300px) {
	.orderingPage {
		.fixtureArea {
			.fixtures {
				.fixtureRow {
					max-width: 33%;
					flex: 1 1 33%;
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.orderingPage {
		margin-top: 30px;
		margin-left: 15px;
		margin-right: 15px;
	
		.header{
			.filter {	
				padding-right: 20px;		
				input[type="text"] {
					width: 100%;
				}
			}
		}
	
		.fixtureArea {
			.fixtures {				
				.fixtureRow {
					max-width: 50%;					
					flex: 1 1 50%;
				}
			}
	
			.items {
				/*margin-left: 15px;*/	

				.itemRow{
					width: 100%;
					.top{
						.desc {
							@include flex(1);
						}
					}
				}	
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.orderingPage {
		margin-top: 30px;
		margin-left: 0;
		margin-right: 0;

		
	
		.header{

			&>div{
				width: 100%;
			}

			h1 {
				word-break: break-all;
				display: block;
				height: auto;
			}
			
			h1, .filter, .notes {
				margin-left: 15px;
				margin-right: 15px;
			}

			.filter {			
				input[type="text"] {
					width: 100%;
				}
			}

			.notes {
				padding-right: 0;
			}

		}
	
		.fixtureArea {
		    margin-left: 0;
    		margin-right: 0;

			.fixtures {		
				width: 100%;
						
				.fixtureRow {	
					
					width: 100%;
					min-width: 100%;
					flex: 1;
					height: auto;
					margin-top: -1px;
					padding: 0px;

					&.viewing-products .fixtureData{
						padding-bottom: 17px;
					}

					&.viewing-products:after{
						content: '';
					    position: absolute;
					    top: 100%;
					    left: 50%;
					    margin-top: -14px;
					    width: 0;
					    height: 0;
					    border-bottom: solid 15px #f4f4f4 !important;
					    border-left: solid 15px transparent;
					    border-right: solid 15px transparent;
					    z-index: 1;
					}

					.fixtureData{
						padding: 20px 15px;
						display: flex;
						@include flexwrap(wrap);
					}

					.image {
						width: 90px;
						height: 90px;
						flex: 0 90px;
					}

					.desc{
						flex: 1;
						padding-left: 10px;
					}

					.buttons {
						width: 100%;						
						margin-top: 8px;

						.fixtureSelect {
							width: 100%;
						}
					}

					.custom-line.line-item{
						width: 100%;
						margin-left: 0;
						@include flexbox;
						@include flexwrap(wrap);

						.list-item-price{
							flex: 1;
						}

						.button-emphasis{
							margin-left: 0;
							width: 100%;
						}

						.quantity-column{
							float:right;
						}
					}
				}


			}
	
			.items {
				background-image: none !important;
				margin: 0;
				border-radius: 0;
				padding: 0;

				#itemsLoading {
					top: 110px;
				}

				.itemRow {
					width: 100%;
				    margin: 0px;

				    & > div {
				    	margin: 0px
				    }

					.top{
						margin: 0;
					}	

					.bottom {
						@include flexbox;
						@include flexwrap(wrap);
	
						.cart {
							width: 110px;
							text-align: right;
	
							button.line-item-button {
								margin-left: 0;
							}
						}
	
						.button {
							width: 100%;

							button {
								width: 100%;
								margin-left: 0;
								margin-top: 20px;
							}

							button.line-item-button {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
}