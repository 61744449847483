@import "../variables";

body.solar-template {
	.my-catalogs {
		margin-top: 24px;
		padding-left: 16px;
		padding-right: 16px;				

		.my-catalogs-left-content {
			width: 240px;

			.my-catalogs-title-row {
				padding-left: 0;

				h4 {
					font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
				}

				#create-new-catalog {
					float: none !important;
				}
			}

			.my-catalogs-filter-row {
				position: relative;

				input {
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 0.2px;
					padding: 8px 28px 8px 12px;					
				}

				input::placeholder {
					color: #959ea0;
				}
			}

			.my-catalogs-filter-row:after {
				content: "\f002";
				font-family: 'FontAwesome';					
				position: absolute;
				right: 28px;
				top: 28px;
				color: #959ea0;
			}
		}

		.my-catalogs-right-content {
			margin-left: 20px;
			padding-left: 0;
			padding-right: 0;

			h1 {
				font-size: 32px;
				line-height: 1.25;
				letter-spacing: 0.3px;
			}

			h2 {
				font-size: 24px;
				line-height: 1.17;
				letter-spacing: 0.2px;
			}

			a.rename-catalog {
				padding-left: 28px;
				position: relative;
			}
			a.rename-catalog:before {
				content: "\f040";
				position: absolute;
				left: 8px;
				top: 4px;
				font-family: 'FontAwesome';	
				font-size: 17px;
			}

			a.delete-catalog {
				padding-left: 28px;
				position: relative;
			}
			a.delete-catalog:before {
				content: "\f057";
				position: absolute;
				left: 8px;
				top: 4px;
				font-family: 'FontAwesome';	
				font-size: 17px;
			}

			.catalog-link {
				margin-left: 8px;
			}

			.buttons {
				button {
					margin-left: 0;
				}				

				.add-to-cart-all,
				.my-catalogs-save-help-section,
				.add-list-to-project {
					margin-left: 8px;
				}
			}

			h5 {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;
			}

			label {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;
				margin-bottom: 2px;
			}

			.user-section {
				label, .selectedUserName {
					font-size: 14px;
					line-height: 1.43;
					letter-spacing: 0.1px;
					font-weight: 100;
				}
			}

			.my-catalogs-items {
				.my-catalogs-item-top {
					margin-top: 24px;

					h6 {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}

					label {
						display: inline-block;
						margin-right: 8px;
					}
				}

				hr {
					margin-top: 24px;
					margin-bottom: 0;
				}

				.line-item {
					padding-top: 16px;
					padding-bottom: 16px;					
				}

				.my-catalogs-item-row {
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;

					.image-col {
	  					width: 96px;
	  					text-align: center;
	  					margin-left: 16px;

	  					img {
	  						max-width: 96px;
	  						max-height: 96px;
	  					}
	  				}

					.description-col {
	  					flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
				  		padding-left: 16px;
				  		padding-right: 16px;

				  		.product-name {
				  			a {
				  				font-size: 16px;
								font-weight: 600;
								line-height: 1.5;
								letter-spacing: 0.2px;
				  			}
				  		}

				  		.attribute-line {
				  			ul {
				  				list-style-type: none;
				  				margin: 0 0 0 -7px;
				  				padding: 0;

				  				li {
				  					display: inline-block;
				  					border-right-width: 1px;
				  					border-left-width: 0;
				  					border-top-width: 0;
				  					border-bottom-width: 0;
				  					font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									padding-right: 7px;
									color: #606e71;
									margin: 0 0 0 7px;
				  				}

				  				li:first-of-type {
				  					padding-left: 0;
				  				}

				  				li:last-of-type {
				  					border-right-width: 0;
				  				}
				  			}
				  		}

				  		.description-line {
				  			margin-top: 8px;
				  			font-size: 14px;
							line-height: 1.43;
							letter-spacing: 0.1px;

							.button-section {
								margin-top: 16px;

								a {
									margin-right: 8px;
								}
							}
				  		}

				  		.price-line {
							display: none;
						}
	  				}

	  				.price-quantity-col {
	  					width: 450px;
	  					display: -webkit-box;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						margin-right: 16px;

	  					.price-col {
		  					width: 228px;

		  					.list-item-price {
		  						.sell-price {
		  							font-size: 18px;
									font-weight: 600;
									line-height: 1.56;
									letter-spacing: 0.2px;
		  						}

		  						.list-price {
		  							font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									color: #606e71;
		  						}

		  						.uom {
		  							font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									color: #606e71;
		  						}
		  					}

		  					.item-availability {
		  						font-size: 12px;
								line-height: 1.67;
								letter-spacing: 0.1px;
		  					}
		  				}

		  				.quantity-col {
		  					width: 226px;
		  					text-align: right;

		  					.qty-input {
		  						input {
		  							width: 88px;
		  							height: 40px;
		  						}
		  					}

		  					.qty-input:before {
		  						top: 10px;
		  					}

		  					button.button-emphasis {
		  						margin-left: 4px;
		  						padding: 12px 16px;
		  						height: 40px;
    							border-top: none;
		  					}
		  				}
	  				}
				}

				.buttons {
					margin-top: 24px;
				}
			}
		}

		.error-status {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(182, 79, 53, 0.15);
			border-radius: 3px;
			border: 1px solid rgb(182, 79, 53);
			padding: 10px; 
			color: rgb(33, 33, 33);  
			margin-bottom: 24px;
			margin-top: 24px;
	
			i.fa-exclamation-circle {
				width: 20px;
			}
	
			.message-content {
				flex-grow: 1;
	
				a {
					color: rgb(33, 33, 33);
					text-decoration: underline;
				}
			}
	
			.fa-exclamation-circle {
				color: rgb(182, 79, 53);
			}
		
			.fa-times {
				color: rgb(33, 33, 33);
			}
		}

		.status {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(88, 152, 21, 0.15);
			border-radius: 3px;
			border: 1px solid rgb(90, 177, 118);
			padding: 10px; 
			color: rgb(33, 33, 33);  
			margin-bottom: 24px;
			margin-top: 24px;
	
			i.fa-check-circle {
				width: 20px;
			}
	
			.message-content {
				flex-grow: 1;
	
				a {
					color: rgb(33, 33, 33);
					text-decoration: underline;
				}
			}
	
			.fa-check-circle {
				color: #589815;
			}
		
			.fa-times {
				color: rgb(33, 33, 33);
			}
		}  
	}

	#add-list-to-project-modal {
		.selector-description {
			color: rgb(33, 33, 33);
			font-size: 14px;
			font-weight: normal;
			letter-spacing: 0.13px;
			line-height: 20px;
		}
		.project-search-box {
			margin-top: 24px;
			position: relative;

			input {
				border: 1px solid rgb(149, 158, 160);
				border-radius: 3px;
				width: 100%;
			}
			input::placeholder {
				color: rgb(149, 158, 160);
			}

			.project-search-input-icon {
				position: absolute;
				width: 20px;
				height: 20px;
				right: 8px;
				top: 12px;
				color: rgb(149, 158, 160);
			}
		}

		#no-project-selected {
			border: 1px solid rgb(182, 79, 53);
			border-radius: 3px;
			padding: 10px;
			background: rgba(182, 79, 53, 0.15);
			font-size: 14px;
			margin-top: 24px;
			
			i {
				color: rgb(182, 79, 53);
				margin-right: 8px;
			}
		}

		.project-count {
			margin-top: 16px;
			color: rgb(33, 33, 33);
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0.13px;
			line-height: 20px;
		}

		.project-list {
			margin-top: 16px;
			height: 252px;
			border: 1px solid rgb(213, 216, 217);
			border-radius: 0px;
			overflow-y: scroll;

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					.project-row {
						display: flex;
						padding-top: 8px;
						padding-bottom: 8px;
						align-items: center;
						border-bottom: 1px solid rgb(213, 216, 217);

						.selector {
							width: 35px;
							text-align: center;
						}						

						.details {
							flex-grow: 1;

							.name {
								color: rgb(33, 33, 33);
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0.13px;
								line-height: 20px;
							}

							.address {
								color: rgb(43, 61, 65);
								font-size: 14px;
								font-weight: normal;
								letter-spacing: 0.13px;
								line-height: 20px;
							}
						}
					}

					.project-row.active {
						background: #f4f4f4;
					}
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.my-catalogs {
			.my-catalogs-left-content {
				width: 200px;
			}

			.my-catalogs-right-content {
				h2 {
					display: block;
				}

				.my-catalogs-mobile-input-wrapper {
					display: block;
					margin-bottom: 20px;
				}

				.catalog-link {
					margin-left: 0;
					margin-right: 8px;
				}

				.buttons {
					.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
						width: 145px;
					}

					#open-format-modal {
						margin-top: 8px;
					}
				}

				.user-section {
					.selectUserBox {
						margin: 0;
					}
					label {
						margin-bottom: 0;
					}

					div.col-sm-6 {
						padding-bottom: 3px;
					}
				}

				.my-catalogs-items {
					.my-catalogs-item-top {
						.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
							width: 139px;
						}
					}

					.my-catalogs-item-row {
						.my-catalogs-product-quantity {
							margin-left: 0;
						}

						.description-col {
							padding-right: 12px;

							.price-line {
								display: none;
							}
						}

						.price-quantity-col {
							width: 120px;
							flex-direction: column;
							-webkit-box-orient: vertical;
						    -moz-box-orient: vertical;
						    -webkit-flex-direction: column;
						    -ms-flex-direction: column;

							.price-col {
								width: 120px;
							}								

							.quantity-col {
								width: 120px;
								text-align: left;

								.qty-input {
									margin-top: 16px;

									input {
										width: 100%;
									}
								}

								button.button-emphasis {
									margin-top: 8px;
									margin-left: 0;
									font-size: 14px;
									font-weight: 600;
									line-height: 1.14;
									letter-spacing: 0.1px;
									padding: 0;
									text-align: center;
									width: 120px;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.my-catalogs {
			padding-right: 0;
			padding-left: 0;

			.my-catalogs-left-content {
				width: 100%;
				padding-right: 16px;
				padding-left: 16px;				

				> div.row {
					> div.col-xs-12 {
						padding-left: 0;
						padding-right: 0;
					}
				}

				.my-catalogs-mobile-wrapper {					
					margin-top: 24px;

					.collapsible-header {
						padding-top: 10px;
						padding-bottom: 10px;
						padding-left: 16px;
						padding-right: 16px;
						position: relative;

						h5 {
							font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
						}
					}

					.collapsible-header::after {
						font-family: 'FontAwesome';
					    position: absolute;
					    right: 12px;
					    top: 9px;
					    content: "\f068";
					    font-size: 18px;
					}

					.my-catalogs-mobile-wrapper-content {
						padding-left: 16px;
						padding-right: 16px;
						padding-bottom: 16px;

						.my-catalogs-title-row {
							margin-top: 0;

							h4 {
								display: block;
								float: none !important;
								margin-bottom: 6px;
							}
						}
					}
				}

				.my-catalogs-mobile-wrapper.closed {
					.collapsible-header::after {
						content: "\f067";
					}

					.my-catalogs-mobile-wrapper-content {
						display: none;
					}
				}
			}

			.my-catalogs-right-content {
				margin-left: 0;

				.status, .error-status {
					margin-left: 16px;
					margin-right: 16px;
				}

				form {
					> div.row {
						> div.col-xs-12 {
							padding-left: 16px;
							padding-right: 16px;
						}
					}

					.user-section, #my-catalogs-filter-section-help {
						margin-left: 16px;
						margin-right: 16px;
					}

					h2 {
						display: block;
						height: auto;
					}

					.catalog-link {
						margin-left: 0;
						margin-right: 8px;
					}

					.delete-catalog {
						width: auto;
						display: inline-block;
						margin-top: 0;
					}

					.buttons {
						.add-to-cart-all {
							margin-left: 0;
							margin-bottom: 24px;
						}
						.add-list-to-project {
							margin-left: 0;
						}

						.my-catalogs-mobile-input-wrapper {
							display: -webkit-box;
							display: -ms-flexbox;
							display: -webkit-flex;
							display: flex;
							width: 100%;
							align-items: center;
					        -ms-flex-align: center;
							margin-bottom: 20px;

							label {
								display: inline-block;
								width: 70px;
							}

							.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
								flex: 1;
								-webkit-box-flex: 1;
						  		-moz-box-flex:  1;
						  		-webkit-flex:  1;
						  		-ms-flex:  1;
							}
						}						
					}

					#my-catalogs-filter-section-help {
						.text-right {
							text-align: left;
						}
					}

					.my-catalogs-items {
						.my-catalogs-item-top {
							> div {
								padding-left: 16px;
								padding-right: 16px;
							}

							.text-right {
								text-align: left;
							}

							.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
								width: 140px;
							}
						}

						.my-catalogs-item-row {
							flex-wrap: wrap;
							-ms-flex-wrap: wrap;

							.image-col {
								margin-left: 16px;
								width: 91px;
								text-align: center;

								a {
									img {
										max-height: 91px;
									}
								}
							}

							.description-col {
								padding-left: 8px;

								.description-line {
									display: none;
								}

								.attribute-line {
									ul {
										li {
											display: block;
											border: none;
										}
									}

									.button-section {
										margin-top: 16px;

										a {
											display: inline-block;
											margin-bottom: 8px;
										}
									}
								}

								.price-line {
									display: block;

									.list-item-price {
										.sell-price {
											font-size: 18px;
											font-weight: 600;
											line-height: 1.56;
											letter-spacing: 0.2px;
										}

										.uom {
											font-size: 12px;
											line-height: 1.67;
											letter-spacing: 0.1px;
											color: #606e71;
										}

										.search-price {
											.list-price {
												font-size: 12px;
												line-height: 1.67;
												letter-spacing: 0.1px;
												color: #606e71;
											}
										}
									}
								}
							}

							.price-quantity-col {
								width: 100%;
								padding-left: 16px;
								padding-right: 16px;
								margin-top: 6px;

								.quantity-col {
									display: -webkit-box;
									display: -ms-flexbox;
									display: -webkit-flex;
									display: flex;
									width: 100%;

									button {
										flex: 1;
										-webkit-box-flex: 1;
								  		-moz-box-flex:  1;
								  		-webkit-flex:  1;
								  		-ms-flex:  1;
									}
								}

								.price-col {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* desktop thin */
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
	body.solar-template {
		.my-catalogs {
			.my-catalogs-right-content {
				.my-catalogs-items {
					.my-catalogs-item-row {
						.price-quantity-col {
							width: 255px;

							.price-col {
								width: 130px;
							}

							.quantity-col {
								width: 125px;
								text-align: right;

								button {
									margin-top: 8px;
								}
							}
						}
					}
				}
			}
		}
	}
}