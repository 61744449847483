@import "../variables";

.shipping-information {
	.shipping-information-content {
		.shipping-information-form {
			max-width: 1440px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 40px;

			.shipping-method {
				border: solid 1px #b9bbc0;
				margin-top: 20px;
				margin-bottom: 50px;
				padding: 30px;

				> div {
					margin-top: 20px;

					label {
						display: inline-block;
					}
					a {
						margin-left: 15px;
						font-weight: bold;
					}
				}
			}

			.recent-button {
				margin-top: 20px;
			}

			.address-section {
				> div.row {
					margin-top: 20px;
				}
			}

			.billing-section {
				margin-top: 50px;

				.same-as-shipping {
					margin-top: 20px;

					label {
						font-weight: 100;
					}
				}
			}

			.shipping-info-label {
				margin-bottom: 0;
			}

			.section-header {
				margin-top: 50px;
			}

			.terms-text {
				font-size: 16px;
				line-height: 1.6;
			}

			hr {
				border-top: 1px solid #b9bbc0;
				margin-top: 35px;
			}

			.button-row {
				margin-top: 10px;

				.go-back {
					margin-top: 12px;
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
				}
			}
		}
	}
}

.recent-ship-delete, .recent-ship-delete:hover, .recent-ship-delete:active, .recent-ship-delete:focus, .recent-bill-delete, .recent-bill-delete:hover, .recent-bill-delete:active, .recent-bill-delete:focus {
	color: $color-error;
}

.recent-ship-address-select, .recent-bill-address-select, .recent-ship-address-select-mobile, .recent-bill-address-select-mobile {
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
}

#recently-used-shipping h4, #recently-used-billing h4 {
	display: block;
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.shipping-information {
		.shipping-information-content {
			.shipping-information-form {
				margin-top: 30px;

				.shipping-method {
					padding: 20px 15px;
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.shipping-information {
		.shipping-information-content {
			.shipping-information-form {
				margin-top: 20px;

				.shipping-method {
					padding: 20px 15px;
				}

				.button-row {
					button {
						float: none !important;
						width: 100%;
					}

					.go-back {
						margin-top: 20px;
						display: block;
						text-align: center;
					}
				}

				.xs-margin {
					margin-top: 20px;
				}
			}
		}
	}

	.mobile-table-heading {
		box-shadow: inset 0 1px 0 0 #b9bbc0;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.5;
		padding: 10px 15px;
	}

	.mobile-address-row {
		padding-top: 10px;
		padding-left: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #b9bbc0;

		> div {
			span:first-of-type {
				width: 70px;
				font-size: 13px;
				line-height: 1.5;
				display: inline-block;
			}
			span {
				font-size: 15px;
				line-height: 1.7;
			}
		}

		a.recent-ship-delete-mobile, a.recent-bill-delete-mobile {
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
			margin-top: 12px;
			display: block;
		}
	}
}