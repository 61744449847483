.detail-page{
	.accordion-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.admin-detail-row{
		padding-top: 10px;
		padding-bottom: 10px;
		margin-left: 0;
		margin-right: 0;

		&>div{
			padding-left: 0
		}
	}
}