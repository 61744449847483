@import "../variables";
@import "../mixins";

.recentlyViewedItems {
	padding-bottom: 20px;

	h2 {
		font-size: 20px;
		font-weight: 100;
		padding: 10px 40px;
		display: block;
		height: auto;
		margin: 0;
	}

	.recentlyViewedSlider {
		@include flexbox;
		@include flexalign(center);

		.recentlyViewedLeft, .recentlyViewedRight {
			width: 40px;

			a {
				display: block;
				text-align: center;
			}
		}

		.recentlyViewedContent {
			@include flex(1);
			overflow: hidden;

			#recentlyViewedSlider {
				@include flexbox;
				height: auto !important;

				ol#recentlyViewedSlider{
					display: flex;
					border: 1px solid black;
					width: 100% !important;
					li{
						list-style-type: none;
						margin: 8px;
					}
				}
			}

			a.product-link {
				display: block;
				cursor: pointer;
			}

			.recentlyViewedProduct {
				height: 132px;			
				background-color: #fff;
				padding: 8px 15px;
				
				.image {
					width: 100% !important;
					height: 80px;
					margin-right: 8px;
					text-align: center;

					img {
						max-width: 100%;
						max-height: 80px;
					}
				}

				.details {
					width: 100%;
					overflow: hidden;

					.title {
						a {
							font-size: 16px;
						}
					}

					.mfr {
						font-size: 13px;
						text-align: center;
    					overflow: hidden;
						text-overflow: ellipsis;
						max-height: 3em;
					}

					.price {
						font-weight: 600;
					}
				}
			}
		}
	}
}