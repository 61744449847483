@import "../variables";

.greentech-my-links-left-container {

    margin: 20px 10px 0 10px;

    .menu-item {
        padding: 5px 0 5px 0;
        width: 192px;
    }

    .menu-item:hover {
        background: rgba(33, 33, 33, 0.07);
        border-radius: 4px;
        border-right-color: rgba(33, 33, 33, 0.07);

    }

    .greentech-my-links-title-text {
        color: rgb(33, 33, 33);
        font-size: 14px;
        letter-spacing: 0.6px;
        line-height: 24px;
        text-transform: uppercase;
        padding-left: 5px;
        font-weight: bold;
    }

    a.current {
        border-left: 3px;
        border-left-color: #4c9c4c;
        border-left-style: solid;
        border-right-color: transparent;
        border-right-style: solid;
    }

    a {
        display: flex;
        justify-content: center;
    }

    a:not(.current) {
        border-width: 3px;
        border-color: transparent;
        border-left-style: solid;
        border-right-style: solid;
    }

    .my-links-left-text {
        padding-left: 5px;
    }

    div.current {
        background: rgb(250, 250, 250);
        border-radius: 0px;
    }
}

@media only screen and (max-width: $tablet-max-width) and (min-width: $tablet-min-width) {
    .home-left-content {
        width: 68px;

        .open {
            .menu-item {
                width: unset;

                a {
                    justify-content: left;
                    padding-left: 5px;
                }
            }
        }
    }
    
    .greentech-my-links-left-container {
        border-radius: 6px;
        border: 1px solid rgb(206, 207, 205);

        button {
            border: none;
            background-color: transparent;
        }

        .menu-item {
            padding: 5px 0 5px 0;
            width: 46px;
        }

        .menu-item:hover {
            border-radius: 0px;
        }

        #greentech-my-links-expand-button {
            padding-left: 10px;
        }
    }
}
@media only screen and (max-width: $mobile-max-width){

    a {
        justify-content: left !important;
    }

    .greentech-my-links-left-container {
        width: 95%;
        margin: 0px;
        border: 1px solid rgb(213, 216, 217);
        border-radius: 3px;
    }

    .greentech-my-links-left-container .menu-item {
        width: 100%;
    }

    #greentech-my-links-expand-mobile-button, #greentech-my-links-collapse-mobile-button {
        background-color: transparent;
        border: none;
        width: 100%;
    }

    .title-icon {
        float: right;
        padding-top: 5px;
    }

    .greentech-my-links-title-text {
        float: left;
    }
}