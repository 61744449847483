@import "../variables";

.searching-overlay {
    position: absolute;
    padding-top: 0;
    text-align: center;
    left: 0;
    background-color: rgba(255,255,255,0.7);
    color: #000;
    height: 100%;
    width: 100%;
    z-index: 999;
    font-weight: bold;
}

.search-all {
    h4 {
        margin-top: 18px;
        display: block;
    }

    h4:first-of-type {
        margin-top: 0;
    }

    #searchAllError {
        position: absolute;
        bottom: -20px;
        color: $color-error;
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.search-all {
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		h4 {
		    margin-left: 15px;
		}

		#search-row {
		    margin-left: 0;
		    margin-right: 0;

		    input[type="text"] {
		        float: none;
		    }

		    button {
		        width: 100%;
		        margin-top: 20px;
		    }
		}

        .noresults {
            margin-left: 15px;
        }
	}
}
