@import "../variables";

.not-found {
	text-align: center;
	margin-top: 40px;

	.not-found-message {
		margin-top: 20px;
	}

	.not-found-button {
		margin-top: 30px;
	}
}

#add-keyword, #add-cpn{
	#keyword-text, #cpn-text, #cpn-description{
		width:100%;
	}

	a.button-emphasis{
		display: inline-block;
	}
}

.product-detail {
	padding-top: 30px;

	.breadcrumbs {
		padding-left: 15px;
    	padding-right: 15px;
    	max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}

	.line-item {
	    padding-top: 0;
	    padding-bottom: 0;
	    border-bottom: none;
	}

	.product-detail-top {
		margin-top: 50px;
		padding-left: 15px;
    	padding-right: 15px;
    	max-width: 1440px;
		margin-left: auto;
		margin-right: auto;

		h1.product-short-description {
			> img {
				max-height: 49px;
				margin-right: 8px;
			} 
		}

		h1.product-short-description-solar {
			font-size: 30px;
		}

		.product-detail-image {
			text-align: center;
			padding-left: 0;
			padding-right: 0;

			img {
				max-width: 350px;
				max-height: 350px;
			}

			.product-overview {
				margin: 15px 0 0 0;
				text-align: left;
			}
		}

		.product-detail-price-availability {
			.product-detail-price {
				float: left;

				.price {
					font-size: 36px;
					font-weight: bold;
					line-height: 1.1;
				}

				.price-solar {
					font-size: 24px;
					font-weight: bold;
					line-height: 1.1;
				}

				.uom {
					font-size: 16px;
					line-height: 1.6;
				}
			}

			.product-detail-availability {
				float: right;
				padding-top: 15px;

				.in-stock, .out-of-stock, .non-stock {
					font-size: 16px;
					line-height: 1.6;
				}
			}
		}

		.product-quantity {
            width: 45px;
            height: 40px;
		}

		hr {
			height: 1px;
			background-color: #b9bbc0;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		.product-detail-buttons {
			button, input[type="submit"]{
				margin-right: 15px;
				margin-bottom: 15px;
			}

			button:last-of-type {
				margin-right: 0;
			}
		}

		.product-detail-buttons-solar {
			margin-top: 45px;
		}
	}

	.product-detail-bottom {
		margin-top: 45px;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;

		.product-detail-info-row {
			padding: 10px 0;
			font-size: 15px;
			line-height: 1.7;
			margin-left: 15px;
			margin-right: 15px;

			> div {
				padding-left: 0;
			}

		}

		.product-detail-document-row {
			padding: 10px 0 0 0;
			font-size: 15px;
			line-height: 1.7;
			margin-left: 15px;
			margin-right: 15px;			

			> div {
				padding-left: 0;

				a {
					display: block;
					padding-bottom: 10px;
				}
			}

			.product-detail-document-left a {
				margin-right: 15px;
			}

		}

		.product-detail-info-row:last-of-type {
			border-bottom-width: 0;
		}

		.product-detail-document-row:last-of-type .product-detail-document a {
			border-bottom-width: 0;
		}

		.product-detail-document-icon {
			margin-right: 3px;
		}
	}

	.solar-details{
    	padding: 0px 16px;
    	margin: auto;

    	.product-detail-bottom{
	    	margin-top: 0;
	    }
    }

	.kit-list{
		margin-left: 15px;
	}

	.kit-list li:nth-of-type(n + 5){
		display: none;
	}

	.show-more-kit{
		display: none;
	}

	#imageDisplay video{
		width: 100%;
	}

	#imageSelector{
		flex-wrap: wrap;
		margin-top: 10px;
		flex-shrink: 0;
	}

	#imageDisplayWrapper{
		position: relative;
		padding-left: 12px;
		padding-right: 12px;


		a.slider-left:hover, a.slider-right:hover, a.slider-left:focus, a.slider-right:focus{
			color: #fff;
		}
	}

	.slider-left, .slider-right{
		position: absolute;
		top: 50%;
		top: calc(50% - 20px);

		width: 40px;
		height: 40px;
		border-radius: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}

	.slider-left{
		left: 15px;
	}

	.slider-right{
		right: 15px;
	}

	#imageSelector{
		display: flex;
		justify-content: center;
		gap: 12px;
	}

	#imageSelector img, #imageSelector div{
		height: 56px;
		width: 56px;
		overflow: hidden;
		display: inline-block;
		border: 1px solid #b9bbc0;
	}

	#imageSelector div{
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
	}
}

body.solar-template .product-detail{
	.line-item{
		border: none;
	}

	.product-detail-top .product-detail-price-availability .product-detail-price{
		float: none;
	}

	.product-detail-top .product-detail-price-availability .product-detail-availability{
		float: none;
		padding: 0
	}

	.solar-quick-info div{
		display: inline-block;
	}
		
	.border-right {
		border-right: 1px solid;
		padding-right: 4px;
	}
	.border-left {
		border-left: 1px solid;
		margin-left: 4px;
	}

	.accordion-container.closed .accordion-content{
    	display:block;
    }
}

.spinner-qty {
	-moz-appearance:textfield;
	border:1px solid gray;
	border-radius:3px;
	padding:4px 1em;
	text-align:center;
	width:calc(100px - 2em);
}
.spinner-qty:focus,
.spinner-qty:hover {
	appearance: textfield;
}
.spinner-qty::-webkit-outer-spin-button,
.spinner-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media only screen and (min-width: $desktop-min-width) {
	.product-detail {

		.product-detail-image{
			display: flex;
		}

		#imageDisplay, #videoDisplay{
			order: 1;
			flex-grow: 1;
		}

		#imageSelector{
			width: 70px;
			margin-top: 0;
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.not-found {
		margin-top: 30px;
	}

	.product-detail {
		padding-top: 20px;

		.breadcrumbs {
			padding-left: 18px;
    		padding-right: 18px;
		}

		.product-detail-top {
			margin-left: 0;
			margin-right: 0;
			margin-top: 30px;

			.product-detail-image {

				img {
					max-width: 256px;
					max-height: 256px;
				}
			}

			.product-detail-price-availability {
				margin-top: 20px;
			}

			hr {
				margin-top: 20px;
				margin-bottom: 25px;
			}
		}
	}

	body.solar-template .product-detail .accordion-container.closed .accordion-content{
    	display:block;
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.not-found {
		margin-top: 30px;
	}

	.breadcrumbs{
    	display: none;
    }
	
	.product-detail {
		padding-top: 20px;

		.mobile-title {
			padding-left: 15px;
			padding-right: 15px;
		}

		.product-detail-top {
			margin-top: 20px;
			margin-left: 0;
			margin-right: 0;

			h1.product-short-description {
				font-size: 40px;

				> img {
					max-height: 37px;
				} 
			}

			h1.product-short-description-solar {
				font-size: 30px;
			}

			> div {
				padding-left: 0;
				padding-right: 0;
			}

			.quantity-section {
				padding-top: 45px;
				text-align: right;
			}

			.line-item {
			    padding-top: 0;
			    padding-bottom: 0;
			    border-bottom: none;
			}

			.product-quantity {
				width: 40px;
				padding-left: 0;
				padding-right: 0;
			}

			.product-detail-image {
				text-align: center;

				img {
					max-width: 290px;
					max-height: 290px;
				}
			}

			.product-detail-price-availability {
				.product-detail-price {
					float: none;

					.price {
						font-size: 27px;
						font-weight: bold;
						line-height: 1.3;
					}

					.price-solar {
						font-size: 24px;
						font-weight: bold;
						line-height: 1.3;
					}

					.uom {
						font-size: 15px;
						line-height: 1.7;
					}
				}

				.product-detail-availability {
					float: none;
					padding-top: 0;

					.in-stock, .out-of-stock, .non-stock {
						font-size: 13px;
						line-height: 1.5;
					}
				}
			}

			.add-to-cart-individual-improved {
				margin-top: 20px;
			}

			hr {
				height: 1px;
				background-color: #b9bbc0;
				margin-top: 25px;
				margin-bottom: 15px;
			}

			.product-detail-buttons {
				text-align: center;
				padding-left: 15px;
				padding-right: 15px;

				button, input[type="submit"] {
					display: block;
					width: 100%;
				}

				.myCatalogLinksSection {
					ul {
						li {
							text-align: left;
						}
					}
				}
			}
		}

		.product-detail-bottom {
			margin-top: 40px;
		}



	}	

	body.solar-template{
		

	    .product-detail {
	    	.tab-content>.tab-pane{
		    	display:block;
		    	border-radius: 2px;
				border: solid 1px #d5d8d9;
				margin-bottom: 4px;
		    }

		    .breadcrumbs{
		    	display: block;
		    }
	    	
		    .accordion-container .accordion-title a::after{
		    	content: "\f068";
		    }

		    .accordion-container.closed .accordion-title a::after{
		    	content: "\f067";
		    }

		    .accordion-container .accordion-title{
		    	box-shadow: none;
		    }

		    .product-detail-top .quantity-section{
		    	text-align: left;
		    	padding-top: 0;
		    }
		}

		.solar-details{
		    .product-detail-bottom{
		    	margin-top: 20px;
		    }

		    .accordion-container .accordion-title, .accordion-container.closed .accordion-title{
		    	background-color: transparent;
		    }

		    .accordion-container.closed .accordion-content{
		    	display:none;
		    }
		}
	}
}