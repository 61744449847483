.greentech-purchasing {
    margin-left: 10px;
    margin-bottom: 30px;

    .purchasing-heading {
        color: rgb(33, 33, 33);
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
        position: relative;
        line-height: 1;
        margin-bottom: 8px;
    }

    .my-catalogs .my-catalogs-right-content .my-catalogs-items .my-catalogs-item-row .description-col .description-line .button-section a.spec-sheet-help-section,
    .my-catalogs .my-catalogs-right-content form .my-catalogs-items .my-catalogs-item-row .description-col .attribute-line .button-section a.spec-sheet-help-section {
        background: #fff;
        border: 1px solid rgb(28, 130, 28);
    }

    .home-right-content {
        float: right;
        width: 256px;
        border: 1px solid rgb(213, 216, 217);
        border-radius: 6px;
        padding: 16px;

        .order-summary {
            color: rgb(33, 33, 33);
            font-size: 24px; 
            font-weight: normal;
            letter-spacing: 0.23px;
            line-height: 28px;
        }

        .order-summary-details {
            .order-summary-line {
                display: flex;
                border-bottom: 1px solid rgb(213, 216, 217);
                align-items: center;

                .order-summary-line-label {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    width: 50%;
                }

                .order-summary-line-value {
                    text-align: right;
                    width: 50%;
                }
            }
        }

        .project-cart-link {
            margin-top: 16px; 

            .project-cart-button {
                width: 100%;
                display: block;
                text-align: center;
            }
            .project-cart-button:hover {
                color: #fff;
                text-decoration: none;
            }
            .project-cart-button:focus {
                color: #fff;
                text-decoration: none;
                background: #3DB05D;
            }
        }           
    }

    .name-heading {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        padding-top: 20px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 5px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }
    }

    hr {
        padding-bottom: 5px;
    }

    .purchasing-description {
        width: 557px;
        height: 40px;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        line-height: 20px;
    }

    .name {
        padding-left: 10px;
        color: rgb(106, 107, 109);
    }

    .purchasing-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .purchasing-content {
        width: 100%;
    }

    .error-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(182, 79, 53, 0.15);
        border-radius: 3px;
        border: 1px solid rgb(182, 79, 53);
        padding: 10px; 
        color: rgb(33, 33, 33);  
        margin-bottom: 24px;
        margin-top: 24px;

        i.fa-exclamation-circle {
            width: 20px;
        }

        .message-content {
            flex-grow: 1;

            a {
                color: rgb(33, 33, 33);
                text-decoration: underline;
            }
        }

        .fa-exclamation-circle {
            color: rgb(182, 79, 53);
        }
    
        .fa-times {
            color: rgb(33, 33, 33);
        }
    }

    .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(88, 152, 21, 0.15);
        border-radius: 3px;
        border: 1px solid rgb(90, 177, 118);
        padding: 10px; 
        color: rgb(33, 33, 33);  
        margin-bottom: 24px;
        margin-top: 24px;

        i.fa-check-circle {
            width: 20px;
        }

        .message-content {
            flex-grow: 1;

            a {
                color: rgb(33, 33, 33);
                text-decoration: underline;
            }
        }

        .fa-check-circle {
            color: #589815;
        }
    
        .fa-times {
            color: rgb(33, 33, 33);
        }
    } 
    
    .purchasing-search {
        margin-top: 24px;
        margin-bottom: 24px;

        #search-wrapper {
            max-width: 557px;
            position: relative;

            label {
                color: rgb(33, 33, 33);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.13px;
                line-height: 20px;
            }

            #purchasingSearch {
                width: 100%;
                border: 1px solid rgb(149, 158, 160);
                border-radius: 3px;
                padding: 8px 32px 8px 12px;
                background-color: #fff;
            }

            #purchasingSearch::placeholder {
                color: rgb(149, 158, 160);
            }

            #search-input-icon {
                position: absolute;
                width: 20px;
                height: 20px;
                right: 2px;
                top: 33px;
                color: rgb(149, 158, 160);
                background-color: #fff;    
            }

            .autocomplete-suggestions {
                width: 100% !important;
                margin-left: 0;
                margin-top: 3px;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 8px;
                padding-top: 8px;

                .add-product-processing {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    background: rgba(255,255,255,.7);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 1px;
                    border-radius: 6px;

                    span {
                        margin-left: 8px;
                    }
                }

                .product-container {
                    max-height: 360px;
                    overflow: auto;

                    .more-text {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        padding-bottom: 12px;
                        padding-top: 12px;
                    }

                    .product-row {
                        display: flex;
                        align-items: flex-start;
                        border-bottom: 1px solid rgb(213, 216, 217);
                        padding-bottom: 7px;
                        padding-top: 7px;

                        .image-container {
                            width: 48px;
                            text-align: center;

                            img {
                                max-width: 48px;
                                max-height: 48px;
                            }
                        }

                        .content {
                            padding-left: 5px;
                            flex-grow: 1;

                            .product-name {
                                color: rgb(33, 33, 33);
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                                margin-bottom: 4px;
                            }

                            .product-sub-detail {
                                color: rgb(106, 107, 109);
                                font-size: 12px;
                                font-weight: normal;
                                letter-spacing: 0.11px;
                                line-height: 20px;

                                span {
                                    border-left: 1px solid rgb(213, 216, 217);
                                    padding-left: 8px;
                                    margin-left: 8px;
                                }
                            }
                        }

                        .selector {
                            width: 40px;
                            margin-right: 10px;

                            button {
                                border: 1px solid rgb(28, 130, 28);
                                background: #fff;
                                padding: 12px 10px 8px 10px;
                                width: 40px;

                                i {
                                    color: rgb(28, 130, 28);
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .autocomplete-suggestions:before, .autocomplete-suggestions:after {
                content: none;
            }
        }

    }

    .bom-heading {
        padding: 50px 0 15px 0;
        color: rgb(33, 33, 33);
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 0.23px;
        line-height: 28px;
        max-width: 847px;

        .add-to-project-cart {
            float: right;
        }
    }

    .add-cart-button-container {
        position: relative;
        float: right;

        .added-message {
            position: absolute;
            left: -46px;
            top: 14px;
            display: none;

            i {
                color: rgb(30, 129, 67);
            }
        }
    }

    .cat-number {
        padding-bottom: 20px;
    }

    .my-catalogs {
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 847px;

        .my-catalogs-right-content {
            margin-left: 0px !important;
        }
    }

    .add-to-cart-button {
        margin-right: 20px;
        float: right;
        height: 40px;
        width: 134px;
    }
    
    .quantity-spinner {
        float: right;
        width: 125px;
        margin-right: 23px;
    }

    .custom-attribute-line {
        padding-left: 110px !important;
    }

    .custom-button-section {
        margin-top: 20px;
        padding-left: 110px;
    }
    
    .custom-product-quantity {
        padding-top: 0px !important;
    }
    .price-quantity {
        width: 150px;

        .price-col {
            float: right;
            margin-right: 25px;
        }
    }

    .quantity-spinner {
        padding: 10px;        

        .spinner-qty {
            height: 30px;
            width: 50px;
        }

        .small-circle-button {
            height: 20px;
            width: 20px;
            padding: 0;
        }
    }

    .sell-price {
        color: rgb(33, 33, 33);
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.17px;
        line-height: 28px;
    }
}

@media only screen and (max-width: $tablet-max-width) and (min-width: $tablet-min-width) {
    .greentech-purchasing {
        margin-left: 10px;

        .breadcrumbs {
            padding-left: 0px;
        }
        
        .purchasing-layout {
            .purchasing-content {
                margin-right: 16px;
            }

            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }

        .purchasing-content {
            padding-right: 0;
        }

        .purchasing-search {
            padding: 40px 0 20px 0;

            #search-wrapper {
                max-width: 343px;

                #purchasingSearch {
                    width: 343px;
                }
            }
        }

        .bom-heading {
            max-width: 672px;
        }

        .my-catalogs {
            width: 672px;
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    .greentech-purchasing {
        margin-left: 16px;
        margin-right: 16px;

        .purchasing-heading {
            .heading-text {
                display: block;
            }
            
        }

        .my-catalogs .my-catalogs-right-content form .my-catalogs-items .my-catalogs-item-row .description-col {
            padding-right: 0;
        }
        .my-catalogs .my-catalogs-right-content form .my-catalogs-items .my-catalogs-item-row .description-col .attribute-line .button-section a {
            margin-right: 8px;
        }        

        .home-left-content {
            margin-top: 24px;
        }

        .add-cart-button-container {
            width: 100%;
            .added-message {
                left: -66px;
            }

            .add-to-cart-button {
                width: 100%;
                margin-right: 0;
            }
        }

        .home-right-content {
            width: 100%;
            float:none;
            margin-top: 20px;
        }

        .purchasing-content {
            .purchasing-heading {
                margin-bottom: 8px;
            } 
            
            .import-description {
                width: 300px;
                height: 38px;
                color: rgb(33, 33, 33);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.13px;
            }
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .purchasing-description {
            width: 343px;
            height: 60px;
        }

        .purchasing-search {
            #search-wrapper {
                #purchasingSearch {
                    font-size: 16px;  /*this is needed for ios to prevent zoom on focus*/
                }

                #search-input-icon {                   
                    top: 35px; 
                }
            }
        }

        .bom-heading {
            padding-top: 30px;
            max-width: 343;
        }

        .my-catalogs {
            width: auto;
        }

        .add-to-project-cart-all {
            width: 310px;
            margin-left: 15px;
        }

        .add-to-project-cart {
            float: none !important;
        }

        .quantity-spinner-mobile {
            float: none !important;
            padding-left: 0px !important;
            margin-top: 10px;
        }

        .add-to-cart-button {
            float: none !important;
            margin-left: 0px !important;
        }

        .button-section-mobile {
            padding-left: 110px;
        }

        .price-quantity {
            padding-left: 115px;
        }   
        
        .attribute-line-mobile {
            padding-bottom: 20px;
            margin-left: 110px;
        }

        .attribute-line{
            padding-left: 0px !important;
        }
    }
}