@import "../variables";

.product-landing {
    margin: 20px;

    .category-breadcrumbs {
        li a {
            padding-left: 2px;
        }
    }

    .cat-container {
        padding-right: 0;
        margin-bottom: 16px;
    }

    .product-landing-breadcrumb {
        margin-top: 5px;
    }

    h2 {
        padding-top: 10px;
    }

    .category-area {
        margin-top: 25px;

        div.top-cat {
            height: 100%;
            padding: 0;
            margin: 0 0 16px 0;
            border: 1px solid #d3d3d3;
            border-radius: 3px;

            .cat-wrapper {
                margin: 0;
                padding: 10px;

                .cat-img-wrapper {
                    padding: 0;

                    .cat-img {
                        max-width: 50px;
                        height: 50px
                    }
                }

                .cat-label-wrapper {
                    h3 {
                        font-size: 24px;
                        padding: 10px 0;
                    }
        
                    .sub-cat-container {
                        margin-top: 16px;
        
                        div.sub-cat {
                            font-size: 14px;
                            padding: 3px 16px;
            
                        }
                    }
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .product-landing {
        .cat-container {
            padding-right: 16px;
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .product-landing {
        .cat-container {
            padding-right: 16px;
        }

        .category-area {
            div.top-cat {
                .cat-wrapper {
                    .cat-img-wrapper {
                        .cat-img {
                            max-width: 36px;
                            height: 36px
                        }
                    }
                }
            }
        }
    }
}