.greentechServiceOrderNew {

    .field-label.required {
        position: relative;
       }

    .required::after {
        content: "*";
        color: #b64f35;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.7;
    }
    .helper-icon {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        position: absolute;
    }
    .helper-icon .helper-icon-text,
    .helper-icon .helper-icon-existingsystem-text,
    .helper-icon .helper-icon-engineering-text,
    .helper-icon .helper-icon-notes-text,
    .helper-icon .helper-icon-ac-text {
        display: none;
        position: absolute;
        bottom: 100%;
        background-color: #555;
        color: #fff;
        text-align: left;
        padding: 4px 8px;
        border-radius: 6px;
        width: 320px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
        font-size: 12px;
        z-index: 1000;
    }
    .helper-icon:hover .helper-icon-existingsystem-text,
    .helper-icon:hover .helper-icon-engineering-text,
    .helper-icon:hover .helper-icon-ac-text,
    .helper-icon:hover .helper-icon-notes-text,
    .helper-icon:hover .helper-icon-text {
        display: block;
        opacity: 1;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }

        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    .service-order-new-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }

        .service-order-new-content {
            width: 100%;

            .plansets-home {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;
                padding-top: 20px;

                a {
                    padding: 15px 15px 15px 0;
                }
            }

            .dashboard-heading {
                color: rgb(33, 33, 33);
                font-size: 32px;
                font-weight: normal;
                letter-spacing: 0.3px;
            }

            .name-heading {
                color: rgb(106, 107, 109);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.13px;
                padding-top: 20px;
            }

            hr {
                padding-bottom: 5px;
            }

            .warning-message, .danger-message {
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                align-items: stretch;
                -ms-flex-align: stretch;

                border-radius: 3px;
                padding-top: 10px;
                padding-right: 10px;
                margin-bottom: 9px;

                .status {
                    flex: 1;
                }

                .icon {
                    padding-top: 3px;
                    padding-left: 10px;
                    padding-right: 10px;
                    flex: 0;
                }
            }

            .warning-message {
                border: 1px solid $status-warning-2;
                background: $status-warning-3;

                .icon {
                    svg {
                        fill: $status-warning-2;
                    }
                }
            }

            .danger-message {
                border: 1px solid $status-danger-2;
                background: $status-danger-3;

                .icon {
                    svg {
                        stroke: $status-danger-2;
                    }
                }
            }

            .service-order-entry {
                max-width: 557px;
                width: 557px;

                #addDocumentModal {
                    .modal-content {
                        border-radius: 0;
                    }
            
                    #upload-status {
                        display: none;
                        margin-right: 10px;
                    }
                }

                .document-section {
                    .add-document-button {
                        border-radius: 3px;
                        margin: 10px 0;
                        padding: 10px 16px 11px 11px;
        
                        i {
                            margin-right: 6px;
                        }
                    }

                    .added-documents-text {
                        margin: 18px 0 8px 0;
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                    }
        
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
        
                        li {
                            width: 100%;
                            padding: 5px;
                            margin: 5px 0;
                            border-radius: 3px;
                            border: 1px solid rgb(213, 216, 217);
        
                            .delete {
                                text-align: right;
                            }
        
                            .delete-document {
                                cursor: pointer;
                            }

                            a {
                                display: block;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .service-order-new-description {
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    margin-top: 15px;
                }

                .save-button {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.13px;
                    line-height: 16px;
                    margin: 20px 0 20px 0;
                }

                .grid.radio-grid {
                    display: grid;
                    padding: 0;

                    .type-card {
                        label {
                            color: rgb(43, 61, 65);
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                            display: inline;
                        }
                    }
                }

                input[type="radio"] {
                    display: inline-block;
                }

                .field-label {
                    margin-top: 15px;
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }

                .services-heading, .system-information-heading {
                    margin-top: 25px;
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                    font-weight: normal;
                    letter-spacing: 0.23px;
                    line-height: 28px;
                }

                .card {
                    border: 1px solid rgb(213, 216, 217);
                    border-radius: 6px;
                    padding: 9px 15px;

                    .card-label {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                    }

                    .card-label-quiet {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                    }
                }

                label.checkbox-label {
                    color: rgb(43, 61, 65);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    margin-bottom: 0;

                    &.other {
                        display: inline;
                    }

                    input[type="checkbox"] {
                        margin-right: 10px;
                    }
                }

                .add-space {
                    margin-top: 10px;
                }

                .field-required-message {
                    color: rgb(182, 79, 53);
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 0.11px;
                    line-height: 20px;
                }

                input.invalid {
                    border-color: rgb(182, 79, 53) !important;
                }

                .notes {
                    width: 100%;
                    height: 125px;
                    border: 1px solid rgb(149, 158, 160);
                    border-radius: 3px;
                }
            }
        }
    }
}

@media only screen and (max-width: $greentech-tablet-max-width) and (min-width: $greentech-tablet-min-width) {
    .greentechServiceOrderNew {
        margin-left: 10px;
        padding-right: 16px;

        .breadcrumbs {
            padding-left: 0px;
        }

        .service-order-new-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }

            .service-order-new-content {

                .service-order-entry {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    .greentechServiceOrderNew {
        margin: 0 10px 0 10px;
        padding-right: 12px;

        .breadcrumbs {
            padding-left: 0px;
        }

        .service-order-new-layout {

            .service-order-new-content {

                .service-order-entry {
                    max-width: 100%;
                    width: 100%;

                    .greentech-numeric-entry {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .greentechServiceOrderNew .helper-icon .helper-icon-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-engineering-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-ac-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-notes-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-existingsystem-text {
        width: 250px;
    }
}
@media only screen and (max-width: 480px){
    .greentechServiceOrderNew .helper-icon .helper-icon-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-ac-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-notes-text,
    .greentechServiceOrderNew .helper-icon .helper-icon-engineering-text {
        width: 150px;
    }
    .greentechServiceOrderNew .helper-icon .helper-icon-existingsystem-text {
        transform: translateX(-32%);
        width: 150px;
    }
}
@media only screen and (max-width: 400px){
    .greentechServiceOrderNew .helper-icon .helper-icon-text {
        width: 150px;
    }
    .greentechServiceOrderNew .helper-icon .helper-icon-existingsystem-text {
        transform: translateX(-80%);
    }
    .greentechServiceOrderNew .helper-icon .helper-icon-engineering-text {
        transform: translateX(-30%);
    }
    .greentechServiceOrderNew .helper-icon .helper-icon-ac-text {
        transform: translateX(-15%);
    }
    .greentechServiceOrderNew .helper-icon .helper-icon-notes-text {
        transform: translateX(-45%);
    }
}
