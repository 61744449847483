@import "../variables";

.home-full-content.manufacturers {
	margin-left: 15px;
}

.home-right-content.manufacturers {
	margin-left: 30px;
}

.manufacturers {
	margin-top: 40px;

	ul.key {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-left: 0;
			margin-right: 7px;
			margin-top: 10px;

			a {
				display: block;
				border-radius: 3px;
				padding: 7px 11px;
				font-weight: bold;
				font-size: 13px;
				line-height: 1.5;
			}

			a:hover {
				color: #fff;
				text-decoration: none;				
			}
		}
	}

	.key-row {
		margin-top: 50px;

		.key {
			font-size: 23px;
			line-height: 1.3;			
		}
	}

	.value-row {
		a {
			font-size: 16px;
			line-height: 1.6;
			padding-left: 36px;
    		position: relative;
		}

		a:before {
			font-family: 'FontAwesome';
		    font-size: 5px;
		    content: "\f111";
		    position: absolute;
		    top: 9px;
			left: 12px;	
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.home-right-content.manufacturers {
		margin-left: 15px;
	}

	.manufacturers-left-content {
		#my-links-left-container {
			margin-top: 30px;
		}
	}
	
	.manufacturers {
		margin-top: 30px;
		margin-left: 15px;

		.key-row {
			.key {
				font-size: 22px;
				line-height: 1.4;			
			}
		}

		.value-row {
			a {
				font-size: 15px;
				line-height: 1.7;
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.home-right-content.manufacturers {
		margin-left: 15px;
	}

	.manufacturers {
		margin-top: 30px;
		margin-left: 15px;
		margin-right: 15px;

		.key-row {
			.key {
				font-size: 19px;
				line-height: 1.6;			
			}
		}

		.value-row {
			a {
				font-size: 15px;
				line-height: 1.7;
			}
		}
	}
}