.greensightPortalApplication {
    margin-bottom: 15px;

    .marketDetailTable {
        input {
            width:100%;
        }

        th[scope=col] {
            font-size: medium;
            text-align: center;
        }

        th[scope=row] {
            font-size: small;
            vertical-align: middle;
        }
    }

    .rich-panelbar-header-act.market-header{
        font-size: 16px;
        font-weight: bold;
        border-top: 1px solid rgba(0, 0, 0, .125);
        padding: 0.25rem 0.75rem;
    }

    .rich-panelbar-content-exterior {
        overflow: hidden;
    }

    .rich-panelbar-content .row {
        margin-left: 0px;
        margin-right: 0px;

    }

    .rich-panelbar-header.market-header {
        font-size: 16px;
        font-weight: bold;
        border-top: 1px solid rgba(0, 0, 0, .125);
        padding: 0.25rem 0.75rem;
    }
    .rich-panelbar-header.market-header:hover {
        cursor: pointer;
        background-color: #efecec;
        background-image: linear-gradient(#f7f7f7, white);
    }

    .custPopup {
        background-color: white;
        z-index: 9999;
        left: 25%;
        padding:10px;
        position: absolute;
        margin: 0 auto;
        top: 25%;
        opacity: 1;
    }
    .popupBackground {
        background-color:black;
        opacity: 0.20;
        filter: alpha(opacity = 20);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9998;
    }

    .okay-button {
        border-radius: 0px;
        height: 40px;
        width: 100px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.13px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        justify-content: right;
    }

    .accuracy-error {
        display: inline-block;
        margin-bottom: 10px;
        color: rgb(182, 79, 53);
        font-size: 12px;
        font-weight: normal;
        height: 20px;
        letter-spacing: 0.11px;
        line-height: 20px;
    }

    .close-modal-x {
        border: 0px;
        background-color: white;
    }

    .notifications {
        .error-row {
            margin-top: 10px;

            .top-error {
                border-radius: 3px; 
                line-height: 1.2;
                padding: 6px 10px;
                color: rgb(182, 79, 53);
                background-color: rgba(182, 79, 53, 0.15);
                border: 1px solid rgb(182, 79, 53);

                .message-text {
                    padding-left: 10px;
                    color: #000;
                }

                &.alert {
                    margin-bottom: 0;
                }
            }
        }

        .success-row {
            margin-top: 10px; 

            .top-success {
                border-radius: 3px; 
                line-height: 1.2;
                padding: 6px 10px;
                color: rgb(90, 177, 118);
                background-color: rgba(88, 152, 21, 0.15);
                border: 1px solid rgb(90, 177, 118);

                .message-text {
                    padding-left: 10px;
                    color: #000;
                }

                &.alert {
                    margin-bottom: 0;
                }
            }
        }
    }

    .section-heading {
        margin-top: 15px;
    }

    .notification-panel {
        display: none;

        .error-row {
            display: none;
        }

        .success-row {
            display: none;
        }
    }

    #addDocumentModal {
        .modal-content {
            border-radius: 0;
        }

        #upload-status {
            display: none;
            margin-right: 10px;
        }

        .license-fields {
            margin-bottom: 10px;
        }

        .contain-message {
            padding-top: 20px;
        }

        #contractorsLicenseFields, #insuranceDocumentFields {
            margin-top: 10px;
        }

        #licenseFieldsError {
            color: red;
            margin-top: 5px;
        }
    }

    #newMarketDetailModal {
        #addMarketError {
            color: red;
        }
    }
    
    .admin-right-content {
        .section {
            h2 {
                margin-left: 16px;
            }

            &.last {
                border-bottom: 1px solid #b9bbc0;
            }

            border-top: 1px solid #b9bbc0;
            padding: 10px 0;

            .section-head {
                padding-top: 12px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;

                .section-title {
                    font-size: 22px;
                    line-height: 30px;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;

                    h2 {
                        font-size: 22px;
                        margin: 0;
                    }

                    .section-number {
                        border: 2px solid #212121;
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        font-size: 16px;
                        line-height: 21px;
                        text-align: center;
                        vertical-align: top;
                        display: inline-block;
                        margin-top: 2px;
                    }

                    .section-number-complete {
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        font-size: 30px;
                        line-height: 21px;
                        text-align: center;
                        vertical-align: top;
                        display: inline-block;
                        margin-top: 2px;
                    }

                    .section-name {
                        padding-right: 10px;
                    }

                    :hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .back-link {
            padding-left: 10px;
            height: 20px;
            font-size: 14px;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        .title-heading-line-2 {
            height: 40px;
            color: rgb(33, 33, 33);
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
            margin-top: 10px;
        }

        .title-heading-line-3 {
            height: 20px;
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
            margin-top: 10px;
        }

        .status-badge {
            display: inline-block;
            vertical-align: top;
            margin-top: 12px;
            height: 14px;
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 14px;
        }

        .status {
            display: inline-block;
        }

        .red-icon {
            color: #b64f35;
        }

        .green-icon {
            color: #589815;
        }

        .yellow-icon {
            color: #bf9000;
        }

        .yellow-background {
            background: rgb(191, 144, 0, 0.2);
            border-radius: 12px;
            height: 24px;
            padding: 5px;
        }

        .grey-background {
            background: rgb(248, 249, 249);
            border-radius: 12px;
            height: 24px;
            padding: 5px;
        }

        .red-background {
            background: rgba(182, 79, 53, 0.15);
            border-radius: 12px;
            height: 24px;
            padding: 5px;
        }

        .green-background {
            background: rgba(88, 152, 21, 0.15);
            border-radius: 12px;
            height: 24px;
            padding: 5px;
        }

        .return-notes {
            margin-left: 15px;
            color: #000;
        }

        .warning-summary {
            color: #000;
        }

        .top-warn {
            background: rgb(191, 144, 0, 0.2);
        }

        .information-basic-summary,
        .information-business-summary,
        .information-bank-summary,
        .information-documents-summary {
            margin-left: 28px;
            font-size: 12px;
            background: rgb(248, 249, 249);
            
            .incomplete {
                color: rgb(182, 79, 53);
                font-weight: bold;
            }

            .not-applicable {
                color:rgb(211, 211, 211);
                font-weight: bold;
            }

            .incomplete-summary-icon {
                margin-left: 20px;
                color: rgb(182, 79, 53);
            }

            #incomplete-header {
                color: rgb(182, 79, 53);
                font-weight: bold;
                font-size: 13px;
                margin-left: 2px;
            }

            #complete-header {
                font-size: 13px;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    display: block;

                    .field-label {
                        font-weight: bold;
                        margin-right: 8px;
                    }
                }
            }

            &.collapsible-section {
                border: none;
                margin-top: 0;

                .collapsible-header {
                    padding: 5px;
                    background: rgb(248, 249, 249);
                }

                .collapsible-header:hover {
                    background-color: rgb(248, 249, 249);
                    cursor: pointer;
                    color: #8c8d8f;
                }

                .collapsible-header::before {
                    font-family: 'FontAwesome';
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    content: "\f106";
                    font-size: 18px;
                    color: #8c8d8f;
                }

                .collapsible-header::after {
                    content: "";
                }

                &.incomplete-section {
                    border: 1px solid rgb(182, 79, 53);
                }

                &.closed {
                    .collapsible-header::before {
                        content: "\f107";
                        color: #8c8d8f;
                    }
                    
                    .collapsible-header::after {
                        content: "";
                    }

                    .collapsible-header:hover {
                        background-color: rgb(248, 249, 249);
                        cursor: pointer;
                        color: #8c8d8f;
                    }
                }

                .collapsible-content {
                    border: none;
                }
            }
        }

        .information-business-summary {
            .sub-section {
                display: inline-block;
                padding-bottom: 5px;
                font-size: 14px;
                font-weight: bold;
            }

            .tr-section {
                padding-top: 15px;
            }
        }

        .application-form {
            .application-submit-button {
                margin-bottom: 10px;
            }

            h3 {
                height: 28px;
                color: rgb(33, 33, 33);
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.17px;
                line-height: 28px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            label {
                display: block;
                margin-top: 10px;
                height: 20px;
                color: rgb(33, 33, 33);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.13px;
                line-height: 20px;

                &.federal-tax-id {
                    cursor: pointer;
                }
            }

            .signed {
                border-color: #b9bbc0;
            }

            .unsigned {
                border-color: #b64f35;
            }

            .checkbox-label {
                font-weight: bold;
            }

            .state-wrapper {
                padding-left: 0;
                padding-right: 0;
            }

            .application-button-message {
                margin-bottom: 10px;

                .application-save-button {
                    display: inline-block;
                    border-radius: 0;
                    margin-bottom: 10px;
                }

                .message {
                    padding-left: 5px;
                }
            }

            .greensightDocumentsTableEmpty {
                td {
                    text-align: center;
                }
            }

            .submit-button {
                border-radius: 0px;
                height: 40px;
                width: 193px;
                text-transform: uppercase;
            }

            .accuracy-signed {
                border-radius: 6px;
                border: 1px solid rgb(149, 158, 160);
                padding: 10px;
                margin: 20px 0;

                .label-accuracy {
                    margin: 5px;
                }
            }

            .accuracy-not-signed {
                border-radius: 6px;
                border: 1px solid #b64f35;
                padding: 10px;
                margin: 20px 0;

                .label-accuracy {
                    margin: 5px;
                }
            }
        }

        .application-continue-section {
            margin-bottom: 10px;

            .application-continue-button {
                display: inline-block;
                border-radius: 0;
            }
        }

        .document-section {
            border: 1px solid rgb(213, 216, 217);
            border-radius: 5px;
            padding: 15px;

            .document-section-complete {
                font-size: 22px;
            }

            .add-document-button {
                border-radius: 3px;
                margin: 10px 0;
                padding: 10px 16px 11px 11px;

                i {
                    margin-right: 6px;
                }
            }

            .added-documents-text {
                margin: 18px 0 8px 0;
                color: rgb(33, 33, 33);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.13px;
                line-height: 20px;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    width: 100%;
                    padding: 5px;
                    margin: 5px 0;
                    border-radius: 3px;
                    border: 1px solid rgb(213, 216, 217);

                    .delete {
                        text-align: right;
                    }

                    .delete-document {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    input[type='file'] {
        display: inline-block;
    }

    #fileSizeError, #invalidCharactersError {
        display: none;
        margin-top: 5px;
        color: red;
    }
}

/* sm size (tablet) */
@media only screen and (max-width: $tablet-max-width) {
    .greensightPortalApplication {
        .admin-right-content {
            .section {
                padding: 10px 0 14px 0;

                .section-head {
                    padding-top: 4px;

                    .section-title {
                        .section-number {
                            margin-top: 4px;
                        }
                    }
                }
            }

            .document-section {
                ul {
                    li {
                        a {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 275px
                        }
                    }
                }
            }

            .information-basic-summary,
            .information-business-summary,
            .information-bank-summary,
            .information-documents-summary {
                margin-left: 0;
                width: 100%;

                &.collapsible-section {
                    margin-top: 10px;

                    .collapsible-content {
                        padding-top: 0;

                        .sub-section {
                            margin-top: 10px
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $mobile-max-width) {
        .title-heading-line-2 {
            padding-bottom: 75px;
        }
	}
}