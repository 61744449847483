@import "../variables";
@import "../mixins";

div.greentech-new-project {
    padding-left: 24px;
    padding-right: 24px;

    div.breadcrumbs {
        margin-top: 20px;
    }

    div.back-to-projects {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: bold;
    }

    h1 {
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
        line-height: 40px;
        margin-top: 16px;
        display: block;
    }

    h2 {
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 0.23px;
        line-height: 28px;
        margin-top: 40px;
        display: block;

        & + p.address-instruction {
            margin-top: 16px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }
    }

    .button-emphasis {
        margin-top: 24px;
    }

    .form-field {
        margin-top: 24px;

        label {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        input:not([type="checkbox"]),
        select {
            height: 40px;
            border-radius: 3px;
        }

        &.has-error {
            input:not([type="checkbox"]),
            select {
                border: 1px solid $border-color-error;
            }
        }

        &.project-name,
        &.address1,
        &.address2,
        &.customer-share-link {
            input {
                width: 433px;
            }
        }

        &.city {
            input {
                width: 317px;
            }
        }

        &.postal-code {
            input {
                width: 201px;
            }
        }

        &.primary-store {
            select {
                width: 433px;
            }
        }

        &.state {
            select {
                width: 201px;
            }
        }
    }

    .error-message {
        position: static;

        &.validation {
            display: none;
        }
    }

    .error-row {
        display: none;
        margin-top: 24px;

        .top-error {
            border-radius: 3px; 
            line-height: 1.2;
            padding: 10px;
            color: $color-error;
            background-color: $background-color-error;
            border: 1px solid $border-color-error;

            .message-text {
                padding-left: 10px;
                color: #000;
            }

            &.alert {
                margin-bottom: 0;
            }
        }
    }

    .second-share-link {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        line-height: 20px;
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $greentech-tablet-max-width) {
    div.greentech-new-project {
        .breadcrumbs {
            display: block;
        }

        .form-field {
            &.project-name,
            &.customer-share-link,
            &.primary-store,
            &.address1,
            &.address2,
            &.city,
            &.state,
            &.postal-code {
                input:not([type="checkbox"]),
                select {
                    width: 100%;
                }
            }
        }
    }
}