@import "../variables";

#notFound {
	margin-left: 300px;

	.title {
		margin-top: 40px;
		font-size: 36px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.11;
		letter-spacing: normal;
	}

	.subtitle {
		margin-top: 16px;
		font-size: 23px;
	  	font-weight: normal;
	  	font-style: normal;
	  	font-stretch: normal;
	  	line-height: 1.3;
	  	letter-spacing: normal;
	}

	.popularLinks {
		.popularTitle {
			margin-top: 16px;
			font-size: 16px;
		  	font-weight: bold;
		  	font-style: normal;
		  	font-stretch: normal;
		  	line-height: 1.56;
		  	letter-spacing: normal;
		}

		a {
			display: block;
			font-size: 16px;
		  	font-weight: normal;
		  	font-style: normal;
		  	font-stretch: normal;
		  	line-height: 1.56;
		  	letter-spacing: normal;
		}
	}

	.bottomMessage {
		margin-top: 32px;
		font-size: 16px;
	  	font-weight: normal;
	  	font-style: normal;
	  	font-stretch: normal;
	  	line-height: 1.56;
	  	letter-spacing: normal;
	}

	.nobreak {
		white-space: nowrap;
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	#notFound {
		margin-left: 15px;
		margin-right: 15px;

		.title {
			margin-top: 30px;
			font-size: 32px;
			line-height: 1.25;
		}

		.subtitle {
			font-size: 22px;
		  	line-height: 1.36;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	#notFound {
		margin-left: 15px;
		margin-right: 15px;

		.title {
			margin-top: 30px;
			font-size: 27px;
			line-height: 1.3;
		}

		.subtitle {
			font-size: 19px;
		  	line-height: 1.58;
		}
	}
}

body.solar-template {
	#notFound {
		.title {
			margin-top: 48px;
			font-size: 32px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25;
			letter-spacing: 0.3px;
		}

		.subtitle {
			margin-top: 16px;
			font-size: 18px;
		  	font-weight: normal;
		  	font-style: normal;
		  	font-stretch: normal;
		  	line-height: 1.56;
		  	letter-spacing: 0.2px;
		}

		.popularLinks {
			.popularTitle {
				margin-top: 16px;
				font-size: 14px;
			  	font-weight: 600;
			  	font-style: normal;
			  	font-stretch: normal;
			  	line-height: 1.43;
			  	letter-spacing: 0.1px;
			}

			a {
				display: block;
				font-size: 14px;
			  	font-weight: normal;
			  	font-style: normal;
			  	font-stretch: normal;
			  	line-height: 1.43;
			  	letter-spacing: 0.1px;
			}
		}

		.bottomMessage {
			margin-top: 32px;
			font-size: 14px;
		  	font-weight: normal;
		  	font-style: normal;
		  	font-stretch: normal;
		  	line-height: 1.43;
		  	letter-spacing: 0.1px;
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		#notFound {
			margin-left: 16px;
			margin-right: 16px;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		#notFound {
			margin-left: 16px;
			margin-right: 16px;
		}
	}
}