@import "../variables";

.discussion-detail {
	.status-section {
		label {
			display: inline-block;
			margin-right: 5px;
		}
	}

	.admin-title-back {
		.saveButton {
			margin-left: 25px;
		}
	}

	.cancel-link {
		font-weight: bold;
	    margin-top: 12px;
	    display: inline-block;
	}

	hr.section-divider {		
		margin-top: 50px;
	}

	hr {
		border-top: 1px solid #b9bbc0;
		margin: 20px 0 0 0;
	}

	.title {
		margin-top: 50px;
	}

	.file-upload {
		input[type="file"] {
			display: inline-block;
		}

		.upload-file-button {
			margin-left: 15px;
		}
	}

	.discussion-text-area-help {
		.comment-button {
			display: flex;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;

			textarea {
				height: 45px;
				flex: 1;
				-webkit-box-flex: 1;
		  		-moz-box-flex:  1;
		  		-webkit-flex:  1;
		  		-ms-flex:  1;
				overflow: hidden;
				margin-right: 18px;
				padding-top: 9px;
			}
			
			input[type="submit"] {
				width: 98px;
			}
		}
		
		.post-row {
			border-bottom: 1px solid #b9bbc0;
			padding-bottom: 25px;
			padding-top: 20px;
			margin-left: 0;
			margin-right: 0;

			> div {
				padding-left: 0;
				padding-right: 0;
			}

			h6 {
				margin-right: 15px;
			}

			.message-text, .reply {
				margin-top: 10px;
			}

			.reply {
				a {
					font-weight: bold;
				}
			}

			.comment {
				margin-top: 10px;
				margin-left: 60px;

				> div {
					margin-top: 10px;
					position: relative;
				}

				> div:first-of-type::before {
					font-family: 'FontAwesome';
				    position: absolute;
				    left: -15px;
    				top: 2px;
				    content: "\f102";
				    color: #b9bbc0;
				}
			}
		}

		.post-row:first-of-type {
			border-top: 1px solid #b9bbc0;
		}

		#commentBox {
			display: none;

			.cancel-comment {
				font-weight: bold;
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.discussion-detail {
		padding-left: 0;
		padding-right: 0;

		.admin-title-back {
			a {
				display: block;
				text-align: left;
				margin-top: 15px;
			}

			button.saveButton {
				width: 100%;
				margin-left: 0;
			}
		}

		div.row {
			padding-left: 15px;
			padding-right: 15px;
		}

		hr.section-divider {
			margin-left: 15px;
			margin-right: 15px;
		}

		.file-upload {
			.upload-file-button {
				margin-left: 0;
			}
		}

		.discussion-text-area-help {
			.comment-button {
				display: block;

				textarea {
					height: 45px;
					width: 100%;
				}
				
				input[type="submit"] {
					width: 100%;
				}
			}

			.post-row {
				padding-left: 0;
				padding-right: 0;
				margin-left: 15px;
				margin-right: 15px;

				.comment {
					margin-left: 30px;
				}
			}
		}

		#commentBox.comment {
			margin-left: 0;
			margin-top: 35px;

			> div:first-of-type::before {
			    left: 0;
				top: -20px;
			}
		}
	}
}