@import "../variables";
@import "../mixins";

div.greentech-change-request {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;

    div.breadcrumbs {
        margin-top: 20px;
    }

    .title-heading-line-1 {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        line-height: 20px;
        color: rgb(106, 107, 109);
    }

    .title-heading-line-2 {
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
        line-height: 40px;
    }

    div.back-to-projects {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: bold;
    }

    .change-request-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .change-request-content {
        width: 100%;

        textarea {
            border-radius: 3px;
            width: 363px;
            min-height: 200px;
        }
        
    }

    h1 {
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
        line-height: 40px;
        margin-top: 16px;
        display: block;
    }

    h2 {
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 0.23px;
        line-height: 28px;
        margin-top: 40px;
        display: block;

        & + p.address-instruction {
            margin-top: 16px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }
    }

    .button-emphasis {
        margin-top: 24px;
    }

    .form-field {
        margin-top: 24px;

        label {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        input:not([type="checkbox"]),
        select {
            height: 40px;
            border-radius: 3px;
        }

        &.has-error {
            input:not([type="checkbox"]),
            select {
                border: 1px solid $border-color-error;
            }
        }

        &.project-name,
        &.address1,
        &.address2,
        &.customer-share-link {
            input {
                width: 433px;
            }
        }

        &.city {
            input {
                width: 317px;
            }
        }

        &.postal-code {
            input {
                width: 201px;
            }
        }

        &.primary-store {
            select {
                width: 433px;
            }
        }

        &.state {
            select {
                width: 201px;
            }
        }
    }

    .error-message {
        position: static;

        &.validation {
            display: none;
        }
    }

    .error-row {
        display: none;
        margin-top: 24px;

        .top-error {
            border-radius: 3px; 
            line-height: 1.2;
            padding: 10px;
            color: $color-error;
            background-color: $background-color-error;
            border: 1px solid $border-color-error;

            .message-text {
                padding-left: 10px;
                color: #000;
            }

            &.alert {
                margin-bottom: 0;
            }
        }
    }

    .second-share-link {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        line-height: 20px;
    }

    .loading-overlay {
        span {
            padding-left: 10px;
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $greentech-tablet-max-width) {
    div.greentech-change-request {
        .title-heading-line-2 {
            padding-bottom: 0;
        }

        .breadcrumbs {
            display: block;
            margin-bottom: 10px;
        }

        .form-field {
            &.project-name,
            &.customer-share-link,
            &.primary-store,
            &.address1,
            &.address2,
            &.city,
            &.state,
            &.postal-code {
                input:not([type="checkbox"]),
                select {
                    width: 100%;
                }
            }
        }

        .change-request-content {
            width: 100%;
    
            textarea {
                border-radius: 3px;
                width: 345px;
                min-height: 150px;
            }
            
        }
    }
}