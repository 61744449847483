@import "../variables";

@media print {
	body.solar-template #pageWrapper  {
		.overlay-container {
			align-items: stretch;

			.main-content {
				max-width: none;
				margin-left: 0;
				margin-right: 0;
				width: 1000px;
			}
		}
	}
}

.quotes-processed-detail-content {
    .line-item-image {
        justify-content: left;
        padding-left: 25px;

        img {
            max-width: 100px;
        }
    }

    .product-headings {
        background-color: #f4f4f4;
        box-shadow: inset 0 1px 0 0 #b9bbc0;
        padding: 10px 0px;
        margin: 0;
    }

    .line-item {
        border-bottom: none;
        margin: 0;
        padding: 10px 0;

        .line-item-cell {
            line-height: 45px;
        }
    }

    .special-note,
    .special-note-content {
        font-size: 16px;
        line-height: 25px;
    }

    .special-note {
        font-weight: bold;
    }

    .available-option-block {
        margin: 25px 0 0 0;
    
        .available-option {  
            cursor: pointer; 
            background: #FFFFFF;
            padding: 16px;
            border: 1px solid #fff;
            border-top: 1px solid #B9BBC0;

            &:hover {
                background-color: #F4F4F4;
                border-color: #2370B3;
            }

            input[type=radio] {
                float: left;
            }

            .option-content {
                float: left;
                margin-left: 10px;

                .option-name {               
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: bold;
                }
    
                .option-details {
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            .option-price {
                font-size: 16px;
                text-align: right;
                line-height: 25px;

                .unit-price{
                    display: none;
                }
            }

            .selected-option-price {
                margin-left: 16px;

                .unit-price{
                    display: none;
                }
            }
        }

        .selected-option {
            &:hover {
                background-color: #ffffff;
                border-top-color: #B9BBC0;
                border-left-color: #FFFFFF;
                border-right-color: #FFFFFF;
                border-bottom-color: #FFFFFF;
            }
        }

        .available-option-selected {
            background-color: #F4F4F4;
            border-color: #2370B3;
        }

    }

    .special-note-block {
        margin-top: 25px;
    }

    .order-summary-wrapper {
        .order-summary-block {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #B9BBC0;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            padding: 20px;
            padding-bottom: 30px
        }

        .order-summary-heading {      
            font-size: 19px;
            line-height: 25px;
        }

        .order-summary-line {
            box-shadow: inset 0 1px 0 0 #B9BBC0;
        }

        .repair-warranty-checkout-button {
            padding-left: 22px;
            padding-right: 22px;

            .checkout-button {
                width: 100%;
            }
        }

        .total-title {
            margin-top: 10px;
            margin-left: 10px;
            font-size: 19px;
            line-height: 25px;
        }

        .total-row {
            margin-left: 25px;
            margin-right: 25px;
            font-size: 16px;
            font-weight: bold;
            line-height: 25px;
        }

        .subtotal-amount {
            font-size: 16px;
            line-height: 25px;
            text-align: right;
            font-weight: normal;
        }

        .summary-fee-calc {
            font-size: 13px;
            line-height: 20px;
            text-align: right;
            font-weight: normal;
        }

        .tax-amount {
            padding-right: 0;
        }
    }
}

/* sm size (tablet) */
@media only screen and (max-width: $tablet-max-width) {
    .quotes-processed-detail-content {
        .order-summary-wrapper {
            .total-title {
                margin-top: 25px;
                margin-left: 25px;
            }

            .checkout-button {
                width: 60%;
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .quotes-processed-detail-content {
        h2 {
            font-size: 27px;
        }

        .mobile-field-label {
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .mobile-field-label:not(:first-child) {
            margin-top: 15px;
        }

        .line-item-image {
            padding-left: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid #B9BBC0;
        }

        .line-item {
            .line-item-cell {
                padding-left: 0;
                padding-bottom: 15px;
                line-height: 25px;
            }

            .line-item-cell:not(:last-child) {
                border-bottom: 1px solid #B9BBC0;
            }
        }

        .available-option-block {
            .available-option {
                .option-content {
                    width: 90%;
                }

                .option-price {
                    margin-left: 20px;
                    text-align: left;
                }

                .selected-option-price {
                    margin-left:8px;
                }
            }

            .selected-option {
                &:hover {
                    background-color: #ffffff;
                    border-top-color: #B9BBC0;
                    border-left-color: #FFFFFF;
                    border-right-color: #FFFFFF;
                    border-bottom-color: #FFFFFF;
                }
            }
        }

        .quote-information-wrapper {
            margin-top: 50px;
        }

        .order-summary-wrapper {
            .total-title {
                margin-left: 0;
            }

            .total-row {
                margin-left: 15px;
                margin-right: 15px;
            }

            .checkout-button {
                width: 100%;
            }
        }
    }
}