@import "../variables";

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.recently-purchased {
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.footable-row-detail {
			.footable-row-detail-cell {
				.footable-row-detail-inner {
					.footable-row-detail-row:last-of-type {
						.footable-row-detail-name {
							display: none;
						}
						.footable-row-detail-value {
							position: relative;
    						height: 50px;

    						button {
    							position: absolute;
							    top: 5px;
							    width: 160px;
    						}
						}
					}
				}
			}
		}
	}
}