@import "../variables";

#product-compare-contents{
	padding: 16px;


	h1{
		display: block;
	}

	#compare-products-table{
		margin: 24px 0;
		table-layout: fixed;
		width: 100%;
	}

	#compare-products-table td{
		border-right: solid 1px #b9bbc0;
		border-bottom: solid 1px #b9bbc0;
		padding: 15px;
	}

	#compare-products-table td:last-child{
		border-right: none;
	}

	.compare-product-image{
		width: 100%;
		height: 220px;
		margin: 15px auto;
	}

	.compare-product-image a img{
		max-width: 100%;
		max-height: 220px;
		margin: auto;
		display: block;
	}

	#product-detail-row td{
		width: 21%;
		vertical-align: top;
		word-break: break-word;
		position: relative;
		padding-bottom: 175px;
	}

	#product-detail-row td.attribute-column{
		width: 16%;
	}

	.specification-header{
		font-size: 24px;
		line-height: 1.17;
		letter-spacing: 0.2px;
	}

	.product-spec-row td:first-child{
		font-size: 14px;
		font-weight: 600;
		line-height: 1.43;
		letter-spacing: 0.1px;
		background-color: #f4f4f4;
		word-break: break-word;
	}

	.compare-product-price{
		font-size: 18px;
		font-weight: 600;
		line-height: 1.56;
		letter-spacing: 0.2px;
	}

	.compare-product-uom{
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.1px;
	}

	.button-neutral{
		height: 34px;
		padding: 7px 12px;
	}

	.solar-button.button-emphasis{
		display: block;
	    width: 100%;
	    margin: 5px 0;
	}

	.small-circle-button{
		padding: 0;
	}

	.compare-price-qty{
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 15px;
	}
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	#product-compare-contents #product-detail-row td{
		padding-bottom: 200px;
	}
}

body.solar-template #product-compare-contents .qty-input{
	display: block;
	input{
		width: 100%;
	}
}