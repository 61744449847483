.quote-req-page{
	padding-left: 15px;
	padding-right: 15px;

	label + input, textarea,
	input.contactName {
		width: 100%;
	}

	.quote-request-radio table:not(.table-condensed):not(.radio-options) tbody tr{
		border: none;
	}

	.quote-request-radio label{
		display: inline-block;
		padding-left: 5px;
	}

	.cart-progress hr.back{
		width: 55%;
		left: 48px;

	}
	.indented-row{
		margin-left: 0;
		margin-right: 0;
	}

	.line-item {
		.line-item-image img {
			max-width: 100%;
		}
	}

	@media only screen and (max-width: $mobile-max-width){
		.flex-email-container{
			display: flex;
    		flex-flow: column;

    		div:nth-of-type(1){
    			order: 1;
    		}
    		div:nth-of-type(2){
    			order: 3;
    		}
    		div:nth-of-type(3){
    			order: 2;
    		}
		}

		.line-item {
			.line-item-image {
				justify-content: left;
			}
		}
	}
}