@import "../variables";

.repairWarranty {
    label.fileSelection {
        cursor: pointer;
    }

    #file-drop-box{
        color: #2370B3;
        font-weight: bold;
        padding: 70px 0;
    }

    #uploadImage{
    }

    #previewImage{
        display: flex;
        background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 100%);
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #BBBDC6;
        box-shadow: inset 0 -1px 0 0 #FFFFFF;
        height: 112px;
        width: 340px;
        padding: 8px;
        justify-content: space-between;

        img{
            max-height: 100%;
            max-width: 100%;
            align-self: flex-start;
        }

        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 0;
        }
    }

    #addImageButton{
        display: inline-block;
        text-align: center;
    }

    /*#previewImage div:first-child{
        padding-right: 10px;
    }*/

    #removeImage{
        align-self: flex-end;
    }

    .field-error-message{
        display: none;
    }

    .field-error-message.active{
        color: $color-error;
        display: block;
    }

    .fileupload label{
        display: inline;
    }

    .fileupload input[type="file"]{
        display: none;
    }

    .info-block input{
        width: 50%
    }

    .info-block textarea{
        width: 100%
    }

    #newRequestBlock {
        display: none;
    }

    #newRequestBlock {
        .indented-row {
            margin-right: -5px;

            table tbody tr {
                border: none;
            }
        }
    }

    .imageBlock {
        .line-item-image {
            justify-content: left;
        }

        img {
            max-width: 48px;
        }
    }

    .fullname,
    .email,
    .phone,
    .fax,
    .additionalEmails,
    .quotedates,
    .deliverydates,
    .notes {
        width: 100%;
    }

    .newRequest {
        margin-left: 0;
    }

    .newRequest {
        .line-item {
            border-bottom: none;
        }

        .product-headings {
            line-height: 40px;
            border-top: 1px solid #B9BBC0;
            background-color: #f4f4f4;
            margin-right: 8px;
            font-weight: bold;
        }
    }

    #editButton {
        padding-right: 10px;
        line-height: 48px;
        color: #2370B3;
        font-size: 13px;
        font-weight: bold;
    }

    .requestFieldHeader {
        font-weight: bold;
    }

    .catalog,
    .serialNumber,
    .manufacturer {
        line-height: 48px;
    }

    #jobSelection {
        div {
            width: 100%;
        }
    }

    label.noafter:after {
        display: none;
    }

    @media only screen and (max-width: $mobile-max-width) {
        .info-block input{
            width: 100%
        }

        #addImageButton{
            width: 100%;
        }

        .next-step{
            width: 100%;
        }

        #previewImage{
            height: 80px;
            width: 290px;
        }

        .imageBlock {
            .line-item-image {
                border-bottom: 1px solid #B9BBC0;
                padding-top: 10px;
                padding-bottom: 15px;
                padding-left: 0;
                margin-left: 15px;
                margin-right: 32px;
            }

            img {
                max-width: 100px;
            }
        }

        .catalog,
        .serialNumber,
        .manufacturer {
            line-height: 10px;
            border-bottom: 1px solid #B9BBC0;
            padding-top: 10px;
            padding-bottom: 15px;
            padding-left: 0;
            margin-left: 15px;
            margin-right: 32px;
        }

        .line-item {
            padding-bottom: 0;
        }

        .button-row {
            margin-top: 0;
        }
    }
}