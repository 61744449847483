@import "../variables";

.helper-icon {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
}
.helper-icon .helper-icon-text,
.helper-icon .helper-icon-medium-text,
.helper-icon .helper-icon-end-text,
.helper-icon .helper-icon-inverter-text,
.helper-icon .helper-icon-Optimizer-text,
.helper-icon .helper-icon-flatroof-text,
.helper-icon .helper-icon-charge-text,
.helper-icon .helper-icon-required-inverter-text,
.helper-icon .helper-icon-required-evcharger-text,
.helper-icon .helper-icon-module-text,
.helper-icon .helper-icon-management-text,
.helper-icon .helper-icon-address-text,
.helper-icon .parapetdimensions-helptext,
.helper-icon .helper-icon-upload-text,
.helper-icon .helper-icon-design-text {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 320px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon:hover .helper-icon-text,
.helper-icon:hover .small-helptext,
.helper-icon:hover .large-medium-helptext,
.helper-icon:hover .medium-helptext,
.helper-icon:hover .large-helptext,
.helper-icon:hover .eeslarge-helptext,
.helper-icon:hover .eessmall-helptext,
.helper-icon:hover .helper-icon-medium-text,
.helper-icon:hover .helper-icon-end-text,
.helper-icon:hover .helper-icon-inverter-text,
.helper-icon:hover .helper-icon-Optimizer-text,
.helper-icon:hover .helper-icon-flatroof-text,
.helper-icon:hover .helper-icon-charge-text,
.helper-icon:hover .helper-icon-required-inverter-text,
.helper-icon:hover .helper-icon-required-evcharger-text,
.helper-icon:hover .helper-icon-module-text,
.helper-icon:hover .helper-icon-nvpinverter-text,
.helper-icon:hover .helper-icon-racking-text,
.helper-icon:hover .helper-icon-attachment-text,
.helper-icon:hover .helper-icon-management-text,
.helper-icon:hover .parapetdimensions-helptext,
.helper-icon:hover .helper-icon-address-text,
.helper-icon:hover .helper-icon-upload-text,
.helper-icon:hover .helper-icon-design-text {
    display: block;
    opacity: 1;
}
.helper-icon .small-helptext {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 150px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon .large-medium-helptext {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 270px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon .medium-helptext {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 250px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon .large-helptext {
    display: none;
    transform: translateY(10%);
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 500px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon .eeslarge-helptext {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 290px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}
.helper-icon .eessmall-helptext {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 4px 8px;
    border-radius: 6px;
    width: 190px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    font-size: 12px;
    z-index: 1000;
}

.greentechProjectIntakeInput {
    .button:not(.disabled),
    .button-emphasis,
    .button-emphasis-small {
        margin: 5px;
    }

    .incomplete-field, {
        stroke: $status-danger-1;
        margin-top: 2px;
        padding-top: 2px;
    }
    
    .incomplete-section-error {
        color: red
    }

    .measurement-container {
        display: flex;
        margin-top: 24px;
        align-items: flex-start;
        margin-bottom: 44px;

        .measurement-fields {
            width: 43%;            
            padding-right: 24px;

            .add-measurements {
                border-radius: 6px;
                border: 1px solid rgb(213, 216, 217);
                background: rgb(248, 249, 249);
                padding: 16px;

                .measurement-heading {
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                    font-weight: normal;
                    letter-spacing: 0.23px;
                    line-height: 28px;
                }

                .collapsible-section {
                    border-radius: 3px;
                    border: 1px solid rgb(213, 216, 217);
                    background: rgb(255, 255, 255);
                    margin-top: 8px;

                    .collapsible-header {
                        padding: 10px 16px;

                        h5 {
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                            color: rgb(33, 33, 33);
                        }
                    }
                    .collapsible-header::after {
                        color: rgb(33, 33, 33);
                        font-size: 16px;
                        top: 10px;
                    }
                    .collapsible-content {
                        padding: 8px 16px;

                        .measurement-field {
                            margin: 0 16px;
                            padding: 7px 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid rgb(213, 216, 217);

                            label {
                                color: rgb(33, 33, 33);
                                font-size: 14px;
                                font-weight: bold;
                                height: 20px;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                            }

                            input {
                                width: 90px;
                                height: 40px;
                                padding-left: 4px;
                            }
                        }
                        .measurement-field:last-of-type {
                            border-bottom: none;
                        }
                    }
                }
                .collapsible-section.closed {
                    .collapsible-header {
                        background: rgb(255, 255, 255);
                    }

                    .collapsible-header::after {
                        color: rgb(106, 107, 109);
                    }

                    .collapsible-header:hover {
                        h5 {
                            color: rgb(33, 33, 33);
                        }
                    }
                }
            }
        }

        .measurement-diagram {
            width: 57%;
            border: 1px solid rgb(149, 158, 160);
            padding: 24px;
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }

        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    .admin-right-content {
        margin-bottom: 30px;
        
        .title-heading {
            margin-bottom: 11px;

            > .intake-go-back {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;
            }
        }
    }

    .input-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .admin-right-content {
        margin-bottom: 30px;
        width: 100%;

        .incomplete-error-message {
            background: rgba(242, 49, 1, 0.15);
            border-radius: 3px;
            border: 1px solid rgb(242, 49, 1);
            height: 40px;
            padding: 10px;
            color: rgb(33, 33, 33);
            margin-bottom: 5px;
            height: auto;

            .fa-exclamation-circle {
                color: rgb(242, 49, 1);
            }
        }

        .autocomplete-suggestions {
            width: auto !important;
            margin-left: 0;
            margin-top: 3px;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 8px;
            padding-top: 8px;

            .add-product-processing {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                background: rgba(255,255,255,.7);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1px;
                border-radius: 6px;

                span {
                    margin-left: 8px;
                }
            }

            .product-container {
                max-height: 360px;
                overflow: auto;

                .more-text {
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    padding-bottom: 12px;
                    padding-top: 12px;
                }

                .product-row {
                    display: flex;
                    align-items: flex-start;
                    border-bottom: 1px solid rgb(213, 216, 217);
                    padding-bottom: 7px;
                    padding-top: 7px;

                    .image-container {
                        width: 48px;
                        text-align: center;

                        img {
                            max-width: 48px;
                            max-height: 48px;
                        }
                    }

                    .content {
                        padding-left: 5px;
                        flex-grow: 1;

                        .product-name {
                            color: rgb(33, 33, 33);
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                            margin-bottom: 4px;
                        }

                        .product-sub-detail {
                            color: rgb(106, 107, 109);
                            font-size: 12px;
                            font-weight: normal;
                            letter-spacing: 0.11px;
                            line-height: 20px;

                            span {
                                border-left: 1px solid rgb(213, 216, 217);
                                padding-left: 8px;
                                margin-left: 8px;
                            }
                        }
                    }

                    .selector {
                        width: 40px;
                        margin-right: 10px;

                        button {
                            border: 1px solid rgb(28, 130, 28);
                            background: #fff;
                            padding: 12px 10px 8px 10px;
                            width: 40px;

                            i {
                                color: rgb(28, 130, 28);
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .autocomplete-suggestions:before, .autocomplete-suggestions:after {
            content: none;
        }

        .title-heading-line-1 {
            margin-top: 16px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        .title-heading-line-2 {
            font-size: 24px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        .project-save-button {
            width: 168px;
            height: 40px;
            border-radius: 0px;

            color: rgb(255, 255, 255);
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.13px;
            line-height: 16px;
        }

        .project-save-exit-button {
            width: 168px;
            height: 40px;
            border-radius: 0px;
            
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.13px;
            line-height: 16px;
        }

        .service-order-intake-form {
            margin-top: -20px;

            #addDocumentModal {
                .modal-content {
                    border-radius: 0;
                }
        
                #upload-status {
                    display: none;
                    margin-right: 10px;
                }
            }

            .document-section {
                .field-label {
                    color: #212121;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: .13px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
                
                .add-document-button {
                    border-radius: 3px;
                    margin: 10px 0;
                    padding: 10px 16px 11px 11px;
    
                    i {
                        margin-right: 6px;
                    }
                }

                .added-documents-text {
                    margin: 18px 0 8px 0;
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }
    
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
    
                    li {
                        width: 100%;
                        padding: 5px;
                        margin: 5px 0;
                        border-radius: 3px;
                        border: 1px solid rgb(213, 216, 217);
    
                        .delete {
                            text-align: right;
                        }
    
                        .delete-document {
                            cursor: pointer;
                        }

                        a {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }

            .intake-form-group {
                .intake-form-group-heading {
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                    font-weight: normal;
                    letter-spacing: 0.23px;
                    line-height: 28px;
                    margin-top: 40px;
                    margin-bottom: 24px;
                }

                &:first-child {
                    .intake-form-group-heading {
                        margin-top: 24px;
                    }
                }

                .intake-form-field {
                    margin-top: 24px;
                    margin-bottom: 24px;

                    > label {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }

                    .checkbox-options {
                        tbody { 
                            tr {
                                border-bottom: none;

                                td {
                                    padding: 0px 2px;
                                }
                            }
                        }
                        label {
                            font-weight: normal;
                        }
                    }

                    input:not([type="radio"]):not([type="checkbox"]),                
                    select {
                        height: 40px;
                    }

                    input:not([type="radio"]):not([type="checkbox"]),
                    select,
                    textarea {
                        border-radius: 3px;
                        width: 363px;

                        &.small-entry {
                            width : 169px;
                        }

                        &.medium-entry {
                            width: 363px;
                        }

                        &.large-entry {
                            width: 460px;
                        }
                    }

                    .yesNoRadio {
                        tbody tr {
                            border: none;
                        }
                        td {
                            padding: 2px;
                        }
                        td label {
                            display: inline;
                            font-size: 14px;
                            font-weight: normal;
                            margin-left: 4px;
                        }
                        input[type="radio"] {
                            accent-color: #1c821c;
                        }
                    }

                    .product-search-input {
                        position: relative;
                        display: inline;
                    }
                    .product-search-input:before {
                        font-family: 'FontAwesome';
				    	position: absolute;
                        content: "\f002";
                        right: 8px;
                        top: -2px;
                        color: #959ea0;
                    }

                    .selected-product {
                        border-radius: 3px;
                        border: 1px solid rgb(149, 158, 160);
                        display: flex;
                        max-width: 460px;
                        padding: 12px;
                        align-items: center;

                        .image-section {
                            width: 48px;
                            margin-right: 8px;

                            img {
                                width: 100%;
                            }
                        }

                        .selected-product-detail {
                            flex-grow: 1;

                            .desc {
                                color: rgb(33, 33, 33);
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                            }

                            .product-sub-items {
                                color: rgb(106, 107, 109);
                                font-size: 12px;
                                font-weight: normal;
                                letter-spacing: 0.11px;
                                line-height: 20px;

                                .product-sub-item {
                                    border-right: 1px solid rgb(213, 216, 217);
                                    padding-right: 12px;            
                                    margin-right: 8px;                        
                                    display: inline-block;
                                }
                                .product-sub-item:last-of-type {
                                    border-right: none;
                                }
                            }
                        }

                        .selected-product-close {
                            width: 24px;
                            text-align: right;
                            cursor: pointer;

                            i {
                                color: rgb(106, 107, 109);
                            }
                        }
                    }

                    .asterisk {
                        color: red;
                        display: inline;
                    }
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentechProjectIntakeInput {
        .admin-right-content {

        }

        .input-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentechProjectIntakeInput {

        .home-left-content {
            margin-left: 10px;
        }

        .title-heading {
            padding: 15px;
        }

        .admin-right-content {
            .title-heading-line-2 {
                font-size: 18px;
                padding-bottom: 0;
            }

            .service-order-intake-form {
                .intake-form-group {
                    .intake-form-field {
                        input:not([type="radio"]),
                        select,
                        textarea {
                            width: 100%;
                            &.small-entry,
                            &.medium-entry,
                            &.large-entry {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentechProjectIntakeInput {
        .measurement-container {
            flex-wrap: wrap;

            .measurement-fields {
                order: 2;
                width: 100%;
                padding-right: 0;
            }
            .measurement-diagram {
                order: 1;
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }
}
/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentechProjectIntakeInput {
        .measurement-container {
            flex-wrap: wrap;

            .measurement-fields {
                order: 2;
                width: 100%;
                padding-right: 0;
            }
            .measurement-diagram {
                order: 1;
                width: 100%;
                margin-bottom: 24px;
                padding: 16px;
            }
        }
        
        .admin-right-content {
            
            .service-order-intake-form {
                .intake-form-group {
                    .intake-form-field {
                        input:not([type="radio"]) {
                            width: 20px;
                        }
                        input:not([type=radio]):not([type=checkbox]) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .helper-icon .helper-icon-text,
    .helper-icon .helper-icon-Optimizer-text,
    .helper-icon .helper-icon-medium-text, 
    .helper-icon .helper-icon-end-text,
    .helper-icon .helper-icon-inverter-text,
    .helper-icon .large-medium-helptext,
    .helper-icon .helper-icon-flatroof-text,
    .helper-icon .medium-helptext,
    .helper-icon .helper-icon-charge-text,
    .helper-icon .helper-icon-design-text,
    .helper-icon .helper-icon-required-inverter-text,
    .helper-icon .helper-icon-required-evcharger-text,
    .helper-icon .helper-icon-module-text,
    .helper-icon .helper-icon-management-text,
    .helper-icon .parapetdimensions-helptext,
    .helper-icon .helper-icon-address-text,
    .helper-icon .helper-icon-upload-text,
    .helper-icon .eeslarge-helptext {
        width: 250px;
    }
}
@media only screen and (max-width: 480px) {
    .helper-icon .helper-icon-text,
    .helper-icon .helper-icon-medium-text,
    .helper-icon .medium-helptext,
    .helper-icon .helper-icon-charge-text,
    .helper-icon .helper-icon-module-text,
    .helper-icon .parapetdimensions-helptext,
    .helper-icon .helper-icon-address-text,
    .helper-icon .helper-icon-upload-text,
    .helper-icon .helper-icon-design-text {
        width: 150px;
    }
    .helper-icon .helper-icon-end-text,
    .helper-icon .helper-icon-inverter-text{
        transform: translateX(-20%);
        width: 150px;
    }
    .helper-icon .large-helptext {
        width: 300px;
        transform: translateX(-20%);
    }
    .helper-icon .eeslarge-helptext,
    .helper-icon .large-medium-helptext {
        width: 110px;
    }
    .helper-icon .helper-icon-required-inverter-text {
        transform: translateX(-65%);
        width: 150px;
    }
    .helper-icon .helper-icon-required-evcharger-text {
        transform: translateX(-55%);
        width: 150px;
    }
    .helper-icon .helper-icon-flatroof-text {
        transform: translateX(-45%);
        width: 150px;
    }
    .helper-icon .eessmall-helptext {
        width: 125px;
    }
    .helper-icon .helper-icon-management-text {
        width: 150px;
    }
    
    
}
@media only screen and (max-width: 440px) {
    .helper-icon .helper-icon-Optimizer-text {
        transform: translateX(-75%);
        width: 150px;
    }
    .helper-icon .helper-icon-upload-text {
        transform: translateX(-75%);
        width: 150px;
    }
}
@media only screen and (max-width: 420px) {
    .helper-icon .helper-icon-upload-text {
        transform: translateX(0%);
        width: 150px;
    }
}
@media only screen and (max-width: 400px) {
    .helper-icon .helper-icon-medium-text {
        transform: translateX(-35%);
    }
    .helper-icon .helper-icon-end-text{
        transform: translateX(-55%);
    }
    .helper-icon .helper-icon-inverter-text {
        transform: translateX(-50%);
    }
    .helper-icon .helper-icon-design-text,
    .helper-icon .large-helptext {
        transform: translateX(-50%);
    }
    .helper-icon .helper-icon-Optimizer-text {
        transform: translateX(0%);
    }
    .helper-icon .helper-icon-flatroof-text {
        transform: translateX(-70%);
        width: 100px;
    }
    .helper-icon .helper-icon-charge-text {
        transform: translateX(-20%);
    }
    .helper-icon .large-medium-helptext {
        transform: translateX(-30%);
        width: 150px;
    }
    .helper-icon .eeslarge-helptext {
        transform: translateX(-50%);
        width: 110px;
    }
    .helper-icon .helper-icon-required-inverter-text {
        transform: none;
    }
    .helper-icon .helper-icon-module-text {
        transform: translateX(-45%);
    }
    .helper-icon .small-helptext {
        width: 90px;
    }
    .helper-icon .helper-icon-required-evcharger-text {
        transform: translateX(-80%);
    }
    .helper-icon .helper-icon-text {
        width: 100px;
    }
    .helper-icon .helper-icon-management-text {
        width: 150px;
    }
    .helper-icon .parapetdimensions-helptext {
        transform: translateX(-60%);
    }
    .helper-icon .eessmall-helptext {
        transform: translateX(-40%);
        width: 125px;
    }
}
@media only screen and (max-width: 370px) {
    .helper-icon .helper-icon-required-evcharger-text {
        transform: translateX(0%);
    }
    .helper-icon .large-medium-helptext {
        width: 150px;
    }
}
@media only screen and (max-width: 355px) {
    .helper-icon .helper-icon-flatroof-text {
        transform: translateX(0%);
        width: 100px;
    }
}