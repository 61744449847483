@import "../variables";

body.solar-template {
	.solar-home {
		margin-top: 24px;	

		#quick-links {
			@include flexbox;
			@include flexalign(center);
			@include flexwrap(wrap);
			justify-content: center;

			.quick-link-item {
				width: 210px;
				margin-left: 10px;
				margin-right: 10px;
				border-radius: 2px 2px 2px 2px;				
				background-color: #fff;
				margin-top: 16px;				
				height: 120px;

				form {
					height: 100%;
				}

				a {
					display: block;
					height: 100%;
					padding: 20px 8px;

					.quick-link-content {
						@include flexbox;

						.quick-link-icon {
							width: 32px;
							text-align: center;

							i:before {
								font-size: 22px;
							}

							img {
								width: 26px;
								height: 26px;
							}
						}

						.quick-link-text {
							@include flex(1);
							font-size: 12px;
							letter-spacing: 0.11px;
							line-height: 20px;
							padding-left: 4px;

							.quick-link-title {
								font-size: 16px;
								letter-spacing: 0.15px;
								line-height: 24px;
							}						

							.quick-link-description {
								display: block;
							}
						}
					}
				}

				a:hover {
					text-decoration: none;		
				}
			}
		}	

		.message-container {
			margin-top: 8px;
			margin-left: 16px;
			margin-right: 16px;
		}

		.top-row.home-categories-11 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 50px;
						max-height: 50px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-13 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 45px;
						max-height: 45px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-15 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 40px;
						max-height: 40px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-17 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 35px;
						max-height: 35px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-19 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 30px;
						max-height: 30px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}


		.top-row {
			margin-left: 16px;
			margin-right: 16px;
			display: -webkit-box;
		    display: -moz-box;
		    display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-box-orient: horizontal;
		    -moz-box-orient: horizontal;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    justify-content: center;
		    -ms-flex-pack: center;

		    .browse-category {
		    	width: 50%;
		    	order: 1;
		    	-ms-flex-order: 1;
		    }

		    .home-slider {
		    	width: 50%;
		    	order: 2;
				-ms-flex-order: 2;
				align-self: flex-start;
		    }
		    #solar-home-slider.home-slider {
		    	margin-top: 6px;
		    }
		    #solar-home-slider.home-slider.title-spacer {
		    	margin-top: 32px;
		    }

			#slider-container {
				
				margin: 0;
				position: relative;

				#single-slider{
					/*height: 350px !important;*/
					height: auto !important;
					max-height: 395px !important;

					li{
						.slide-wrapper {
							text-align: center;
						}

						img{
				    		/*width: 100%;
				    		height: 350px;*/
				    		max-height: 395px;
				    		max-width: 100%;
						}
						.caption {
							position: absolute;
						    left: 16px;
						    bottom: 16px;
						    width: 65%;
						    opacity: 0.95;
						    padding: 30px;
						    text-align: left;
						}
					}
				}

				.lSSlideOuter {
					.lSSlideWrapper {
			            margin-left: 0;
			            margin-right: 0;
			            overflow: hidden;
			        }
				}

				.lSSlideOuter .lSPager.lSpg {
					position: absolute;
				    bottom: 10px;
				    right: 26px;

				    >li a {
				    	width: 16px;
				    	height: 16px;
				    }
				}
			}

			#home-categories-row {
				margin-top: 16px;
				padding-right: 8px;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;

			    div {
			    	width: 49%;
			    }

				a {
					font-size: 16px;
					font-weight: 100;
					line-height: 1.5;
					letter-spacing: 0.2px;
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					align-items: center;
					-ms-flex-align: center;
					padding-bottom: 8px;

					span {
						flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
				  		display: block;
					}

					img {
						max-width: 50px;
						max-height: 50px;
						margin-right: 12px;
						margin-left: 8px;
					}
				}

				#view-all-categories {
					margin-top: 16px;
					display: none;
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 75%;
					padding-left: 10px;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}

		.home-bottom-row {
			margin-top: 24px;
			margin-left: -16px;
			margin-right: 0;

			.home-bottom-content {		
				position: relative;
				overflow: hidden;
				background-repeat: no-repeat;
			    background-size: cover;
				background-position: center;
				width: 33.3333333%;
				border-left: 16px solid white;
				border-bottom: 16px solid white;

				.caption {
					position: absolute;
					bottom: 16px;
					left: 32px;
					right: 32px;
					background-color: rgba(255,255,255,.9);
					padding: 16px;

					> label {
						font-size: 16px;
						font-weight: 600;
						line-height: 1.5;
						letter-spacing: 0.2px;
						margin-bottom: 16px;
					}

					div.button-emphasis {
						display: inline-block;
					}
				}
			}

			.quick-order {
				padding: 16px;

				.title {
					font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
					text-align: center;
					margin-bottom: 16px;
				}

				.input-row {
					margin-bottom: 8px;	
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;

					> input {
						margin-right: 10px;
						flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
					}								
				}

				.button-row {
					margin-top: 16px;

					button {
						margin-right: 16px;
					}
				}
			}
		}

		.solar-selector{
			text-align: center;
		}
	}

	.solar-home-logged-in {
		.customer-message-container {
			margin: 24px 16px;
			padding: 24px;
			border-radius: 6px;
			border: 1px solid rgb(213, 216, 217);
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.solar-home {
			.top-row {
				#home-categories-row {
					div {
				    	width: 100%;
				    	padding-left: 8px;
    					padding-right: 16px;
				    }
					a {						
						img {
							max-width: 40px;
							max-height: 40px;
							margin-right: 12px;
							margin-left: 8px;
						}
					}

					#view-all-categories {
						margin-top: 16px;
						display: inline-block;
						margin-left: 16px;
					}

					div.catcont-solar {
						position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 86%;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 60px;
						}
					}

					#home-products-row-button {
						margin: 0 0 20px 20px;
					}
				}
			}

			.top-row.home-categories-11 {
			
				#home-categories-row {
					div.catcont-solar {
						position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 86%;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 60px;
						}
					}

					#home-products-row-button {
						margin: 0 0 20px 20px;
					}
				}
			}

			.featured-brand-item {
				padding: 15px 8px;
			}

			.home-bottom-row {
				.quick-order {
					.input-row {
						margin-bottom: 8px;	

						> input {
							margin-right: 8px;
						}									
					}

					.button-row {
						a {
							display: block;
							margin-top: 16px;
						}
					}
				}
			}
		}

		.solar-home-logged-in {
			.customer-message-container {
				margin: 0;
				padding: 24px;
				border: none;
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.solar-home {
			margin-top: 16px;

			#quick-links {
				.quick-link-item {
					width: 100%;				
					height: auto;

					a {
						padding: 12px 8px 8px 8px;

						.quick-link-content {							
							.quick-link-text {														
								.quick-link-description {
									display: none;
								}
							}
						}
					}
				}
			}
			
			.top-row {
			    -webkit-box-orient: vertical;
			    -moz-box-orient: vertical;
			    -webkit-flex-direction: column;
			    -ms-flex-direction: column;
			    flex-direction: column;

			    .browse-category {
			    	width: 100%;
			    	order: 2;
			    	-ms-flex-order: 2;
			    	margin-top: 24px;
			    }

			    .home-slider {
			    	width: 100%;
			    	order: 1;
			    	-ms-flex-order: 1;
			    }
			    #solar-home-slider.home-slider {
			    	margin-top: 0px;
			    }
			    #solar-home-slider.home-slider.title-spacer {
			    	margin-top: 0px;
			    }

			    #home-categories-row {
			    	div {
				    	width: 100%;
				    	padding-left: 8px;
    					padding-right: 16px;
				    }

			    	#home-categories-row-button {
			    		padding-left: 16px;
			    		padding-right: 16px;

						#view-all-categories {
							margin-top: 16px;
							display: block;
							width: 100%;
						}
					}

					#home-products-row-button {
						padding-left: 16px;
						padding-right: 16px;
						
						#view-all-products {
							margin-top: 16px;
							display: block;
							width: 100%;
						}
					}

					.catcont-solar {
						position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 80%;
							padding-left: 25px;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 50px;
						}
					}
				}

				#slider-container {
					#single-slider {
						height: auto !important;

						li{
							img{
								/*width: 100%;
					    		height: 170px;*/
					    		object-fit: contain;
					    		max-width: 100%;
					    		max-height: 100%;
							}

							.caption {
								position: initial;
								padding: 0;
							    width: 100%;
							    text-align: left;
							    height: auto;
							    background-color: #fff;
							    opacity: 1;
							    box-shadow: none;
							    background-image: none;
							    margin-top: 8px;
							    font-size: 16px;
								font-weight: 600;
								line-height: 1.5;
								letter-spacing: 0.2px;
							}
						}
					}

					.lSSlideOuter .lSPager.lSpg {
						position: absolute;
						/*top: 128px;*/
					    bottom: 55px;
					    right: 16px;
					}
				}
			}

			.top-row.home-categories-11 {

				#home-categories-row {
					.catcont-solar {
						position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 86%;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 50px;
						}
					}
				}
			}
			
			.featured-brand-item {
				width: 140px;
				display: inline-table;
			}
			.home-bottom-row {
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				margin-left: 0;

			    .home-bottom-content {
			    	min-height: 300px;
			    	margin-left: 16px;
					margin-right: 16px;
					width: 100%;
					border: none;
			    }

				.home-bottom-content {
					margin-bottom: 16px;
				}
				.quick-order {
					margin-left: -15px;
    				margin-right: -15px;

    				.button-row {
    					button {
    						display: inline-block;
    						width: auto;
    					}
    				}
				}
			}
		}

		.solar-home-logged-in {
			.customer-message-container {
				margin: 0;
				padding: 16px;
				border: none;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
