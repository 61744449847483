@import "../variables";

.wrapped-flex {
	display: flex;
	flex-wrap: wrap;
}

.top-border-box {
	position: relative;
	display: flex;
	max-width: 32%;
	min-width: 32%;
	margin-right: 1%;
	background: linear-gradient(-180deg, #fff 0%, #FCFCFC 100%);
	border: 1px solid #BBBDC6;
	box-shadow: inset 0 -1px 0 0 #FFFFFF;
	border-radius: 3px 3px 3px 3px;
	padding: 16px;
	color: black;
	margin-bottom: 15px;
}

.top-border-box.size-2x{
	max-width: 65%;
	min-width: 65%;
}

.top-border-box > .border {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
}

.circle-icon{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	border-width: 2px;
	border-style: solid;
	margin-right: 8px;
}

.circle-icon i{
	font-size: 1.2em;
}

#address-section{
	display: flex;
	flex-wrap: wrap;
}

#address-section > div{
	display: flex;
	width: 100%;
}

#map {
	font-family: Arial;
	font-size:12px;
	line-height:normal !important;
	width:100%;
	background:transparent;
	margin-bottom: 10px;
}



#map{
	height: 300px;
	width: calc(100% + 16px);
	margin: -12px 0 -16px -16px;
}

.map-container{
	flex: 1;
}

#address-section{
	flex: 1;
}

.location-details, .business-hours{
	padding-left: 0;
	list-style: none;
}

.location-details li{
	margin-bottom: 20px;
}

.business-hours li{
	border-bottom: solid 1px #dde1e2;
	padding-bottom: 10px;
	margin-bottom: 20px;
}

.icon-right{
	display: inline-block;
	flex: 1;
}

.contact-us-form{
	input:not(.button-emphasis), textarea{
		width: 50%
	}

	&>div>div{
		margin-top: 20px;
		position: relative;
	}

	.button-emphasis{
		margin-top: 30px;
	}
}

.after-hours .icon-right{
	width: 90%;
}

.sales-person + .sales-person{
	padding-top: 10px;
}

//small and up
@media only screen and (min-width: $tablet-min-width){
	.contact-us-page .home-full-content, .contact-us-page .home-right-content{
		padding-left: 16px;
		padding-right: 16px;
	}

	#address-section{
		padding-left: 16px;
	}
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width){


	.top-border-box.size-2x, .top-border-box.sales-persons{
		max-width: 98%;
		min-width: 98%;
	}

	.contact-us-form{
		input:not(.button-emphasis), textarea{
			width: 98%
		}
	}

	.sales-person {
		display: inline-block;
		width: 50%;
	}

	.sales-person + .sales-person{
		padding-top: 0;
	}
}

@media only screen and (max-width: $mobile-max-width) {

	.contact-us-page{
		padding: 16px;
	}

	.contact-us-page.home-container{
		padding-left: 15px;
		padding-right: 15px;
	}

	.contact-us-form{
		input:not(.button-emphasis), .button-emphasis, textarea{
			width: 100%
		}
	}

	.top-border-box, .top-border-box.size-2x{
		max-width:100%;
		min-width:100%;
	}

	.map-container{
		width:100%;
		flex:initial;
	}

	.top-border-box.map-box{
		display: flex;
		flex-wrap: wrap;
		padding: 5px 0 0 0;
	}

	#map{
		width:100%;
		height: 300px;
		margin: 0;
	}

	#address-section{
		padding: 16px;
		width: 100%;
		flex:initial;
	}

}