.greensightPortalApplications {

	#application-tile-wrapper {
		display: flex;
		flex-wrap: wrap;
	}
    .title-heading {
        color: rgb(43, 61, 65);
        font-size: 14px;
        font-weight: normal;
        height: 20px;
        letter-spacing: 0.13px;
        line-height: 20px;
        width: 250px;
    }
    .title-heading-line-2 {
        color: rgb(33, 33, 33);
        font-size: 32px;
        font-weight: normal;
        height: 40px;
        letter-spacing: 0.3px;
        line-height: 40px;
    }
    .nav-button {
        position:relative;
        display: flex;
		max-width: 25%;
		min-width: 25%;
		margin-right: 1%;

		background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 100%);
		border: 1px solid #BBBDC6;
		box-shadow: inset 0 -1px 0 0 #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		padding-top: 16px;
        padding-left: 16px;
        padding-bottom: 16px;
		color: black;
		margin-bottom: 15px;
    }
    .program-name-field {
        font-size: 16px;
        font-weight: bold;
        height: 24px;
        letter-spacing: 0.15px;
        line-height: 24px;
        width: 176px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .application-button:hover {
        .program-name-field {
            text-decoration-line: underline;
        }
    }
    .status {
        display: inline-block;
    }
    .red-icon {
        color: #a91700;
    }
    .green-icon {
        color: #589815;
    }
    .yellow-icon {
        color: #bf9000;
    }
    .yellow-background {
        background: rgb(191, 144, 0, 0.2);
        border-radius: 12px;
        height: 24px;
        padding: 5px;
    }
    .grey-background {
        background: rgb(248, 249, 249);
        border-radius: 12px;
        height: 24px;
        padding: 5px;
    }
    .red-background {
        background: rgba(182, 79, 53, 0.15);
        border-radius: 12px;
        height: 24px;
        padding: 5px;
    }
    .green-background {
        background: rgba(88, 152, 21, 0.15);
        border-radius: 12px;
        height: 24px;
        padding: 5px;
    }
    .warning-summary {
        color: #000;
    }
    .top-warn {
        background: rgb(191, 144, 0, 0.2);
    }
    .last-modified-date-field {
        color: rgb(33, 33, 33);
        font-size: 12px;
        font-weight: normal;
        height: 20px;
        letter-spacing: 0.11px;
        line-height: 20px;
        width: 176px;
    }
    .status-field {
        text-align: end;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0);
        height: 24px;
        padding-right: 0px;
    }
    @media only screen and (min-width: $desktop-min-width) {
		.nav-button{
			label{
				margin-top: 8px;
			}
		}
	}
	@media only screen and (max-width: $tablet-max-width) {
		.nav-button{
			display: flex;

			.nav-icon{
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 8px;
			}
		}
	}
	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.nav-button{
			max-width: 49%;
			min-width: 49%;
		}
	}
	@media only screen and (max-width: $mobile-max-width) {
		.nav-button{
			max-width: 100%;
			min-width: 100%;
            padding-left: 30px;
		}
        .status {
            padding-bottom: 5px;
        }
        #application-tile-wrapper {
            margin-top: 50px;
        }
        .title-heading-line-2 {
            padding-bottom: 75px;
        }
	}
}
