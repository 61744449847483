@import "../variables";

@mixin media-searchfilter-md {
	@media screen and (min-width: 300px) and (max-width: 600px) {
	    @content;
	}
}
@mixin media-searchfilter-lg {
	@media screen and (min-width: 601px) {
	    @content;
	}
}
@mixin media-drawer-sm {
	@media (max-width: 392px) {
		@content;
	}
}
@mixin media-drawer-lg {
	@media (min-width: 393px) {
		@content;
	}
}
@mixin media-dropdownbutton-md {
	@media (max-width: 16rem) and (min-width: 10rem) {
		@content;
	}
}
@mixin media-dropdownbutton-sm {
	@media (max-width: 10rem) {
		@content;
	}
}
@mixin media-dropdownbutton-lg {
	@media (max-width: 37.5em) {
		@content;
	}
}
@mixin media-datatable-sm {
	@media (max-width: 37.5em) {
		@content;
	}
}
@mixin media-datatable-lg {
	@media (min-width: 37.5em) {
		@content;
	}
}
@mixin media-pagination-lg {
	@media (min-width: 600px) {
		@content;
	}
}
@mixin media-pagination-sm {
	@media (max-width: 599px) {
		@content;
	}
}

#main-content {
	min-height: 200px !important;
	padding-bottom: 0px;
}

.greentech-so-reduced-intake-container[class="input-help-text"] lightning-helptext {
    position: absolute;
    top: 0;
    right: 20px;
    display: inline-block;
	left: -30px;
}
.slds-popover__body,
.slds-popover__footer,
.slds-popover__header {
    position: relative;
    padding: .5rem .75rem;
    word-wrap: break-word;
	margin-right: 5px;
}

.slds-popover {
    position: relative;
    border-radius: .25rem;
    width: 20rem;
    min-height: 2rem;
    z-index: 6000;
    background-color: #fff;
    display: inline-block;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16);
    border: 1px solid #d9dbdd;
}

.slds-popover--tooltip,
.slds-popover_tooltip {
    width: auto;
    max-width: 20rem;
    background: black;
    border: 0;
	left: 10px;
    top: 8px;
}
.slds-popover--tooltip .slds-popover__body,
.slds-popover_tooltip .slds-popover__body {
    font-size: 10px;
    color: #fff;
}

.slds-fall-into-ground {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, 0);
    transition: opacity .1s linear, visibility .1s linear, transform .1s linear;
    will-change: transform;
}

.slds-nubbin--bottom-left:before,
.slds-nubbin_bottom-left-corner:before,
.slds-nubbin_bottom-left:before {
    width: 1rem;
    height: 1rem;
    position: absolute;
    transform: rotate(45deg);
    content: '';
    background-color: inherit;
    bottom: -.5rem;
    margin-left: -.5rem;
}

.slds-nubbin--bottom-left:after,
.slds-nubbin--bottom-left:before,
.slds-nubbin_bottom-left-corner:after,
.slds-nubbin_bottom-left-corner:before,
.slds-nubbin_bottom-left:after,
.slds-nubbin_bottom-left:before {
    left: 1.5rem;
    top: 100%;
    margin-top: -.5rem;
}

.slds-nubbin--bottom-left:after,
.slds-nubbin_bottom-left-corner:after,
.slds-nubbin_bottom-left:after {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .16);
    z-index: -1;
}

.slds-popover :last-child {
    margin-bottom: 0;
}
.slds-button_icon-bare{
	background: none;
	border: none;
}

.greentech-so-reduced-intake-container {

	lightning-helptext {
		position: relative;
		display: inline-block;
		.slds-assistive-text {
			display: none;
		}

		lightning-button-icon {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			height: auto;
		}
		lightning-primitive-icon {
			height: 14px;
			width: 14px;
			display: inline-block;
			visibility: visible;
			svg {
				fill: #000000;
			}
		}
	}

	#lightningVfIntakeForm {

		font-size: 16px;

		ul, ol {
			list-style: none;
		}

		c-lwr-access-denied {
			.access-denied-block {
				margin: 50px auto;
				width: 355px;
				display: flex;
				flex-wrap: wrap;
			}
			
			.access-denied-title{
				font-size: 3rem;
				padding-left: 1rem;
				line-height: 3rem;
			}
		}

		c-lwr-progress-bar {
			.lwr-loader[aria-hidden=true] {
				display: none;
			}
			
			.lwr-loader {
				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				padding: var(--ced-spacing-xxlarge) 0;
				cursor: progress;
			}
			
			.lwr-loader__icon_container {
				align-items: center;
				display: flex;
				height: 96px;
				justify-content: center;
				position: relative;
				width: 96px;
			}
			
			.lwr-loader__icon_container:before {
				background: var(--ced-color-global-brand-4);
				border-radius: 99999em;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 0;
			}
			
			.lwr-loader__icon {
				background: var(--ced-color-global-white);
				border-radius: var(--ced-border-radius-base, .375rem);
				box-shadow: inset 0 0 0 3px var(--ced-color-global-neutral-3);
				display: flex;
				flex-direction: column;
				/* height: 32px; */
				justify-content: space-between;
				overflow: hidden;
				padding: var(--ced-spacing-small, 12px) var(--ced-spacing-small, 12px);
				/* width: 40px; */
				width: 64px;
				height: 56px;
				z-index: 0;
			}
			
			.lwr-loader__line {
				border-radius: 2px;
				display: block;
				height: 6px;
				width: 40px;
				will-change: transform;
			}
			
			.lwr-loader__line_1 {
				background: var(--ced-color-global-brand-2);
			}
			
			.lwr-loader__line_2 {
				background: var(--ced-color-global-brand-3);
			}
			
			.lwr-loader__line_3 {
				background: var(--ced-color-global-brand-4);
			}
			
			/* CSS animation keyframes */
			@keyframes loading {
				0% {
					background: var(--ced-color-global-neutral-5);
					transform: scale(95%);
				}
			
				3% {
					background: var(--ced-color-global-neutral-5);
				}
			
				33% {
					transform: scale(100%);
					background: var(--ced-color-global-brand-3);
				}
			
				73% {
					background: var(--ced-color-global-brand-3);
					transform: scale(100%);
				}
			
				98% {
					background: var(--ced-color-global-neutral-5);
				}
			
				100% {
					background: var(--ced-color-global-neutral-5);
					transform: scale(95%);
			
				}
			}
			
			.lwr-loader__text {
				display: block;
				text-align: center;
			}
		}

		c-lwr-icons {
			display: flex;
			
			.clickable {
				cursor: pointer;
			}
		}

		c-lwr-button {
			/* Primary Button **/
			.primary {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-primary);
				background-color: var(--ced-color-global-brand-2);
				color: var(--ced-color-text-inverse);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border-width: 0;
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.primary:hover {
				background-color: var(--ced-color-background-brand-hover)
			}

			.primary:focus {
				background-color: var(--ced-color-background-brand);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.primary:active {
				background-color: var(--ced-color-background-brand-focus);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			/*End Primary Button*/

			/***********Secondary Button***********/
			.secondary {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-neutral);
				background-color: var(--ced-color-global-neutral-6);
				color: var(--ced-color-global-neutral-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.secondary:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
			}

			.secondary:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.secondary:active {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			/***********End Secondary Button***********/

			.text {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: inherit;
				color: var(--ced-color-global-brand-2);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid inherit;
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.text:hover {
				background-color: var(--ced-color-global-neutral-5);
			}

			.text:focus {
				background-color: inherit;
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.text:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.danger{
				color: var(--ced-color-text-danger);
			}
			.danger-text{
				color: var(--ced-color-text-danger);
			}

			.thin-text{
				padding: var(--ced-spacing-small) 0;
			}
			/** Disabled button styling **/

			/***********Danger Button***********/
			.danger:not(.text){
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				/* box-shadow: var(--ced-shadow-button-neutral); */
				box-shadow: 0px -2px 0px rgba(255,255,255, 0.5) inset;
				background-color: var(--ced-color-global-danger-4);
				color: var(--ced-color-global-danger-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-1);
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.danger:hover {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-1);
			}

			.danger:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.danger:active {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			/***********End Danger Button***********/

			.lwr-input_loader {
				margin: auto;
				display: flex;
				max-width: 1.5rem;
				justify-content: space-between;
			}

			.lwr-input_loader span {
				width: 4px;
				height: 4px;
				border-radius: 2px;
				background: var(--ced-color-global-brand-3);
				color: var(--ced-color-global-brand-3);
				display: block;
				box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
				will-change: animation;
			}


			.lwr-input_loader .circle-1 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: .25s;
			}

			.lwr-input_loader .circle-2 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: .75s;
			}

			.lwr-input_loader .circle-3 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: 1.25s;
			}

			.visually-hidden {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				white-space: nowrap;
				border-width: 0;
			}

			button[aria-busy="true"] {
				cursor: wait;
				display: flex;
			}

			button[aria-busy="true"] .button-text {
				visibility: hidden;
			}

			button .lwr-input_loader {
				width: 1.5rem;
				margin: 0 auto;
				position: absolute;
				left: 50%;
				margin-left: -.75rem;
				top: 50%;
				margin-top: -2px;
			}

			@keyframes dot-pulse {
				0% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}

				40% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-2), 0 0 0 3px var(--ced-color-global-brand-2);
					background: var(--ced-color-global-brand-2);
				}

				70% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}

				100% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}
			}

			@keyframes dot-pulse-white {
				0% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-4), 0 0 0 0 var(--ced-color-global-brand-4);
					background: var(--ced-color-global-brand-4);
				}

				40% {
					box-shadow: inset 0 0 10px var(--ced-color-global-white), 0 0 0 3px var(--ced-color-global-white);
					background: var(--ced-color-global-white);
				}

				70% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-4), 0 0 0 0 var(--ced-color-global-brand-4);
					background: var(--ced-color-global-brand-4);
				}

				100% {
					box-shadow: inset 0 0 10px var(--ced-color-global-white), 0 0 0 0 var(--ced-color-global-white);
					background: var(--ced-color-global-white);
				}
			}


			button .lwr-input_loader .circle-1 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: .25s;
			}

			button .lwr-input_loader .circle-2 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: .75s;
			}

			button .lwr-input_loader .circle-3 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: 1.25s;
			}
			.display-none{
				display: none;
			}
		}

		c-lwr-icon-button {
			/***********Secondary Button***********/
			.secondary{
				position:relative;
				padding: var(--ced-spacing-xsmall);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-neutral);
				background-color: var(--ced-color-global-neutral-6);
				color: var(--ced-color-global-neutral-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
				display:flex;
				justify-content: center;
				align-items: center;
				transition: background var(--ced-transition-hover);
				margin: 4px;
			}

			.secondary:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
			}

			.secondary:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.secondary:active {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			/***********End Secondary Button***********/

			/* Primary Button **/
			.primary {
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-primary);
				background-color: var(--ced-color-global-brand-2);
				color: var(--ced-color-text-inverse);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border-width: 0;
				display: flex;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
				align-items: center;
				margin: 4px;
				gap: var(--ced-spacing-xxsmall);
			}

			.primary:hover {
				background-color: var(--ced-color-background-brand-hover)
			}

			.primary:focus{
				background-color: var(--ced-color-background-brand);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.primary:active {
				background-color: var(--ced-color-background-brand-focus);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}
			/*End Primary Button*/

			/***********Danger Button***********/

			.danger {
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-background-danger-light);
				color: var(--ced-color-text-danger);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-2);
				display:flex;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
				margin: 4px;
			}

			.danger:hover {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-2);
			}

			.danger:focus {
				background-color: var(--ced-color-background-danger-light);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.danger:active {
				background-color: var(--ced-color-global-danger-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}
			/***********End Danger Button***********/

			.text {
				position:relative;
				padding: var(--ced-spacing-xsmall);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-global-white);
				color: var(--ced-color-global-brand-2);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-white);
				display:flex;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				margin: 4px;
			}

			.text:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-5);
			}

			.text:focus {
				background-color: var(--ced-color-global-white);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.text:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.visually-hidden {
				clip: rect(0 0 0 0); 
				clip-path: inset(100%); 
				height: 1px; 
				overflow: hidden; 
				position: absolute; 
				white-space: nowrap; 
				width: 1px; 
			}

			.label {
				position: relative;
				/* left: var(--ced-spacing-xxsmall);
				top: var(--ced-spacing-xxsmall); */
				text-align: left;
			}

			.round{
				border-radius: var(--ced-border-radius-full);
			}

			.transparent{
				background-color: var(--ced-color-global-alpha-transparent);
				border-color: var(--ced-color-global-alpha-transparent);
			}

			.full-width {
				width: 100%;
			}
		}
 
		c-lwr-back-link {
			a {
				display: flex;
				align-items: center;
				font-size: var(--ced-font-size-xxsmall);
				line-height: 16px;
			}
		}

		c-lwr-notification {
			.container{
				flex-grow: 1;
				width: 100%;
				height: auto;
				padding-top: 12px;
				padding-bottom: 12px;
				padding-left: 12px;
				border-radius: 6px;
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				display: inline-block;
			}
			
			.content {
				display: inline-block;
				vertical-align: top;
				width: 75%;
				overflow-wrap: anywhere;
			}
			
			.link-info {
				text-decoration: none;
				opacity: 1;
				color: var(--ced-color-global-brand-2) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-success {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-success-1) !important;
				opacity: 1;
				color: var(--ced-color-global-success-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-warning {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-warning-1) !important;
				opacity: 1;
				color: var(--ced-color-global-warning-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-error {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-danger-1) !important;
				opacity: 1;
				color: var(--ced-color-global-danger-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			ul.circle-bullet {
				/* text-decoration: underline; */
				/* text-decoration-color:rgba(169,23,0,1); */
				list-style-type: disc;
				margin-left: 1em;
			}
			
			ul li::before {
				/* color: rgba(169,23,0,1); */
				font-weight: bold;
				display: inline-block; 
				width: 1em;
				margin-left: -1em;
			}
			
			.dismissable-notification {
				padding-right: 8px;
			}
			
			.type-icon {
				height: 24px;
				/* width: 8%; */
				margin-right: 8px;
				display: inline-block;
			}
			
			.close-icon {
				display: inline-block;
				float: right;
				margin-right: 14px;
			}
			
			.close-icon:hover {
				border-radius: 6px;
				opacity: 1;
				background-color: rgba(33,33,33, 0.07);
			}
			
			.close-icon:focus {
				border-radius: 8px;
				border: 2px solid rgba(125,142,207, 1);
				box-shadow: 0px 0px 0px 2px rgba(255,255,255, 1) inset;
				opacity: 1;
			}
			
			.close-icon:active {
				border-radius: 8px;
				border: 2px solid rgba(125,142,207, 1);
				box-shadow: 0px 0px 0px 2px rgba(255,255,255, 1) inset;
				opacity: 1;
			}
			
			.title {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
				display: inline-block;
				vertical-align: top;
				margin: 0;
			}
			
			.title-for-summary {
				font-weight: 700;
				letter-spacing: 0px;
			}
			
			.summary {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.info {
				border: 1px solid var(--ced-color-global-neutral-4);
				background-color: var(--ced-color-global-neutral-6);
			}
			
			.success {
				border: 1px solid var(--ced-color-global-success-2);
				background-color: var(--ced-color-global-success-3);
			}
			
			.warning {
				border: 1px solid var(--ced-color-global-warning-2);
				background-color: var(--ced-color-global-warning-3);
			}
			
			.error {
				border: 1px solid var(--ced-color-global-danger-2);
				background-color: var(--ced-color-global-danger-4);
			}
			
			.actions-container {
				display: inline-block;
				margin-top: 16px;
			}
			
			.action {
				width: 141px;
				height: 40px;
				border-radius: 6px;
				box-shadow: 0px -2px 0px rgba(255,255,255, 0.25) inset, 0px -1px 0px rgba(34,46,114, 1) inset;
				opacity: 1;
				background-color: rgba(80,103,190, 1);
				opacity: 1;
				color: rgba(255,255,255,1);
				font-size: 16px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
				vertical-align: top;
				margin-right: 16px;
			}
			
			.action-only{
				background: var(--ced-color-global-brand-2);
				width: 185px;
				height: 40px;
				border-radius: 6px;
				box-shadow: 0px -2px 0px rgba(255,255,255, 0.25) inset, 0px -1px 0px rgba(34,46,114, 1) inset;
				opacity: 1;
				color: rgba(255,255,255,1);
				font-size: 16px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
				vertical-align: top;
				margin-right: 16px;
			}
			
			.cancel {
				width: 80px;
				height: 40px;
				border-radius: 6px;
				opacity: 1;
				background-color: rgba(33,33,33, 0);
				opacity: 1;
				color: rgba(80,103,190,1);
				font-size: 16px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
				vertical-align: top;
			}
		}

		c-lwr-page-header {
			.category {
				font-weight: var(--ced-font-weight-normal);
				color: var(--ced-color-text);
				text-align: left;
				font-size: var(--ced-font-size-xxsmall);
				line-height: var(--ced-line-height-heading-xsmall);
				letter-spacing: 0.3px;
			}
			
			.header {
				font-weight: var(--ced-font-weight-semi-bold);
				color: var(--ced-color-text);
				text-align: left;
				margin-bottom: var(--ced-spacing-medium);
				font-size: var(--ced-font-size-xlarge);
				line-height: var(--ced-line-height-heading-xlarge);
			}
			
			.subheading-text {
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: var(--ced-font-size-medium);
				line-height: var(--ced-line-height-heading-medium);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			.line {
				width: 4px;
				margin-right: var(--ced-spacing-small);
				display: block;
				background-color: var(--ced-color-global-brand-3);
				height: auto;
				border-radius: 2px;
			}
			
			.subheading {
				display: flex;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.description {
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-body);
				color: var(--ced-color-text);
				text-align: left;
				margin-bottom: var(--ced-spacing-xlarge);
				max-width: var(--ced-content-width-large);
			}
			
			.link {
				margin-left: var(--ced-spacing-xsmall);
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text-brand) !important;
			}
			
			.link:hover {
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
			
			.link:focus {
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
				outline-width: var(--ced-text-focus-indicator-size);
				outline-offset: var(--ced-text-focus-indicator-size);
			}
			
			.link:active {
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
				outline-width: var(--ced-text-focus-indicator-size);
				outline-offset: var(--ced-text-focus-indicator-size);
			}
			
			.icon {
				display: inline;
				margin-left: var(--ced-spacing-xxsmall);
			}
		}

		c-lwr-input-field {
			.input-field {
				text-indent: 10px;
				width: 65%;
				height: 40px;
				border-radius: 0px 6px 6px 0px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset, 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
			}
			
			.input-field.invalid {
				border: 2px solid var(--ced-color-global-danger-2);
			}
			
			.rounded {
				width: 100%;
				border-radius: 6px 6px 6px 6px;
			}
			
			.input-field-label {
				display: block;
				/* margin-top: 24px; */
				margin-bottom: 9px;
				color: rgba(33,33,33,1);
				/* font-family: "Inter"; */
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
			}
			
			.required-field::after {
				content: "*";
				color: #b64f35;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.7;
			}
			
			.error-logo-input {
				width: 16px;
				height: 16px;
				display: inline-block;
				float: left;
				margin-right: 6px;
			}
			
			.error-message-input {
				width: 372px;
				height: auto;
				margin-bottom: 8px;
			}
			
			.error-message-input-content {
				color: rgba(169,23,0,1);
				font-size: 14px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 16px;
				display: table;
			}
			
			.country-code {
				width: 35%;
				height: 40px;
				border: none;
				border-radius: 6px 0px 0px 6px;
				padding: 8px;
				background-color: #f1f1f1;
			}
			
			.country-code::-ms-expand {
				display: none;
			}
		}

		c-lwr-text-area {
			label, textarea{
				display:block
			}
			
			.wrapperClass > *{
				margin-top: 2px;
			}
			
			.wrapperClass{
				width: inherit;
				height: inherit;
			}
			
			label{
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			label.required::after {
				content: "*";
				color: #b64f35;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.7;
			}
			
			textarea{
				padding: var(--ced-spacing-small);
				font-family: var(--ced-font-family-normal);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				box-sizing: border-box;
				box-shadow: var(--ced-shadow-input);
				border-radius: var(--ced-border-radius-base);
				border-width: var(--ced-border-width);
				background-color: var(--ced-color-global-white);
				border-color: var(--ced-color-border-neutral-dark);
				width: inherit;
				height: inherit;
			}
			
			textarea.wide {
				width: 100%;
				resize: vertical;
			}
			
			textarea:focus{
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.hidden{
				display: none;
			}
			
			.description-text, .count-text{
				font-size: 14px;
				color: var(--ced-color-text-light);
			}
			
			.error-message{
				display: flex;
				align-items: center;
			}
			
			.error-message > span{
				font-size: 14px;
				font-weight: var(--ced-font-weight-bold);
			}
			
			.error > .count-text, .error-message > span{
				color: var(--ced-color-text-danger);
			}
			
			.error > textarea{
				border: var(--ced-border-width-large) solid var(--ced-color-global-danger-2);
				border-radius: var(--ced-border-radius-base);
			}
			
			.visually-hidden {
				position: absolute;
				top: auto;
				overflow: hidden;
				clip: rect(1px, 1px, 1px, 1px);
				width: 1px;
				height: 1px;
				white-space: nowrap;
			}
		}

		c-lwr-address {
			.required {
				color: var(--ced-color-global-danger-1);
			}
			
			.label {
				font-size: 16px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
				display: block;
				margin-bottom: var(--ced-spacing-xxsmall);
				color: var(--ced-color-text);

				.required:after {
					content: none;
				}
			}
			
			.input-field {
				border-radius: var(--ced-border-radius-base);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset, 0px -2px 0px var(--ced-color-global-white) inset;
				opacity: 1;
				background-color: var(--ced-color-global-white);
				height: 40px;
				width: 100%;
				max-width: 600px;
			}
			
			.address2 {
				max-width: 213px;
			}
			
			.input-field:focus-visible {
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.option {
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.action-button-section {
				margin-bottom: var(--ced-spacing-large);
			}
			
			.radio-button-group-container {
				margin-bottom: var(--ced-spacing-xlarge);
			}
			
			.sub-title {
				margin-top: var(--ced-spacing-xlarge);
			}
			
			.add-building-info {
				margin-bottom: var(--ced-spacing-medium);
				margin-top: var(--ced-spacing-large);
			}
			
			.city, .state-province {
				margin-bottom: var(--ced-spacing-large);
			}
			
			.required-field {
				border: 2px solid var(--ced-color-global-danger-2);
			}
			
			.suggestions-container {
				position: absolute;
				z-index: 1;
				width: 100%;
				max-width: 600px;
				max-height: 150px;
				overflow-y: auto;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0.25rem;
				background-color: #fff;
			}
			
			.hide {
				display: none;
			}
			
			.suggestions {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}
			
			.suggestion {
				padding: 8px;
				cursor: pointer;
				width: 100%;
				text-align: left;
				background-color: transparent;
				border-color: transparent;
			}
			
			.suggestion:hover {
				background-color: #f0f0f0;
			}
			
			.error-inline-message{
				display: flex;
			}
			
			.error-outline-icon{
				margin-right: var(--ced-spacing-xxsmall);
				height: 16px;
				display: flex;
			}
			
			.errorMessage {
				margin-bottom: var(--ced-spacing-xsmall);
				color: var(--ced-color-global-danger-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 14px;
				letter-spacing: 0.3px;
				line-height: 16px;
				display: inline-block;
			}
			
			.register-container{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				column-gap: var(--ced-spacing-medium);
			}
		}

		c-lwr-product-selection {
			.input-label {
				opacity: 1;
				color: var(--ced-color-global-neutral-1);
				font-size: 16px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.product-card {
				margin-bottom: var(--ced-spacing-xsmall);
				padding: var(--ced-spacing-small) var(--ced-spacing-xsmall) var(--ced-spacing-small) var(--ced-spacing-xsmall);
				display: flex;
				gap: var(--ced-spacing-small);
				border-bottom: 1px solid var(--ced-color-global-neutral-4);
			}
			
			img {
				max-width: 40px;
				max-height: 40px;
				overflow: hidden;
			}
			
			.product-main-panel {
				flex: 1;
				display: flex;
				gap: var(--ced-spacing-small);
			}
			
			.product-details {
				flex: 1;
			}
			
			.product-edit {
				display: flex;
			}
			
			.product-quantity {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: var(--ced-spacing-small);
			}
			
			.primary-text {
				display: block;
				opacity: 1;
				color: var(--ced-color-global-neutral-1);
				font-size: 16px;
				font-weight: var(--ced-font-weight-bold);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0px;
				text-align: left;
				line-height: 24px;
			}
			
			.secondary-text {
				display: block;
				opacity: 1;
				color: var(--ced-color-global-neutral-2);
				font-size: 14px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
			}
			
			.input {
				height: 42px;
				/* If the input has an icon padding right needs to change to 8 px */
				padding: var(--ced-spacing-small);
				font-family: var(--ced-font-family-normal);
				font-size: 16px;
				line-height: var(--ced-line-height-component-base);
				box-sizing: border-box;
				box-shadow: var(--ced-shadow-input);
				border-radius: var(--ced-border-radius-base);
				border-width: var(--ced-border-width);
				background-color: var(--ced-color-global-white);
				border-color: var(--ced-color-border-neutral-dark);
				margin-right: 0;
			}
			
			.number-input {
				margin-top: var(--ced-spacing-xxsmall);
				width: 54px;
				text-align: center;
			}
			
			/* Hides number arrows */
			/* Chrome, Safari, Edge, Opera */
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			
			/* Firefox */
			input[type=number] {
				-moz-appearance: textfield;
			}
		}

		c-lwr-product-quick-select {
			.assistive-text {
				border: 0;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}
			
			.product-card {
				margin-bottom: var(--ced-spacing-xsmall);
				padding: var(--ced-spacing-small) var(--ced-spacing-xsmall) var(--ced-spacing-small) var(--ced-spacing-xsmall);
				display: flex;
				gap: var(--ced-spacing-small);
				cursor: pointer;
			
				border: none;
				border-bottom: 1px solid var(--ced-color-global-neutral-4);
				width: 100%;
				overflow: visible;
			
				background: transparent;
			
				/* inherit font & color from ancestor */
				color: inherit;
				font: inherit;
			
				/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
				line-height: normal;
			
				/* Corrects font smoothing for webkit */
				-webkit-font-smoothing: inherit;
				-moz-osx-font-smoothing: inherit;
			
				/* Corrects inability to style clickable `input` types in iOS */
				-webkit-appearance: none;
				text-align: left;
			}
			
			.primary-text {
				display: inline;
				opacity: 1;
				color: var(--ced-color-global-neutral-1);
				font-size: 16px;
				font-weight: var(--ced-font-weight-bold);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0px;
				text-align: left;
				line-height: 24px;
			}
			
			.secondary-text {
				opacity: 1;
				color: var(--ced-color-global-neutral-2);
				font-size: 14px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
			}
			
			.custom-product {
				padding: var(--ced-spacing-small);
				display: flex;
			}
			
			.plus-icon {
				margin-right: var(--ced-spacing-xsmall);
			}
			
			.custom-product-button-title {
				opacity: 1;
				color: var(--ced-color-global-neutral-1);
				font-size: 16px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.search-container {
				position: relative;
			}
			
			.search-input {
				height: 40px;
				width: 100%;
				padding: var(--ced-spacing-small) var(--ced-spacing-xlarge);
				padding-left: 44px;
				font-size: 16px;
				border-radius: var(--ced-border-radius-base);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				box-shadow: var(--ced-shadow-inset-input);
				background-color: var(--ced-color-global-white);
			}
			
			.search-input.invalid {
				border: 2px solid var(--ced-color-global-danger-2);
			}
			
			.magnifying-glass {
				position: absolute;
				top: 12px;
				left: 12px;
				width: 16px;
				height: 16px;
			}
			
			.clear-button {
				position: absolute;
				top: 8px;
				right: 12px;
				background: none;
				border: none;
				text-decoration: none;
				cursor: pointer;
				opacity: 1;
				color: var(--ced-color-global-brand-2);
				font-size: 16px;
				font-weight: var(--ced-font-weight-bold);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
			}
			
			.clear-button:hover {
				color: var(--ced-color-global-brand-2);
			}
			
			.search-input:focus-visible {
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.input-label {
				opacity: 1;
				color: var(--ced-color-global-neutral-1);
				font-size: 16px;
				font-weight: var(--ced-font-weight-normal);
				font-style: var(--ced-font-style-normal);
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.sugested-products-container{
				position: absolute;
				overflow-y: hidden;
				width: 100%;
				max-height: 350px;
				z-index: 2;
				background-color: var(--ced-color-global-white);
			}
			
			.suggested-products {
				border-radius: var(--ced-border-radius-small);
				border-width: var(--ced-border-width);
				border-color: var(--ced-color-border-neutral);
				border-style: solid;
				box-shadow: var(--ced-shadow-dropdown);
				opacity: 1;
				background-color: var(--ced-color-global-white);
				width: 100%;
				max-height: 350px;
				overflow-y: auto;
			}
			
			.description {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			
			img {
				max-width: 40px;
				max-height: 40px;
				overflow: hidden;
			}
			
			.required-field::after {
				content: "*";
				color: #b64f35;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.7;
			}

			.input-help-text {
				display: inline-flex;
			}
			
			.error-message-input {
				width: 372px;
				height: auto;
				margin-bottom: 8px;
			}
			
			.error-logo-input {
				width: 16px;
				height: 16px;
				float: left;
				margin-right: 6px;
			}
			
			.error-message-input-content {
				color: rgba(169,23,0,1);
				font-size: 14px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 16px;
				display: table;
			}
		}

		c-lwr-file-upload-modal {
			.side-padding {
				margin-left: var(--ced-spacing-medium);
				margin-right: var(--ced-spacing-medium);
			}
			
			.flex {
				display: flex;
			}
			
			.icon {
				margin-left: var(--ced-spacing-xsmall);
				flex: 1 1 0;
			}
			
			.title {
				font-weight: var(--ced-font-family-semi-bold);
				font-size: var(--ced-font-size-xlarge);
				line-height: var(--ced-line-height-heading-xlarge);
				margin-bottom: var(--ced-spacing-medium);
				flex: 1 1 100%;
			}
			
			
			:host{
				position: fixed;
				display: flex;
				justify-content: center;
				align-items: center;
				color: var(--ced-color-text);
				text-align: left;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}
			
			.background{
				position: fixed;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100vw;
				height: 100vh;
				background-color: var(--ced-color-global-alpha-neutral-2);
			}
			
			.modal{
				margin-left: var(--ced-spacing-medium);
				margin-right: var(--ced-spacing-medium);
				margin-top: 2.5rem;
				margin-bottom: 2.5rem;
				z-index: 3;
				width: var(--ced-content-width-medium);
				background-color: var(--ced-color-global-white);
				border-radius: var(--ced-border-radius-large);
				padding-top: var(--ced-spacing-medium);
				display: flex;
				flex-direction: column;
				box-shadow: var(--ced-shadow-modal);
				min-height: 22.5rem;
				max-height: calc(100vh - 5rem);
				height: auto;
				position: static;
			}
			
			.title{
				font-weight: var(--ced-font-weight-bold);
				font-size: var(--ced-font-size-xlarge);
				line-height: var(--ced-line-height-heading-xlarge);
				margin-bottom: var(--ced-spacing-medium);
			}
			
			.current-account-area{
				padding: var(--ced-spacing-small);
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-background-neutral);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral);
				box-shadow: 0px -2px 0px var(--ced-color-global-white) inset;
				margin-bottom: var(--ced-spacing-medium);
			}
			
			.account{
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				display: flex;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
			}
			
			.current-account{
				font-weight: var(--ced-font-weight-bold);
			}
			
			.accounts{
				display: flex;
				min-height: min-content;
				flex-direction: column;
				flex-grow: 1;
			}
			
			.account-option{
				padding: var(--ced-spacing-small);
				border-radius: var(--ced-border-radius-base);
				display: flex;
				align-items: center;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.account-name{
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-body);
				text-align: left;
			}
			
			.radio-button {
				width: 24px;
				height: 24px;
				appearance: none;
				border-radius: 50%;
				background: var(--ced-color-global-neutral-6);
				box-shadow: inset 0px 0px 0 2px var(--ced-color-global-neutral-3);
				margin-right: var(--ced-spacing-xsmall);
			}
			
			.focus-button {
				box-shadow: inset 0px 0px 0 2px var(--ced-color-global-neutral-3), 0px 0px 0px 2px var(--ced-color-focus-indicator-offset), 0px 0px 0px 4px var(--ced-color-focus-indicator);
				outline-width: 0;
			}
			
			.active-button {
				box-shadow: 0px 0px 0 2px var(--ced-color-global-brand-2);
				border: 2px solid var(--ced-color-global-neutral-6);
				background: var(--ced-color-global-brand-2);
			}
			
			.active-box {
				background-color: var(--ced-color-background-brand-light);
			}
			
			.bottom{
				border-bottom-left-radius: var(--ced-border-radius-large);
				border-bottom-right-radius: var(--ced-border-radius-large);
				box-shadow: 0px 1px 0px var(--ced-color-global-neutral-4) inset;
				background-color: var(--ced-color-background-neutral);
				padding: var(--ced-spacing-medium);
			}
			
			.change-account-button{
				margin-right: var(--ced-spacing-medium);
			}
			
			.visually-hidden {
				clip: rect(0 0 0 0); 
				clip-path: inset(100%); 
				height: 1px; 
				overflow: hidden; 
				position: absolute; 
				white-space: nowrap; 
				width: 1px; 
			  }
			
			.modal:focus{
				outline-offset: calc(var(--ced-focus-indicator-offset-inner) * 2);
				outline-width: var(--ced-focus-indicator-width);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
			}
			
			.content{
				flex: 1;
				display: flex;
				overflow-y: auto;
				margin-top: var(--ced-spacing-xsmall);
			}
			
			.subtitle{
				font-weight: var(--ced-font-weight-bold);
				font-size: 14px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-xsmall);
				text-align: left;
				color: var(--ced-color-text);
			}
			
			.link {
				display: flex;
				margin-bottom: var(--ced-spacing-medium);
				width: fit-content;
				align-items: center;
			}
			
			.link:focus {
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
				outline-width: var(--ced-text-focus-indicator-size);
				outline-offset: var(--ced-text-focus-indicator-size);
			}
			
			.link:active {
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
				outline-width: var(--ced-text-focus-indicator-size);
				outline-offset: var(--ced-text-focus-indicator-size);
			}
			
			.link-text {
				margin-left: 4px;
				color: var(--ced-color-text-brand);
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
			}
			
			.link:hover .link-text {
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
			
			.link:focus .link-text {
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
		}

		c-lwr-file-upload-multiple {
			.main-container{
				display: flex;
				flex-direction: column;
				/*padding: var(--ced-spacing-medium);*/
				gap: var(--ced-spacing-xsmall);
				background-color: var(--ced-color-global-white);
				max-width: 400px;
				text-align: left;
			}
			
			h3{
				font-size: 16px;
			}
			.subtext, h4{
				color: var(--ced-color-text-light);
				font-size: 16px;
			}
			.attachments-container, .error-attachments-container{
				display: flex;
				flex-direction: column;
			}
			.attachments-list a{
				color: var(--ced-color-global-brand-2);
			} 
			
			.file-error-box {
				border-radius: 6px;
				border: 1px solid var(--ced-color-text-danger);
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
				padding: 12px;
				font-size: 16px;
			}
			
			.file-error-box button{
				border: none;
				background: transparent;
				float: right;
			}
			
			.file-error {
				color: var(--ced-color-text-danger);
			}
			
			.uploading {
				display: flex;
				align-items: center;
			}
			
			.dropzone{
				border-radius: 6px;
				border: 2px dashed var(--ced-color-border-neutral-dark);
				opacity: 1;
				background-color: rgba(255,255,255, 1);
				position: relative;
				height: 5rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			.error .dropzone{
				border: 2px dashed var(--ced-color-border-danger);
			}
			
			.dropzone [type="file"] {
				cursor: pointer;
				position: absolute;
				opacity: 0;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
			}
			
			.dropzone:focus-within{
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-border-brand);
			}
			
			.dropzone:hover{
				background: var(--ced-color-background-transparent-hover);
				border: 2px dashed var(--ced-color-border-brand);
			}
			
			.dropzone div, .filename{
				display: flex;
				justify-content: space-between;
			}
			
			.filename{
				border-radius: 6px;
				border: 1px solid var(--ced-color-border-neutral);
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
				padding: 12px;
				font-size: 16px;
			}
			
			.filename button{
				border: none;
				background: transparent;
			}
			
			.error .filename{
				border: 1px solid var(--ced-color-border-danger);
				background-color: var(--ced-color-background-danger-light);
			}
			
			.dropzone, .filename, .file-error-box{
				margin-bottom: var(--ced-spacing-medium);
				color: var(--ced-color-text-brand);
				font-weight: var(--ced-font-weight-bold);
			}
			
			.description-text{
				font-size: 14px;
				color: var(--ced-color-text-light);
			}
			
			.error-message{
				display: flex;
				align-items: center;
			}
			
			.error-message > span{
				font-size: 14px;
				font-weight: var(--ced-font-weight-bold);
				color: var(--ced-color-text-danger);
			}
			
			c-lwr-button{
				margin-right: var(--ced-spacing-small);
			}
			
			.upload-wrapper{
				font-size: 14px;
				display: flex;
				align-items: center;
			}
			
			.upload-success{
				font-size: 14px;
				color: var(--ced-color-text-success);
				display: inline-flex;
				align-items: center;
			}
			
			.hidden{
				display: none;
			}
			
			.fadeout{
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s linear 300ms, opacity 300ms;
			}
			
			.title {
				font-weight: var(--ced-font-weight-bold);
			}
			
			.required-field::after {
				content: "*";
				color: #b64f35;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.7;
			}	
		}

		c-lwr-icons {
			display: flex;
			
			.clickable {
				cursor: pointer;
			}
		}

		c-lwr-tooltip {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
			.align-center{
				display: flex;
				justify-content: center;
			}
			
			.align-vertically{
				display: flex;
				align-items: center;
				width: inherit;
				height: inherit;
			}
			
			.tooltip {
				width: max-content;
				max-width: 14rem;
				background-color: var(--ced-color-global-neutral-1);
				color: var(--ced-color-text-inverse);
				text-align: left;
				padding: var(--ced-spacing-xsmall);
				border-radius: var(--ced-border-radius-small);
				position: absolute;
				z-index: 1;
				font-weight: var(--ced-font-weight-bold);
				font-size: 14px;
				line-height: 16px;
				border-width: 0;
				box-sizing: border-box;
				text-wrap: wrap;
			}
			
			.tooltip-above {
				bottom: 100%;
				/* Size of pointer (6px) plus 4px gap */
				margin-bottom: 10px;
			}
			
			.tooltip-below {
				top: 100%;
				/* Size of pointer (6px) plus 4px gap */
				margin-top: 10px;
			}
			
			.tooltip-right{
				left: calc(100% + 10px);
			}
			
			.tooltip-left{
				right: calc(100% + 10px);
			}
			
			/* Creates arrow */
			.tooltip-above::after {
				content: " ";
				position: absolute;
				top: 100%;
				/* At the bottom of the tooltip */
				left: 50%;
				margin-left: -6px;
				border-width: 6px;
				border-style: solid;
				border-color: var(--ced-color-global-neutral-1) transparent transparent transparent;
			}
			
			.tooltip-below::after {
				content: " ";
				position: absolute;
				bottom: 100%;
				left: 50%;
				margin-left: -6px;
				border-width: 6px;
				border-style: solid;
				border-color: transparent transparent var(--ced-color-global-neutral-1) transparent;
			}
			
			.tooltip-right::after {
				content: " ";
				position: absolute;
				top: 50%;
				right: 100%;
				margin-top: -6px;
				border-width: 6px;
				border-style: solid;
				border-color: transparent var(--ced-color-global-neutral-1) transparent transparent;
			}
			
			.tooltip-left::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 100%;
				margin-top: -6px;
				border-width: 6px;
				border-style: solid;
				border-color: transparent transparent transparent var(--ced-color-global-neutral-1);
			}
		}

		c-lwr-checkbox {
			display: flex;
			position: relative;

			input[type=checkbox] {
				-webkit-appearance: none;
				appearance: none;
				background: #FAFAFA; 
				border: 2px solid var(--ced-color-border-neutral-dark);
				height: 1.5em;
				width: 1.5em;
				min-height: 1.5em;
				min-width: 1.5em;
				display: inline-block;
				border-radius: 100%;
				vertical-align: text-bottom;
				position: relative;
			
				&[disabled],
				&[aria-disabled=true] {
					opacity: .4;
				}
			
				&::before {
					content: '';
					position: absolute;
					margin: auto;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;
					top: 0;
				}
			
				&:focus {
					outline: 2px solid var(--ced-color-global-brand-3);
					outline-offset: 2px;
				}
			}
			
			input[type=checkbox] {
				border-radius: 6px;
			}
			  
			input[type=checkbox]:indeterminate::before,
			input[type=checkbox][aria-checked=mixed]::before {
				border: 2px solid var(--ced-color-global-brand-3);
				height: 0;
				width: 40%;
			}
			
			.default-checkbox:checked::before {
				border-right: 2px solid #FFFFFF;
				border-bottom: 2px solid #FFFFFF;
				height: 70%;
				width: 40%;
				transform: rotate(45deg) translateY(-7%) translateX(-12%);
			}
			
			.minus-checkbox:checked::before {
				border-right: 2px solid #FFFFFF;
				border-bottom: 0px solid #FFFFFF;
				height: 70%;
				width: 40%;
				transform: rotate(90deg) translateY(3%) translateX(-25%);
			}
			
			input[type=checkbox].error-checkbox {
				border: 2px solid #D34A35;
			}
			
			input[type=checkbox]:checked {
				background: var(--ced-color-global-brand-2);
			}
			
			.checkbox-label {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				margin-left: 8px;
				vertical-align: top;
			}
			
			.label {
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				margin-left: var(--ced-spacing-xsmall);
				color: var(--ced-color-text);
				text-align: left;
				white-space: normal;
			}
			
			.hidden {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				white-space: nowrap;
				border-width: 0;
			}
		}

		c-lwr-greentech-service-order-intake-reduced {
			
			.intake-content {
				display: block;
				max-width: 789px;
			}

			.line {
				display: block;
				height: 1px;
				background-color: var(--ced-color-global-neutral-4);
				margin-top: var(--ced-spacing-xlarge);
				margin-bottom: var(--ced-spacing-xlarge);
			}

			.required-field-label::after {
				content: "*";
				color: #b64f35;
				font-size: 15px;
				font-weight: bold;
				line-height: 1.7;
			}

			.address-container,
			.intake-select,
			c-lwr-input-field,
			c-lwr-product-selection,
			c-lwr-product-quick-select,
			c-lwr-file-upload-multiple,
			c-lwr-text-area {
				display: block;
				margin-top: var(--ced-spacing-medium);
				margin-bottom: var(--ced-spacing-large);
			}

			.intake-select {
				display: flex;
				flex-direction: column;
				gap: var(--ced-spacing-xsmall);
			}

			.intake-select label {
				font-weight: var(--ced-font-weight-normal);
				font-size: 16px;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				text-align: left;
			}

			.intake-select select {
				border-radius: var(--ced-border-radius-base);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset, 0px -2px 0px var(--ced-color-global-white) inset;
				opacity: 1;
				background-color: var(--ced-color-global-white);
				height: 40px;
				width: 100%;
				max-width: 600px;
			}

			.intake-select select:focus-visible {
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.intake-select select.invalid {
				border: 2px solid var(--ced-color-global-danger-2);
			}

			c-lwr-page-header.page-header {
				display: block;
				margin-top: var(--ced-spacing-medium);
				margin-bottom: var(--ced-spacing-xlarge);
			}

			.lwr-text-heading-large {
				display: block;
				margin-top: var(--ced-spacing-xlarge);
				margin-bottom: var(--ced-spacing-large);
			}

			.lwr-text-heading-medium,
			.Optimizer-Rapid-Shutdown-Equipment-layout {
				display: block;
				margin-top: var(--ced-spacing-large);
				margin-bottom: var(--ced-spacing-medium);
			}

			c-lwr-notification {
				display: block;
				margin-top: var(--ced-spacing-medium);
				margin-bottom: var(--ced-spacing-medium);
			}

			.agreement {
				padding-top: var(--ced-spacing-xsmall);
				padding-bottom: var(--ced-spacing-xsmall);
				padding-right: var(--ced-spacing-small);
				padding-left: var(--ced-spacing-small);
			}
			
			.error-inline-message {
				display: flex;
				margin-top: var(--ced-spacing-xsmall);
			}

			.error-message {
				font-size: 14px;
				margin: 0;
				line-height: 16px;
			}
			
			.error-outline-icon{
				margin-right: var(--ced-spacing-xxsmall);
				height: 16px;
				display: flex;
			}
			
			.hidden {
				display: none;
			}
		}
	}
}
			
/* apply animation only if the user has not set a reduced-motion setting in their browser */
@media (prefers-reduced-motion: no-preference) {

	.greentech-so-reduced-intake-container {

		c-lwr-progress-bar {

			.lwr-loader__line {
				background: var(--ced-color-global-brand-3);
			}

			.lwr-loader__line:nth-child(3) {
				animation: loading 3s ease-out infinite;
			}

			.lwr-loader__line:nth-child(2) {
				animation: loading 3s ease-out infinite .3s;
			}

			.lwr-loader__line:nth-child(1) {
				animation: loading 3s ease-out infinite .6s;
			}
		}
	}
}
			
@media (max-width: 37.5em){

	.greentech-so-reduced-intake-container {

		c-lwr-address {
			.register-container{
				display: block;
			}
		}

		c-lwr-file-upload-modal {

			.modal{
				width: 100% !important;
			}
		}
	}
}
			
@media (min-width: 37.5em) {

	.greentech-so-reduced-intake-container {

		c-lwr-page-header {

			.header {
				font-size: var(--ced-font-size-xxlarge);
				line-height: var(--ced-line-height-heading-xxlarge);
			}
		}
	}
}		

@media (max-width: 64em) {

	.greentech-so-reduced-intake-container {

		padding: 16px;

		c-lwr-product-selection {

			.product-main-panel {
				flex-direction: column;
			}
		}

		c-lwr-file-upload-multiple {
			.dropzone{
				visibility: hidden;
				height: 0px;
				margin: 0px;
			}
		}

		c-lwr-greentech-service-order-intake-reduced {

			.content-actions {
				display: flex;
				flex-direction: column;
				justify-content: right;
				gap: var(--ced-spacing-xsmall);
			}
			
			c-lwr-button {
				white-space: nowrap;
				width: 100%;
			}
		}
	}
}

@media (min-width: 64em) {

	.greentech-so-reduced-intake-container {

		padding: 16px;

		c-lwr-product-selection {

			.product-main-panel {
				flex-direction: row;
			}
		}

		c-lwr-greentech-service-order-intake-reduced {

			.content-actions {
				display: flex;
				flex-direction: row;
				justify-content: right;
				gap: var(--ced-spacing-xsmall);
			}

			c-lwr-button {
				white-space: nowrap;
				width: auto;
			}
		}
	}
}
@media (max-width: 450px) {
	#lightningVfIntakeForm {
		c-lwr-greentech-service-order-intake-reduced {
			.Optimizer-Rapid-Shutdown-Equipment-layout .lwr-text-heading-medium {
				margin-bottom: 35px;
			}
		}

	}
}