@import "../variables";

.add-doc-button{
	border: 1px solid;
	padding: 8px;
	position: absolute;
	background-color: #fff;
	left: 14px;
	cursor: pointer;
	display: none;
	z-index: 200;
}

.admin-job-details{

	.deaktop-break:after{
		content: "";
		display: block;
	}
	

	.item-button-bar > input{
		margin-left: 3px;
	}

	.line-item-menu-button{
		
		text-align: center;
	}

	#addLineDocsModal .modal-body>div input, #addLineDocsModal .modal-body>div .bootstrap-select{
		width: 100%
	}

	.admin-container .admin-right-content{
		padding-bottom: 33px;
	}

	.fa-xl{
		font-size: 1.666667em;
	}

	.job-item-table .footable-first-column{
		white-space: nowrap;
	}

	.deleteLineDocs{
		color: grey;
	}

	.relatedOrders{
		display: none;
	}

	#relatedOrdersModal .relatedOrders{
		display: block;
		width: 100%;
	}

	.jobLinesTable2{
		position:relative;
		max-width:100%;
		margin:auto;
		overflow:hidden;
	}

	.table-wrap {
		width:100%;
		padding-bottom: 0;
	}

	.openRelatedOrders{
		cursor: pointer;
	}

	.table-wrap{
			position: relative;
			height: 400px;
			overflow: auto;
			margin-top: 30px;
		}

	.jobLinesTable2{
		
		

		.shadow{
			width: 12px;
			background-image: linear-gradient(to right, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
			position: absolute;
			top: 30px;
			bottom: 0;
			right: 0;
			z-index: 101;
			pointer-events:none;

			&.shadow-left{
				background-image: linear-gradient(to left, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
				display: none;
			}
		}

		table {
			width:100%;
			margin:auto;

			&.admin-table{
				margin-top: 0px;
			}


			th {
				white-space: nowrap;
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				box-shadow: inset 0 1px 0 0 #b9bbc0;
				z-index: 99;

				&.fixed-side{
					z-index: 100;
				}
			}

			.fixed-side{
				position: -webkit-sticky;
				position: sticky;
				z-index: 50;
			}

			td.fixed-side{
				background: white;
				background-clip: padding-box;
			}

			.fixed-side:nth-child(1){
				left: 0px;
				min-width: 35px;
				max-width: 35px;
			}
			
			.fixed-side:nth-child(2){
				left: 35px;
				min-width: 35px;
				max-width: 35px;
			}
			
			.fixed-side:nth-child(3){
				left: 70px;
				min-width: 100px;
				max-width: 100px;
			}
			
			.fixed-side:nth-child(4){
				left: 170px;
				min-width: 150px;
				max-width: 150px;
			}
			
			.fixed-side:nth-child(5){
				left: 320px;
				min-width: 84px;
				max-width: 84px;
			}
		}

		.clone {
			position:absolute;
			top:10px;
			left:0;
			pointer-events:none;

			th, td {
				visibility:hidden;
				border-color:transparent;
			}

			.fixed-side {
				visibility:visible;
			}

			td.fixed-side {
				background:#fff;
			}
		}
	}

	.job-inventory-table{
		th {
			white-space: nowrap;
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			box-shadow: inset 0 1px 0 0 #b9bbc0;
			z-index: 99;
		}
	}


	.detail-box{
		background-color: #f4f4f4;
		border: solid 1px #b9bbc0;
		padding: 20px 15px;

		label{
			margin-top: 20px;
		}

		&:first-child{
			margin-right: 31px;
		}

		&:last-child{
			margin-left: 31px;
		}
	}

	.job-detail-info-table{
		display: flex;
	}

	div.admin-right-content table.admin-table tbody tr td, 
	div.admin-right-content table.admin-table thead tr th{
		padding-left: 15px;
		padding-right: 15px;
	}

	.nowrap{
		white-space: nowrap;
	}

	.report-header {
		margin-top: 20px;
		padding-bottom: 15px; 
		border-bottom: 1px solid #aaa;
	}

	.job-run-report {
		margin-top: 20px;
		margin-bottom: 10px;

		h5 {
			font-weight: bold;
		}
	}

	#jobExportButton {
		margin-left: 2px;
	}

	.job-release-materials{
		float: right;
	}
}

.job-detail-info-table{

	h5{
		padding-top: 10px;
	    padding-bottom: 10px;
	    border-bottom: solid 1px #b9bbc0;
	    display: block;
	    height: auto;
	}

    .row > div:not(.color-neutral-light-text){
    	word-wrap: break-word;
    }

    .first-col{
		flex: 1;
	}
}

.job-release-materials{
	display: inline-block;
}

.uploaded-title {
    display: block;
}

.collapsible-content .button-column{
	display: flex;
	justify-content: flex-end;

	input{
		align-self: flex-end;
	}
}

#lineDocsModal{
	table:not(.table-condensed):not(.radio-options){
	    width: 100%;
		margin-bottom: 1em;

		tbody tr{
			&:last-of-type{
				border: none;
			}

			td{
				padding: .5em 0px;

				&:first-of-type{
					width:100%;
				}
			}
		}
	}
}

.admin-table{
	.footable-row-detail-value .fa-ellipsis-v{
		display: none;
	}

	.docList{
		display: none;

		


	}
}



.release-materials{
	.release-table{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.nav-buttons{
		position: absolute;
		bottom: 0;
		right: 15px;
		text-align: right;
	}

	.nav-buttons input{
		display: block;
	}

	.alert-box{
		font-size: 13px;
		line-height: 1.5;
		color: $color-error;
		border-radius: 5px;
		border: solid 1px $color-error;
	}
}



@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.admin-job-details{
		.left-details .admin-detail-row:last-of-type{
			border-bottom: 1px solid #dde1e2;
		}

		.deaktop-break:after{
			content: "";
			display: block;
		}

		.nav-tabs{
			margin-top: 40px;
		}
	}
}

@media only screen and (max-width: $mobile-max-width) {
	.admin-job-details{
		.nav>li>a{
			padding: 10px 9px;
		}

		.left-details .admin-detail-row:last-of-type{
			border-bottom: 1px solid #dde1e2;
		}

		.item-button-bar > button, .item-button-bar > a, .item-button-bar > input{
			display: block;
			text-align: center;
			margin: 1px;
			margin-bottom: 3px;
			width: 100%;
		}

		.release-materials{
			.release-table{
				margin-left: -15px;
				margin-right: -15px;
			}

			.full-width-xs{
				display: block;
				width: 100%
			}
		}

		.job-detail-info-table{
			flex-wrap: wrap;

			.detail-box{
				&:first-child{
					margin-right: 0;
					margin-bottom: 31px;
				}

				&:last-child{
					margin-left: 0;
					margin-top: 31px;
				}
			}

		}

		.job-release-materials{
			display: block;
			text-align: center;
			float: none;
			margin-bottom: 20px;
		}

		.jobLinesTable2 .clone, .jobLinesTable2 .shadow{
			display: none !important;
		}

		.deaktop-break:after{
			content: " ";
			display: inline;
		}
	}
}