@import "../variables";

.all-brands {
	margin-top: 40px;
	padding-left: 30px;
	padding-right: 0px;

	.brands-row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		
		-webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        > .sm-3 {
        	/*-ms-flex: 1 0 auto;
			-webkit-flex-grow: 1;
			flex-grow: 1;*/
		    
		    display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
        }

        > .sm-3 {
        	width: 25%;
        	padding-right: 30px;
        }       
	}

	.brand {
		margin-top: 30px;
		border: 1px solid #b9bbc0;
		padding: 30px 60px;
		text-align: center;
		width: 100%;

		.image-container {
			height: 153px;
			width: 100%;

			a {
				display: inline-block;
    			width: 95%;				
			}			
			img {
				max-width: 100%;
				max-height: 153px;;
			}
		}	

		.image-container:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}	

		.title {
			font-size: 16px;
			line-height: 1.6;
			text-align: center;
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.all-brands {
		margin-top: 30px;
		padding-left: 15px;
		padding-right: 0px;

		.brand {
			margin-top: 20px;
			padding: 20px;

			.image-container {
				height: 100px;

				img {
					max-height: 100px;;
				}
			}
		}

		.title {
			font-size: 15px;
			line-height: 1.7;
		}

		.brands-row {
	        > .sm-3 {
	        	padding-right: 15px;
	        }       
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.all-brands {
		margin-top: 30px;
		padding-left: 15px;
		padding-right: 5px;

		> div.row {
			margin-right: 0;
		}

		.brand {
			padding: 15px;
			margin-top: 10px;

			.image-container {
				height: 79px;

				img {
					max-height: 79px;;
				}
			}
		}

		.title {
			font-size: 15px;
			line-height: 1.7;
		}

		.brands-row {
			> .xs-6 {
				padding-right: 10px;
				width: 50%;
			}
		}
	}
}