@import "../variables";

.upload-product-photo {
	margin-top: 40px;
	padding-left: 30px;
	padding-right: 30px;

	.product-headings {
		font-weight: bold;
	}

	.fa-times-circle{
		color: #ff0000;
		padding-top: 12px;
	}

	.line-items {
		.line-item {
			.drag-leave {
				padding: 70px 10px;
				color: #1D74B7;
				font-weight: bold;
				font-size: 0.9em;
				border: 1px solid #1D74B7;
				box-shadow: none;
				border-radius: 5px;
				max-width: 165px;
				background-color: #E9F1F8;
			}

			.drag-leave.is-dragover {
				background-color: green;
				color: white;
			}

			.drag-leave.previewImage {
				padding: 10px 10px;

				img.file-drop-box {
					max-width: 100%;
					border: none;
					border-radius: 0;
					padding: 0;
				}
			}

			input[type="file"] {
				display: none;
			}

			.row.vertical-align {
				.text-center {
					justify-content: center;
				}
				.text-right {
					justify-content: flex-end;
				}
			}

			.fileupload {
				label {
					display: inline-block;
					cursor: pointer;
				}				
			}

			.error-container {
				width: 100%;

				.field-error-message {
					position: relative;
					top: 0;
					color: $color-error;
					font-size: 13px;
					line-height: 1.5;
					display: none;
				}

				.field-error-message.active {
					position: relative;
					top: 0;
					color: $color-error;
					font-size: 13px;
					line-height: 1.5;
					display: block;
				}
			}

			.error-container.active {
				.field-error-message {
					display: block;
				}
				div.field-error-message ~ input {
					border: solid 1px $color-error;
				}
			}

			.deleteProductRow {
				cursor: pointer;
			}
		}
	}

	.add-another-row {
		#addAnotherProductButton {
			padding: 12px 18px 13px 18px;
		}
	}	
}

.upload-product-photo.cart-page .line-items {
    margin-bottom: 15px;
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.upload-product-photo {
		margin-top: 30px;
		padding-left: 15px;
		padding-right: 15px;

		.line-items {
			.line-item {
				.drag-leave {
					padding: 30px 10px;
				}

				.drag-leave.is-dragover {
					background-color: green;
					color: white;
				}

				.row.vertical-align {

					.file-drop-box {
						display: none;
						max-width: 100%;
					    border: none;
					    background-color: transparent;
					    padding: 0;
					    max-height: 175px;
					}

					.file-drop-box.previewImage {
						display: block;

						img {
							display: inline;
						}
					}
				}
			}
		}	
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.upload-product-photo {
		margin-top: 30px;
		padding-left: 0;
		padding-right: 0;

		h2 {
			padding-left: 15px;
		}

		> div.row {
			margin-right: 0;
		}

		.error-row .top-error {
			margin-left: 15px;
		}

		#uploadingMessage {
			text-align: center;
			display: block;
		}

		.line-items {
			margin-top: 15px;

			.line-item {
				padding-top: 10px;
				padding-bottom: 10px;

				.row.vertical-align {
					display: block;
					margin-left: 0;

					.file-drop-box {
						display: none;
						max-width: 100%;
					    border: none;
					    background-color: transparent;
					    padding: 0;
					    max-height: 175px;
					}

					.file-drop-box.previewImage {
						display: block;

						img {
							display: inline;
						}
					}

					.fileupload {
						label {
							width: 100%;
							text-align: center;
						}
					}

					.quantityContainer {
						margin-top: 10px;

						.field-error-message {
							text-align: right;
						}

						.deleteProductRow {
							float: left;
							padding-top: 15px;
						}
						.productUploadQuantity {
							float: right;
						}
					}					
				}
			}
		}

		.add-another-row {
			.col-sm-6 {
				padding-left: 30px;

				button {
					width: 100%;
					margin-bottom: 10px;
				}
			}			
		}
	}
}