@import "../variables";

body.solar-template {
	.search-contents .search-container:not(.frequent-purchas-container) {
		display: block;
	}

	.solar-search {
		&:not(.admin-right-content){
			margin-top: 24px;
			margin-left: 40px;
			margin-right: 40px;
			display: block;
		}

		#search-box-search-page {
			margin-top: 8px;

			#search-input-search {
                height: 38px;
				padding-left: 12px;
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: 0.2px;	
				width:100%;
				z-index: 3;
				border-top-left-radius: 0px;
	            border-bottom-left-radius: 0px;
	            border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
				border: 1px solid #959ea0;
            }

            #search-input-search::placeholder {
				color: #959ea0;
            }

            .search-input-container {
            	display: table-cell;
            	vertical-align: middle;
            	position: relative;

            	#search-input-icon-search {
            		position: absolute;
            		width: 36px;
					height: 35px;
				    right: 1px;
				    top: 23px;
				    color: #959ea0;
				    background-color: #fff;
				    cursor: pointer;
				    left: auto;
				    display: block;
				    z-index: 4;

				    i {
				    	padding-top: 10px;
						padding-left: 12px;
				    }
            	}
            }	     

            #search-box-search {
            	display: table;
            	width: 100%;
            	position: relative;
            	height: 80px;
            	padding-right: 15px;

            	.search-categories {
            		display: table-cell;
            		width: 140px;
            		vertical-align: middle;
            	}
            }	            

            .styled-select {
            	height: 38px;					
				display: inline-block;
				width: 140px;
				vertical-align: middle;
			    overflow: hidden;
			    cursor: pointer;
			    position: relative;
			    margin-left: 0;
			    border-top-left-radius: 2px;
			    border-bottom-left-radius: 2px;
			    border-top: 1px solid #959ea0;
				border-left: 1px solid #959ea0;
				border-bottom: 1px solid #959ea0;				    

	            select {
	                background: transparent;
	                border: 0;
	                border-radius: 0;
	                -webkit-appearance: none;
	                width: 140px;
	                top: 0;
	                left: 0px;
	                background-color: white;
	                height: 38px;
	                margin-top: 0;
	                position: absolute;
	                z-index: 2;
	                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	                filter: alpha(opacity=0);
	                -khtml-opacity: 0;
	                -moz-opacity: 0;
	                opacity: 0;
	                cursor: pointer;
	                padding-right: 0;		                
	            }

	            div.select-output-search {
	                z-index: -1;
	                height: 38px;
	                overflow: hidden;
	                padding: 1rem 1.7rem;
	                cursor: pointer;
	                width: 140px;
	                white-space: nowrap;
	                font-size: 14px;
					font-weight: 600;
					line-height: 1.14;
					letter-spacing: 0.1px;
					color: #657878;
	            }
	        }

	        .styled-select:after {
	            font-family: 'FontAwesome';
	            position: relative;
	            right: -116px;
			    top: -38px;
			    content: "\f0dd";
			    padding-top: 7px;
			    padding-bottom: 15px;
			    padding-right: 10px;
			    padding-left: 5px;
	        }
	    }

		.advanced-search-narrow-help {
			margin-top: 24px;

			div.row {
				> div {
					margin-top: 16px;

					input[type="text"], .advancedSearchCategory, .advancedSearchManufacturer {
						width: 100%;
					}

					label {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}
				}
			}

			.advanced-search-button {
				margin-top: 16px;
			}
		}

		.filter-selections {
  			margin-top: 4px;
  			padding: 16px;
  			border-radius: 2px;

  			.filter-selections-title {
				display: block;
  				font-size: 16px;
				font-weight: 600;
				line-height: 1.5;
				letter-spacing: 0.2px;
				margin: 0 0 4px;
  			}

  			button.filter-selections-remove {
  				display: inline-block;
  				border-radius: 100px;
				background-color: #ffffff;
				padding: 6px 36px 6px 10px;
				font-size: 14px;
				line-height: 1.43;
				letter-spacing: 0.1px;
				cursor: pointer;
				position: relative;

				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;

  				input[type='checkbox'] {
  					display: none;
  				}
			}
			
			button.filter-selections-locked{
				padding-right: 10px;
			}
			button.filter-selections-locked:after {
				content: ""; 
			}

  			button.filter-selections-remove:after {
  				position: absolute;
  				font-family: 'FontAwesome';
  				content: "\f057";
  				top: 0px;
				right: 7px;
				font-size: 24px;
				color: #959ea0; 
  			}

  			.filter-selections-clear-all {
  				display: inline-block;
  				font-size: 14px;
				font-weight: 600;
				line-height: 1.14;
				letter-spacing: 0.1px;
				margin-left: 24px;
				color: #657878;
				border: none;
    			background: none;
  			}
  		}

		.breadcrumbs {
			#search-category-breadcrumbs {
				li a{
					font-size: 12px;
					line-height: 1.67;
					letter-spacing: 0.1px;
				}
			}

			.search-category-breadcrumb+.search-category-breadcrumb::before {
				content: "/";
				font-family: 'Open Sans', sans-serif;
				font-size: 12px;
				line-height: 1.67;
				letter-spacing: 0.1px;
			}
		}

		.title {
			font-size: 32px;
			line-height: 1.25;
			letter-spacing: 0.3px;
			margin-top: 8px;

			.term {
				font-weight: 600;
			}
		}

		.solar-search-container {
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			margin-top: 24px;

			.facets {
				width: 240px;
				margin-right: 20px;

				.facet-title {
					font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
					margin-bottom: 8px;
					padding-left: 5px;
					margin-top: 15px;
				}

				.solar-facet {
					margin-top: 4px;
					margin-bottom: 4px;

					button.solar-facet-title-container {
						display: block;
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
						padding: 10px 32px 10px 16px;
						text-decoration: none;
						position: relative;
					}
					button.solar-facet-title-container:hover {
						text-decoration: none;
					}
					button.solar-facet-title-container.open-facets:after {
						position: absolute;
						right: 16px;
						content: "\f068";
						top: 8px;
					}
					button.open-facets{
						background: white;
					}
					button.solar-facet-title-container.closed-facets:after {
						position: absolute;
						right: 16px;
						content: "\f067";
						top: 8px;
						color: #606e71;
					}

					.filter-section {
						padding: 0 12px 16px 12px;

						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;

							li {
								label.filter-label {
									font-size: 14px;
									line-height: 1.43;
									letter-spacing: 0.1px;
									font-weight: 100;
								}
							}
						}
					}
				}
			}

			.right-content {
				flex: 1;
				-webkit-box-flex: 1;
		  		-moz-box-flex:  1;
		  		-webkit-flex:  1;
		  		-ms-flex:  1;

		  		.didnt-find-link {
		  			margin-top: 24px;
		  		}

		  		.line-items {
		  			.line-item:first-child {
		  				margin-top: 24px;
		  			}

		  			.line-item {
		  				padding-top: 15px;
		  				padding-bottom: 14px;
		  				display: -webkit-box;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;

		  				.image-col {
		  					width: 96px;
		  					text-align: center;
		  					position: relative;

		  					.imagezoom-container {
			  					a.product-link-image {
			  						img {
			  							max-width: 96px;
	    								max-height: 96px;
			  						}			  						
			  					}

			  					.imagezoom {
			  						display: none;
			  						position: absolute;
			  						top: -25px;
    								right: 96px;
    								border-radius: 2px;
    								padding: 8px;
    								background-color: #fff;
    								z-index: 99;

			  						img {
			  							max-width: 200px;
			  						}
			  					}
			  				}
			  				.imagezoom-container:hover {
			  					.imagezoom {
			  						display: block;
			  					}
			  				}
		  					label.compare {
		  						font-size: 12px;
								line-height: 1.67;
								letter-spacing: 0.1px;
								padding-left: 4px;
								font-weight: 100;
								margin-bottom: 0;
		  					}
		  				}

		  				.description-col {
		  					flex: 1;
							-webkit-box-flex: 1;
					  		-moz-box-flex:  1;
					  		-webkit-flex:  1;
					  		-ms-flex:  1;
					  		padding-left: 16px;
					  		padding-right: 16px;

					  		.product-name {
					  			a {
					  				font-size: 16px;
									font-weight: 600;
									line-height: 1.5;
									letter-spacing: 0.2px;
					  			}
					  		}

					  		.attribute-line {
					  			ul {
					  				list-style-type: none;
					  				margin: 0 0 0 -7px;
					  				padding: 0;

					  				li {
					  					display: inline-block;
					  					border-right-width: 1px;
					  					border-left-width: 0;
					  					border-top-width: 0;
					  					border-bottom-width: 0;
					  					font-size: 12px;
										line-height: 1.67;
										letter-spacing: 0.1px;
										padding-right: 7px;
										color: #606e71;
										margin: 0 0 0 7px;
					  				}

					  				li:first-of-type {
					  					padding-left: 0;
					  				}

					  				li:last-of-type {
					  					border-right-width: 0;
					  				}
					  			}
					  		}

					  		.description-line {
					  			margin-top: 8px;
					  			font-size: 14px;
								line-height: 1.43;
								letter-spacing: 0.1px;

								.button-section {
									margin-top: 16px;
								}
					  		}

					  		.price-line {
								display: none;
							}
						}						  

		  				.price-quantity-col {
		  					width: 450px;
		  					display: -webkit-box;
							display: -ms-flexbox;
							display: -webkit-flex;
							display: flex;

		  					.price-col {
			  					width: 228px;

			  					.list-item-price {
			  						.sell-price {
			  							font-size: 18px;
										font-weight: 600;
										line-height: 1.56;
										letter-spacing: 0.2px;
			  						}

			  						.list-price {
			  							font-size: 12px;
										line-height: 1.67;
										letter-spacing: 0.1px;
										color: #606e71;
			  						}

			  						.uom {
			  							font-size: 12px;
										line-height: 1.67;
										letter-spacing: 0.1px;
										color: #606e71;
			  						}
			  					}

			  					.item-availability {
			  						font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
			  					}
			  				}

			  				.quantity-col {
			  					width: 240px;

			  					.qty-input {
			  						input {
			  							width: 45px;
			  							height: 40px;
			  						}
			  					}

			  					.qty-input:before {
			  						top: 10px;
			  					}

			  					button.button-emphasis {
			  						margin-left: 4px;
			  						padding: 12px 16px;
			  						height: 40px;
	    							border-top: none;
			  					}
			  				}
		  				}		  				
		  			}

		  		}

		  		.search-item-header {
		  			display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					align-items: flex-end;
					-ms-flex-align: end;
					margin-top: 24px;

					.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
						width: 160px;
					}

		  			label {
		  				font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
		  			}

		  			.sort-by {
		  				width: 170px;
		  			}

		  			.pagination {
		  				flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
				  		margin: 0;
				  		text-align: center;

				  		ul {
				  			list-style-type: none;
				  			margin: 0;
				  			padding: 0;

							button.previous-page-button,
							button.next-page-button {
								border-radius: 50%;
								width: 32px;
								height: 32px;
								border: none;

								i {
									font-weight: bold;
								}
							}

				  			.large-circle-button {
								width: 32px;
								height: 32px;
								position: relative;
								display: block;

								a, button {
								    display: block;
								    height: 32px;
								    padding: 0;		
									border: 0;						    
								}

								i {
							    	display: none;
							    }
							}

							li.arrow.unavailable {
								.large-circle-button.next-button.disabled,
								.large-circle-button.prev-button.disabled {
									cursor: default;
								}

								.large-circle-button.next-button:before {
									position: absolute;
									font-family: 'FontAwesome';
									content: "\f054";
									top: 8px;
								    right: -2px;
								    width: 32px;
								    font-size: 0.8em;
								}

								.large-circle-button.prev-button:before {
									position: absolute;
									font-family: 'FontAwesome';
									content: "\f053";
									top: 8px;
								    right: -1px;
								    width: 32px;
								    font-size: 0.8em;
								}
							}

							.large-circle-button.next-button a:before,
							.large-circle-button.next-button button:before {
								position: absolute;
								font-family: 'FontAwesome';
								content: "\f054";
								top: 8px;
							    right: -2px;
							    width: 32px;
							    font-size: 0.8em;
							}

							.large-circle-button.prev-button a:before,
							.large-circle-button.prev-button button:before {
								position: absolute;
								font-family: 'FontAwesome';
								content: "\f053";
								top: 8px;
							    right: -1px;
							    width: 32px;
							    font-size: 0.8em;
							}

				  			li.page-selector {
				  				margin-left: 8px;
				  				margin-right: 8px;
				  				font-size: 14px;
								line-height: 1.43;
								letter-spacing: 0.1px;								

								.hidden-select {
									position: relative;

									.text-center {
										padding-right: 16px;
										position: relative;

										.fa-angle-down {
											display: none;
										}
									}
									.text-center:after {
										font-family: 'FontAwesome';
		  								content: "\f0d7";
		  								position: absolute;
		  								top: 0px;
		  								right: 0px;
									}
								}
				  			}
				  		}
		  			}

		  			.view {

		  				.toggle-button-box{

							.toggle-button{
								font-size: 15px;
								font-weight: bold;

								&.toggle-button-right{
									margin-right: 20px;
								}
							}
						}

		  				> div {
		  					display: inline-block;
		  				}
		  			}

		  			&.bottom-section{
		  				.view{
		  					width: 161px
		  				}
		  			}
		  		}		  		

		  		.refine-category-title {
		  			font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
					margin-bottom: 8px;
		  		}

		  		.refine-categories {
		  			display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					flex-wrap: wrap;
					-ms-flex-wrap: wrap;

		  			.refine-category {
		  				width: 170px;		  				
		  				margin-right: 18px;
		  				margin-bottom: 18px;
						list-style-type: none;
						display: inline-block;

		  				a {
		  					font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
							text-align: center;
							white-space: pre-wrap;
						    white-space: -moz-pre-wrap;
						    white-space: -pre-wrap;
						    white-space: -o-pre-wrap;
						    word-wrap: break-word;
						    padding: 16px;
						    margin-bottom: 0;
						    height: 100%;
						    display: inline-block;
						    width: 100%;

					    	img {
					    		max-width: 96px;
					    		max-height: 96px;
					    		display: block;
					    		margin-left: auto;
					    		margin-right: auto;
					    		pointer-events: none;
					    	}						    
		  				}
						a:hover { text-decoration: none; }
		  			}
		  		}
			}
		}
	}
}

@media only screen and (min-width: $tablet-max-width) and (max-width: 1279px) {
	body.solar-template {
		.solar-search {
			margin-right: 16px;
			margin-left: 16px;

			.solar-search-container {
				.right-content {
					.line-items:not(.grid) {
						.line-item {
							.price-quantity-col {
								width: 255px;

								.price-col {
									width: 130px;
								}

								.quantity-col {
									width: 125px;
									text-align: right;

									button {
										margin-top: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/** needed for ipad horizontal layout **/
@media only screen and (min-width: 900px) and (max-width: $tablet-max-width) {
	body.solar-template {
		.solar-search {
			.solar-search-container {
				.right-content {
					.search-item-header {
					    .pagination {
    						min-width: 300px;
					    }
					}
				}
			}
		}
	}
}

/** needed for ipad vertical layout **/
@media only screen and (min-width: $tablet-min-width) and (max-width: 899px) {
	body.solar-template {
		.solar-search {
			.solar-search-container {
				.right-content {
					.search-item-header {
					    .pagination {
    						min-width: 255px;
					    }
					}
				}
			}
		}
	}
}

@media (min-width: $tablet-min-width) {
	body.solar-template {
		.solar-search {
			.solar-search-container {
				.right-content {
					.line-items.grid{
						.line-item{
							margin-top: 0;
							flex-wrap: wrap;

							.image-col, .price-quantity-col{
								width: 100%;
							}

							.image-col{
								padding-top: 25px;

								.imagezoom-container{

									.product-image-link{
										height: 155px;
										display: flex;

										img{
											align-self: center;
											margin: auto;
										}
									}
						
									.centerimagezoom{
										position:absolute;
										left: 50%;
										top: 0;

										.imagezoom{
											position:relative;
											left: -50%;
										}
									}
								}

								label.compare{
									font-size: 14px;
								}

								.add-to-catalog{
									font-size: 20px;
								}
							}

							.description-col{
								.attribute-line{
									ul{
										li{
											display:block;
											border: none;
										}
									}
								}
							}

							.price-quantity-col{
								display: block;
								padding-left: 15px;
								padding-right: 15px;
								position: relative;
								padding-bottom: 90px;

								.quantity-column-wrapper{
									position: absolute;
									bottom: 0;
								}

								.price-col{
									width: 100%;
								}

								.button-section{
									margin-top: 10px;
								}
							}

						}
					}
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.solar-search {
			&:not(.admin-right-content){
				margin-right: 16px;
				margin-left: 16px;
			}

			.solar-search-container {
				.facets {
					width: 200px;
				}

				.right-content {
					.search-item-header {
					    flex-wrap: wrap;
					    -ms-flex-wrap: wrap;
					    justify-content: space-between;
					    -ms-flex-pack: end;

					    .sort-by, .view {
					    	
					    	label {
					    		width: 90px;
					    	}
					    }

					    .view {
					    	order: 2;
					    	-ms-flex-order: 2;
					    	width: 263px;
					    }

					    .sort-by {
					    	order: 1;
					    	-ms-flex-order: 1;
					    	width: 200px;
					    }

					    .pagination {
					    	text-align: center;
    						order: 3;
    						-ms-flex-order: 3;
							margin-top: 20px;
							flex:0 0 auto;
							width: 100%;
					    }

					    &.bottom-section{
			  				.view{
			  					width: 161px;
			  					order: 3;
					    		-ms-flex-order: 3;
			  				}

			  				.pagination {
			  					order: 1;
    							-ms-flex-order: 1;
			  				}

			  				.sort-by {
						    	order: 2;
						    	-ms-flex-order: 2;
						    	width: 200px;
						    }
			  			}
					}

					.line-items:not(.grid) {
						.line-item {
							.description-col {
								padding-right: 12px;

								.price-line {
									display: none;
								}
							}

							.price-quantity-col {
								width: 120px;
								flex-direction: column;
								-webkit-box-orient: vertical;
							    -moz-box-orient: vertical;
							    -webkit-flex-direction: column;
							    -ms-flex-direction: column;

								.price-col {
									width: 120px;
								}								

								.quantity-col {
									width: 120px;
									text-align: left;

									.qty-input {
										margin-top: 16px;

										input {
											width: 36px;
										}
									}

									button.button-emphasis {
										margin-top: 8px;
										margin-left: 0;
										font-size: 14px;
										font-weight: 600;
										line-height: 1.14;
										letter-spacing: 0.1px;
										padding: 0;
										text-align: center;
										width: 120px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.solar-search {
			&:not(.admin-right-content){
				margin-right: 0;
				margin-left: 0;
			}

			#search-box-search-page {
				padding-left: 16px;
    			padding-right: 16px;

    			#search-page-search {
    				width: 100%;
    				padding-right: 0;

	    			#search-box-search {
	    				padding-right: 0;
	    				
	    				.search-categories {
	    					display: none;
	    				}
    				}
    			}
			}

			.title, .breadcrumbs {
				margin-left: 16px;
				margin-right: 16px;
			}

			.advanced-search-narrow-help {
				margin-top: 24px;
				margin-left: 16px;
				margin-right: 16px;
			}

			.solar-search-container {
				.facets {
					position: fixed;
				    background-color: #fff;
				    z-index: 50000000;
				    //left: -1000px;
					display: none;	

				    .mobile-buttons {
				    	a {
				    		font-size: 14px;
							font-weight: 600;
							line-height: 1.14;
							letter-spacing: 0.1px;
							color: #657878;
							margin-top: 28px;
							margin-bottom: 28px;
							margin-left: 32px;
							display: inline-block;
				    	}
				    }	

				    .facet-title {
				    	margin-left: 16px;
				    }	

				    .solar-facet {
				    	margin-left: 16px;
				    	margin-right: 16px;
				    }	    
				}

				.facets.open {
					left: 0;
					top: 0;
					display: block;
					overflow-y: scroll;


				}

				.right-content {
					.refine-category-title {
						font-size: 16px;
						font-weight: 600;
						line-height: 1.5;
						letter-spacing: 0.2px;
						margin-left: 16px;
						margin-right: 16px;
					}

					.refine-categories {
						flex-direction: column;
						-webkit-box-orient: vertical;
					    -moz-box-orient: vertical;
					    -webkit-flex-direction: column;
					    -ms-flex-direction: column;
						margin-left: 16px;
						margin-right: 16px;

						.refine-category {
							width: 100%;
							margin-bottom: 8px;
							display: flex;
							align-items: center;

							a {
								font-size: 16px;
								font-weight: 600;
								line-height: 1.5;
								letter-spacing: 0.2px;
								padding: 8px;
								display: -webkit-box;
								display: -ms-flexbox;
								display: -webkit-flex;
								display: flex;
								align-items: center;
								-ms-flex-align: center;
								-ms-flex-pack: start;
								text-align: left;

								img {
									max-width: 56px;
									max-height: 56px;
									margin-left: 0;
									margin-right: 12px;
								}
							}
						}
					}

					.filter-products-button-container {
						margin-left: 16px;
						margin-right: 16px;

						#filter-products-button {
							margin-top: 24px;
							width: 100%;
							height: 40px;
	    					padding: 0;	    					
						}
					}

					.search-item-header.top-section {
						margin-left: 16px;
						margin-right: 16px;

						.pagination, .view {
							display: none;
						}

						.sort-by {
							width: 100%;
							display: -webkit-box;
							display: -ms-flexbox;
							display: -webkit-flex;
							display: flex;
							align-items: center;
							-ms-flex-align: center;

							label {
								width: 66px;
							}

							.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
								flex: 1;
								-webkit-box-flex: 1;
						  		-moz-box-flex:  1;
						  		-webkit-flex:  1;
						  		-ms-flex:  1;
							}
						}
					}

					.search-item-header.bottom-section {						
						.sort-by, .view {
							display: none;
						}
					}

					.line-items {
						.line-item {
							flex-wrap: wrap;
							-ms-flex-wrap: wrap;

							.image-col {
								margin-left: 16px;
								width: 91px;
								text-align: center;

								a {
									img {
										max-height: 91px;
									}
								}
								.compare {
									display: none;
								}
							}

							.description-col {
								padding-left: 8px;

								.description-line {
									display: none;
								}

								.attribute-line {
									ul {
										li {
											display: block;
											border: none;
										}
									}

									.button-section {
										margin-top: 16px;

										a {
											display: inline-block;
											margin-bottom: 8px;
										}
									}
								}

								.price-line {
									display: block;

									.list-item-price {
										.sell-price {
											font-size: 18px;
											font-weight: 600;
											line-height: 1.56;
											letter-spacing: 0.2px;
										}

										.uom {
											font-size: 12px;
											line-height: 1.67;
											letter-spacing: 0.1px;
											color: #606e71;
										}

										.search-price {
											.list-price {
												font-size: 12px;
												line-height: 1.67;
												letter-spacing: 0.1px;
												color: #606e71;
											}
										}
									}
								}
							}

							.price-quantity-col {
								width: 100%;
								padding-left: 16px;
								padding-right: 16px;
								margin-top: 6px;

								.quantity-col {
									display: -webkit-box;
									display: -ms-flexbox;
									display: -webkit-flex;
									display: flex;
									width: 100%;

									button {
										flex: 1;
										-webkit-box-flex: 1;
								  		-moz-box-flex:  1;
								  		-webkit-flex:  1;
								  		-ms-flex:  1;
									}
								}

								.price-col {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}