@import "../variables";

body.solar-template {
	.cart-page {
		.checkout-content {
			

			.cart-empty {
				margin-top: 0;
				padding-top: 20px;
			}

			h2 {
				font-size: 24px;
				line-height: 1.17;
				letter-spacing: 0.2px;
			}

			.need-help {
				font-size: 18px;
				font-weight: 600;
				line-height: 1.56;
				letter-spacing: 0.2px;
			}

			.saved-cart-section {
				> div.row {
					> div {
						padding-left: 0;
						padding-right: 0;
					}
				}

				.product-headings {
					padding-left: 0;
					padding-right: 0;
				}

				.return-all-items {
					margin-left: 16px;
				}
			}

			.quote-split-section{
				> div.row {
					> div {
						padding-left: 0;
						padding-right: 0;
					}
				}

				.product-headings {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.solar-no-pad {
				padding-left: 0px;
				padding-right: 0px;
			}

			.payment-form {
				.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
					width: 100%;
				}

				> div {
					padding-left: 0;
					padding-right: 0;
				}

				label {
					font-size: 14px;
					font-weight: 600;
					line-height: 1.43;
					letter-spacing: 0.1px;
					margin-top: 16px;
				}

				.carrier-info {
					margin-top: 24px;

					.carrier-info-title {
						font-size: 18px;
						font-weight: 600;
						line-height: 1.56;
						letter-spacing: 0.2px;
					}

					input[type="text"] {
						width: 100%;
					}
				}

				.ground-shipping {
					padding: 16px;
					border-radius: 2px;
					margin-left: 16px;
					margin-top: 16px;

					.ground-shipping-title {
						font-size: 16px;
						font-weight: 600;
						line-height: 1.5;
						letter-spacing: 0.2px;
						margin-bottom: 8px;
					}

					ul {
						list-style-type: none;
						padding: 0;
						margin: 0;

						li {
							padding: 10px 0;

							span.ground-shipping-heading {
								font-size: 14px;
								font-weight: 600;
								line-height: 1.43;
								letter-spacing: 0.1px;
							}
							span.ground-shipping-data {
								font-size: 14px;
								line-height: 1.43;
								letter-spacing: 0.1px;
								float: right;
							}
						}

						li:last-of-type {
							border-bottom-width: 0;
						}
					}
				}
			}

			div.row {
				margin-left: 0;
				margin-right: 0;
			}

			.error-row {
				> div {
					padding-left: 0px;
					padding-right: 0px;
				}
			}



			.page-buttons {
				margin-bottom: 0px;
				padding-left: 0;
				padding-right: 0;

				button {
					margin-right: 8px;
				}
			}

			.wizard-area {
				

				> div.row {
					> div {
						padding-left: 0;
						padding-right: 0;
					}
				}

				h1 {
					font-size: 32px;
					line-height: 1.25;
					letter-spacing: 0.3px;
				}
			}

			.product-headings {				
			    background-color: #fff;
			    box-shadow: none;
			    margin-top: 24px;
			    font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;

				> div {
					padding: 10px 0;
				}

				div {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.line-items {
				.line-item:last-of-type {
					border-bottom: none;
				}

				.line-item {
					padding: 16px 0;

					.lotQuoteItemsTable {
						margin-left: 0px;
			    		margin-right: 0px;
					}

					div {
						padding-left: 0;
						padding-right: 0;
					}

					.spinner-qty {
                        width: 45px;
                        height: 40px;;
					}

					.line-action-buttons {
						margin-top: 0;

						a {
							margin-right: 8px;
							margin-bottom: 0;
						}
					}

					.line-note-box, .line-action-buttons {
						> div {
							padding-left: 16px;
							margin-bottom: 16px;
						}
					}

					label {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}

					.line-item-image {
						padding-left: 0;
						padding-right: 0;

						img {
							max-width: 100%;
							max-height: 96px;
						}
					}

					.product-details {
						padding-left: 16px;
						padding-right: 16px;
						margin-top: 0;
						margin-bottom: 16px;

						.product-name {
							font-size: 16px;
							font-weight: 600;
							line-height: 1.5;
							letter-spacing: 0.2px;
						}

						ul {
			  				list-style-type: none;
			  				margin: 0;
			  				padding: 0;

			  				li {
			  					display: block;
			  					font-size: 12px;
								line-height: 1.67;
								letter-spacing: 0.1px;
								color: #606e71;
								margin: 0;
			  				}
			  			}
					}
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.cart-page {
			.checkout-content {
				

				.wizard-area {
					
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.cart-page {
			overflow: hidden;

			.checkout-content {
				.top-proceed-button {
					width: 100%;
					margin-top: 24px;
				}

				.page-buttons {					
					button {
						margin-bottom: 8px;
					}
				}

				.bottom-proceed-button {
					z-index: 99;
				}

				.saved-cart-section {
					padding-left: 0;
					padding-right: 0;

					.saved-header {
						padding-left: 16px;
    					padding-right: 16px;
					}

					.return-all-items {
						margin-left: 0;
						margin-top: 16px;
						width: 100%;
					}
				}

				.payment-form {
					.carrier-info {
						margin-top: 24px;
					}

					.ground-shipping {
						margin-left: 0;
					}
				}

				.line-items {
					margin-top: 16px;
					padding-left: 0;
    				padding-right: 0;

    				.line-item {
	    				.line-item-image {
	    					padding-left: 16px;

	    					img {
	    						max-height: 73px;
	    					}
	    				}

	    				.line-note-box {
	    					> div {
	    						padding-right: 16px;
	    					}
	    				}

	    				.lotQuoteItemsTable {
							margin-left: 15px;
				    		margin-right: 15px;
						}
	    			}

    				> div {
    					padding-left: 16px;
    					padding-right: 16px;
    				}

    				.solar-line {
    					position: relative;

    					.solar-line-content {
    						position: initial;

    						.product-details {
    							margin-bottom: 65px;
    						}
    						.product-details.custom-product {
    							margin-bottom: 105px;
    						}

    						.solar-line-price {
    							position: absolute;
							    bottom: 0;
							    left: 164px;
    						}

    						.solar-line-quantity {
    							position: absolute;
							    bottom: 0;
							    left: 16px;
							    text-align: left;

							    .cart-qty-input {
									position: relative;
									display: inline-block;

									input {
										width: 72px;
										text-align: right;
										padding-left: 30px;
										padding-right: 12px;
										font-size: 16px;
										line-height: 1.5;
										letter-spacing: 0.2px;			
									}
								}
								
    						}
    					}
    				}

    				.line-item {
    					.line-action-buttons {
    						a {
    							margin-bottom: 8px;
    						}

    						> div {
    							margin-bottom: 0;
    						}
    					}
    				}
				}

				

				.wizard-area {
					
				}
			}
		}
	}
}

/* thin desktop */
@media only screen and (min-width: $tablet-max-width) and (max-width: 1280px) {
	body.solar-template {
		.cart-page {
			.checkout-content {
				

				.wizard-area {
					
				}
			}
		}
	}
}