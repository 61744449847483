@import "../variables";

.loan-detail {
    margin: 0 40px 40px 40px;

    .top-summary {
        margin-top: 24px;

        .breadcrumbs {
            font-size: 12px;
            font-weight: normal;
            color: rgb(106, 107, 109);

            a {
                font-size: 12px;
                font-weight: normal;
                margin-right: 9px;
            }

            .center-link, .name {
                margin-left: 9px;
            }
        }

        .title-heading {
            font-size: 14px;
            font-weight: 600;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .title-heading-line-1 {
            color: rgb(106, 107, 109);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        .title-heading-line-2 {
            display: flex;

            .loan-id {
                width: 50%;
                color: rgb(33, 33, 33);
                font-size: 32px;
                font-weight: normal;
                height: 40px;
                letter-spacing: 0.3px;
                line-height: 40px;
            }

            .create-project {
                width: 50%;
                text-align: right;
            }
        }
    }

    .content-section {
        display: flex;
        margin-top: 24px;
        align-items: flex-start;

        .finance-info {
            width: 345px;
            padding-right: 24px;

            .finance-content {
                padding: 16px;
                border-radius: 6px;
                border: 1px solid rgb(213, 216, 217);

                h2 {
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                    font-weight: normal;
                    letter-spacing: 0.23px;
                    line-height: 28px;
                }

                .loan-heading {
                    color: rgb(33, 33, 33);
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.15px;
                    line-height: 24px;
                    padding-bottom: 8px;
                    margin-top: 16px;
                }

                .loan-field {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    border-top: 1px solid rgb(213, 216, 217);
                    color: rgb(43, 61, 65);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;

                    &.address {
                        .loan-field-label {
                            display: block;
                        }
                    }
                }

                .loan-field-label {
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }
            }
        }

        .invoices {
            flex-grow: 1;
            padding: 16px;
            border-radius: 6px;
            border: 1px solid rgb(213, 216, 217);

            h2 {
                color: rgb(33, 33, 33);
                font-size: 24px;
                font-weight: normal;
                height: 28px;
                letter-spacing: 0.23px;
                line-height: 28px;
                margin-bottom: 16px;
            }

            #invoices-table {
                margin: 12px 0px;
                width: 100%;

                .downloadText {
                    display: none;
                }

                a.pod-button, a.pod-button-file-path {
                    display: inline-block;
                    cursor: pointer;
                    margin-top: 5px;
                }
            
                th {
                    background: rgb(248, 249, 249);
                    border: 1px solid rgb(213, 216, 217);
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    padding: 10px 16px;
                }
                
                td {
                    background: rgb(255, 255, 255);
                    border: 1px solid rgb(213, 216, 217);   
                    color: rgb(33, 33, 33);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                    padding: 10px 16px;
                }
    
                tr.footable-detail-show {
                    background-color: white;
                }
                
                .loanIdCol{
                    font-weight: bold;
                }
            }
        }
    }

    #modal-confirm-create-project {
        h4 {
            margin-top: 16px;
            margin-bottom: 8px;
            color: rgb(33, 33, 33);
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.17px;
            line-height: 28px;
            display: block;
        }

        .modallabel {
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.13px;
            line-height: 20px;
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .loan-detail {    
        padding: 0 16px;
        margin: 0 0 24px 0;        

        .content-section {
            display: flex;
            margin-top: 24px;
            align-items: flex-start;
            flex-wrap: wrap;
    
            .finance-info {
                width: 100%;
                padding-right: 0;
                margin-bottom: 24px;
    
                .finance-content {
                    padding: 16px;
                    border-radius: 6px;
                    border: 1px solid rgb(213, 216, 217);
                    display: flex;
                    flex-wrap: wrap;
    
                    h2 {
                        color: rgb(33, 33, 33);
                        font-size: 24px;
                        font-weight: normal;
                        letter-spacing: 0.23px;
                        line-height: 28px;
                        width: 100%;
                    }
    
                    .loan-details, .project-information {
                        width: 50%;
                    }

                    .loan-details {
                        .loan-field {
                            margin-right: 12px;
                        }
                    }
                    .project-information {
                        .loan-field, .loan-heading {
                            margin-left: 12px;
                        }
                    }                    
                }
            } 
            
            .invoices {
                a.pod-button, a.pod-button-file-path {
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .loan-detail {
        padding: 0 16px;   
        margin: 0 0 40px 0;

        .top-summary {
            .title-heading-line-2 {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 24px;
    
                .loan-id {
                    width: 100%;
                }
    
                .create-project {
                    width: 100%;
                    text-align: left;
                    margin-top: 8px;
                }
            }
        }
            
        .content-section {
            display: flex;
            margin-top: 0;
            padding-top: 24px;
            align-items: flex-start;
            flex-wrap: wrap;
            border-top: 1px solid rgb(213, 216, 217);
    
            .finance-info {
                width: 100%;
                padding-right: 0;                
            }
    
            .invoices {
                flex-grow: 1;
                width: 100%;
                padding: 0;
                border-radius: 0;
                border-bottom: none;   
                border-left: none;
                border-right: none;
                margin-top: 24px;
                border-top: 1px solid rgb(213, 216, 217);   
                
                h2 {
                    display: none;
                }

                #invoices-table.noneFound {
                    .footable-row-detail-name {
                        display: none;
                    }
                }
    
                #invoices-table {
                    margin: 0px;
                    width: 100%;

                    .downloadText {
                        display: inline;
                        margin-left: 8px;
                    }
                
                    th {
                        background: rgb(248, 249, 249);
                        border: 1px solid rgb(213, 216, 217);
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        padding: 10px 16px;
                    }
                    
                    td {
                        background: rgb(255, 255, 255);
                        border-top: none;
                        border-left: none;
                        border-right: none; 
                        border-bottom: 1px solid rgb(213, 216, 217);   
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        padding: 10px 16px;
                    }
                    
                    .loanIdCol{
                        font-weight: bold;
                    }                    

                    .footable-row-detail {
                        .footable-row-detail-cell {
                            .footable-row-detail-inner {
                                .footable-row-detail-row {
                                    .button-small {
                                        margin-top: 10px;
                                    }
                                }

                                .footable-row-detail-row:last-of-type {
                                    .footable-row-detail-value {
                                        position: relative;

                                        a.pod-button, a.pod-button-file-path {
                                            display: block;
                                            margin-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}