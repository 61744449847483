@import "../variables";

.greentechPlansets {
    margin-left: 10px;
    margin-bottom: 30px;

    .dashboard-heading {
        color: rgb(33, 33, 33);
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
        position: relative;
        line-height: 1;
        margin-bottom: 8px;

        .heading-text {
            margin-right: 8px;
        }

        .status-pill {
            border-radius: 35px;
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: normal;
            padding: 7px 10px;
            line-height: 20px;
            position: absolute;
            bottom: 0;
    
            &.status-pill-green {
                border: 1px solid rgb(88, 152, 21);
                background: rgba(88, 152, 21, 0.15);
    
                .icon i {
                    color: rgb(88, 152, 21);
                    font-size: 16px;
                }
            }

            &.status-pill-yellow {
                border: 1px solid rgb(191, 144, 0);
                background: rgba(191, 144, 0, 0.15);

                .icon i {
                    color: rgb(191, 144, 0);
                    font-size: 16px;
                }
            }

            &.status-pill-red {
                border: 1px solid rgb(182, 79, 53);
                background: rgba(182, 79, 53, 0.15);

                .icon i {
                    color: rgb(182, 79, 53);
                    font-size: 16px;
                }
            }

            &.status-pill-grey {
                border: 1px solid rgb(213, 216, 217);
                background: rgba(213, 216, 217, 0.15);

                .icon i {
                    color: rgb(106, 107, 109);
                    font-size: 16px;
                }
            }
        }
    }

    .name-heading {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        padding-top: 20px;
    }

    .project-settings-button {
        height: 32px;   
        padding: 0px 10px 0px 10px;
        float: right;
        text-transform: uppercase;
    }

    .statement-of-work {
        border: 1px solid rgb(88, 152, 21);
        border-radius: 6px;
        padding: 15px;
        margin-bottom: 20px;
    }

    .statement-of-work-heading {
        color: rgb(33, 33, 33);
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 0.23px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    .statement-of-work-message {
        color: rgb(43, 61, 65);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        line-height: 20px;
        padding: 5px 0 10px 28px;

    }

    .nav-icon.status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(88, 152, 21, 0.15);
        border-radius: 3px;
        border: 1px solid rgb(90, 177, 118);
        padding: 10px; 
        color: rgb(33, 33, 33); 
        margin-top: 10px; 
        margin-bottom: 24px;

        i.fa-check-circle {
            width: 20px;
        }

        .message-content {
            flex-grow: 1;

            a {
                color: rgb(33, 33, 33);
                text-decoration: underline;
            }
        }

        .fa-check-circle {
            color: #589815;
        }
    
        .fa-times {
            color: rgb(33, 33, 33);
        }
    }   

    .green-icon {
        color: rgb(88, 152, 21);
        width: 18px;
        height: 18px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 5px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }
    }

    hr {
        padding-bottom: 5px;
    }

    .mobile-button {
        display: none;
    }

    .desktop-button {
        display: inline;
        float: right;
    }

    .service-order-button {
        border-radius: 0px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.13px;
        line-height: 16px;
    }

    .plansets-description-title {
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15px;
        line-height: 24px;
    }

    .plansets-description {
        width: 446px;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .name {
        padding-left: 10px;
        color: rgb(106, 107, 109);
    }

    .plansets-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .plansets-content {
        width: 100%;

        .planset-item {
            margin-right: 16px;
        }

        .planset-subheading {
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: bold;
        }

        .home-middle-content {
            padding-left: 0;

            .message-center-content {
                margin-top: 24px;
                padding: 16px;
                border-radius: 6px;
                border: 1px solid rgb(213, 216, 217);

                .ms-title {
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                }
            }

            .product-list-content {
                margin-top: 24px;
                border-radius: 6px;
                border: 1px solid rgb(213, 216, 217);

                .ms-title {
                    color: rgb(33, 33, 33);
                    font-size: 24px;
                    padding-left: 16px;
                    padding-top: 16px;
                }
                
                table {
                    margin-top: 16px;
                    width: 100%;

                    thead {
                        th {
                            color: rgb(33, 33, 33);
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                            padding: 10px 16px;
                            background: rgb(248, 249, 249);
                            border-bottom: 1px solid rgb(213, 216, 217);
                            border-top: 1px solid rgb(213, 216, 217);
                        }
                    }

                    tbody {
                        tr {
                            border-bottom: 1px solid rgb(213, 216, 217);

                            td {
                                padding-left: 16px;
                                font-size: 14px;
                                line-height: 20px;

                                .product-detail {
                                    display: flex;
                                    align-items: flex-start;
                                    padding-top: 0;

                                    .product-image {
                                        width: 56px;                                        

                                        img {
                                            max-width: 100%;
                                            max-height: 100%;
                                        }
                                    }

                                    .product-content {
                                        flex-grow: 1;
                                        padding-left: 16px;

                                        .product-title {
                                            a {
                                                font-size: 16px;
                                                font-weight: bold;
                                                letter-spacing: 0.15px;
                                                line-height: 24px;
                                            }
                                        }

                                        .product-sub-title {
                                            color: rgb(106, 107, 109);
                                            font-size: 12px;
                                            font-weight: normal;
                                            letter-spacing: 0.11px;
                                            line-height: 20px;

                                            span {
                                                border-right: 1px solid rgb(213, 216, 217);
                                                padding-right: 8px;
                                                padding-left: 6px;
                                                padding-top: 2px;
                                                padding-bottom: 2px;
                                            }

                                            span:first-of-type {
                                                padding-left: 0;
                                            }

                                            span:last-of-type {
                                                border-right: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        tr.no-products {
                            border-bottom: none;
                        }
                    }                    
                }

                .planset-product-qty,
                .change-request-reason,
                .change-request-status,
                .change-request-submitted-date {
                    vertical-align: top;
                    border-left: 1px solid rgb(213, 216, 217);
                }
                
                .add-to-project-order-button {
                    margin: 16px 0 16px 16px;
                }
            }
        }

        .project-information {
            padding-right: 0;

            .planset-documents {
                border-radius: 6px;
                border: 1px solid rgb(213, 216, 217);   
                margin-bottom: 24px;
                padding: 16px;

                .version-header {
                    display: flex;
                    margin-top: 16px;
                    margin-bottom: 8px;

                    .current-version {
                        color: rgb(33, 33, 33);
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                        line-height: 24px;
                    }

                    .version-date {
                        color: rgb(33, 33, 33);
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: 0.15px;
                        line-height: 24px;
                        text-align: right;
                        flex-grow: 1;
                    }
                }

                .version-files {
                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;

                        li {
                            padding: 12px 0;
                            border-top: 1px solid rgb(213, 216, 217);
                            margin: 0;

                            a {
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                            }
                        }
                    }
                }

                .previous-versions {
                    .previous-version {
                        background: rgb(248, 249, 249);
                        border-radius: 3px;                        

                        .version-header {
                            border: none;
                            outline: none;
                            background: none;
                            width: 100%;
                            padding: 8px 11px 8px 12px;
                            align-items: center;
                            margin-top: 8px;

                            .current-version {
                                padding-left: 12px;
                            }

                            .current-version, .version-date {
                                font-size: 14px;
                                font-weight: normal;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                            }
                        }

                        .version-content {
                            padding-left: 28px;
                            padding-right: 12px;
                            padding-bottom: 8px;
                        }

                        .version-header:focus {
                            outline: 3px solid #3DB05D;
                        }

                        .version-header::before {
                            font-family: FontAwesome;
                            content: '\f106'; /*up*/
                            color: rgb(106, 107, 109);
                            font-size: 1.2em;
                        }

                        &.closed {
                            .version-header::before {
                                font-family: FontAwesome;
                                content: '\f107'; /*down*/
                                color: rgb(106, 107, 109);
                                font-size: 1.2em;
                            }

                            .version-content {
                                display: none;
                            }
                        }
                    }
                }

                .reviewer-header {
                    border-top: 1px solid rgb(213, 216, 217);
                    display: flex;
                    padding-top: 16px;
                    margin-bottom: 8px;

                    .reviewer-header-title {
                        color: rgb(33, 33, 33);
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0.15px;
                        line-height: 24px;
                    }
                }

                .reviewer-button {
                    margin-top: 16px;

                    button {
                        display: block;
                        width: 100%;
                        text-align: centerl
                    }
                }
            }

            .projectinfo-top {
                padding: 16px;
                border-radius: 6px 6px 0px 0px;
                border-top: 1px solid rgb(213, 216, 217);
                border-left: 1px solid rgb(213, 216, 217);
                border-right: 1px solid rgb(213, 216, 217);
            }

            .projectinfo-bottom {
                border: 1px solid rgb(213, 216, 217);
                border-radius: 0px 0px 6px 6px;
                padding: 16px;

                .revise {
                    color: rgb(33, 33, 33);
                    font-size: 16px;
                    font-weight: bold;
                }

                .projectinfo-button {
                    margin-top: 16px;

                    input {
                        width: 100%;
                    }

                    a {
                        display: block;
                        text-align: center;
                    }
                }
            }

            .information-header {
                color: rgb(33, 33, 33);
                font-size: 24px;
            }

            .overview-link {
                font-weight: 600;
                padding-top: 16px;
                padding-bottom: 8px;
            }

            .planset-projectinfo {
                margin-top: 16px;

                .heading-small {
                    color: rgb(33, 33, 33);
                    font-size: 16px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    border-bottom: 1px solid rgb(213, 216, 217);
                    display: block;
                    margin: 0;
                }

                .summary-group {
                    .summary-field {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(213, 216, 217);

                        .summary-field-label {
                            color: rgb(33, 33, 33);
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                    .summary-field:last-child {
                        border-bottom: none;
                    }
                }                
            }

            .address-section {
                margin-top: 16px;

                .address-title {
                    color: rgb(33, 33, 33);
                    font-size: 16px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    border-bottom: 1px solid rgb(213, 216, 217);
                }

                .address {
                    margin-top: 5px;
                }
            }
        }
    }

    /*.project-review-status {
        width: 137px;
        height: 32px;
        border: 1px solid rgb(88, 152, 21);
        border-radius: 35px;
        background: rgba(88, 152, 21, 0.15);

        .green-icon {
            color: rgb(88, 152, 21);
            font-size: 14px;
        }

        .review-status {
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }
    }*/

    #chatterContainer {
        .publishercontainer .bottomBar {
            height: 48px;
        }
        .feedcontainer {
            max-width: 100%;
        }
        #publishersharebutton {
            box-shadow: none;
            border: none;
            background: rgb(28, 130, 28);
        }
        .cke_bottom {
            box-shadow: none;
            background-image: none;
        }
        .feedcontainer .comboMenu.feedsupermenu input {
            width: auto;
        }
        .publishercontainer .publisherFeedItemTypeChoices a.publisherattach, 
        a.cxcontentcommentaction, 
        span.cxcontentcommentactiontext,
        .zen .comboMenu #sortDisplay, 
        .zen .comboMenu #filterDisplay,
        .contentPublisherSlideDown td a {
            color: rgb(28, 130, 28);
        }
        .feedcontainer .feeditem a.actorentitylink:hover,
        .feedcontainer a:hover,
        .chatter-avatarRegular.feeditemusericon:hover,
        .cxhover{
            text-decoration: none;
            color: rgba(0, 0, 0, 1);
            font-weight: 400;
            cursor: default;
        }
        .feedcontainer .feeditem a.actorentitylink:focus,
        .feedcontainer a:focus{
            text-decoration: none;
            color: rgba(0, 0, 0, 1);
            font-weight: 400;
            outline: none;
        }
        .feedcontainer .feeditem a.actorentitylink,
        .feedcontainer a{
            text-decoration: none;
            color: rgba(0, 0, 0, 1);
            font-weight: 400;
        }
        .contentPublisherSlideDown .panel {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .bottomBarLeft .visibilityWidgetParent, .postTo {
            display: none;
        }
    }

    .success-message,
    .danger-message {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        align-items: center;
        -ms-flex-align: center;
    
        
        border-radius: 3px;
        padding: 10px 0;
        margin-top: 4px;
        margin-bottom: 20px;
    
        .status {
            flex: 1;
        }
    
        .icon {
            width: 16px;
            height: 16px;
            margin: 0 13px;
            flex: 0;
        }
    }
    
    .success-message {
        border: 1px solid $status-success-2;
        background: $status-success-3;
    
        .icon {
            svg {
                fill: $status-success-2;
            }
        }
    }
    
    .danger-message {
        border: 1px solid $status-danger-2;
        background: $status-danger-3;
    
        .icon {
            svg {
                stroke: $status-danger-2;
            }
        }
    }
    .danger-message.onhold-or-requestinfo{
        margin-top: 24px;
        margin-bottom: -8px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 6px;
        .icon{
            margin-right: 4px;
        }
        .danger-message-title-container{
            display: flex;
        }
        .danger-message-title{
            font-weight: 700;
        }
        .danger-message-reason-container{
            display:flex;
            margin-left: 33px
        }
    }
    .greentech-planset-references.references-container{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        padding: 16px;
        border-radius: 6px;
        border: 1px solid #d5d8d9;
        h3{
            margin: 0;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: 0.06px;
        }
        .links-container, .team-container{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .references-header-container{
                padding: 8px 0px;
                border-bottom: 1px solid #d5d8d9;
            }
            
            h4{
                display: block;
                font-weight: 700;
                font-size: 14px;
                margin: 0;
                height: unset;
                line-height: 1;
            }
            label{
                margin-bottom: 0;
                line-height: 1;
                font-size: 13px
            }
            a{
                word-wrap: break-word;
            }
        }
        
    }
}

#modal-approve-documents,
#modal-reject-documents {
    .modal-footer {
        button {
            margin-right: 6px;
        }
    }
}

#modal-reject-documents {

    .reject-reason-label {
        font-weight: normal;

        &::after {
			color: #A91700;
            content: '*';
        }
    }

    .reject-reason {
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        &.error {
            border-color: #D34A35;
            border-width: 2px;
        }
    }

    .reject-reason-error-message {
        color: #A91700;
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 8px;

        .error-icon {
            stroke: #A91700;
        }

        &.hidden {
            display: none;
        }
    }
}

@media only screen and (max-width: $tablet-max-width) and (min-width: $tablet-min-width) {
    .greentechPlansets {
        margin-left: 10px;

        .breadcrumbs {
            padding-left: 0px;
        }

        .service-order-button {
            margin-bottom: 25px;
        }

        .project-settings-button {
            margin-right: 10px;
        }

        .plansets-layout {
            .plansets-content {
                margin-right: 16px;

                .project-information {
                    padding-left: 0;
                    margin-top: 24px;
                }
            }

            .planset-documents {
                .reviewer-button {
                    width: 49%;
                    display: inline-block;
                }
                .reviewer-button:first-of-type {
                    margin-right: 8px;
                }
            }

            .projectinfo-bottom {
                .projectinfo-button {
                    width: 49%;
                    display: inline-block;
                }
                .projectinfo-button:first-of-type {
                    margin-right: 8px;
                }
            }
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }

        .plansets-content .home-middle-content {
            padding-right: 0;
            
            .product-list-content .planset-product-qty {
                border-left: 1px solid rgb(213, 216, 217);
            }
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    .greentechPlansets {
        margin-left: 16px;
        margin-right: 16px;

        .dashboard-heading {
            .heading-text {
                display: block;
            }
            
            .status-pill {
                display: inline-block;
                position: relative;
            }
        }

        .nav-icon.status {
            margin-top: 10px;
            align-items: flex-start;
        }

        .mobile-button {
            display: block;
        }

        .home-left-content {
            margin-top: 24px;
        }

        #chatterContainer {
            .chatterPublisherFileActionContainerLeft {
                display: none;
            }
            #chatterUploadFileActionPanel {
                #chatterUploadFileAction {
                    margin-right: 5px;
                }
            }
        }

        .plansets-content {
            .planset-item, .project-review-status {
                display: block;
            }
            .planset-item {
                margin-bottom: 4px;
            }
            .dashboard-heading {
                margin-bottom: 8px;
            }
            .project-information {
                padding-left: 0;
                margin-top: 24px;
            }

            .home-middle-content {
                padding-right: 0;

                .product-list-content .planset-product-qty {
                    border-left: 1px solid rgb(213, 216, 217);
                }
                
                .product-list-content {
                    table {
                        tbody {
                            tr {
                                td {
                                    .product-detail,
                                    .change-request-detail {
                                        .product-image {
                                            width: 48px;
                                            min-width: 48px;
                                        }

                                        .product-content {
                                            .product-sub-title {
                                                span {
                                                    display: block;
                                                    border-right: none;
                                                    padding-left: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #changeRequestTable {
                        thead {
                            th {
                                display: none;
                            }
                        }
            
                        tbody {
                            border-top: 1px solid #b9bbc0;
            
                            tr.main-row {
                                padding-top: 15px;
                                padding-bottom: 20px;
                                display: block;
            
                                td {
                                    border: none;
                                    position: relative;
                                    padding-left: 175px;
                                    display: block;
                                    padding-top: 0px;
                                    padding-bottom: 0px;
                                }
            
                                td:before {
                                    position: absolute;
                                    left: 15px;	
                                    font-size: 13px;
                                    line-height: 1.5;	
                                    top: 0;				
                                }
            
                                td:nth-of-type(1):before { content: "Case Number"; }
                                td:nth-of-type(2):before { content: "Change Request Reason"; }
                                td:nth-of-type(3):before { content: "Status"; }
                                td:nth-of-type(4):before { content: "Date Submitted"; }
                            }
                        }
                    }
                }
            }            
        }

        .desktop-button {
            display: none;
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .service-order-button {
            margin-bottom: 25px;
        }

        .plansets-description {
            width: 300px;
        }

        .project-settings-button {
            float: none;
            margin-top: 10px;
        }
    }
}