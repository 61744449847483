@import "../variables";
@import "../mixins";

.my-inbox {
	#myInboxTable {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		.downloadAll {
			margin-left: 8px;
		}

		.attachment {			
			a {
				padding-right: 16px;
				display: table-cell;
			}
			span {
				display: table-cell;
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.my-inbox {
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.buttons {
			margin-top: 16px;
		}

		#myInboxTable {
			.attachment {
				a {
					padding-right: 16px;
					display: inline;
				}
				span {
					display: inline;
				}
			}
		}
	}
}