@import "../variables";

.greentech-add-financing {
    .button:not(.disabled),
    .button-emphasis,
    .button-emphasis-small {
        margin: 5px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }

        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    .admin-right-content {
        margin-bottom: 30px;
        width: 100%;

        .back-link {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.13px;
            line-height: 16px;

            margin-bottom: 16px;
        }

        .title-heading-line-1 {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        .title-heading-line-2 {
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        .success-message,
        .danger-message {
            display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            align-items: center;
            -ms-flex-align: center;


            border-radius: 3px;
            padding: 10px 0;
            margin-top: 4px;
            margin-bottom: 20px;

            .status {
                flex: 1;
            }

            .icon {
                width: 16px;
                height: 16px;
                margin: 0 13px;
                flex: 0;
            }
        }

        .success-message {
            border: 1px solid $status-success-2;
            background: $status-success-3;

            .icon {
                svg {
                    fill: $status-success-2;
                }
            }
        }

        .danger-message {
            border: 1px solid $status-danger-2;
            background: $status-danger-3;

            .icon {
                svg {
                    stroke: $status-danger-2;
                }
            }
        }

        #loans-table-container{
            margin: 12px 0px;
            width: 100%;
            min-width: fit-content;

            .noLoansFound{
                background-color: none;
            }

            #filters-container{
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
                border-radius: 3px;
                border-width: 1px;
                border-style: solid;
                padding: 16px;
                // base styles for filters section structure
                #filters-top, #filters-middle, #filters-bottom{
                    display: inherit;
                    flex-direction: row;
                }
                // base styles for inner elements of filters sections
                .loans-filter{
                    display:inherit;
                    flex-direction: column;
                    gap: 8px;
                    .filter-label{
                        margin-bottom: 0px !important;
                    }

                    .filter-label{
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    input, select{
                        height: 40px;
                        padding: 8px;
                        border-width: 1px;
                        border-style: solid;
                        border-radius: 3px;
                    }

                    button{
                        display: flex;
                        border: none;
                        font-size: 14px;
                        font-weight: bold !important;
                        padding: 10px 10px 10px 10px;
                    }
                }
                // more narrow styling for each of the 3 rows in loans-filters-container
                #filters-top{
                    align-items: center;
                    justify-content: space-between;
                    #filters-heading{
                        margin: 0px;
                        font-weight: bold;
                        font-size: 16px;
                        height: 20px;
                    }
                }
                #tablet-row-container{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 24px;
                }
                #filters-middle{
                    justify-content: space-between;
                    gap: 24px;

                    select{
                        min-width: 200px;
                    }
                    .loans-filter{
                        flex-basis: auto;
                        flex-grow: 1;
                    }

                    #store-filter {
                        fieldset{
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }

                    #origination-date-filter, #total-materials-filter{
                        display: flex;
                        flex-direction: column;

                        div{
                            display: flex;
                        }

                        input.filter-input {
                            max-width: 125px;
                        }

                        div:nth-of-type(2){
                            flex-direction: row;
                            justify-content: space-between;
                            gap: 8px;

                            .calendar-container{
                                position: relative;
                                .greentech-calendar{
                                    position: absolute;
                                    top:10px;
                                    right: 8px;
                                    padding: 3px 0px;
                                }
                            }
                        }
                    }
                    #search-filter{
                        flex-basis: 425px;
                    }
                }

                #filters-bottom{
                    justify-content: flex-start;
                    align-content: center;
                    gap: 24px;

                    select{
                        min-width: 200px;
                    }

                    #lenderFilter,
                    #loanStatusFilter,
                    #availabilityFilter{
                        fieldset{
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }

                    #clearAllFilter{
                        align-self: flex-end;
                    }

                }
                //handles expanding and collapsing of filters section
                &.expanded{
                    #filters-middle, #filters-bottom{
                        display: inherit;
                    }
                    #filters-collapse{
                        display:inherit;
                    }
                    #filters-expand{
                        display:none;
                    }
                }
                &.collapsed{
                    #filters-middle, #filters-bottom{
                        display: none;
                    }
                    #filters-collapse{
                        display: none;
                    }
                    #filters-expand{
                        display:inherit;
                    }
                    #clearAllFilters{
                        display: none;
                    }
                }
            }

            #showing-of-text{
                margin-top: 12px;
            }

            #loans-table{
                margin: 12px 0px;
                border-width: 1px;
                border-style: solid;

                th{
                    border-width: 1px;
                    border-style: solid;
                    border-left: 0px;
                    padding: 10px;
                    font-size: 14px;
                    line-height: 20px;
                }

                td{
                    border-width: 1px;
                    border-style: solid;
                    border-left: 0px;
                    padding: 10px;
                    font-size: 14px;
                    line-height: 20px;
                    vertical-align: top;

                    div.availability {
                        margin-top: 7px;
                        margin-bottom: 10px;
                    }

                    .available-badge {

                        border: none;
                        border-radius: 12px;
                        padding: 5px 6px;
                        margin-top: -2px;

                        &::after {
                            color: rgb(33, 33, 33);
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0.13px;
                            line-height: 14px;
                        }

                        i.available-icon {
                            display: none;
                            margin-right: 5px;

                            &.available {
                                color: $status-success-2;
                            }

                            &.unavailable {
                                color: $status-neutral-2;
                            }
                        }

                        &.available {
                            background-color: $status-success-3;
                            &::after {
                                content: "Available";
                            }
                            i.available-icon.available {
                                display: inline;
                            }
                        }

                        &.unavailable {
                            background-color: $status-neutral-4;
                            &::after {
                                content: "Unavailable";
                            }
                            i.available-icon.unavailable {
                                display: inline;
                            }
                        }
                    }
                }

                .loanIdCol{
                    font-weight: bold;
                }

                .show-more-info{
                    display: none;
                    .showMoreCol{
                        cursor: pointer;
                    }
                }
            }

            #loansPagination{
                display: flex;
                justify-content: center;
                list-style: none;
                .chevron-container{
                    border-width: 2px;
                    border-style: solid;
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                }
            }

            #loans-table {
                width: 100%;
            }
            #noLoansFound{
                border-right: 0px !important;
            }
        }
    }

    .overview-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentech-add-financing {

        .breadcrumbs {
            padding-left: 0px;
        }

        .overview-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }

        .admin-right-content {
            .noLoansFound{
                background-color: none;
            }
            margin: 16px;
            padding-left: 0px;
            padding-right: 0px;
            #loans-table-container {
                #filters-container.expanded{
                    padding: 16px;
                    gap: 0px;
                    #filters-top{
                        margin-bottom: 20px;
                    }
                    #filters-middle {
                        .loans-filter {
                            display:flex;
                            margin-bottom: 20px;
                        }

                        #origination-date-filter,
                        #total-materials-filter{

                            input.filter-input {
                                max-width: unset;
                            }
                        }
                    }
                    #filters-middle, #filters-bottom{
                        display: block !important;
                        .loans-filter, .filter-input{
                            flex-basis: auto !important;
                            width: 100%;
                        }
                        .date-picker-container div{
                            flex-grow: 1;
                            flex-basis: auto;
                        }
                        #clearAllFilter{
                            width: auto;
                            min-width: 70px;
                        }
                    }
                    #filters-middle,
                    #filters-bottom{
                        > #tablet-row-container {
                            flex-wrap: wrap;

                            > .loans-filter {
                                width: 48%;
                                max-width: 48%;
                            }
                        }
                    }
                }

                #loans-table {
                    thead{
                        th:nth-of-type(1){
                            min-width: 150px;
                        }
                        th:nth-of-type(4),
                        th:nth-of-type(5){
                            display: none;
                        }
                    }
                    tr{
                        td:nth-of-type(4),
                        td:nth-of-type(5){
                            display: none;
                        }
                    }
                    tbody{
                        tr.show-more-info{
                            border-bottom: 0px !important;
                            padding-top: 5px;
                            padding-bottom: 10px;
                            display: block;
                            &.collapsed{
                                height: 32px;
                                td:first-child{
                                    i:nth-of-type(1){
                                        display: inline-block;
                                    }
                                    i:nth-of-type(2){
                                        display: none;
                                    }
                                }
                                td:nth-of-type(2),
                                td:nth-of-type(3){
                                    display: none;
                                }
                            }

                            &.expanded{
                                height: 100px;
                                td:first-child{
                                    i:nth-of-type(1){
                                        display: none;
                                    }
                                    i:nth-of-type(2){
                                        display: inline-block;
                                    }
                                }
                                td:nth-of-type(2),
                                td:nth-of-type(3){
                                    display: block;
                                }
                            }
                            td{
                                border: none;
                                display: block;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                font-size: 12px;
                            }
                            i{
                                padding-left: 12px;
                                margin-right: 3px;
                            }
                            td:before {
                                position: absolute;
                                left: 40px;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 1.5;
                                bottom: 4px;
                                top: 1px;
                            }
                            td:nth-of-type(2):before {
                                z-index: 1;
                                content: "Origination Date:";
                            }
                            td:nth-of-type(3):before {
                                content: "Total Materials:";
                                padding-top:24px
                            }

                            td:nth-of-type(2){
                                position: absolute;
                                z-index: 1;
                                padding-left: 150px;
                            }
                            td:nth-of-type(3){
                                position: absolute;
                                padding-left: 150px;
                                padding-top: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentech-add-financing {
        .breadcrumbs {
            padding-left: 0px;
        }

        .home-left-content {
            margin-left: 5px;
        }

        .admin-right-content {

            .title-heading-line-2 {
                font-size: 24px;
                padding-bottom: 0;
            }

            .noLoansFound{
                background-color: none;
            }

            hr {
                margin: 12px 0px;
                border-top-width: 1px;
                border-top-style: solid;
            }

            #loans-table-container{

                #filters-container{
                    padding: 16px;
                    #filters-middle,
                    #filters-bottom,
                    #tablet-row-container,
                    .date-picker-container,
                    .date-picker-container div,
                    .date-picker-container div input,
                    #total-materials-filter,
                    #total-materials-filter div,
                    #total-materials-filter div input{
                        flex-direction: column !important;
                        flex-grow: 1;
                        flex-basis: auto;
                    }

                    #filters-middle {
                        #origination-date-filter,
                        #total-materials-filter{

                            input.filter-input {
                                max-width: unset;
                            }
                        }
                    }
                    #search-filter{
                        flex-basis: auto !important;
                    }
                    #clearAllFilter{
                        align-self: flex-start !important;
                    }
                }

                #loansPagination {
                    padding-left: 0;

                    .page-selector {
                        width: unset;
                    }
                }

                .footable-toggle{
                    display: none !important;
                }

                th{
                    border-right-width: 1px;
                    border-right-style: solid;
                }
                td{
                    border: none !important;
                }

                #showing-of-text{
                    display: block;
                    margin: 12px 0px;
                }

                #all-loans-table-header-mobile{
                    display: inline !important;
                    margin: 12px 0px;
                    font-weight: bold;
                }

                #loans-table {
                    margin: 24px 0px;
                    border: none;

                    thead {
                        th {
                            display: none;
                        }

                    }

                    tbody {
                        tr.footable-detail-show {
                            border-bottom-width: 1px;
                            border-bottom-style: solid;
                            padding-top: 5px;
                            padding-bottom: 30px;
                            display: block;

                            td {
                                border: none;
                                position: relative;
                                padding-left: 150px;
                                display: block;
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }

                            td:before {
                                position: absolute;
                                left: 30px;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 1.5;
                                bottom: 4px;
                                top: 1px;
                            }

                            td:nth-of-type(1):before { content: "Loan ID"; }
                            td:nth-of-type(2):before { content: "Finance Address"; }
                            td:nth-of-type(3):before { content: "Lender"; }
                            td:nth-of-type(4):before { content: "Origination Date"; }
                            td:nth-of-type(5):before { content: "Total Materials"; }
                            td:nth-of-type(6):before { content: "Loan Status"; }

                            td.loanIDCol {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            #noLoansFound{
                padding-left: 13px !important;
            }
        }
    }
}
