.greentechProjectDetail {
    margin-left: 10px;
    margin-bottom: 30px;

    .dashboard-heading {
        color: rgb(106, 107, 109);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.13px;
        padding-top: 25px;
    }

    .name-heading {
        color: rgb(33, 33, 33);
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.3px;
    }

    .dashboard-addresses {
        width: 153px;
        height: 28px;
        color: rgb(33, 33, 33);
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.17px;
        line-height: 28px;
    }

    .address-component {
        display: inline-flex;
        width: 363px;
        height: 136px;
        border: 1px solid rgb(213, 216, 217);
        border-radius: 6px;
        padding-top: 10px;
        padding-right: 20px;
        margin-right: 20px;
        margin-top: 20px;

        a {
            padding: 10px;
        }
    }

    .heading {
        color: rgb(33, 33, 33);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15px;
        line-height: 24px;
        margin-left: 15px;
    }

    .projectSettingsButton {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.13px;
        line-height: 16px;
        height: 32px;
        width: 152px;
        border-radius: 5px;
        background: none;
        margin-right: 10px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }
    }

    hr {
        padding-bottom: 5px;
    }

    .name {
        padding-left: 10px;
        color: rgb(106, 107, 109);
    }

    .change-add-address {
        padding-top: 20px;
        margin-left: 5px;
    }

    .address {
        margin-left: 15px;
    }

    .mobile-button {
        visibility: hidden;
    }

    .desktop-button {
        float: right;
    }

    .modalBackground {
        background-color:black;
        opacity: 0.20;
        filter: alpha(opacity = 20);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9998;
    }

    .addressModal {
        background-color: white;
        z-index: 9999;
        left: 20%;
        padding:10px;
        position: absolute;
        margin: 0 auto;
        top: 0%;
        opacity: 1;
        width: 600px;
        height: 600px;
    }

    .close-modal-x {
        border: 0px;
        background-color: transparent;
        float: right;
    }

    .fields {
        padding-top: 20px;
    }

    .cancel-button {
        float: left;
        margin-top: 20px;
    }

    .save-button {
        margin-top: 20px;
    }

    .state-field {
        padding: 10px 0 10px 0;
    }

    .project-detail-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .dashboard-content {
        width: 100%;
    }
}

@media only screen and (max-width: $greentech-tablet-max-width) and (min-width: $greentech-tablet-min-width) {
    .greentechProjectDetail {
        margin-left: 10px;
        .desktop-button {
            margin-left: 75%;
            float: right;
        }

        .address-component {
            display: inline-flex;
            width: 208px;
            height: 156px;
            border: 1px solid rgb(213, 216, 217);
            border-radius: 6px;
            padding-top: 10px;
            padding-right: 20px;
            margin-right: 20px;
            margin-top: 20px;
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .project-detail-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    .greentechProjectDetail {
        .mobile-button {
            visibility: visible;
            display: block;
            margin-left: 0%;
            padding-top: 15px;
        }

        .desktop-button {
            display: none;
            visibility: hidden;
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .addressModal {
            width: 288px;
            left: 10%;
        }
    }
}