@import "../variables";

body.solar-template {
	.order-verification {
		.order-verification-content {
			padding-right: 0;
			padding-left: 0;

			div.row {
				margin-left: 0;
				margin-right: 0;
			}

			.product-headings {				
			    background-color: #fff;
			    box-shadow: none;
			    margin-top: 24px;
			    font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;

				> div {
					padding: 10px 0;
				}

				div {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.edit-cart{
				padding: 0
			}

			

			.wizard-area {
				padding-left: 40px;
				padding-right: 40px;

				> div.row {
					> div {
						padding-left: 0;
						padding-right: 0;
					}
				}

				h1 {
					font-size: 32px;
					line-height: 1.25;
					letter-spacing: 0.3px;
				}
			}

			.order-verification-form > div{
				padding-left: 40px;
				padding-right: 40px;
			}

			.order-verification-form {
				margin-top: 24px;

				.line-items {
					.line-item:last-of-type {
						border-bottom: none;
					}

					.line-item {
						padding: 16px 0;

						.lotQuoteItemsTable {
							margin-left: 0px;
				    		margin-right: 0px;
						}

						div {
							padding-left: 0;
							padding-right: 0;
						}

						.spinner-qty {
							width: 72px;
						}

						.line-action-buttons {
							margin-top: 0;

							a {
								margin-right: 8px;
								margin-bottom: 0;
							}
						}

						.line-note-box, .line-action-buttons {
							> div {
								padding-left: 16px;
								margin-bottom: 16px;
							}
						}

						label {
							font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
						}

						.line-item-image {
							padding-left: 0;
							padding-right: 0;

							img {
								max-width: 100%;
								max-height: 96px;
							}
						}

						.product-details {
							padding-left: 16px;
							padding-right: 16px;
							margin-top: 0;
							margin-bottom: 16px;

							.product-name {
								font-size: 16px;
								font-weight: 600;
								line-height: 1.5;
								letter-spacing: 0.2px;
							}

							ul {
				  				list-style-type: none;
				  				margin: 0;
				  				padding: 0;

				  				li {
				  					display: block;
				  					font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									color: #606e71;
									margin: 0;
				  				}
				  			}
						}
					}
				}

				.title-row {
					> div {
						padding-left: 0;

						h1 {
							font-size: 32px;
							line-height: 1.25;
							letter-spacing: 0.3px;
						}
					}
					
				}

				.additional-receipts .col-xs-12, .additional-receipts-field .col-xs-12, .button-row .col-sm-12{
					padding-left: 0px;
					padding-right: 0px;
				}

				.order-verification-info {

					> div:nth-of-type(1){
	    				padding-left: 0px;
		    		}

		    		> div:nth-of-type(2){
		    			padding-right: 0px;
		    		}

					.col-sm-4 {
						padding-left: 10px;
						padding-right: 10px;
					}

					.order-verification-info-block {
						padding: 16px;
						border-radius: 2px;

						h4 {
							font-size: 16px;
							font-weight: 600;
							line-height: 1.5;
							letter-spacing: 0.2px;
						}

						.heading {
							h6 {
								font-size: 14px;
								font-weight: 600;
								line-height: 1.43;
								letter-spacing: 0.1px;
							}
						}
					}
				}

				.additional-receipts {
					margin-top: 24px;
					margin-bottom: 16px;

					h3 {
						font-size: 18px;
						font-weight: 600;
						line-height: 1.56;
						letter-spacing: 0.2px;
					}
				}

				.additional-receipts-field {
					label {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}
				}

				.button-row {
					.go-back {
						color: #657878;
					}
				}

				.items-row {
					
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.order-verification {
			.order-verification-content {
				.order-verification-form > div {
					padding-left: 16px;
					padding-right: 16px;
				}

				.wizard-area {
					padding-left: 16px;
					padding-right: 16px;
				}

				
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.order-verification {



			overflow: hidden;

			.checkout-content {
				.top-proceed-button {
					width: 100%;
					margin-top: 24px;
				}

				.page-buttons {					
					button {
						margin-bottom: 8px;
					}
				}

				.bottom-proceed-button {
					z-index: 99;
				}

				.edit-cart-row > div{
					padding-left: 0;
					padding-right: 0;
				}

				.order-verification-form > div{
					padding-left: 16px;
					padding-right: 16px;
				}

				.wizard-area {
					padding-left: 16px;
					padding-right: 16px;
				}
			}



			.order-verification-content {
				.order-verification-form {
					.order-verification-info{
						> div{
							padding-left: 0;
	    					padding-right: 0;
						}
					}

					.line-items {
						margin-top: 16px;
						padding-left: 15px;
						padding-right: 15px;

	    				.line-item {
		    				.line-item-image {
		    					padding-left: 16px;

		    					img {
		    						max-height: 73px;
		    					}
		    				}

		    				.line-note-box {
		    					> div {
		    						padding-right: 16px;
		    					}
		    				}

		    				.lotQuoteItemsTable {
								margin-left: 15px;
					    		margin-right: 15px;
							}
		    			}

	    				> div {
	    					padding-left: 16px;
	    					padding-right: 16px;
	    				}

	    				.solar-line {
	    					position: relative;

	    					.solar-line-content {
	    						position: initial;

	    						.product-details {
	    							margin-bottom: 65px;
	    						}
	    						.product-details.custom-product {
	    							margin-bottom: 105px;
	    						}

	    						.solar-line-price {
	    							position: absolute;
								    bottom: 0;
								    left: 114px;
	    						}

	    						.solar-line-quantity {
	    							position: absolute;
								    bottom: 0;
								    left: 16px;
								    text-align: left;

								    .cart-qty-input {
										position: relative;
										display: inline-block;

										input {
											width: 90px;
											text-align: right;
											padding-left: 30px;
											padding-right: 12px;
											font-size: 16px;
											line-height: 1.5;
											letter-spacing: 0.2px;			
										}
									}
									.cart-qty-input:before {
										content: "Qty";
										position: absolute;
										left: 8px;
										top: 13px;
										font-size: 12px;
										font-weight: 600;
										line-height: 1.67;
										letter-spacing: 0.1px;
									}
	    						}
	    					}
	    				}

	    				.line-item {
	    					.line-action-buttons {
	    						a {
	    							margin-bottom: 8px;
	    						}

	    						> div {
	    							margin-bottom: 0;
	    						}
	    					}
	    				}
					}


					.title-row {
						margin-top: 16px;
					}

					

					.mobile-order-summary {
						.total-row {
							margin-right: 0;
						}
					}

					.items-row {
						.mobile-verification-row {
							padding: 16px;

							.product-image {
								width: 91px;

								img {
								    max-width: 91px;
									max-height: 91px;
								}
							}

							.product-detail-info {
								width: auto;
								margin-left: 7px;

								ul {
					  				list-style-type: none;
					  				margin: 0;
					  				padding: 0;

					  				li {
					  					display: block;
					  					font-size: 12px;
										line-height: 1.67;
										letter-spacing: 0.1px;
										color: #606e71;
										margin: 0;
					  				}
					  			}

					  			.item-number {
					  				margin-top: 8px;

					  				h6 {
					  					font-size: 18px;
										font-weight: 600;
										line-height: 1.56;
										letter-spacing: 0.2px;
					  				}
					  			}
							}									
						}
					}
				}
			}
		}
	}
}

/* thin desktop */
@media only screen and (min-width: $tablet-max-width) and (max-width: 1280px) {
	body.solar-template {
		.order-verification {
			.order-verification-content {
				.wizard-area {
					padding-left: 16px;
					padding-right: 16px;
				}

				.order-verification-form > div{
					padding-left: 16px;
					padding-right: 16px;
				}
			}
		}
	}
}