@import "../variables";

.qb-setup{
	#nav-box{
		display: flex;
		flex-wrap: wrap;
	}

	.nav-button{
		position: relative;
		display: block;
		width: 306px;
		margin-right: 1%;
		background: linear-gradient(-180deg, #fff 0%, #FCFCFC 100%);
		border: 1px solid #BBBDC6;
		box-shadow: inset 0 -1px 0 0 #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		padding: 24px 16px;
		color: black;
		margin-bottom: 15px;

		.border{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: #5a9723
		}

		img{
			width: 56px;
		}

		label{
			display: inline;
			font-size: 18px;
			line-height: 25px;
		}
	}

}