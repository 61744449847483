@import "../variables";


#service-page-container{
	&.notifications{
		#page-head{
			padding: 56px 80px 372px 80px;
		}
	
		.page-msg{
			height: 400px;
			float: right;
			background: white;
			width: 100%;
			box-shadow: 0px 30px 50px -20px rgba(39, 40, 41, 0.4);
			display: flex;
		}
	
		.text-side{
			flex: 34%;
			color: rgb(77, 77, 77);
			padding: 56px;
			text-align: left;

			h2{
				display: block;
				margin: 0;
				margin-bottom: 8px;
				height: auto;
				font-size: 23px;
				font-weight: bold;
			}

			span{
				font-size: 16px;
				font-weight: normal;
				line-height: 25px;
				display: block;
				margin-bottom: 32px;
			}
		}
	
		.image-side{
			display: flex;
			flex: 66%;
	
			&>div{
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
	
			img{
				max-width: 100%;
				min-height: 0;
				align-self: center;
			}
		}
	}

	.page-msg {
		font-size: 19px;
	  	font-weight: normal;
	  	font-style: normal;
	  	font-stretch: normal;
	  	line-height: 1.32;
	  	letter-spacing: normal;
	}

	.service-page{
        width: 100%;
        margin-top: 40px;
    }

    .service-page ul#service-list{
        padding-left: 0px;
    }

    .service-page h2{
    	height: 24px;
        font-size: 16px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 0.2px;
    }

    

    .regular-service-section{
		background-color: #f8f9f9;
        width: 100%;
    }

    .service-list{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        max-width: 1200px;
		padding: 0 15px 2em 15px;
		margin:0 auto;
    }

    .service-list > li {
		margin-top: 2em;
		width: 50%;
		padding-right: 25px;
		list-style: none;

		.service-contents{
	        margin-left: 1.2em;
	        margin-top:.5em;
	        margin-bottom: .5em;
	        font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;

	        img{
	        	max-width: 100%;
	        	vertical-align: inherit;
	        }
	    }

        & > a{
        	margin-left: 1.2em;
        }
    }
	

    

    .featured-service-list{
        display: flex;
		align-items: flex-start;
    	justify-content: center;
		flex-direction: row;
		padding: 0;
    	max-width: 1200px;
		margin: 10px auto;
		flex-wrap: wrap;

		& > li{
			list-style: none;
			max-width: 33%;
			margin: 36px 11px;
			flex: 1 1 30%;

			h2{
				margin-top: 24px;
				display: block;
			}

			.image-container{
				margin: auto;
				display: flex;
				height: 177px;

				img{
					display: block;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}

			.service-contents{
		        margin-top:.5em;
		        margin-bottom: .5em;
		        font-size: 14px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.43;
		    }
	    }
    }
	
    
    .service-contents ul {
        margin-left:0;
    }

    .service-page .page-msg {
        margin-top:.5em;
    }

    .nav-tabs {
        text-align:center;
        max-width: 1200px;
    	margin: 0 auto;

    	& > li {
	        float:none;
	        display:inline-block;
	        zoom:1;
	    }
    }

    #page-head{
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        text-align: center;
        color: #fff;
        padding: 56px 270px;

        h1{
	        font-size: 32px;
	        font-weight: 600;
	        line-height: 1.25;
	        letter-spacing: 0.3px;
	        text-align: center;
	        color: #ffffff;
	        margin-bottom: 24px;
	        display: block;
	        margin-top: 0;
	    }
    }
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	#service-page-container{

		&.notifications{
		
			.page-msg{
				flex-direction: column-reverse;
			}
		
			.text-side{
				flex: 1 0 auto;
				padding: 24px;

				span{
					margin-bottom: 0;
				}

				a{
					display:none;
				}
			}
		
			.image-side{
				display: block;
				flex: 0 1 100%;
				height: 100%;
				min-height: 0;

				div{
					height: 100%;
					display: block;
				}

				img{
					max-height: 100%;
				}
			}
		}

		#page-head{
			padding: 56px 16px;
		}

		.featured-service-list{
			margin: 10px auto;
			padding: 0 6px;

			& > li{
				list-style: none;
				margin: 28px 10px;
				.image-container{
					height: 150px;
				}
		    }
    	}
	}
}

@media only screen and (max-width: $mobile-max-width) {
	#service-page-container{
		&.notifications{

			#page-head{
				padding: 48px 16px 110px 16px;
			}

			h1{
				height: auto;
			}
		
			.page-msg{
				flex-direction: column-reverse;
				height: auto;
			}
		
			.text-side{
				display: none;
			}
		
			.image-side{
				display: block;
				flex: 0 1 100%;
				height: 100%;
				min-height: 0;

				div{
					height: 100%;
					display: block;
				}

				img{
					max-height: 100%;
				}
			}
		}

		#page-head{
			padding: 48px 16px;
		}

		.service-list>li {
		    flex: 0 0 100%;
		    width: 100%
		}

		.featured-service-list{
			flex-direction: column;
			align-items: center;
			padding: 0 10px;

			& > li{
				list-style: none;
				max-width: 100%;
				width: 100%;
				margin: 24px 10px;
				.image-container{
					height: 240px;
				}
		    }
    	}
	}

	
}