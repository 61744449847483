@import "../variables";

.home-full-content {
	overflow: hidden;
}

.home-container {
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;

	.home-left-content {
		width: 300px;	

		hr {
			border-top: 1px solid #b9bbc0;
		}
	}

	.home-right-content {
		flex: 1;
		-webkit-box-flex: 1;
  		-moz-box-flex:  1;
  		-webkit-flex:  1;
  		-ms-flex:  1;
		overflow: hidden;
	}
}

.list-style-reset {
    ul {
        margin: 1em;
        margin-left: 40px;
        li {
            list-style-type: disc;
            margin-left: 0;
        }
    }
    ol {
        margin: 1em;
        margin-left: 40px;
        li {
            list-style-type: decimal;
            margin-left: 0;
        }
    }
}

hr.message-container {
	border-top: 1px solid #b9bbc0;
}

.message-container {
    padding-bottom:10px;
    margin-left: 30px;
    margin-right: 30px;
}

/** home page slides **/
#outer-slider-wrapper {
	margin-left: 30px;
	margin-right: 30px;

	#slider-container {
		margin-top: 40px;
		margin-bottom: 40px;

		/*max-width: 1200px;*/
		max-width: 900px;
	    margin-left: auto;
		margin-right: auto;
		
		&.show-prev{
			max-width: 1200px;

			.lSSlideOuter {
				.lSSlideWrapper {
					margin-left: 150px;
					margin-right: 150px;
				}
			}
		}

		#single-slider {
			/*height: 350px !important;*/
			height: auto !important;
	        max-height: 500px !important;

			li {
				height: 100%;
				position: relative;
				margin-left: 0;

				img {
					/*object-fit: cover;
	    			width: 100%;
				    height: 350px;*/
				    max-width: 100%;
	                max-height: 100%;
	                object-fit: contain;
	                height: auto;
				}

				.caption {
		            position: absolute;
		            right: 30px;
		            bottom: 30px;	            	            
		            width: 60%;	            
		            opacity: 0.95;
					text-align: right;
					font-size: 24px;
					font-style: italic;
					font-weight: 800;
		        }
			}

			.slide-wrapper {
	            display: block;
	            position: relative;
	            text-align: center;
	        }
		}

		.lSSlideOuter {
			.lSSlideWrapper {
	            /*margin-left: 150px;
	            margin-right: 150px;*/
	            overflow: visible;
	        }

			.lSPager.lSpg>li {
				margin-left: 0;

				a {
					width: 12px;
					height: 12px;
				}
			}
		}
	}
}

.lightSlider li {
    position: relative;
}
.lightSlider li img {
    margin-left: auto;
    margin-right: auto;
}
/** end home page slides **/

/** start featured brands **/
.featured-brands-bg {
	height: 365px;
	padding: 60px 110px 40px 110px;
	margin-top: 60px;

	.featured-brands-content {
		margin-top: 10px;
	}

	ul.lightSlider {
		li {
			margin-left: 0;			
			height: 90px;

			a {
				display: block;
				text-align: center;
				height: 90px;

				img.featuredBrandImage {
					max-height: 90px;
					max-width: 100%;

					position: relative;
					top: 50%;
					transform: perspective(1px) translateY(-50%);
					-webkit-transform: perspective(1px) translateY(-50%);
					-moz-transform: perspective(1px) translateY(-50%);
					-ms-transform: perspective(1px) translateY(-50%);
 					-o-transform: perspective(1px) translateY(-50%);
				}
			}

			> img.featuredBrandImage {
				display: block;
				text-align: center;
				max-height: 90px;
				max-width: 100%;

				position: relative;
				top: 50%;
				transform: perspective(1px) translateY(-50%);
				-webkit-transform: perspective(1px) translateY(-50%);
				-moz-transform: perspective(1px) translateY(-50%);
				-ms-transform: perspective(1px) translateY(-50%);
					-o-transform: perspective(1px) translateY(-50%);
			}
		}
	}
	.lSAction>a {
		background-image: none;
		opacity: 1;
		width: 11px;
		height: 30px;
	}
	#featured-brands-prev {
		display: block;
		height: 90px;
    	
    	i {
    		position: relative;
	  		top: 50%;
	  		transform: perspective(1px) translateY(-50%);
	  		-webkit-transform: perspective(1px) translateY(-50%);
	  		-moz-transform: perspective(1px) translateY(-50%);
			-ms-transform: perspective(1px) translateY(-50%);
 			-o-transform: perspective(1px) translateY(-50%);
    	}
	}
	#featured-brands-next {
		display: block;
		text-align: right;
		height: 90px;
    	
    	i {
    		position: relative;
	  		top: 50%;
	  		transform: perspective(1px) translateY(-50%);
	  		-webkit-transform: perspective(1px) translateY(-50%);
	  		-moz-transform: perspective(1px) translateY(-50%);
			-ms-transform: perspective(1px) translateY(-50%);
 			-o-transform: perspective(1px) translateY(-50%);
    	}
	}
}

/** end featured brands **/

.home-page-title {
	text-align: center;
	margin-bottom: 30px;
}

.home-page-button {
	text-align: center;
	margin-top: 30px;
}

.featured-products {
	margin-left: 30px;
	margin-right: 30px;

	div.row.row-eq-height {
		> div.col-sm-4 {
			width: 33.2%;
		}		

		> div {
			display: -webkit-box;
  			display: -webkit-flex;
  			display: -ms-flexbox;
  			display: flex;
		}
	}
	.featured-product-item {
		object-fit: contain;
		border: solid 1px #b9bbc0;
		padding: 20px;
		width: 100%;

		.featured-product-image {
			width: 100%;
			height: 150px;
			
			a {
				display: block;
				text-align: center;
				position: relative;
	  			top: 50%;
	  			transform: perspective(1px) translateY(-50%);
	  			-webkit-transform: perspective(1px) translateY(-50%);
	  			-moz-transform: perspective(1px) translateY(-50%);
				-ms-transform: perspective(1px) translateY(-50%);
 				-o-transform: perspective(1px) translateY(-50%);

				img {
					max-width: 75%;
					max-height: 130px;
				}
			}
		}

		.featured-product-right {
			a {
				font-size: 16px;
				font-weight: bold;
				line-height: 1.6;
			}
		}

		.featured-product-price {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.4;
		}

		.featured-product-uom {
			font-size: 15px;
			line-height: 1.7;
		}
	}
}

/** start top sellers **/
.top-sellers {
	margin-top: 60px;
	margin-left: 30px;
	margin-right: 30px;

	div.row.row-eq-height {
	
		> div {
			display: -webkit-box;
  			display: -webkit-flex;
  			display: -ms-flexbox;
  			display: flex;
		}
	}
	.top-sellers-item {
		object-fit: contain;
		border: solid 1px #b9bbc0;
		padding: 20px;
		width: 100%;

		.top-sellers-image {
			width: 100%;
			height: 150px;
			
			a {
				display: block;
				text-align: center;
				position: relative;
	  			top: 50%;
	  			transform: perspective(1px) translateY(-50%);
	  			-webkit-transform: perspective(1px) translateY(-50%);
	  			-moz-transform: perspective(1px) translateY(-50%);
				-ms-transform: perspective(1px) translateY(-50%);
 				-o-transform: perspective(1px) translateY(-50%);

				img {
					max-width: 75%;
					max-height: 130px;
				}
			}
		}

		.top-sellers-right {
			a {
				font-size: 16px;
				font-weight: bold;
				line-height: 1.6;
			}
		}

		.top-sellers-price {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.4;
		}

		.top-sellers-uom {
			font-size: 15px;
			line-height: 1.7;
		}
	}
}
/** end top sellers **/

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.home-container {
		.home-left-content {
			width: 63px;
		}
	}

	#outer-slider-wrapper {
		margin-left: 15px;
		margin-right: 15px;
	}

	.featured-brands-bg {
		height: 298px;
		margin-top: 20px;
		padding: 50px 15px;

		ul.lightSlider {
			li {
				margin-left: 0;			
				height: 50px;

				a {
					display: block;
					text-align: center;
					height: 50px;

					img.featuredBrandImage {
						max-height: 50px;
						max-width: 100%;

						position: relative;
						top: 50%;
						transform: perspective(1px) translateY(-50%);
						-webkit-transform: perspective(1px) translateY(-50%);
						-moz-transform: perspective(1px) translateY(-50%);
						-ms-transform: perspective(1px) translateY(-50%);
 						-o-transform: perspective(1px) translateY(-50%);
					}
				}
			}
		}

		#featured-brands-prev, #featured-brands-next {
			height: 50px;
		}
	}

	.message-container {
		margin-right: 15px;
		margin-left: 15px;
	}

	.customer-message-container {
		img {
			width: 100%;
		}
	}

	.featured-products {
		margin-right: 15px;
		margin-left: 15px;

		> div.row {
			margin-right: 0;

			div.col-sm-4:first-of-type {
				padding-left: 0;
			}

			div.col-sm-4 {
				width: 33.2%;
				padding-left: 18px;
				padding-right: 0;
			}
		}

		div.row.row-eq-height {
			margin-right: 0;

			> div.col-sm-6 {
				width: 49.9%;
			}

			> div {
				display: -webkit-box;
	  			display: -webkit-flex;
	  			display: -ms-flexbox;
	  			display: flex;
	  			margin-bottom: 15px;
			    padding-left: 15px;
			    padding-right: 0;
			}
		}

		.featured-product-item {
			object-fit: contain;
			border: solid 1px #b9bbc0;
			padding: 20px;

			.featured-product-image {
				width: 82px;
				height: 82px;
				padding-right: 18px;
				float: left;

				a {
					display: block;
					text-align: center;
					position: relative;
		  			top: auto;
		  			transform: none;
		  			-webkit-transform: none;
		  			-moz-transform: none;
					-ms-transform: none;
 					-o-transform: none;

					img {
						max-width: 82px;
						max-height: 82px;
						overflow: hidden;
					}
				}
			}

			.featured-product-right {
				float: left;
			}

			.featured-product-price {
				font-size: 22px;
				font-weight: bold;
				line-height: 1.4;
			}

			.featured-product-uom {
				font-size: 13px;
				line-height: 1.5;
			}
		}
	}

	/** start top sellers **/
	.top-sellers {
		margin-left: 15px;
		margin-right: 15px;

		.home-page-button {
			margin-top: 15px;
		}

		div.row.row-eq-height {
			margin-right: 0;

			> div.col-sm-6 {
				width: 49.9%;
			}

			> div {
				display: -webkit-box;
	  			display: -webkit-flex;
	  			display: -ms-flexbox;
	  			display: flex;
	  			margin-bottom: 15px;
			    padding-left: 15px;
			    padding-right: 0;
			}
		}
		.top-sellers-item {
			object-fit: contain;
			border: solid 1px #b9bbc0;
			padding: 20px 15px;
			width: 100%;

			.top-sellers-image {
				width: 82px;
				height: 82px;
				padding-right: 18px;
				float: left;

				a {
					display: block;
					text-align: center;
					position: relative;
		  			top: auto;
		  			transform: none;
		  			-webkit-transform: none;
		  			-moz-transform: none;
					-ms-transform: none;
 					-o-transform: none;

					img {
						max-width: 82px;
						max-height: 82px;
						overflow: hidden;
					}
				}
			}

			.top-sellers-right {
				float: left;
			}

			.top-sellers-price {
				font-size: 22px;
				font-weight: bold;
				line-height: 1.4;
			}

			.top-sellers-uom {
				font-size: 13px;
				line-height: 1.5;
			}
		}		
	}
	/** end top sellers **/
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.lSSlideWrapper {
        margin-left: 0px !important;
        margin-right: 0 !important;
    }
                    
	.featured-brands-bg {
		padding: 50px 15px 30px 15px;
		height: 282px;
		margin-top: 50px;

		.featured-brands-content {
			margin-top: 10px;
		}

		ul.lightSlider {
			li {
				margin-left: 0;			
				height: 43px;

				a {
					display: block;
					text-align: center;
					height: 43px;

					img.featuredBrandImage {
						max-height: 43px;
						max-width: 100%;

						position: relative;
						top: 50%;
						transform: perspective(1px) translateY(-50%);
						-webkit-transform: perspective(1px) translateY(-50%);
						-moz-transform: perspective(1px) translateY(-50%);
						-ms-transform: perspective(1px) translateY(-50%);
 						-o-transform: perspective(1px) translateY(-50%);
					}
				}
			}
		}
		.lSAction>a {
			background-image: none;
			opacity: 1;
			width: 11px;
			height: 30px;
		}
		#featured-brands-prev {
			display: block;
			height: 43px;
	    	
	    	i {
	    		position: relative;
		  		top: 50%;
		  		transform: perspective(1px) translateY(-50%);
		  		-webkit-transform: perspective(1px) translateY(-50%);
		  		-moz-transform: perspective(1px) translateY(-50%);
				-ms-transform: perspective(1px) translateY(-50%);
 				-o-transform: perspective(1px) translateY(-50%);
	    	}
		}
		#featured-brands-next {
			display: block;
			text-align: right;
			height: 43px;
	    	
	    	i {
	    		position: relative;
		  		top: 50%;
		  		transform: perspective(1px) translateY(-50%);
		  		-webkit-transform: perspective(1px) translateY(-50%);
		  		-moz-transform: perspective(1px) translateY(-50%);
				-ms-transform: perspective(1px) translateY(-50%);
 				-o-transform: perspective(1px) translateY(-50%);
	    	}
		}
	}

	.featured-products {
		margin-left: 0;
		margin-right: 0;

		.featured-products-button-row {
    		border-top: 1px solid #b9bbc0;
    		margin-left: 0;
    		margin-right: 0;
    	}

		div.row.row-eq-height {
			margin-right: 0;

			> div.col-xs-12 {
				width: 100%;
			}

			> div {
				display: -webkit-box;
	  			display: -webkit-flex;
	  			display: -ms-flexbox;
	  			display: flex;
	  			margin-bottom: 0;
			    padding-left: 15px;
			    padding-right: 0;
			}
		}
		.featured-product-item {
			object-fit: contain;
			border-left-width: 0;
			border-right-width: 0;
			border-bottom-width: 0;
			padding: 20px 15px;
			width: 100%;

			.featured-product-image {
				width: 65px;
				height: 65px;
				padding-right: 18px;
				float: left;

				a {
					display: block;
					text-align: left;
					position: relative;
		  			top: auto;
		  			transform: none;
		  			-webkit-transform: none;
		  			-moz-transform: none;
					-ms-transform: none;
 					-o-transform: none;

					img {
						max-width: 65px;
						max-height: 65px;
						overflow: hidden;
					}
				}
			}

			.featured-product-right {
				float: left;

				a {
					font-size: 15px;
					font-weight: bold;
					line-height: 1.7;
				}
			}

			.featured-product-price {
				font-size: 19px;
				font-weight: bold;
				line-height: 1.6;
			}

			.featued-product-uom {
				font-size: 13px;
				line-height: 1.5;
			}		
		}
	}

	.home-page-title {
		margin-bottom: 25px;
	}

	.featured-products-title {
		margin-bottom: 5px;
	}

	.message-container {
		margin: 0 15px 10px 15px;

		img {
			width: 100%;
		}
	}

	#outer-slider-wrapper {
		margin-left: 0px;
		margin-right: 0px;
		#slider-container {
			margin-top: 0;
			margin-bottom: 35px;
			margin-left: 0;
			margin-right: 0;

			#single-slider {
				/*height: 220px !important;*/
				height: auto !important;

				li {
					height: 100%;
					position: relative;
					margin-left: 0;

					img {
						/*object-fit: cover;
					    width: 100%;
					    height: 220px;*/
					    object-fit: contain;
					    height: auto;
					    max-width: 100%;
					    max-height: 100%;
					}

					.caption {
			            position: absolute;
			            left: 5%;
			            top: 100px;	            		            
			            width: 90%;	
			            height: 106px;	            
			            opacity: 0.95;		            
			            text-align: right;
						font-size: 24px;
						font-style: italic;
						line-height: 1.5;
						font-weight: 800;
			        }
				}

				.slide-wrapper {
		            display: block;
		            position: relative;
		            text-align: center;
		        }
			}

			.lSSlideOuter {
				.lSPager.lSpg {
					margin-top: 5px !important;
				}

				.lSPager.lSpg>li {
					margin-left: 0;

					a {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}

	.lightSlider li {
	    position: relative;
	}
	.lightSlider li img {
	    margin-left: auto;
	    margin-right: auto;
	}

	/** start top sellers **/
	.top-sellers {
		margin-top: 50px;
    	margin-left: 0;
    	margin-right: 0;

    	.top-sellers-button-row {
    		border-top: 1px solid #b9bbc0;
    		margin-left: 0;
    		margin-right: 0;
    	}

		.home-page-button {
			margin-top: 30px;
			margin-bottom: 40px;
		}

		div.row.row-eq-height {
			margin-right: 0;

			> div.col-xs-12 {
				width: 100%;
			}

			> div {
				display: -webkit-box;
	  			display: -webkit-flex;
	  			display: -ms-flexbox;
	  			display: flex;
	  			margin-bottom: 0;
			    padding-left: 15px;
			    padding-right: 0;
			}
		}
		.top-sellers-item {
			object-fit: contain;
			border-left-width: 0;
			border-right-width: 0;
			border-bottom-width: 0;
			padding: 20px 15px;
			width: 100%;

			.top-sellers-image {
				width: 65px;
				height: 65px;
				padding-right: 18px;
				float: left;

				a {
					display: block;
					text-align: left;
					position: relative;
		  			top: auto;
		  			transform: none;
		  			-webkit-transform: none;
		  			-moz-transform: none;
					-ms-transform: none;
 					-o-transform: none;

					img {
						max-width: 65px;
						max-height: 65px;
						overflow: hidden;
					}
				}
			}

			.top-sellers-right {
				float: left;

				a {
					font-size: 15px;
					font-weight: bold;
					line-height: 1.7;
				}
			}

			.top-sellers-price {
				font-size: 19px;
				font-weight: bold;
				line-height: 1.6;
			}

			.top-sellers-uom {
				font-size: 13px;
				line-height: 1.5;
			}
		}		
	}
	/** end top sellers **/
}