.vmi-products{
	.sub-title{
		font-size: 23px;
		font-weight: normal;
	}

	#edit-modal .modal-dialog{
		width: 360px;
	}

	#edit-modal input{
		width: 100%;
	}

	#qty-error{
		color: $color-error;
	}

	.collapsible-section .collapsible-header {

		padding: 15px 45px 15px 30px;
		position: relative;
	
	}

	.collapsible-section .collapsible-content {

		padding: 20px 30px;
	
	}

	.adminProductsTable .footable-first-column{
		white-space: nowrap;
	}

	.adminProductsTable2{
		position:relative;
		max-width:100%;
		margin:auto;
		overflow:hidden;
	}

	.table-wrap {
		width:100%;
		overflow:auto;
		padding-bottom: 0;
	}

	.adminProductsTable2{
		position: relative;

		.shadow{
			width: 12px;
			background-image: linear-gradient(to right, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
			position: absolute;
			top: 20px;
			bottom: 0;
			right: 0;
			z-index: 1;

			&.shadow-left{
				background-image: linear-gradient(to left, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
				display: none;
			}
		}

		table {
			width:100%;
			margin:auto;

			th {
				white-space: nowrap;
			}
		}

		.clone {
			position:absolute;
			top:0;
			left:0;
			pointer-events:none;

			th, td {
				visibility:hidden;
				border-color:transparent;
			}

			.fixed-side {
				visibility:visible;
			}

			td.fixed-side {
				background:#fff;
			}
		}
	}

	@media only screen and (max-width: $mobile-max-width) {

		.adminProductsTable2 .clone, .adminProductsTable2 .shadow{
			display: none !important;
		}
	}
}

.forced-tablet-menu.admin-container .admin-right-content.vmi-products{
	@media only screen and (max-width: $mobile-max-width) {

		padding-left: 0;
		padding-right: 0;

		.admin-title-back {
			padding-left: 15px;
		}
	}
}