@import "../variables";

.static-page {
	.home-full-content {
		margin-top: 40px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.home-right-content {
		margin-top: 40px;
		padding-left: 30px;
		padding-right: 15px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.static-page {
		.home-right-content {
			margin-top: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}