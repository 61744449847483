@import "../variables";

.order-confirmation {	
	margin-top: 20px;
	padding-left: 15px;
    padding-right: 15px;

    

	

	.order-confirmation-content {

		.product-headings {
		    padding: 10px 15px;
		    background-color: #f4f4f4;
		    box-shadow: inset 0 1px 0 0 #b9bbc0;
		    margin-left: 0px;
		    margin-right: 0px;

		    font-size: 13px;
		    font-weight: bold;
		    line-height: 1.5;
		}

		> div.row.line-items{
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
		}

		.line-item{
		    padding-left: 15px;
			padding-right: 15px;

			&:first-child {
				margin-top: 0;
			}

			

			.product-title{
				font-size: 16px;
				font-weight: bold;
				line-height: 1.6;
			}

			.catalog-number{
				margin-top: 10px;
				word-wrap: break-word;
			}

			.product-details{
				margin-top: 0px;
			}
		}

		.line-item-image{
			padding-right: 0;

			img{
				max-width: 100%;
			}
		}

		.print-button-container {
			display: inline-block;
		}

		> div.row {
			margin-top: 20px;
		}

		.web-order-number {
			font-size: 23px;			
			line-height: 1.3;

			.web-order-number-title {
				font-weight: bold;
			}
		}

		> div.row.items-row {
			margin-top: 60px;

			img {
				max-width: 63px;
				max-height: 63px;
				vertical-align: top;
			}

			.product-image {
				width: 63px;
				display: inline-block;
    			vertical-align: top;
    			text-align: center;
			}

			.product-detail-info {
				display: inline-block;
				margin-left: 14px;

				a {
					font-size: 16px;
					font-weight: bold;
					line-height: 1.6;
				}

				div.item-number {
					margin-top: 10px;
					word-wrap: break-word;
				}
			}

			.price-uom {
				white-space: nowrap;
			}

			table {
				thead {
					tr {
						th {
							padding-left: 15px;
							padding-right: 15px;
						}
					}
				}

				tbody {
					tr {
						td {
							padding-left: 15px;
							padding-right: 15px;
							font-size: 16px;
							line-height: 1.6;
							word-break: break-all;
						}
					}
				}
			}
		}

		> div.row.order-summary-row {
			margin-top: 30px;
		}

		.order-confirmation-info-block {
			padding: 30px 30px 35px 30px;
			border: solid 1px #b9bbc0;
			word-wrap: break-word;

			.heading {
				margin-top: 20px;
			}
		}

		.order-msg{
			font-size: 16px;
		}
	}
}

@media only screen and (min-width: $desktop-min-width ) {
	.order-confirmation .line-item:last-child {
		border-bottom: none;
	}
}


//small and up
@media only screen and (min-width: $tablet-min-width ) {
	.order-confirmation .line-item:first-child {
		border-top: none;
	}

	.order-confirmation .order-confirmation-content .print-button-container{
		margin-left: 10px;
		margin-bottom: -5px;
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.order-confirmation {
		margin-top: 10px;

		&.repair-warranty-checkout .main-section .content{
			padding-right: 0;
		}

		.order-confirmation-content {
			> div.row.items-row {
				margin-top: 40px;

				> div {
					padding-left: 0;
					padding-right: 0;
				}
			}

			div.line-items{
				margin-left: -15px;
				margin-right: -15px;
			}

			.product-headings {
			    margin-left: -15px;
				margin-right: -15px;
			}


			.order-confirmation-info {
				padding-right: 18px;

				.order-confirmation-info-block {
					padding: 20px 15px 25px 15px;
				}

				.section:first-child{
					margin-top: 0;
				}
			}

			.lower-content{
				display: flex;
			    margin-top: 20px;

				.summary{
					order: 2;
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.order-confirmation {
		margin-top: 10px;

		.order-confirmation-content {
			.print-button-container {
			    display: block;
			}

			#continue-shopping {
				width: 100%;
			}

			.order-confirmation-info-block {
				padding: 20px 15px 25px 15px;
				margin-bottom: 20px;
			}

			.items-row > div {
				padding-right: 0;
				padding-left: 0;
			}

			div.line-items{
				margin-left: -15px;
				margin-right: -15px;
				margin-top: 20px;
			}

			.mobile-verification-row {
				padding-bottom: 25px;
				padding-top: 20px;
				padding-left: 15px;
				padding-right: 15px;
				border-bottom: 1px solid #b9bbc0;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;

				.product-detail-info {
					flex: 1;
					-webkit-box-flex: 1;
			  		-moz-box-flex:  1;
			  		-webkit-flex:  1;
			  		-ms-flex:  1;
					overflow: hidden;
				}
			}
			.mobile-verification-row:first-of-type {
				border-top: 1px solid #b9bbc0;
			}
		}
	}
}