@import "variables";
@import "mixins";

html, body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 100;
    height: 100%;
}

html {
    position: relative;
    min-height: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     #pageWrapper {
     	height: 100%;
     }
}

.clearfix {
	clear: both;
}

.sr-only { 
	border: 0; 
	clip: rect(0 0 0 0); 
	height: 1px; 
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.screenreader-skip {
	position: absolute;
	left: -999px;
	width:1px;
	height: 1px;
	top: auto;

	&:focus {
		position: absolute;
		left: 0;
		top: 0;
		padding: 8px 12px;
		border: 1px solid #2370b3;
		background: white;
		color: #2370b3;
		text-align: center;
		height: auto;
		width: auto;
		margin: auto;
	}
}

.vertical-align {
  	display: flex;
  	flex-direction: row;
}

.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  	display: flex;
  	align-items: center;
}

#pageWrapper {
    min-height:100%;
    display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}
#pageWrapper.checkout {
	.main-content {
		padding-bottom: 0;
	}
}

body.searchBarOpen {
	overflow: hidden;	

	#searchBar {
		@include flexbox;
	}
}
#searchBar {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999999;

	.overlaySection {
		background-color: #000;
		opacity: 0.4;
		width: 100%;
		height: 100%;
		@include flex(1);
	}

	.searchSection {
		width: 350px;
		background-color: #fff;
		@include flexbox;
		@include flexdirection(column);

		h2 {
			margin-top: 0px;
			margin-left: 8px;
			margin-right: 8px;
			height: 34px;
		}

		.filter {
			margin-left: 8px;
			margin-right: 8px;
			margin-top: 12px;
			height: 45px;

			input[type="text"] {
				width: 100%;
			}
		}
			
		.resultsHeader {
			box-shadow: inset 0 1px 0 0 #b9bbc0;
			padding: 10px 8px;
			font-size: 13px;
		    font-weight: bold;
		    line-height: 1.5;
		    margin-top: 12px;
		    height: 39px;
		}

		.results {
			@include flex(1);
			overflow-y: scroll;
			padding-top: 5px;

			> div {
				padding: 0px 8px;

				label {
					font-weight: normal;
					display: block;
					word-break: break-word;
				}
			}
		}

		.buttons {
			height: 71px;
			width: 350px;
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 12px;
			padding-top: 12px;

			a {
				height: 20px;
			    font-size: 13px;
			    line-height: 1.5;
			    font-weight: bold;
			    text-decoration: none;
			    margin-top: 12px;
			    float: left;
			}

			button {
				float: right;
			}
		}
	}
}

#add-all-to-my-catalogs.modal {
	.add-my-catalog-create-new-catalog {
		margin-top: 0;
	}
	.add-my-catalogs-all-select-catalog {
		margin-top: 24px;
	}
	.add-all-my-catalogs-filter-row, .add-all-my-catalogs-filter-row-new {
		padding: 16px;
		border: 1px solid #b9bbc0;

		input {
			width: 100%;
		}
	}
	#catalogTreeAll, #catalogTreeAllParent {
		height: 126px;
		border-top-width: 0;
		max-width: 388px;
		overflow: auto;
		box-shadow: none;
		border-right: 1px solid #b9bbc0;
		border-bottom: 1px solid #b9bbc0;
		border-left: 1px solid #b9bbc0;
	}
	.solar-modal-input.solar-align-start {
		align-items: flex-start;
		-ms-flex-align: flex-start;
	}
	.solar-modal-input {
		display: flex; 
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		align-items: center; 
		-ms-flex-align: center;

		.hidden-label {
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.1px;
			font-weight: 100;
		}

		> label {
			display: block;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.43;
			letter-spacing: 0.1px;
			width: 150px;
		}

		> input[type="text"], .solar-modal-input-field {
			flex: 1;
			-webkit-box-flex: 1;
			  -moz-box-flex:  1;
			  -webkit-flex:  1;
			  -ms-flex:  1;
		}
	}	
}

#add-my-catalogs.modal {
	.new-my-catalogs-filter-row, .new-my-catalogs-filter-row-new {
		padding: 16px;
		position: relative;
		border: 1px solid #b9bbc0;

		input {
			width: 100%;
		}
	}

	#catalogTreeNew, #catalogTreeNewCatalog {
		height: 126px;
		border-top-width: 0;
		max-width: 388px;
		overflow: auto;
		box-shadow: none;
		border-right: 1px solid #b9bbc0;
		border-bottom: 1px solid #b9bbc0;
		border-left: 1px solid #b9bbc0;
	}

	.add-my-catalog-item-quantity {
		display: flex; 
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		align-items: center; 
		-ms-flex-align: center;

		.add-my-catalog-item-preview {
			width: 282px;
			padding: 8px 12px;
			display: flex; 
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			align-items: center; 
			-ms-flex-align: center;
			border-radius: 2px;
			border: 1px solid #b9bcc0;

			#add-my-catalog-item-image {
				max-width: 48px;

				img {
					max-width: 48px;
					max-height: 48px;
				}
			}

			.add-my-catalog-item-detail {
				font-size: 12px;
				line-height: 1.67;
				letter-spacing: 0.1px;
				font-weight: 100;
				flex: 1;
				-webkit-box-flex: 1;
				  -moz-box-flex:  1;
				  -webkit-flex:  1;
				  -ms-flex:  1;
				  padding-left: 16px;

				#add-my-catalog-product-title {
					a {
						font-size: 12px;
						font-weight: 600;
						line-height: 1.67;
						letter-spacing: 0.1px;
					}
				}
			}
		}

		.add-my-catalog-quantity {
			padding-left: 16px;

			a.small-circle-button {
				height: 32px;
				width: 32px;
				line-height: 32px;
				padding: 0;

				i {
					margin-left: 2px;
				}
			}

			a.subtract-quantity {			            		
				margin-right: 3px;
			}

			a.add-quantity {
				margin-left: 3px;
			}

			#add-my-catalogs-qty {
				width: 72px;
			}
		}
	}

	.add-my-catalog-new-catalog, .new-catalog {
		margin-top: 24px;

		#catalogTreeNewCatalog, #catalogTreeAllParent {
			height: 126px;
		}
	}

	.add-my-catalog-create-new-catalog {
		margin-top: 24px;

		label {
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.1px;
			font-weight: 100;
		}
	}

	.add-my-catalog-select-catalog, .add-my-catalogs-all-select-catalog {
		margin-top: 24px;

		#catalogTreeNew, #catalogTreeNewCatalog, #catalogTreeAll, #catalogTreeAllParent {
			height: 126px;
		}
	}

	.solar-modal-input.solar-align-start {
		align-items: flex-start;
		-ms-flex-align: flex-start;
	}
	.solar-modal-input {
		display: flex; 
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		align-items: center; 
		-ms-flex-align: center;

		.hidden-label {
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.1px;
			font-weight: 100;
		}

		> label {
			display: block;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.43;
			letter-spacing: 0.1px;
			width: 150px;
		}

		> input[type="text"], .solar-modal-input-field {
			flex: 1;
			-webkit-box-flex: 1;
			  -moz-box-flex:  1;
			  -webkit-flex:  1;
			  -ms-flex:  1;
		}
	}	
}

.accountselectpicker {
	display: none;
}

.web-order-approval {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.access-denied-block {
    margin:50px auto;
    width:355px;

    .fa-lock {
        font-size:10rem;
        float:left;
    }

    .fa-ban {
        font-size:8rem;
        float:left;
    }

    .fa-shopping-cart {
        font-size:8rem;
        float:left;
    }

    .fa-eye-slash {
        font-size:8rem;
        float:left;
    }

    .fa-exclamation-circle {
        font-size:10rem;
        float:left;
    }

    .access-denied-title {
        font-size:4rem;
        float:left;
        padding-left:1rem;
        line-height: 4.5rem;
    }

    .access-denied-text {
        clear:both;
    }
}

.cart-denied-block {
    margin:50px auto;
    width:355px;

    .fa-lock {
        font-size:10rem;
        float:left;
    }

    .fa-ban {
        font-size:8rem;
        float:left;
    }

    .fa-shopping-cart {
        font-size:8rem;
        float:left;
    }

    .fa-eye-slash {
        font-size:8rem;
        float:left;
    }

    .fa-exclamation-circle {
        font-size:10rem;
        float:left;
    }

    .cart-denied-title {
        font-size:4rem;
		float:left;
		padding-top:1rem;
        padding-left:1rem;
        line-height: 4.5rem;
    }

    .cart-denied-text {
		clear:both;
		text-align: center;
    }
}

.main-content {
	min-height: 800px;
	position: relative;
    -ms-flex: 1 0 auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-bottom: 30px;
}

.item-cell {
	white-space: nowrap;
}

.print-show {
    display: none;
}

.admin-title-back {
	> div.col-sm-4, > div.col-sm-5, > div.col-sm-3 {
		a {
			font-weight: bold;
		    margin-top: 12px;
		    display: inline-block;
		}
	}
}

.forgot-password-field {
	position: relative;
}

.range-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	input[type="text"], div.bootstrap-select {
		flex: 1;
		-webkit-box-flex: 1;
  		-moz-box-flex:  1;
  		-webkit-flex:  1;
  		-ms-flex:  1;
	}
	input[type="text"] {
		overflow: hidden;
	}
	.filter-to {
		width: 31px;
    	text-align: center;
    	padding-top: 12px;
	}
}

.footable.breakpoint>tbody>tr>td>span.footable-toggle {
	font-family: 'FontAwesome';
	font-size: 20px;
	line-height: 1;
}
.footable.breakpoint>tbody>tr>td>span.footable-toggle:before {
	content: "\f107";
}
.footable.breakpoint>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
	content: "\f106";
}
.footable-row-detail-name {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	line-height: 1.5;
	font-weight: 100;
	padding-left: 20px;
	white-space: nowrap;
}
div.admin-right-content table.admin-table tbody tr td.footable-row-detail-cell {
	padding-top: 0;
}
.footable-row-detail-value {
	font-family: 'Open Sans', sans-serif;
}
table:not(.table-condensed):not(.radio-options) tbody tr.footable-detail-show {
	border-bottom: none;
}

/** tabs **/
.nav-tabs {
    border-bottom: 1px solid #b9bbc0;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border: 1px solid #b9bbc0;
	border-bottom-color: transparent;
}
.nav-tabs > li {
	margin-left: 0;
}
.nav-tabs > li > a {
	border-radius: 3px 3px 0 0;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
}
/** end tabs **/

.large-column {
	min-width: 300px;
}
.flex {
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

.flex-grow {

	-ms-flex: 1 0 auto;
	-webkit-flex-grow: 1;
	flex-grow: 1;


    display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.overlay-container {
	position: relative;
	-ms-flex: 1 0 auto;
	-webkit-flex-grow: 1;
	flex-grow: 1;
    display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

#main-content-overlay {
    background-color: rgba(178,178,178,0.7);
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 10;

    .loading-message {
        text-align: center;
        position: fixed;
        width: 100%;
        top: 200px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 200px;
        font-weight: 600;
    }
}

.error-row {
	margin-top: 20px;

	.top-error {
		font-size: 13px;
		line-height: 1.5;
		color: $color-error;
		border-radius: 5px;
		border: solid 1px $color-error;

		i {
			font-size: 15px;
		}
	}
}

.success-row {
	margin-top: 20px;

	.top-success {
		font-size: 13px;
		line-height: 1.5;
		color: #5a9723;
		border-radius: 5px;
		border: solid 1px #5a9723;

		i {
			font-size: 15px;
		}
	}
}

.warn-row {
	margin-top: 20px;

	.top-warn {
		font-size: 13px;
		line-height: 1.5;
		color: #bf9000;
		border-radius: 5px;
		border: solid 1px #bf9000;

		i {
			font-size: 15px;
		}
	}
}

.pagination-wrapper {
	> div {
		text-align: center;

		ul.pagination-select {
			list-style-type: none;
			padding-left: 0;

			li {
				margin-left: 0;
			    display: inline-block;
			    vertical-align: top;

			    a {
			    	display: block;
			    }
			}

			li.page-selector {
				div.hidden-select {
					cursor: pointer;
				    position: relative;
				    display: inline-block;
				    padding: 5px 20px 0px 20px;

				    select {
				    	background: transparent;
					    border: 0;
					    border-radius: 0;
					    -webkit-appearance: none;
					    width: 100%;
					    height: 40px;
					    top: 0;
					    left: 10px;
					    background-color: white;
					    margin-top: 0;
					    position: absolute;
					    z-index: 2;
					    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
					    filter: alpha(opacity=0);
					    -khtml-opacity: 0;
					    -moz-opacity: 0;
					    opacity: 0;
					    cursor: pointer;
					    padding-right: 0;
				    }

				    div {
				    	width: auto;
				    }
				}
			}
		}
	}
}

.jstree-wholerow-clicked + i.jstree-icon {
	color: #fff;
}

.price-uom {
	text-transform: lowercase;
}

.wrappable{
	word-break: break-all;
	overflow-wrap: break-word;
}

.cat-number-width{
	max-width: 150px;
}

.admin-container {
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;

	.admin-left-content {
		width: 300px;
	}

	.admin-right-content {
		flex: 1;
		-webkit-box-flex: 1;
  		-moz-box-flex:  1;
  		-webkit-flex:  1;
  		-ms-flex:  1;
		overflow: hidden;
		padding-left: 30px;
    	padding-right: 30px;
    	margin-top: 40px;
	}
}

.admin-pagination-select {
    cursor: pointer;
    position: relative;
    display: inline-block;
	padding: 8px 20px 0px 20px;

    select {
        background: transparent;
        border: 0;
        border-radius: 0;
        -webkit-appearance: none;
        width: 100%;
        height: 40px;
        top: 0;
        left: 10px;
        background-color: white;
        margin-top: 0;
        position: absolute;
        z-index: 2;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -khtml-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        cursor: pointer;
        padding-right: 0;
    }
}

.label-select {
	vertical-align: middle;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: 3px;
	border: solid 1px #b9bbc0;
	height: 40px;
	padding: 9px 26px 11px 12px;
	text-align: left;

    select {
        background: transparent;
        border: 0;
        border-radius: 0;
        -webkit-appearance: none;
        width: 100%;
        height: 40px;
        top: 0;
        left: 10px;
        background-color: white;
        margin-top: 0;
        position: absolute;
        z-index: 2;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -khtml-opacity: 0;
        -moz-opacity: 0;
        opacity: 0;
        cursor: pointer;
        padding-right: 0;
    }

    .label-select-output {
        z-index: -1;
        /*height: 40px;*/
        overflow: hidden;
        /*padding: 1.2rem 1.7rem;*/
        cursor: pointer;
        /*width: 140px;*/
        white-space: nowrap;
        font-size: 15px;
		line-height: 1.3;

        .select-label-output-value {
			font-weight: bold;
        }
    }
}

.label-select::after {
	font-family: 'FontAwesome';
    position: absolute;
    right: 10px;
    top: 8px;
    content: "\f107";
    width: 8px;
	height: 5px;
}

#billtrustLeftContainer {
	/*background-image: linear-gradient(to bottom, #ffffff, #eef0f2);
	border: solid 2px #2370B3;*/

	position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 0;

	a {
		display: block;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.39;
		text-align: center;

		img {
			max-width: 100%;
		}

		.pay-now {
			position: absolute;
			bottom: 24px;
			left: 0;
			right: 0;
			text-align: center;
			font-weight: 100;
			margin-left: 24px;
			margin-right: 24px;
			width: auto;
		}

		.pay-text {
			position: absolute;
			left: 0;
			width: 100%;
			text-align: center;
			color: #fff;
			top: 24px;
			font-size: 16px;
			line-height: 1.56;
			font-weight: 100;
			padding-left: 24px;
			padding-right: 24px;
			margin-left: 0;
			margin-right: 0;
			
		}
	}

	a:hover, a:focus, a:active, a:visited {
		text-decoration: none;
	}
}

#requestQuoteLeftContainer,
#vmiLeftContainer {
	position: relative;
	margin-top: 10px;
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	a {
		display: block;
		height: 300px;

		div.uploadText {
			position: absolute;
			left: 0;
		    width: 100%;
		    text-align: center;
		    color: #fff;
		    top: 24px;
		    font-size: 16px;
			line-height: 1.56;
			font-weight: 100;
		    padding-left: 24px;
		    padding-right: 24px;
		}

		div.buildAQuoteButton {
			position: absolute;
		    bottom: 23px;
		    left: 0;
		    right: 0;
		    text-align: center;
		    font-weight: 100;
		    margin-left: 24px;
		    margin-right: 24px;
		}
	}
}

#selectorLeftContainer {
	position: relative;
	margin-top: 10px;
	height: 272px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	a {
		display: block;
		height: 272px;
	}
}

#customProductImageLeftContainer {
	margin-top: 40px;
	position: relative;

	a {
		height: 300px;
    	display: block;

    	img.uploadBackground {
			width: 100%;
			object-fit: cover;
	    	height: 300px;
		}

		img.uploadPhone {
			position: absolute;
		    top: 89px;
		    left: 0;
		    right: 0;
		    margin: auto;
		    width: 100%;
		    object-fit: contain;
		    height: 211px;
		}

		div.uploadText {
			position: absolute;
			left: 0;
		    width: 100%;
		    text-align: center;
		    color: #fff;
		    top: 24px;
		    font-size: 16px;
			line-height: 1.56;
			font-weight: 100;
		    padding-left: 24px;
		    padding-right: 24px;
		}

		div.uploadHereButton {
			position: absolute;
		    bottom: 23px;
		    left: 0;
		    right: 0;
		    text-align: center;
		    font-weight: 100;
		    margin-left: 24px;
		    margin-right: 24px;
		}
	}	
}
#my-links-left-container ~ #customProductImageLeftContainer {
	margin-top: 9px;
}
.customProductImageMainContainer, .buildQuoteRequestMainContainer {
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	a {
		height: 180px;
    	display: block;

    	img.uploadBackground {
			width: 100%;
			object-fit: cover;
	    	height: 180px;
		}

		img.uploadPhone {
			position: absolute;
			top: 24px;
		    right: 114px;
		    margin: auto;
		    width: 159px;
		    height: 156px;
		}

		div.uploadText {
			position: absolute;
		    left: 0;
		    right: 0;
		    top: 40px;
		    height: 50px;
		    font-size: 19px;
		    line-height: 1.32;
		    font-weight: 100;
		    margin-right: 292px;
		    margin-left: 115px;
		    color: #ffffff;
		}

		div.uploadHereButton {
			position: absolute;
		    bottom: 35px;
		    left: 115px;
		    width: 127px;
		    text-align: center;
		    font-weight: 100;
		}

		div.buildAQuoteButton {
			position: absolute;
		    bottom: 35px;
		    left: 115px;
		    width: 150px;
		    text-align: center;
		    font-weight: 100;
		}
	}
}

#my-links-left-container {
	width: 300px;
	margin-top: 40px;

	#my-links-title {
		button {
			height: 55px;
			width: 100%;
			border: 0;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			line-height: 1.6;
			background-color: rgba(0, 0, 0, 0.2);
			padding-left: 30px;
			padding-top: 14px;
			display: none;
			text-decoration: none;
			cursor: default;

			i {
				display: none;
				margin-right: 10px;
			}
		}
		button:hover {
			text-decoration: none;
		}
		h2 {
			height: 55px;
			width: 100%;
			border: 0;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			line-height: 1.6;
			background-color: rgba(0, 0, 0, 0.2);
			padding-left: 30px;
			padding-top: 14px;
			display: block;
			text-decoration: none;
			cursor: default;

			i {
				display: none;
				margin-right: 10px;
			}
		}
	}

	#left-nav-my-links {
		ul {
			margin: 0;
			padding: 25px 0;
			list-style-type: none;

			li {
			 	margin: 0;
				padding: 0;

			 	a {
			 		font-size: 16px;
					line-height: 1.6;
					color: #ffffff;
					text-decoration: none;
					display: block;
					padding: 5px 0 5px 35px;
			 	}

			 	a:hover, a.current {
			 		background-color: rgba(0, 0, 0, 0.2);
			 	}
			}
		}
	}
}

/** start checkout **/
.checkout-container {
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	flex-grow: 1;
	-ms-flex: 1 0 auto;
	padding-top: 20px;

	.checkout-content {
		flex: 1;
		-webkit-box-flex: 1;
  		-moz-box-flex:  1;
  		-webkit-flex:  1;
  		-ms-flex:  1;
  		padding-right: 40px;
  		padding-bottom: 0px;
	}

	.payment-order-summary {
    	flex-basis: 300px;
		padding: 24px 24px 0px 24px;
		border: 1px solid #B9BBC0;
		align-self: flex-start;


		table{
			
			width: 100%;
		}

		table:not(.table-condensed):not(.radio-options) tbody tr:last-child {
			border-bottom: none;
		}

		table td{
			text-align: right;
		}

		.item-details{
			margin-left: -24px;
			margin-right: -24px;
		}

	}
}

.title-row {
	.no-left {
		padding-left: 0;
	}
}

.total-title {
	margin-bottom: 20px;
}

.total-row {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #b9bbc0;
	margin-left: 0;
	margin-right: 0;

	> div {
		padding-left: 0;
		padding-right: 0;
	}
}
/** end checkout **/

/** tooltips **/
.tooltip.in {
	opacity: 1;
}

.tooltip.top .tooltip-arrow {
	border-width: 8px 8px 0;
}
.tooltip.top .tooltip-arrow::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	top: -9px;
	left: -8px;
	border-width: 8px 8px 0;
}
.tooltip.top, .tooltip.bottom {
	padding: 8px 0;
}

.tooltip.right .tooltip-arrow {
	border-width: 8px 8px 8px 0;
}
.tooltip.right .tooltip-arrow::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	top: -8px;
	left: 1px;
	border-width: 8px 8px 8px 0;
}
.tooltip.right, .tooltip.left {
	padding: 0 8px;
}

.tooltip.bottom .tooltip-arrow {
    border-width: 0 8px 8px;
}
.tooltip.bottom .tooltip-arrow::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	top: 1px;
    left: -8px;
    border-width: 0 8px 8px;
}

.tooltip.left .tooltip-arrow {
    border-width: 8px 0 8px 8px;
}
.tooltip.left .tooltip-arrow::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	top: -8px;
    left: -9px;
    border-width: 8px 0 8px 8px;
}

.tooltip {
	.tooltip-inner {
		border-radius: 0;
		padding: 20px 15px;
    	max-width: none;
    	text-align: left;
	}
}
/** end tooltips **/

/** cart progress indicator **/
.cart-progress {
	position: relative;
	display: inline-block;
	overflow: hidden;

	hr.back {
		position: absolute;
	    height: 2px;
	    background-color: #b9bbc0;
	    margin: 0 !important;
	    width: 80%;
	    top: 6px;
	    left: 19px;
	}

	> span {
		margin-left: 10px;
		margin-right: 10px;
		position: relative;
		display: inline-block;

		> span.dot {
			width: 12px;
			height: 12px;
			background-color: #b9bbc0;
			box-shadow: 0 0 0 2px #ffffff;
			border-radius: 6px;
			display: block;
			margin-bottom: 2px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	> span.current {
		> span.dot {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			margin-bottom: 0;
		}
	}
}
.cart-progress.on-account {
	hr.back {
		width: 78%;
	}
}
/** end cart progress indicator **/

input::-ms-clear {
    display: none;
}

label {
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
	display: block;
}

input[type="checkbox"] + label {
	display: inline-block;
	margin: 0 0 0 10px;
}

.error-text{
	color: $color-error;
	font-size: 13px;
	line-height: 1.5;
}

div.error-message {
	color: $color-error;
	font-size: 13px;
	line-height: 1.5;
	position: absolute;
	bottom: -20px;
}

div.error-message.hidden {
	display: none;
}

div.error-message:not(.hidden) + label {
	color: $color-error;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
}

div.error-message:not(.hidden) ~ input {
	border: solid 1px $color-error;
}


.hide-error{
	display: none;

	.hide-error{
		display: inline;
		color: $color-error;
		font-size: 13px;
		line-height: 1.5;
	}

	label {
		color: $color-error;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.6;
	}

	input{
		border: solid 1px $color-error;
	}
}

.in-stock {
	font-size: 13px;
	line-height: 1.5;
	color: #5a9723;
}

.out-of-stock {
	font-size: 13px;
	line-height: 1.5;
	color: $color-error;
}

.non-stock {
	font-size: 13px;
	line-height: 1.5;
}

ul.category-breadcrumbs {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		margin: 0;
		padding: 0 10px 0 0;
		display: inline-block;

		a {
			font-size: 12px;
			line-height: 1.7;
		}
	}

	li:last-of-type:after {
		content: " ";
	}

	li:after {
		font-family: 'FontAwesome';
		position: relative;
		right: -5px;
		top: -1px;
		content: "\f105";
		color: #b9bbc0;
		font-size: 0.9em;
	}
}

ul.greentech-breadcrumbs {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		margin: 0;
		padding: 0 10px 0 0;
		display: inline-block;

		font-size: 12px;
		font-weight: normal;
		letter-spacing: 0.11px;
		line-height: 20px;
	}

	li:last-of-type:after {
		content: " ";
	}

	li:after {
		font-family: 'FontAwesome';
		position: relative;
		right: -5px;
		top: -1px;
		content: "\f105";
		color: #b9bbc0;
		font-size: 0.9em;
	}
}

.accordion-container {
	.accordion-title {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.6;
		box-shadow: inset 0 1px 0 0 #b9bbc0;
		position: relative;

		a {
			display: block;
		    padding: 15px;
		    cursor: pointer;
		    position: relative;
		}

		a::after {
			font-family: 'FontAwesome';
	        position: absolute;
	        right: 15px;
	        top: 10px;
	        content: "\f106";
	        font-size: 2rem;
		}

		a:hover, a:focus, a:active {
			text-decoration: none;
		}
	}

	.accordion-content {
		display: block;
		padding-top: 10px;
		padding-bottom: 10px;

		.admin-accordion-row {
			padding-top: 10px;
			padding-bottom: 10px;
			margin-left: 0;
			margin-right: 0;

			> div {
				padding-left: 0;
			}
		}
	}
}

.accordion-container.closed {
	.accordion-title {
		a::after {
			content: "\f107";
		}
	}

	.accordion-content {
		display: none;
	}
}

.list-style-reset {
    ul {
        margin: 1em;
        margin-left: 40px;
        li {
            list-style-type: disc;
            margin-left: 0;
        }
    }
    ol {
        margin: 1em;
        margin-left: 40px;
        li {
            list-style-type: decimal;
            margin-left: 0;
        }
    }
}

.collapsible-section {
	border: solid 1px #b9bbc0;

	.collapsible-header {
		padding: 15px 45px 15px 30px;
		position: relative;
	}

	.collapsible-header::after {
		font-family: 'FontAwesome';
	    position: absolute;
	    right: 30px;
	    top: 16px;
	    content: "\f106";
	    font-size: 18px;
	}

	.collapsible-content {
		padding: 20px 30px;
	}
}

.collapsible-section.closed {
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;

	.collapsible-header::after {
		content: "\f107";
	}

	.collapsible-content {
		display: none;
	}
}

.small-text {
	font-size: 13px;
	line-height: 1.5;
}

.spacer-medium {
	margin-top: 10px;
}

.spacer-large {
	margin-top: 20px;
}

.spacer-extra-large {
	margin-top: 30px;
}

.row-eq-height {
  	display: -webkit-box;
  	display: -webkit-flex;
  	display: -ms-flexbox;
  	display: flex;
  	-webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.slider-height-fix{
	height: auto !important;
}

.required::after {
	content: '*';
	color: $color-error;
	font-size: 15px;
	font-weight: bold;
	line-height: 1.7;
}

/** form fields **/
input[type="text"], input[type="password"], input[type="tel"], input[type="number"] {
	height: 45px;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #b9bbc0;
	font-size: 15px;
	line-height: 1.7;
	padding-left: 12px;
}

textarea {
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #b9bbc0;
	font-size: 15px;
	line-height: 1.7;
	padding-left: 12px;
}

.radio-options {
	input[type="radio"] {
		position: relative;
		top: -1px;
	}

	input[type="radio"] + label {
		display: inline-block;
		padding-left: 5px;
		padding-right: 10px;
		font-size: 15px;
		line-height: 1.7;
		font-weight: 100;
	}
}

.dateOnlyInput input {
	width: auto;
}
.dateOnlyInput input.col-xs-12 {
	width: 100%;
}

input[type="checkbox"], input[type="radio"] {
    line-height: normal;
    margin: 6px 0 0;
}

.toggle-button-box{
	font-size: 0;

	.toggle-button{
		border: solid 1px #b9bbc0;
		background: #fff;
		font-size: 15px;
		font-weight: bold;
		line-height: 1;
		color: #212121;
		padding: 11px 14px;
		margin-top: 0px;
		display: inline-block;

		&.toggle-button-left{
			border-radius: 3px 0 0 3px;
		}

		&.toggle-button-right{
			border-radius: 0 3px 3px 0;
			margin-right: 40px;
		}
	}
}

.enhanced-select[size]{
	appearance: none;
	height: 45px;

}

.enhanced-select::-ms-expand {
	display: none;
}

.enhanced-select-container{
	position: relative;
}

.enhanced-select-container:after {
	content: "";
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid black;
	position: absolute;
	bottom: 17px;
	right: 23px;
}

/** bootstrap select styles **/
.bootstrap-select {
	margin: 0;
	padding: 0;

	button {
		background: none;
		border-bottom-color: #b9bbc0;
	}
}

.bootstrap-select.col-xs-12 {
	width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu li {
	margin-left: 0;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	line-height: 1.7;
	font-weight: 100;
    padding-left: 12px;
}

.select-button {
	height: 45px;
	border-radius: 3px;
	background: #ffffff;
	box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #b9bbc0;
	font-size: 15px;
	line-height: 1.7;
	font-family: 'Open Sans', sans-serif;
}

/** end bootstrap select styles **/

/** start modal styles **/
.modal {
	.modal-dialog {
		.modal-content {
			padding: 5px 15px 15px 15px;
			box-shadow: none;

			.modal-header, .modal-footer {
				border: 0;

				.close, .close:focus, .close:hover {
					opacity: 1;
				}

				a.cancel {
					height: 20px;
					font-size: 13px;
					line-height: 1.5;
					font-weight: bold;
					text-decoration: none;
					margin-top: 12px;
				}
			}
		}
	}
}

/** end modal styles **/

div.admin-right-content {
	div#start-discussion-area.spacer-large {
		margin-top: 0;
	}

	.filters {
		.buttons {
			button {
				width: 100%;
			}

			input[type="text"] {
				visibility: hidden;
			}
		}
	}

	table.admin-table {
		margin-top: 20px;
		width: 100%;

		thead {
			tr {
				th {
					padding-left: 15px;
				}

				th:last-of-type {
					padding-right: 15px;
				}
			}
		}
		tbody {
			tr {
				td {
					padding-left: 15px;
					padding-top: 10px;
					padding-bottom: 10px;

					a {
						font-weight: 100;
					}
				}

				td:last-of-type {
					padding-right: 15px;
				}
			}
		}
	}
}

table:not(.table-condensed):not(.radio-options) {
	thead {
		tr {
			box-shadow: inset 0 1px 0 0 #b9bbc0;

			th {
				font-size: 13px;
				font-weight: bold;
				line-height: 1.5;
				padding: 10px 2px;

				a, a:hover, a:focus, a:active {
					color: #4a4a4a;
					text-decoration: none;
				}
			}

			th.sorting_desc::after {
				font-family: 'FontAwesome';
			    content: '\f0d7';
			    margin-left: 5px;
			}

			th.sorting_asc::after {
				font-family: 'FontAwesome';
			    content: "\f0d8";
			    margin-left: 5px;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid #b9bbc0;

			td {
				padding: 15px 2px;
				font-size: 15px;
				line-height: 1.7;
			}
		}
	}
}

/** start autocomplete styles **/
.autocomplete-suggestions {
	position: absolute;
    width: 720px !important;
    max-height: 365px;
    z-index: 9999;
    margin-left: calc((100% - 720px)/2);
	margin-top: 0;
	padding: 8px;
	background-color: #fff;
	overflow: auto;
	display: table;
	border-radius: 6px;
	border: 1px solid rgb(213, 216, 217);
	margin-top: 10px;
	box-shadow: 0px 15px 25px 0px rgba(31, 41, 51, 0.15), 0px 5px 10px 0px rgba(31, 41, 51, 0.15);

	&:after,
	&:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 9px;
		left: 50%;
		margin-left: -9px;
		top: -17px;
	}

	&:before {
		border-color: rgba(113, 158, 206, 0);
		border-bottom-color: rgb(213, 216, 217);
		border-width: 10px;
		left: 50%;
		margin-left: -10px;
		top: -19px;
	}

	.autocomplete-suggestion {
		padding-top: 8px;
	    cursor: pointer;
	    font-size: 14px;
		font-weight: normal;
		letter-spacing: 0.13px;
		line-height: 20px;
	}

	.suggest-img{
		width: 120px;
		height: 112px;
		background-color: white;
		display: table-cell;
		vertical-align: middle;
		border-width: 1px;
		border-style: solid;
		text-align: center;
	}

	strong{
		display: block;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0.15px;
		line-height: 24px;
	}

	.mobile-product-container{

		&>div{
			display:flex;
			align-items: center;
			border-bottom:1px solid #d5d8d9;
			padding: 6px 12px;
			position: relative;
		}

		&>div:last-child{
			border-bottom:none;
		}

		&>div:after {
			font-family: 'FontAwesome';
			position: absolute;
			right: 9px;
			top: -2px;
			content: "\f105";
			color: #b9bbc0;
			font-size: 30px;
		}

		.suggest-img{
			width: 32px;
			height: 32px;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			border: none;
			margin-right: 8px;
		}
	}
}

.autocomplete-section-cat{
    display: table-cell;
    width: 40%;
	position: relative;
	padding: 16px 8px 32px 16px;

	.cat-header{
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
	}
}

.autocomplete-section-mfr{
	margin-top: 24px;
}

.autocomplete-section-cat > .autocomplete-suggestion,
.autocomplete-section-mfr > .autocomplete-suggestion {
    display: block;
}

.autocomplete-right-section-container{
    display: table-cell;
	width: 60%;
	border-radius: 3px;
	border: 1px solid rgb(213, 216, 217);
	padding: 16px;
}

.autocomplete-section-prod > .autocomplete-suggestion{
    display: inline-block;
    width: 33%;
    text-align: center;
}

.autocomplete-section-prod{

	.product-container{
		display:flex;

		&>div{
			flex:1;
		}
	}

}

.autocomplete-section-prod > .autocomplete-suggestion > span{
    display: block;
}

.autocomplete-section-view-more{
	position: absolute;
	bottom: 8px;
	cursor: pointer;
}

.autocomplete-suggestions .autocomplete-suggestion {
    border-bottom: none;

    span {
    	word-break: break-word;
    }

    img{
		max-width: 100%;
		max-height: 100%;
	}
}

/** end autocomplete styles **/

/** start heading styles **/
h1 {
	height: 40px;
	font-size: 36px;
	line-height: 1.1;
	display: inline;
}

h2 {
	height: 35px;
	font-size: 29px;
	line-height: 1.2;
	display: inline;
}

h3 {
	height: 30px;
	font-size: 23px;
	line-height: 1.3;
	display: inline;
}

h4 {
	height: 25px;
	font-size: 19px;
	line-height: 1.3;
	display: inline;
}

h5 {
	height: 25px;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.4;
	display: inline;
}

h6 {
	height: 25px;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
	display: inline;
}
/** end heading styles **/



/** start help **/
#getting-started, #getting-started-mobile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 1001;
    line-height: 1.8;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);

    transition-property: all;
    transition-duration: 1s;
}

#getting-started.hide, #getting-started-mobile.hide {
    bottom: -45px;
    display: inline !important;
}

#agreement-box{
	.accept-field{
		input {
			width: auto;
		}

		label{
			font-weight: normal;
		}
	}
}

#tourOptions {
	margin: 0;
	list-style-type: none;
	padding: 0;

	li {
	    padding: 10px 0;
	    margin: 0;

	    a {
	        text-decoration:none;
	        display:block;
	    }
	}
}

.popover.tour {
	padding: 20px 15px;
	border-radius: 0;
	font-family: 'Open Sans', sans-serif;
	border: none;
	min-width: 276px;

	.popover-title {
		padding: 0;
		margin: 0;
		background-color: transparent;
		border-bottom: none;
	}

	.popover-content {
		padding: 10px 0;
		font-size: 15px;
		line-height: 1.7;
		font-family: 'Open Sans', sans-serif;
	}

	.popover-navigation {
		font-family: 'Open Sans', sans-serif;
		padding: 10px 0;
		text-align: right;

		a.close-link {
			float: left;
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
			text-decoration: none;
			margin-top: 9px;
    		display: inline-block;
		}

		a.close-link:hover {
			text-decoration: none;
		}

		.button-small {
			background-color: transparent;
			float: none;
			margin-left: 10px;
		}

		.button-small[data-role="next"]:not([disabled]) + .button-small[data-role="end"] {
			display: none;
		}

		button[disabled] {
			display: none;
		}
	}
}

.tour-backdrop {
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.tour-step-backdrop.light-text {
	background: rgba(0,0,0,0.3);
}
/** end help **/

.hide-tablet-mobile {
	display: block;
}

#start-discussion-area{
	background-color: #f4f4f4;
	border: solid 1px #b9bbc0;
	padding: 20px 15px;
}

.discussion-area-order {
	background-color: #f4f4f4;
	border: solid 1px #b9bbc0;
	padding: 15px;
	margin: 0;

	.text {
		margin-top: 5px;
		padding: 0;
	}

	.button {
		text-align: right;
		padding: 0;

		.button-emphasis {
			padding: 5px 10px;
		}
	}
}

.line-item{
	.lotQuoteItemsTable {
		margin-left: -15px;
		margin-right: -15px;

		table {
			width: 100%;

			th, td {
				padding: 10px 15px;
				font-weight: 100;
			}

			th.qty, td.qty, th.lot, td.lot {
				text-align: center;
			}

			th.price, td.price {
				text-align: right;
			}

			tr:last-child {
				border-bottom: 0;
			}
		}
	}
}

.line-item.lotQuoteOpen {
	padding-bottom: 0px;
}

/** Feedback button **/

.feedback-button {
	height: 40px;
	padding: 8px 20px 4px;
	line-height: 32px;
	-webkit-transform: rotate(-90deg);
	font-weight: 600;
	color: white;
	transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	text-align: center;
	font-size: 17px;
	position: fixed;
	right: -40px;
	top: 70%;
	z-index: 999;
}

#feedback-comments {
	width: 100%;
	height: 175px;
}

.feedback-body {
	min-height: 300px;

	.feedback-helpful {
		padding-top: 12px;
	}
}

#mainFeedback {
	display: block;
}

#feedbackSuccess,
#feedbackError {
	display: none;
}

/** md size (tablet) **/
@media only screen and (min-width: $desktop-min-width) {
	.spacer-medium-md {
		margin-top: 10px;
	}
}

@mixin medium-dash() {
	.home-left-content.open, .admin-left-content.open {
		transition: width 0.3s;

		.greentech-my-links-left-container {
			width: 192px;
			transition: width 0.3s;
			overflow: hidden;
		}

		#my-links-left-container {
			width: 260px;
			transition: width 0.3s;
			overflow: hidden;

			#my-links-title {
				button {
					padding-left: 20px;
					white-space: nowrap;
					display: block;
					text-align: left;
					cursor: pointer;

					.my-links-title-text {
						display: inline;
					}

					i#my-links-left {
						display: inline;
					}

					i#my-links-right {
						display: none;
					}
				}
				
				h2 {
					display: none;
					padding-left: 20px;
					white-space: nowrap;
					cursor: pointer;

					.my-links-title-text {
						display: inline;
					}

					i#my-links-left {
						display: inline;
					}

					i#my-links-right {
						display: none;
					}
				}

			}

			#left-nav-my-links {
				ul {
					padding: 20px 0;

					li {

					 	a {
					 		font-size: 15px;
							line-height: 1.7;
							padding: 5px 0 5px 17px;
							white-space: nowrap;

							.my-links-left-text {
								display: inline;
							}
					 	}
					}
				}
			}
		}
	}

	.home-left-content.open {
		transition: width 0.3s;

		.greentech-my-links-left-container.menu-item {
			width: 192px;
			transition: width 0.3s;
			overflow: hidden;
		}
	}

	.home-left-content, .admin-left-content {
		transition: width 0.3s;
		z-index: 100;

		#my-links-left-container {
			width: 63px;
			transition: width 0.3s;

			#my-links-title {
				button {
					padding-left: 20px;
					white-space: nowrap;
					display: block;
					text-align: left;

					.my-links-title-text {
						display: none;
					}

					i#my-links-left {
						display: none;
					}

					i#my-links-right {
						display: inline;
						margin: 0;
    					padding-left: 9px;
					}
				}
				h2 {
					display: none;
					padding-left: 20px;
					white-space: nowrap;

					.my-links-title-text {
						display: none;
					}

					i#my-links-left {
						display: none;
					}

					i#my-links-right {
						display: inline;
						margin: 0;
    					padding-left: 9px;
					}
				}
			}

			#left-nav-my-links {
				ul {
					padding: 20px 0;

					li {

					 	a {
					 		font-size: 15px;
							line-height: 1.7;
							padding: 5px 0 5px 22px;
							white-space: nowrap;

							.my-links-left-text {
								display: none;
							}
					 	}
					}
				}
			}
		}
	}

	.admin-container {

		.admin-left-content {
			width: 63px;
		}

		.admin-right-content {
			padding-left: 15px;
	    	padding-right: 15px;
	    	margin-top: 30px;
		}
	}

	.collapsible-section {

		.collapsible-header {
			padding: 15px 45px 15px 15px;
		}

		.collapsible-header::after {
		    right: 15px;
		}

		.collapsible-content {
			padding: 15px;
		}
	}
}

.forced-tablet-menu{
	@include medium-dash;
	&.admin-container {

		.admin-left-content {
			width: 63px;
		}

		.admin-right-content {
			padding-left: 15px;
	    	padding-right: 15px;
	    	margin-top: 30px;
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {

	.autocomplete-suggestions {
	    width: 95% !important;
		margin-top: 0;
		margin-left: 2.5%;
	}

	label {
		font-size: 15px;
		line-height: 1.7;
	}

	.hide-tablet-mobile {
		display: none !important;
	}

	h1 {
		font-size: 32px;
		line-height: 1.2;
	}

	h2 {
		font-size: 27px;
		line-height: 1.3;
	}

	h3 {
		font-size: 22px;
		line-height: 1.4;
	}

	.total-title {
		margin-top: 10px;
		margin-right: 15px;
	}

	.total-row {
		margin-right: 0;
		margin-left: -15px;
	}

	.total-row, .total-title {
		> div {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.text-right-sm {
		text-align: right;
	}

	#billtrustLeftContainer {
		padding-bottom: 10px;
		
		a {			
			.pay-now {
				position: absolute;
				bottom: 35px;
				left: 115px;
				width: 127px;
				text-align: center;
				font-weight: 100;
				margin: 0;
			}

			.pay-text {
				position: absolute;
				left: 0;
				right: 0;
				top: 40px;
				height: 50px;
				font-size: 19px;
				line-height: 1.32;
				font-weight: 100;
				margin-right: 292px;
				margin-left: 115px;
				color: #ffffff;
				text-align: left;
				padding: 0;
			}
		}
	}

	@include medium-dash;
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.discussion-area-order {
		.button {
			margin-top: 10px;
			text-align: left;
		}
	}

	body.noscroll {
		overflow: hidden;
	}

	#searchBar {
		.searchSection {
			width: 100%;

			.buttons {
				width: 100%;				
			}
		}
	}

	#add-all-to-my-catalogs.modal {
		.solar-modal-input {
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;

			> label {
				width: 100%;
			}

			.solar-modal-input-field {
				width: 100%;
				display: block;
				-ms-flex: 1 0 auto;
			}
		}

		#catalogTreeAll, #catalogTreeAllParent {
			max-width: 100%;
		}
	}

	#add-my-catalogs.modal {
		.add-my-catalog-item-quantity {
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;

			.add-my-catalog-quantity {
				margin-top: 16px;
			}
		}

		.solar-modal-input {
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;

			> label {
				width: 100%;
			}

			.solar-modal-input-field {
				width: 100%;
				display: block;
				-ms-flex: 1 0 auto;
			}
		}

		#catalogTreeNew, #catalogTreeNewCatalog {
			max-width: 100%;
		}
	}

	.message-container.customProductImageMainContainer, .message-container.buildQuoteRequestMainContainer {
		position: relative;
		margin-top: 0;
		margin-bottom: 20px;
		padding-bottom: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		a {
			height: 300px;
	    	display: block;

	    	img.uploadBackground {
				width: 100%;
				object-fit: cover;
		    	height: 300px;
			}

			img.uploadPhone {
				position: absolute;
			    top: 89px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    width: 100%;
			    height: 211px;
			    object-fit: contain;
			}

			div.uploadText {
				position: absolute;
				left: 0;
			    width: 100%;
			    text-align: center;
			    color: #fff;
			    top: 24px;
			    font-size: 16px;
				line-height: 1.56;
				font-weight: 100;
			    padding-left: 24px;
			    padding-right: 24px;
			    margin-left: 0;
    			margin-right: 0;
			}

			div.uploadHereButton, div.buildAQuoteButton {
				position: absolute;
			    bottom: 24px;
			    left: 0;
			    right: 0;
			    text-align: center;
			    font-weight: 100;
			    margin-left: 24px;
			    margin-right: 24px;
			    width: auto;
			}
		}
	}

	.hide-tablet-mobile {
		display: none !important;
	}

	.text-center-xs {
		text-align: center;
	}

	.text-left-xs {
		text-align: left;
	}

	.spacer-medium-xs-only {
		margin-top: 10px;
	}

	.spacer-large-xs-only {
		margin-top: 20px;
	}

	.mobile-full {
		width: 100%;
	}

	.mobile-pad {
		padding-left: 0;
		padding-right: 0;
	}

	label {
		font-size: 15px;
		line-height: 1.7;
	}

	h1 {
		height: 35px;
		font-size: 27px;
		line-height: 1.3;
	}

	h2 {
		height: 30px;
		font-size: 22px;
		line-height: 1.4;
	}

	h3 {
		font-size: 19px;
		line-height: 1.6;
	}

	h4 {
		font-size: 16px;
		line-height: 1.6;
	}

	h5 {
		font-size: 16px;
		line-height: 1.6;
	}

	h6 {
		font-size: 15px;
		line-height: 1.7;
	}

	/** start autocomplete styles **/
	.autocomplete-suggestions {
	    width: 95% !important;
	    margin-left: 2.5%;
		margin-top: 0;
		padding: 0;
		background-color: #fff;
		overflow: auto;
		
		.autocomplete-suggestion {
			padding-top: 7px;
		    padding-bottom: 7px;
		    cursor: pointer;
		    font-size: 15px;
		    line-height: 1.6;
		    border-bottom-width: 1px;
		}
	}
	/** end autocomplete styles **/

	#pageWrapper {
	    /*margin-bottom: 730px;*/
	}

    .total-row {
		margin-left: 0;
		margin-right: 0;

		> div {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.title-row {
		margin-top: 30px;
	}

	.modal {
		.modal-dialog {
			.modal-content {
				.modal-header {
					padding: 5px 0;
				}

				.modal-body, .modal-footer {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	.admin-container {
		.admin-right-content {
			margin-top: 30px;
			padding-left: 15px;
    		padding-right: 15px;
		}
	}

	.collapsible-section {
		border-right-width: 0;
		border-left-width: 0;
		border-bottom-width: 0;

		.collapsible-header {
			padding: 15px 30px 15px 15px;
		}

		.collapsible-header::after {
		    right: 15px;
		}

		.collapsible-content {
			padding: 15px;
		}
	}

	.range-filter {
		display: block;

		input[type="text"] {
			display: block;
			width: 100%;
		}
		.filter-to {
			padding-top: 20px;
    		padding-bottom: 5px;
    		display: block;
		}
	}

	.pagination-wrapper {
		> div {
			ul.pagination-select {
				li.page-selector {
					width: auto;
				}
			}
		}
	}

	div.admin-right-content {
		table.admin-table {
			margin-top: 0px;

			thead {
				tr {
					th {
						display: none !important;
					}
				}
			}
		}
	}
	div.admin-right-content table.admin-table tbody tr td.footable-row-detail-cell {
		padding-top: 15px;
	}
	.footable-row-detail-name {
		padding-left: 0;
		padding-right: 35px;
	}
	div.admin-right-content table.admin-table tbody tr td.footable-row-detail-cell .footable-row-detail-value a {
		font-weight: 100;
	}

	.admin-title-back {
		> div.col-sm-4 {
			text-align: left;

			a {
				font-size: 13px;
				line-height: 1.5;
			}
		}

		> div.col-xs-4 {
			text-align: right;
		}
	}

	.item-cell {
		white-space: normal;
	}

}

@media print {
    header > div.bottom-content,
    .print-hide,
    #print-button,
    #continue-shopping,
    #info-bar,
    .solar-header-bottom,
	.solar-header-top,
	.recentlyViewedItems {
        display: none !important;
    }

    .solar-header-middle {
    	.search, .my-account, .cart {
    		display: none !important;
    	}
    }

    body.solar-template .order-confirmation .order-confirmation-content>div.row {
    	padding-left: 0 !important;
    	padding-right: 0 !important;
    }

    .admin-left-content.hidden-xs {
    	display: none !important;
    }

    .print-show,
    header > div.top-content {
        display: block !important;
    }

    table.hidden-xs {
    	display: table !important;
    }

    header {
    	#headerTop {
    		> div.col-sm-10 {
    			display: none;
    		}
    	}
    }

    table {
    	thead {
    		tr {
    			th {
    				border-top: 1px solid #000;
    				border-bottom: 1px solid #000;
    			}
    		}
    	}

    	tbody {
    		tr {
    			td {
    				border-bottom: 1px solid #000;
    			}
    		}
    	}
    }

    footer {
        display: none;
    }

    .main-content {
        width: 1000px;
        padding-bottom: 50px;
    }

    #pageWrapper {
        min-height: initial;
    }

    a[href]:after {
        content: '' !important;
    }

    .line-item, .row-eq-height {
        page-break-inside: avoid;
    }

	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	    float: left;
  	}
  	.col-sm-12 {
    	width: 100%;
  	}
  	.col-sm-11 {
    	width: 91.66666667%;
  	}
  	.col-sm-10 {
    	width: 83.33333333%;
  	}
  	.col-sm-9 {
    	width: 75%;
  	}
  	.col-sm-8 {
    	width: 66.66666667%;
  	}
  	.col-sm-7 {
    	width: 58.33333333%;
  	}
  	.col-sm-6 {
    	width: 50%;
  	}
  	.col-sm-5 {
    	width: 41.66666667%;
  	}
  	.col-sm-4 {
    	width: 33.33333333%;
  	}
  	.col-sm-3 {
    	width: 25%;
  	}
  	.col-sm-2 {
    	width: 16.66666667%;
  	}
  	.col-sm-1 {
    	width: 8.33333333%;
  	}
  	.col-sm-pull-12 {
    	right: 100%;
  	}
  	.col-sm-pull-11 {
    	right: 91.66666667%;
  	}
  	.col-sm-pull-10 {
    	right: 83.33333333%;
  	}
  	.col-sm-pull-9 {
    	right: 75%;
  	}
  	.col-sm-pull-8 {
    	right: 66.66666667%;
  	}
  	.col-sm-pull-7 {
    	right: 58.33333333%;
  	}
  	.col-sm-pull-6 {
    	right: 50%;
  	}
  	.col-sm-pull-5 {
    	right: 41.66666667%;
  	}
  	.col-sm-pull-4 {
    	right: 33.33333333%;
  	}
  	.col-sm-pull-3 {
    	right: 25%;
  	}
  	.col-sm-pull-2 {
    	right: 16.66666667%;
  	}
  	.col-sm-pull-1 {
    	right: 8.33333333%;
  	}
  	.col-sm-pull-0 {
    	right: auto;
  	}
  	.col-sm-push-12 {
    	left: 100%;
  	}
  	.col-sm-push-11 {
    	left: 91.66666667%;
  	}
  	.col-sm-push-10 {
    	left: 83.33333333%;
  	}
  	.col-sm-push-9 {
    	left: 75%;
  	}
  	.col-sm-push-8 {
    	left: 66.66666667%;
  	}
  	.col-sm-push-7 {
    	left: 58.33333333%;
  	}
  	.col-sm-push-6 {
    	left: 50%;
  	}
  	.col-sm-push-5 {
    	left: 41.66666667%;
  	}
  	.col-sm-push-4 {
    	left: 33.33333333%;
  	}
  	.col-sm-push-3 {
    	left: 25%;
  	}
  	.col-sm-push-2 {
    	left: 16.66666667%;
  	}
  	.col-sm-push-1 {
    	left: 8.33333333%;
  	}
  	.col-sm-push-0 {
    	left: auto;
  	}
  	.col-sm-offset-12 {
    	margin-left: 100%;
  	}
  	.col-sm-offset-11 {
    	margin-left: 91.66666667%;
  	}
  	.col-sm-offset-10 {
    	margin-left: 83.33333333%;
  	}
  	.col-sm-offset-9 {
    	margin-left: 75%;
  	}
  	.col-sm-offset-8 {
    	margin-left: 66.66666667%;
  	}
  	.col-sm-offset-7 {
    	margin-left: 58.33333333%;
  	}
  	.col-sm-offset-6 {
    	margin-left: 50%;
  	}
  	.col-sm-offset-5 {
    	margin-left: 41.66666667%;
  	}
  	.col-sm-offset-4 {
    	margin-left: 33.33333333%;
  	}
  	.col-sm-offset-3 {
    	margin-left: 25%;
  	}
  	.col-sm-offset-2 {
    	margin-left: 16.66666667%;
  	}
  	.col-sm-offset-1 {
    	margin-left: 8.33333333%;
  	}
  	.col-sm-offset-0 {
    	margin-left: 0%;
  	}
  	.visible-xs, .visible-xs-block {
    	display: none !important;
  	}
  	.hidden-xs {
    	display: block !important;
  	}
  	table.hidden-xs {
    	display: table;
  	}
  	tr.hidden-xs {
    	display: table-row !important;
  	}
  	th.hidden-xs,
  	td.hidden-xs {
    	display: table-cell !important;
  	}
  	.hidden-xs.hidden-print {
    	display: none !important;
  	}
  	.hidden-sm {
    	display: none !important;
  	}
  	.visible-sm {
    	display: block !important;
  	}
  	table.visible-sm {
    	display: table;
  	}
  	tr.visible-sm {
    	display: table-row !important;
  	}
  	th.visible-sm,
  	td.visible-sm {
    	display: table-cell !important;
  	}
}


/* solar template */
body.solar-template {
	.build-quote,
	.upload-product {
		min-height: 291px;
	}
	#pageWrapper {
		.overlay-container {
			align-items: center;
			-ms-flex-align: center;

			.main-content {
				max-width: 1440px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
	    }
	}

	div.error-message {
		bottom: -15px;
	}
	div.error-message+label~input, div.error-message+label~div.btn-group {
		margin-bottom: 5px;
	}

	input[type="text"], input[type="password"], textarea {
		border: 1px solid #959ea0;
		border-radius: 2px;
		box-shadow: none;
		-webkit-appearance: none;
	}

	.select-button {
		box-shadow: none;
		border: 1px solid #959ea0;
	}	
	.bootstrap-select.btn-group .dropdown-toggle .filter-option {
		padding-left: 0;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.2px;
	}

	.out-of-stock, .non-stock {
		font-size: 12px;
		line-height: 1.67;
		letter-spacing: 0.1px;
		color: $color-error;
	}

	.cs-hidden {
	    opacity: 0;
	    filter: alpha(opacity=0);
	    overflow: hidden;
	}

	.qty-input {
		position: relative;
		display: inline-block;

		input {
			width: 45px;
			text-align: center;
			padding-left: 30px;
			padding-right: 1px;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.2px;			
		}
	}
	

	.collapsible-section {
		border-radius: 2px;

		.collapsible-header {
			padding: 10px 32px 10px 16px;
			position: relative;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.43;
			letter-spacing: 0.1px;
			background-color: #fff;
		}

		.collapsible-header::after {
			font-family: 'FontAwesome';
		    position: absolute;
		    right: 12px;
		    top: 9px;
		    content: "\f068";
		    font-size: 18px;
		}

		.collapsible-content {
			padding: 16px;
		}
	}

	.collapsible-section.closed {
		border-bottom-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;

		.collapsible-header::after {
			content: "\f067";
		}

		.collapsible-content {
			display: none;
		}
	}

	

	.checkout-container {
		.solar-order-summary-mobile-cart {
			padding-left: 0;
			padding-right: 0;

			.total-title {
				margin-bottom: 8px;

				> div {
					padding-left: 0;
					padding-right: 0;
				}
			}

			h4 {
				font-size: 18px;
				font-weight: 600;
				line-height: 1.56;
				letter-spacing: 0.2px;
			}

			h6 {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;
			}

			.total-row {
				font-size: 14px;
				line-height: 1.43;
				letter-spacing: 0.1px;
			}

			.order-summary-total-section {
				h6 {
					font-size: 14px;
				    line-height: 1.43;
				    letter-spacing: 0.1px;
				    font-weight: 100;
				}
			}
		}

		.solar-order-summary {
			width: 320px;
			padding: 24px 40px;

			h4 {
				font-size: 18px;
				font-weight: 600;
				line-height: 1.56;
				letter-spacing: 0.2px;
			}

			h6 {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;
			}

			.total-row {
				font-size: 14px;
				line-height: 1.43;
				letter-spacing: 0.1px;
			}

			.order-summary-total-section {
				h6 {
					font-size: 14px;
				    line-height: 1.43;
				    letter-spacing: 0.1px;
				    font-weight: 100;
				}
			}
		}
	}

	.jstree-default .jstree-wholerow {
		border-bottom: 0;
	}
	.jstree-default .jstree-wholerow, .jstree-default>.jstree-wholerow-ul .jstree-anchor, .jstree-default .jstree-icon, .jstree-default .jstree-icon:empty {
		height: 32px;
		line-height: 32px;
	}
	.jstree-default>.jstree-no-dots .jstree-closed>.jstree-ocl:before {
		font-family: 'FontAwesome';
		content: "\f067";
	}
	.jstree-default>.jstree-no-dots .jstree-open>.jstree-ocl:before {
		font-family: 'FontAwesome';
		content: "\f068";
	}
	.jstree-default>.jstree-wholerow-ul .jstree-anchor {
		font-size: 14px;
		letter-spacing: 0.1px;
	}
	.tree-container {
		box-shadow: none;
	}

	#create-catalog.modal, #add-all-to-my-catalogs.modal, #add-my-catalogs.modal {
		#catalogTreeNew, #catalogTreeNewCatalog, #catalogTreeAll, #catalogTreeAllParent {
			height: 228px;
			border-top-width: 0;
			max-width: 416px;
			overflow: scroll;
			border-radius: 0;
			-ms-overflow-style: scrollbar;
		}
	}

	.new-my-catalogs-filter-row, .new-my-catalogs-filter-row-new, .add-all-my-catalogs-filter-row-new, .add-all-my-catalogs-filter-row {
		padding: 16px;
		position: relative;

		input {
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.2px;
			padding: 8px 28px 8px 12px;	
			width: 100%;				
		}

		input::placeholder {
			color: #959ea0;
		}
	}

	.new-my-catalogs-filter-row:after, .new-my-catalogs-filter-row-new:after, .add-all-my-catalogs-filter-row-new:after, .add-all-my-catalogs-filter-row:after {
		content: "\f002";
		font-family: 'FontAwesome';					
		position: absolute;
		right: 28px;
		top: 28px;
		color: #959ea0;
	}

	#add-all-to-my-catalogs.modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					.add-my-catalog-create-new-catalog {
						margin-top: 0;
					}
				}
			}
		}
	}
	
	.modal {
		.modal-dialog {
			.modal-content {
				padding: 0;

				.modal-header {
					padding-left: 16px;
					padding-top: 16px;
					padding-right: 16px;
					padding-bottom: 0;

					h4 {
						font-size: 18px;
						font-weight: 600;
						line-height: 1.56;
						letter-spacing: 0.2px;
					}
				}

				.modal-body {
					padding-left: 16px;
					padding-top: 24px;
					padding-right: 16px;
					padding-bottom: 16px;

					& + .modal-footer{
						margin-top: -16px;
					}

					.add-my-catalog-item-quantity {
						display: flex; 
						display: -webkit-box;
						display: -ms-flexbox;
						display: -webkit-flex;
						align-items: center; 
			            -ms-flex-align: center;

			            .add-my-catalog-item-preview {
			            	width: 282px;
			            	padding: 8px 12px;
			            	display: flex; 
							display: -webkit-box;
							display: -ms-flexbox;
							display: -webkit-flex;
							align-items: center; 
				            -ms-flex-align: center;
				            border-radius: 2px;

			            	#add-my-catalog-item-image {
			            		max-width: 48px;

			            		img {
			            			max-width: 48px;
			            			max-height: 48px;
			            		}
			            	}

			            	.add-my-catalog-item-detail {
			            		font-size: 12px;
								line-height: 1.67;
								letter-spacing: 0.1px;
								font-weight: 100;
								flex: 1;
								-webkit-box-flex: 1;
						  		-moz-box-flex:  1;
						  		-webkit-flex:  1;
						  		-ms-flex:  1;
						  		padding-left: 16px;

			            		#add-my-catalog-product-title {
			            			a {
			            				font-size: 12px;
										font-weight: 600;
										line-height: 1.67;
										letter-spacing: 0.1px;
			            			}
			            		}
			            	}
			            }

			            .add-my-catalog-quantity {
			            	padding-left: 16px;

			            	a.small-circle-button {
			            		height: 32px;
							    width: 32px;
							    line-height: 32px;
							    padding: 0;

							    i {
							    	margin-left: 2px;
							    }
			            	}

			            	a.subtract-quantity {			            		
							    margin-right: 3px;
			            	}

			            	a.add-quantity {
			            		margin-left: 3px;
			            	}

			            	#add-my-catalogs-qty {
			            		width: 72px;
			            	}
			            }
					}

					.add-my-catalog-new-catalog, .new-catalog {
						margin-top: 24px;

						#catalogTreeNewCatalog, #catalogTreeAllParent {
							height: 126px;
						}
					}

					.add-my-catalog-create-new-catalog {
						margin-top: 24px;

						label {
							font-size: 14px;
							line-height: 1.43;
							letter-spacing: 0.1px;
							font-weight: 100;
						}
					}

					.add-my-catalog-select-catalog, .add-my-catalogs-all-select-catalog {
						margin-top: 24px;

						#catalogTreeNew, #catalogTreeNewCatalog, #catalogTreeAll, #catalogTreeAllParent {
							height: 126px;
						}
					}

					.solar-modal-input.solar-align-start {
						align-items: flex-start;
						-ms-flex-align: flex-start;
					}
					.solar-modal-input {
						display: flex; 
						display: -webkit-box;
						display: -ms-flexbox;
						display: -webkit-flex;
						align-items: center; 
			            -ms-flex-align: center;

			            .hidden-label {
			            	font-size: 14px;
							line-height: 1.43;
							letter-spacing: 0.1px;
							font-weight: 100;
			            }

						> label {
							display: block;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
							width: 150px;
						}

						> input[type="text"], .solar-modal-input-field {
							flex: 1;
							-webkit-box-flex: 1;
					  		-moz-box-flex:  1;
					  		-webkit-flex:  1;
					  		-ms-flex:  1;
						}
					}					
				}

				.modal-footer {
					padding-left: 16px;
					padding-top: 24px;
					padding-right: 16px;
					padding-bottom: 16px;

					a.cancel {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.14;
						letter-spacing: 0.1px;
						text-align: center;
						color: #657878;
					}
				}
			}
		}
	}

	.hs-carousel-item {
		background-color: #fff;
		border-radius: 2px;
		display: flex; 
		display: -ms-flexbox;
		display: -webkit-flex;
		padding: 16px;
		flex-wrap: wrap;

		.quantity-col{
			display: flex;
			flex-direction: column;
			justify-content: end;
			width: 100%;
			margin-top: 24px;

			& > button{
				margin-top: 8px;
			}
		}

		.qty-input {
			display: block;
			text-align: center;
		}

		.line-item-button{
			margin-left: 0;
		}

		.add-to-catalog{
			margin-right: 0;
		}

		&.line-item{
			margin-top: 0px;
			border-left: 1px solid#d5d8d9;
			border-bottom: 1px solid#d5d8d9;
			border-right: 1px solid#d5d8d9;
		}

		.featured-product-image {
			width: 100px;
			height: 100px;
			border: 1px solid#d5d8d9;
			margin-right: 16px;

			a {
				display: block;
				text-align: center;

				img {
					max-width: 96px;
					max-height: 96px;
				}
			}
		}

		.featured-product-price {
			.sell-price {
				font-size: 16px;
			  font-weight: 600;
			  line-height: 1.5;
			  letter-spacing: 0.2px;
			}

			.uom {
				font-size: 12px;
			  line-height: 1.67;
			  letter-spacing: 0.1px;
			  color: #606e71;
			}
		}

		.featured-product-name {
			flex-basis: 100%;
			a {
				font-size: 14px;
			  font-weight: 600;
			  line-height: 1.43;
			  letter-spacing: 0.1px;
			}
		}

		.featured-product-details {
			flex: 1;
			-webkit-box-flex: 1;
	  		-moz-box-flex:  1;
	  		-webkit-flex:  1;
	  		-ms-flex:  1;

	  		

	  		.featured-product-detail {
	  			font-size: 12px;
				line-height: 1.67;
				letter-spacing: 0.1px;
				color: #606e71;
	  		}

	  		
		}
	}

	.featured-product-item {
		background-color: #fff;
		border-radius: 2px;
		display: flex; 
		display: -ms-flexbox;
		display: -webkit-flex;
		padding: 16px;

		.featured-product-image {
			width: 96px;
			margin-right: 16px;

			a {
				display: block;
				text-align: center;

				img {
					max-width: 96px;
					max-height: 96px;
				}
			}
		}

		.featured-product-details {
			flex: 1;
			-webkit-box-flex: 1;
	  		-moz-box-flex:  1;
	  		-webkit-flex:  1;
	  		-ms-flex:  1;

	  		.featured-product-name {
	  			a {
	  				font-size: 14px;
					font-weight: 600;
					line-height: 1.43;
					letter-spacing: 0.1px;
	  			}
	  		}

	  		.featured-product-detail {
	  			font-size: 12px;
				line-height: 1.67;
				letter-spacing: 0.1px;
				color: #606e71;
	  		}

	  		.featured-product-price {
	  			.sell-price {
	  				font-size: 16px;
					font-weight: 600;
					line-height: 1.5;
					letter-spacing: 0.2px;
	  			}

	  			.uom {
	  				font-size: 12px;
					line-height: 1.67;
					letter-spacing: 0.1px;
					color: #606e71;
	  			}
	  		}
		}
	}

	.featured-brand-item {
		a {
			color: inherit;
		}
	}

	.featured-brand-item {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.43;
		letter-spacing: 0.1px;
		text-align: center;
		background-color: #fff;
		border-radius: 2px;
		padding: 22px 8px;
		flex-shrink: 0;

		i {
			display: block;
			font-size: 5rem;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 16px;
		}

		img {
			max-width: 100%;
			max-height: 88px;
		}

		.featured-brand-image {
			display: flex; 
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			flex-direction: column; 
			-webkit-box-orient: vertical;
		    -moz-box-orient: vertical;
		    -webkit-flex-direction: column;
		    -ms-flex-direction: column;
			justify-content: center; 
			-ms-flex-pack: center;
			align-items: center; 
			-ms-flex-align: center;
			height:88px;
		}
	}

	.slider-section, .hs-slider-section {
		padding-top: 24px;
		padding-bottom: 24px;
		overflow: hidden;

		.slider-content {
			display: flex;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			align-items: center;
			-ms-flex-align: center;
			margin-top: 16px;

			.slider-left {
				margin-left: 16px;
				margin-right: 16px;

				i {
					line-height: 42px;
				}
			}
			.slider-right {
				margin-right: 16px;
				margin-left: 0px;
				position: relative;

				i {
					line-height: 42px;
				}
			}

			.slider-left, .slider-right {
				width: 40px;
				height: 40px;
				border-radius: 20px;
				color: #fff;
				display: table-cell;
				vertical-align: middle;
				text-align: center;
			}
			.lightSlider {
				flex: 1;
				margin:auto;
				-webkit-box-flex: 1;
		  		-moz-box-flex:  1;
		  		-webkit-flex:  1;
		  		-ms-flex:  1;
		  		position: relative;
		  		flex-wrap: nowrap;
		  		-ms-flex-wrap: none;
			}
			.slider-right:before {
				content: '';
				position: absolute;
				top: -300px;
				left: -45px;
				width: 45px;
				height: 600px;
			}
		}
		.button-section {
			margin-top: 24px;
		}	
	}	

	.hs-slider-section{
		margin: 39px 16px;

		.slider-content {
			.slider-left{
				margin-right: 12px;
				margin-left: 8px;
			}

			.slider-right{
				margin-right: 8px;
				margin-left: 12px;
			}
		}

		.lSSlideOuter .lSPager.lSpg > li{

			a {
				height: 24px;
				width: 24px;
				background-color: transparent;
				border: 1px solid rgb(149, 158, 160);
			}
		}
	}

	.title {
		font-size: 24px;
		line-height: 1.17;
		letter-spacing: 0.2px;
	}
}
/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.qty-input {
			position: relative;
			display: inline-block;

			input {
				width: 45px;
				text-align: center;
				padding-left: 30px;
				padding-right: 1px;			
			}
		}

		.checkout-container {
			.solar-order-summary {
				width: 58.33333333%;
				padding: 0px 16px;
			}

			.col-sm-6.mobile-order-summary.solar-order-summary {
				width: 50%;
			}
		}

		.featured-product-item {				
			.featured-product-image {
				width: 56px;

				a {
					img {
						max-width: 56px;
						max-height: 56px;
					}
				}
			}				
		}

		.hs-slider-section{
			margin: 16px 0;
			border: none !important;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.checkout-container {
			.solar-order-summary {
				padding: 0px 16px;
				width: 100%;
			}
		}

		input[type="text"], input[type="password"], textarea {
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.2px;
		}

		.slider-section, .hs-slider-section {
			.slider-content {
				margin-left: 16px;

				.slider-left, .slider-right {
					display: none;
				}					
			}	
		}	
		.featured-product-item {				
			.featured-product-image {
				width: 56px;

				a {
					img {
						max-width: 56px;
						max-height: 56px;
					}
				}
			}				
		}

		#catalogTreeNew, #catalogTreeNewCatalog, #catalogTreeAll, #catalogTreeAllParent {
			max-width: 100%;
		}

		.modal {
			.modal-dialog {
				.modal-content {
					.modal-body {
						.solar-modal-input {
							-ms-flex-direction: column;
						    -webkit-flex-direction: column;
						    flex-direction: column;

							> label {
								width: 100%;
							}

							> input[type="text"], .solar-modal-input-field {
								width: 100%;
								display: block;
								-ms-flex:  1 0 auto;
							}
						}

						.add-my-catalog-item-quantity {
							-ms-flex-direction: column;
						    -webkit-flex-direction: column;
						    flex-direction: column;

						    .add-my-catalog-quantity {
						    	margin-top: 16px;
						    }
						}	


					}
				}
			}
		}

		.hs-slider-section{
			margin: 16px 0;
			border: none !important;
		}

		.hs-carousel-item{
			.line-item > div {
				margin-bottom: auto;
			}
		}
	}
}

/* end solar template */

@import "partials/header";
@import "partials/footer";
@import "partials/home";
@import "partials/contactUs";
@import "partials/productDetail";
@import "partials/search";
@import "partials/login";
@import "partials/paymentInformation";
@import "partials/cart";
@import "partials/approvedQuotes";
@import "partials/shippingInfo";
@import "partials/orderVerification";
@import "partials/orderConfirmation";
@import "partials/detailPage";
@import "partials/adminDashboard";
@import "partials/quickOrder";
@import "partials/myCatalogs";
@import "partials/adminMyProfile";
@import "partials/adminOrdersInvoices";
@import "partials/adminJobs";
@import "partials/adminJobDetails";
@import "partials/adminQuotes";
@import "partials/adminDiscussions";
@import "partials/adminWebOrderDetail";
@import "partials/customerStorageList"; // Remove when migrating CSL to Lightning
@import "partials/adminOrderInvoicesDetail";
@import "partials/discussionDetail";
@import "partials/adminQuoteRequestDetail";
@import "partials/adminCustomerStatements";
@import "partials/customerUsers";
@import "partials/manufacturers";
@import "partials/adminCreditMemos";
@import "partials/orderHistory";
@import "partials/staticPage";
@import "partials/adminCreditMemoDetail";
@import "partials/addCustomerUser";
@import "partials/allBrands";
@import "partials/viewAll";
@import "partials/register";
@import "partials/createDiscussion";
@import "partials/quoteRequest";
@import "partials/searchAll";
@import "partials/uploadProductPhoto";
@import "partials/solarHeader";
@import "partials/solarHome";
@import "partials/solarSearch";
@import "partials/solarShippingInfo";
@import "partials/solarPaymentInformation";
@import "partials/solarOrderVerification";
@import "partials/solarOrderConfirmation";
@import "partials/solarCart";
@import "partials/compareProducts";
@import "partials/solarMyCatalogs";
@import "partials/orderingPage";
@import "partials/recentlyViewedItems";
@import "partials/myInbox";
@import "partials/ezSelector";
@import "partials/commodityPrices";
@import "partials/conduitFill";
@import "partials/featuredProducts";
@import "partials/topSellers";
@import "partials/ampacity";
@import "partials/services";
@import "partials/productLanding";
@import "partials/notFound";
@import "partials/repairWarranty";
@import "partials/repairWarrantyNew";
@import "partials/repairWarrantyProcessedDetail";
@import "partials/repairWarrantyQuoteRequestComplete";
@import "partials/repairWarrantyCheckout";
@import "partials/vendorManagedInventory";
@import "partials/vmiProducts";
@import "partials/whatsNew";
@import "partials/qbSetup";
@import "partials/vmiProductHistory.scss";
@import "partials/vmiScanTransactions.scss";
@import "partials/homeSolarLoggedIn.scss";
@import "partials/vmiToolCatalogNewTool.scss";
@import "partials/greensightPortalApplications.scss";
@import "partials/greensightPortalApplication.scss";
@import "partials/greentechHeader.scss";
@import "partials/greentechFooter.scss";
@import "partials/greentechProjects.scss";
@import "partials/greentechProjectDetail.scss";
@import "partials/greentechPlansets.scss";
@import "partials/greentechServiceOrderNew.scss";
@import "partials/greentechNewProject.scss";
@import "partials/greentechServiceOrderIntakeOverview.scss";
@import "partials/greentechServiceOrderIntakeInput.scss";
@import "partials/greentechProjectsNavigation.scss";
@import "partials/greentechLoans.scss";
@import "partials/greentechLoanDetail.scss";
@import "partials/greentechFinancing.scss";
@import "partials/greentechAddFinancing.scss";
@import "partials/greentechPurchasing.scss";
@import "partials/greentechBilling.scss";
@import "partials/greentechChangeRequest.scss";
@import "partials/greentechDashboard.scss";
@import "partials/greentechPlansetRequests.scss";
@import "partials/greentechProjectTransferLanding.scss";
@import "partials/greentechServiceOrderIntakeReduced.scss";
@import "partials/greentechProjectExistingMerge.scss";
@import "partials/greentechIntegrations.scss";
@import "partials/greentechProjectBOM.scss";


