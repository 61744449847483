@import "../variables";

greentechFooter {
    box-shadow: 0px 1px 0px rgba(206,207,205, 1) inset;
    opacity: 1;
    background-color: rgba(250,250,250, 1);
    
    .singleColumn {
        margin-left: -12px;
    }

    .legal {
        float: left;
        opacity: 1;
        color: rgba(33,33,33,1);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.3px;
        text-align: left;
        line-height: 24px;
    }

    .legalLinks {
        margin-left: 15px;
        opacity: 1;
        color: rgba(109,110,108,1);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.3px;
        text-align: left;
        line-height: 24px;
        text-decoration: underline;
    }

	.content {
		max-width: 1440px;
		height: 320px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 60px;
		padding-bottom: 60px;

		.ced-logo {
			max-width: 124px;
			max-height: 89px;
		}

		.footer-column {
            .address {
                padding-top: 0px;
				padding-bottom: 0px;
            }

            .mobileLinks {
                opacity: 1;
                display: flex;

                img {
                    width: 135px;
                    height: 40px;
                    margin-right: 10px;
                }
            }

            #emailAddress {
                border-radius: 6px;
                opacity: 1;
            }

            .submitButton {
                border-radius: 6px;
                opacity: 1;
                color: rgba(255,255,255,1);
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: center;
                line-height: 16px;
            }

            a {
                opacity: 1;
                color: rgba(109,110,108,1) !important;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: left;
                line-height: 24px;
                text-decoration: underline;
            }

            label {
                opacity: 1;
                color: rgba(33,33,33,1);
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: left;
                line-height: 24px;
                padding-top: 10px;
                padding-bottom: 5px;
            }

            div {
				padding-top: 5px;
				padding-bottom: 5px;
			}

            .socialMediaIcons {
                display: flex;
                justify-content: left;
        
                a.socialMediaIcon {
                    display: block;
                    width: 32px;
                    height: 32px;
                    opacity: 1;
                    background-color: rgba(109,110,108, 1);
                    border-radius: 16px;
                    margin: 0px 6px 0px 0px;
            
                    > div {
                        text-align: center;
                        padding-top: 0px;
				        padding-bottom: 0px;
        
                        i {
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            line-height: 32px;
                        }
                    }	
                }
        
                .socialHorizontal {
                    display: inline-block;
                }
            }
		}
	}
    
    .flex {
        display:flex;
        justify-content:space-between;
    }
    
    .flex input[type="text"] {
        flex-grow:1;
        margin-right:8px;
    }

	.followUs {
		opacity: 1;
        color: rgba(33,33,33,1);
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.3px;
        text-align: left;
        line-height: 24px;
	}	
}
@media only screen and (max-width: $greentech-laptop-max-width) and (min-width: $greentech-laptop-min-width) {
    greentechFooter {

        .singleColumn {
            width: 100%;
        }

        .footer-column {
            #emailAddress {
                height: 40px !important;
            }
        }
    }
}

@media only screen and (max-width: $greentech-tablet-max-width) and (min-width: $greentech-tablet-min-width) {
    greentechFooter {

        .email {

            label {
                opacity: 1;
                color: rgba(33,33,33,1);
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: left;
                line-height: 24px;
                padding-top: 10px;
                padding-bottom: 5px;
            }

            #emailAddress {
                height: 40px !important;
                margin-bottom: 10px;
            } 

            .submitButton {
                color: rgba(255,255,255,1);
                border-radius: 6px;
                opacity: 1;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: center;
                line-height: 16px;
                height: 40px;
            }
        }

        .legalLinks {
            margin-left: 0px;
            margin-right: 5px;
        }
        
        .legal-links {
            padding-left: 0px;
        }
    }
}

@media only screen and (max-width: $greentech-mobile-max-width){
    greentechFooter {

        .one-column {
            width: 100%;
        }

        .legalLinks {
            margin-left: 0px;
            margin-right: 5px;
        }

        .legal-links {
            padding-left: 0px;
        }

        .email {

            label {
                opacity: 1;
                color: rgba(33,33,33,1);
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: left;
                line-height: 24px;
                padding-top: 10px;
                padding-bottom: 5px;
            }

            #emailAddress {
                margin-bottom: 10px;
            } 

            .submitButton {
                border-radius: 6px;
                opacity: 1;
                color: rgba(255,255,255,1);
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0.3px;
                text-align: center;
                line-height: 16px;
                width: 81px;
                height: 40px;
            }
        }

        .mobileLinks {
            img {
                width: 100px !important;
            }
        }
    }
}
