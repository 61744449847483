@import "../variables";

.admin-right-content.admin-customer-statements #billtrustLeftContainer {
	padding-bottom: 10px; 
	
	.button-billtrust-lg {
		position: relative;
		min-height: 193px;
		height: 193px;

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		.button-logo {
			margin-top: 50px;
			float: right;
			width: 255px;
			height: 77px;
		}

		.billtrust-text {
			margin-top: 75px;
			float: left;
			color: #fff;
			font-size: 24px;
			font-weight: normal;
			letter-spacing: 0.23px;
			line-height: 28px;

			i {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	a {
		.pay-now {
			position: absolute;
			bottom: 35px;
			left: 115px;
			width: 127px;
			text-align: center;
			font-weight: 100;
			margin: 0;
		}

		.pay-text {
			position: absolute;
			left: 0;
			right: 0;
			top: 40px;
			height: 50px;
			font-size: 19px;
			line-height: 1.32;
			font-weight: 100;
			margin-right: 292px;
			margin-left: 115px;
			color: #ffffff;
			text-align: left;
			padding: 0;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-customer-statements {
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.footable-row-detail {
			.footable-row-detail-cell {
				.footable-row-detail-inner {
					.footable-row-detail-row:last-of-type {
						.footable-row-detail-name {
							display: none;
						}
						.footable-row-detail-value {
							position: relative;
    						height: 50px;

    						button {
    							position: absolute;
							    top: 5px;
							    width: 160px;
    						}
						}
					}
				}
			}
		}
	}

	#billtrustLeftContainer {
		position: relative;
		margin-top: 0;
		margin-bottom: 20px;
		padding-bottom: 0;

		.button-billtrust-xs {
			min-height: 285px;
			height: 285px;

			a {
				display: block;
				width: 100%;
				height: 100%;
			}
			
			.button-logo {
				margin-top: 75px;
				width: 255px;
				height: 77px;
			}

			.billtrust-text {
				margin-top: 15px;
				color: #fff;
				font-size: 18px;
				font-weight: normal;
				letter-spacing: 0.23px;
				line-height: 28px;
	
				i {
					font-size: 14px;
					line-height: 18px;
				}
			}
		}

		a {
			display: block;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.39;
			text-align: center;

			img {
				max-width: 100%;
			}

			.pay-now {
				position: absolute;
				bottom: 24px;
				left: 0;
				right: 0;
				text-align: center;
				font-weight: 100;
				margin-left: 24px;
				margin-right: 24px;
				width: auto;
			}

			.pay-text {
				position: absolute;
				left: 0;
				width: 100%;
				text-align: center;
				color: #fff;
				top: 24px;
				font-size: 16px;
				line-height: 1.56;
				font-weight: 100;
				padding-left: 24px;
				padding-right: 24px;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}