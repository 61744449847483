@import "../variables";

.create-discussion {
	.buttons {
		a {
			font-weight: bold;
			margin-top: 12px;
    		font-size: 13px;
			line-height: 1.5;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.create-discussion {
		.buttons {
			input[type="submit"] {
				width: 100%;
				float: none !important;
			}

			a {
				display: block;
			}
		}
	}
}