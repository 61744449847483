$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 1023px;
$desktop-min-width: 1024px;
$desktop-max-width: 1199px;

$background-color-error: #b64f3526;
$border-color-error: #b64f35;
$color-error: #B64F35;

$greentech-mobile-max-width: 600px;
$greentech-tablet-min-width: 601px;
$greentech-tablet-max-width: 1024px;
$greentech-laptop-min-width: 1025px;
$greentech-laptop-max-width: 1440px;
$greentech-desktop-min-width: 1441px;
$greentech-desktop-max-width: 2000px;

$status-danger-1: #A91700;
$status-danger-2: #D34A35;
$status-danger-3: #FBF5F4;
$status-warning-1: #804400;
$status-warning-2: #B38200;
$status-warning-3: #FFF7E5;
$status-success-1: #296804;
$status-success-2: #479915;
$status-success-3: rgba(88, 152, 21, 0.15);
$status-neutral-2: rgb(109, 110, 108);
$status-neutral-4: rgb(206, 207, 205);