@import "../variables";


#whatsnew-page-container{

	.whatsnew-page {
        width: 100%;
        margin-top: 40px;
    }

    .top-featured-item {
        margin: 0 auto;
        max-width: 1130px;

        .featured-header {
            margin: 0 auto;
            width: 900px;
            text-align: center;
        }

        .header-text {
            color: rgb(43, 61, 65);
            font-size: 32px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        p {
            margin-top: 16px;
            margin-bottom: 36px;
            color: rgb(43, 61, 65);
            font-size: 16px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.15px;
            line-height: 24px;
        }

        .featured-wrapper {
            display: flex;

            margin: auto;
            width: 1130px;
            height: 317px;
            border: 1px solid rgb(213, 216, 217);
            border-radius: 6px;
            box-shadow: 0px 15px 25px 0px rgba(31, 41, 51, 0.15),0px 5px 10px 0px rgba(31, 41, 51, 0.15);

            .featured-image-container {
                width: 50%;
                height: 100%;
                border-right: 1px solid rgb(213, 216, 217);
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
    
            .featured-contents {
                display: flex;
                flex-direction: column;

                width: 50%;
                height: 315px;
                padding: 24px;
    
                .just-released {
                    color: rgb(43, 61, 65);
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 0.11px;
                    line-height: 20px;
                    text-transform: uppercase;
                }
    
                .header-text {
                    margin: 10px 0;
                    color: rgb(43, 61, 65);
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0.23px;
                    line-height: 28px;
                    text-align: left;
                }
    
                .whatsnew-contents {
                    margin-bottom: 20px;
                    color: rgb(43, 61, 65);
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0.15px;
                    line-height: 24px;
                }
            }
        }
    }

    .recent-items {
        margin-top: 48px;

        .header-text {
            color: rgb(43, 61, 65);
            font-size: 24px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.23px;
            line-height: 28px;
        }
    }

    .whatsnew-page h2 {
    	height: 24px;
        font-size: 16px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 0.2px;
    }

    .whatsnew-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1400px;
		margin: 0 auto;
        margin-bottom: 48px;
    }

    .whatsnew-list > li {
        width: 31%;
		margin-top: 24px;
		margin-right: 14px;
        margin-left: 0;
		list-style: none;
        border: 1px solid rgb(213, 216, 217);
        border-radius: 3px;

        .image-container {
            margin: auto;
            display: flex;
            height: 250px;
            border-bottom: 1px solid rgb(213, 216, 217);

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .content-container {
            padding: 16px;

            .header-text {
                margin: 5px 0;
                color: rgb(43, 61, 65);
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.17px;
                line-height: 28px;
                text-align: left;
            }

            .whatsnew-contents {
                margin-bottom: 15px;
                color: rgb(43, 61, 65);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.13px;
                line-height: 20px;

                ul {
                    margin-left:0;
                }

                img{
                    max-width: 100%;
                    vertical-align: inherit;
                }
            }

            & > a{
                margin-left: 0
            }
        }
    }
	
    .whatsnew-page .page-msg {
        margin-top:.5em;
    }
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	#whatsnew-page-container {
        .top-featured-item {
            max-width: 1024px;

            .featured-header,
            .featured-wrapper {
                max-width: 900px;
            }

            .featured-wrapper {
                height: 248px;
            }
        }

		.whatsnew-list {
			margin: 10px 20px;
			padding: 0 6px;

			& > li {
				list-style: none;
				margin: 10px 5px;

                .image-container{
					height: 150px;

                    img {
                        width: 100%;
                    }
				}
		    }
    	}
	}
}

@media only screen and (max-width: $mobile-max-width) {
	#whatsnew-page-container{
        .top-featured-item {
            width: 288px;

            .featured-header,
            .featured-wrapper {
                width: 100%;
            }

            .featured-header {
                .header-text {
                    font-size: 24px;
                }
            }

            .featured-wrapper {
                flex-direction: column;
                height: 100%;

                .featured-image-container {
                    width: 100%;
                    border-bottom: 1px solid rgb(213, 216, 217);

                    img {
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }
                }

                .featured-contents {
                    width: 100%;
                    height: 100%;
                    padding: 16px;

                    .header-text {
                        font-size: 18px;
                        margin: 0;
                    }

                    .whatsnew-contents {
                        font-size: 14px;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .whatsnew-list {
            flex-direction: column;
            margin: 0;

            & > li {
				list-style: none;
				flex: 0 0 100%;
		        width: 296px;
                margin-right: 0;
                margin-left: 20px;

                .image-container{
					height: 150px;

                    img {
                        width: 100%;
                    }
				}
		    }
        }
	}
}