@import "../variables";

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-discussions {
		padding-left: 0;
		padding-right: 0;

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.top-row {
			margin-left: 0;
			margin-right: 0;
		}

		.admin-discussions-create-help, .bottom-create-button {
			width: 100%;
		}
	}
}