@import "../variables";

body.solar-template {
	.shipping-information {
		.shipping-information-content {
			padding-left: 0;
			padding-right: 0;

			.shipping-information-form {
				margin-top: 24px;

				.solar-checkout-breadcrumb {
					padding-right: 40px;
					padding-left: 40px;
				}

				> div.row:not(.solar-hr), .address-section, .billing-section {
					padding-right: 40px;
					padding-left: 40px;
				}

				.title-row {
					h1 {
						font-size: 32px;
						line-height: 1.25;
						letter-spacing: 0.3px;
						font-weight: 100;
					}
				}

				.shipping-method {
					padding: 16px 25px;

					h4 {
						font-size: 14px;
						font-weight: 600;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}

					label {
						display: block;
						font-size: 14px;
						line-height: 1.43;
						letter-spacing: 0.1px;
						font-weight: 100;
						margin-top: 3px;
					}

					> div {
						margin-top: 0;

						button {
							margin-top: 10px;
						}
					}
				}

				h2 {
					font-size: 24px;
					line-height: 1.17;
					letter-spacing: 0.2px;
				}

				h3 {
					font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
				}

				label {
					font-size: 14px;
					font-weight: 600;
					line-height: 1.43;
					letter-spacing: 0.1px;
					margin-bottom: 0;
				}

				.address-section {
					> div.row {
						margin-top: 10px;
					}

					.color-neutral-light-text {
						font-size: 12px;
						line-height: 1.67;
						letter-spacing: 0.1px;
						color: #606e71;
					}

					.terms-text {
						font-size: 14px;
						line-height: 1.43;
						letter-spacing: 0.1px;
					}
				}

				.button-row {
					.go-back {
						color: #657878;
					}
				}

				.recently-used-address-section {
					margin-top: 16px;
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.shipping-information {
			.shipping-information-content {
				.shipping-information-form {
					margin-top: 16px;

					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row, >div.row:not(.solar-hr), .address-section, .billing-section, .address-section>div.row {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}

					.shipping-method {
						padding: 16px;
						margin-bottom: 24px;
					}

					.billing-section, .section-header {
						margin-top: 24px;
					}

					hr {
						margin-right: -16px;
						margin-left: -16px;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.shipping-information {
			.shipping-information-content {
				.shipping-information-form {
					margin-top: 24px;

					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row, >div.row:not(.solar-hr), .address-section, .billing-section, .address-section>div.row {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}

					.shipping-method {
						padding: 16px;
						margin-bottom: 24px;
					}

					.billing-section, .section-header {
						margin-top: 24px;
					}

					.title-row {
						margin-top: 0;
					}

					.button-row {
						.go-back {
							text-align: left;
						}
					}

					hr {
						margin-right: -16px;
						margin-left: -16px;
						padding-left: 0;
						padding-right: 0;
					}					
				}
			}
		}
	}
}

/* thin desktop */
@media only screen and (min-width: $tablet-max-width) and (max-width: 1280px) {
	body.solar-template {
		.shipping-information {
			.shipping-information-content {
				.shipping-information-form {
					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row:not(.solar-hr), .address-section, .billing-section {
						padding-right: 16px;
						padding-left: 16px;
					}
				}
			}
		}
	}
}