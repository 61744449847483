@import "../variables";

#ez-selector-page{
	#prod-select-container{
		font-size: 1.2em;
		text-align: center;
		max-width: 1200px;
		margin: 0 auto;
		
		img#banner{
			margin: 10px 0;
			max-width: 100%;
		}

		p {
			text-align: left;
			padding: 15px;
		}

		ul{
			text-align: left;
			list-style-position: outside;

			>li{
				margin-left: 1em;
			}
		}

		#prod-select{
			

			.product-groups{
				@include flexbox;

				@include flexwrap(wrap);

				>div{
					cursor: pointer;
					width: 25%;
					float: left;
					padding: 5px;
					@include flexbox;

					div{
						margin: 0 auto;
					}

					img{
						max-width: 100%;
						max-height: 64px;
					}

					a{
						display: block;
						padding: 0 5px;
					}
				}
			}
		}
		
	}

	iframe {
		position: absolute;
		background: #000;
		border: none;
		top: 0; right: 0;
		bottom: 0; left: 0;
		width: 100%;
		height: 100%;
	}

}

@media only screen and (min-width: $tablet-min-width ) and (max-width: $tablet-max-width) {
	#ez-selector-page{
		#prod-select-container{
			#prod-select{

				.product-groups>div{
					width: 33%;
				}
			}
		}
	}
}

@media only screen and (max-width: $mobile-max-width){
	#ez-selector-page{
		#prod-select-container{
			#prod-select{

				.product-groups>div{
					width: 50%;
				}
			}
		}
	}
}

