@import "../variables";

#whatProfilesTemplateContent {
	padding: 10px;

	ul {
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 0;
		}
	}
}

.add-customer-user {
	padding-bottom: 50px;

	.step2 {
		margin-top: 50px;
	}

	.what-profiles {
		margin-top: 5px;
	}

	.limited-permission {
		display: none;
	}

	.additional-settings {
		margin-top: 5px;

		label {
			font-weight: normal;	
			display: block;
			padding-left: 20px;
			text-indent: -20px;

			input[type="checkbox"] {
				width: 16px;
			    height: 16px;
			    padding: 0;
			    margin: 0;
			    vertical-align: bottom;
			    position: relative;
			    top: -4px;
			}		
		}
	}

	.approval-greater-than-label {
		display: inline-block;
	}
	.approvalGreaterThanInput {
		width: 100px;
	}
	.approverErrorBlock {
		position: relative;
	}

	.additional-settings:first-of-type {
		margin-top: 0;
	}

	input[type="checkbox"].isActive {
		width: 16px;
	    height: 16px;
	    padding: 0;
	    margin: 0;
	    vertical-align: bottom;
	    position: relative;
	    top: -4px;
	}

	#user-management-catalog-table, #user-management-access-table, .user-management-location-account-access-table {
		input[type="checkbox"] {
			width: 16px;
		    height: 16px;
		    padding: 0;
		    margin: 0;
		    vertical-align: bottom;
		    position: relative;
		    top: -4px;
		}

		#user-management-catalogs-all, #user-management-access-all {
			top: -1px;
		}
	}

	.buttons {
		a {
			font-weight: bold;
    		margin-top: 12px;
    		font-size: 13px;
			line-height: 1.5;
		}
	}

	table.impersonation-table {
		thead {
			tr {
				th {
					padding-left: 8px;
				}
			}
		}
		tbody {
			tr {
				td {
					padding-left: 8px;					
				}
				td:last-of-type {
					padding-right: 8px;
				}
			}
		}
	}

	.username-row {
		display: flex;
		.username-verify {
			align-self: flex-end;
		}

		.error-message {
			bottom: -30px;
		}
	}
	.username-available {
		color: #4d9664;
		fill: #4d9664;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.add-customer-user {
		.buttons {
			input[type="submit"] {
				width: 100%;
				float: none !important;
			}

			a {
				display: block;
			}
		}

		#user-management-access-table, .user-management-location-account-access-table {
			thead {
				th {
					display: none;
				}
			}

			tbody {
				tr:first-of-type {
					border-top: 1px solid #b9bbc0;
				}
				tr {

					td:nth-of-type(1) {
						display: block;
						padding-left: 65px;
					    padding-top: 13px;
					    padding-bottom: 0;
					    position: relative;
					}

					td:nth-of-type(1):before {
						content: "Number";
					    position: absolute;
					    left: 12px;
					    font-size: 13px;
					    line-height: 1.5;
					    top: 16px;
					}

					td:nth-of-type(2) {
						display: block;
						padding-left: 65px;
					    padding-top: 0;
					    padding-bottom: 13px;
					    position: relative;
					}

					td:nth-of-type(2):before {
						content: "Name";
					    position: absolute;
					    left: 12px;
					    font-size: 13px;
					    line-height: 1.5;
					    top: 2px;
					}
				}
			}
		}

		#user-management-catalog-table {
			thead {
				th {
					display: none;
				}
			}

			tbody {
				tr:first-of-type {
					border-top: 1px solid #b9bbc0;
				}
				tr {
					td:nth-of-type(1) {
						display: block;
						padding-left: 65px;
					    padding-top: 13px;
					    padding-bottom: 0;
					    position: relative;
					}

					td:nth-of-type(1):before {
						content: "Name";
					    position: absolute;
					    left: 12px;
					    font-size: 13px;
					    line-height: 1.5;
					    top: 16px;
					}

					td:nth-of-type(2) {
						display: block;
						padding-left: 65px;
					    padding-top: 0;
					    padding-bottom: 13px;
					    position: relative;
					}

					td:nth-of-type(2):before {
						content: "# items";
					    position: absolute;
					    left: 12px;
					    font-size: 13px;
					    line-height: 1.5;
					    top: 2px;
					}
				}
			}
		}
	}
}

div.error-message {
	color: $color-error;
	font-size: 13px;
	line-height: 1.5;
	position: relative;
	top: 0px !important;
}

div.error-message.hidden {
	display: none;
}

div.error-message:not(.hidden) + label {
	color: $color-error;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
	margin-top: 12px;
}

div.error-message:not(.hidden) ~ input {
	border: solid 1px $color-error;
}