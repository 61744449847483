//use for VF styles of GreentechProjectBOM VF page using lwrGreentechProjectBOM LWC as lightning-out component
.greentech-project-bom.main-container{
    font-weight: 400;
        gap: 0px !important;
        margin: 0px !important;
        padding: 16px !important;
    a{
        color: var(--ced-color-global-brand-2) !important;
    }
    .summary-systemsize-container, .bom-search{
        margin: 12px 0px !important;
    }
    .search-input{
        height: 40px !important;
        font-size: 14px !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-color: var(--ced-color-global-neutral-4) !important;
        border-radius: 6px !important;
        padding-left: 35px;
    }
    .bom-search .product-details{
        .primary-text{
            color: var(--ced-color-global-neutral-1);
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        .secondary-text{
            font-size: 12px !important;
            font-weight: 400px !important;
            color: var(--ced-color-global-neutral-2) !important;
        }
    }
    .stock-indicator .stock-text-bom{
            font-size: 14px !important;
            font-weight: 400 !important;
    }
    .custom-product-button-title{
        font-size: 14px !important;
        color: var(--ced-color-global-neutral-1);
    }
    .primary-border{
        border-style: solid;
        border-width: 1px;
        border-color: var(--ced-color-global-neutral-4);
        border-radius: 6px;
        padding: var(--ced-spacing-large);
    }

    .summary-content label{
        font-size: 12px !important;
        font-weight: 600 !important;
    }
    .subtotal-row{
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 28px !important;
    }
    .shipping-tax-row{
        font-size: 14px !important;
        line-height: 21px !important;
    }
    
    .contact-us{
        font-size: 13px !important;
        line-height: 22px !important;
        display: flex !important;
        gap: 4px !important
    }
    .header{
        margin: none;
    }
    .showing-number{
        margin: 8px 0px 16px 0px !important;
    }
    .bulk-actions{
        display: flex !important;
        align-items: center !important;
        .record-count{
            margin-left: 0px !important;
        }
        .actions{
            margin-left: 6px !important;
            .container{
                padding: 0 !important;
                button:nth-of-type(1){
                   display:flex !important;
               }
            }
        } 
        .menu-right{
            left: 0 !important;
            min-width: 250px !important;
        }
    }
    .bom-column-headers{
        align-items: center !important;
        justify-content: flex-start !important;
        .checkbox input{
            margin: 0px !important;
            }
        p{
            flex-basis: 175px;
        }
        p:nth-of-type(1){
            flex-basis: 775px;
        }
        p:nth-of-type(3){
            padding-left: 40px;
        }
        
    }
    .checkbox input{
        height: 24px !important;
        width: 24px !important;
    }

    .select-box-wrapper{
        padding-left: 20px;
    }

    .primary {
        position: relative;
        padding: 12px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px !important;
        border-radius:6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-alpha-white-3) inset, 0px -1px 0px var(--ced-color-global-brand-1) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-brand-2);
        color: #FFFFFF;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border-width: 0;
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }
    .proceed-to-cart .primary{
        height: 40px; 
        min-width: 150px
    }

    .primary:hover {
        background-color: var(--ced-color-background-brand-hover)
    }

    .primary:focus {
        background-color: var(--ced-color-background-brand);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .primary:active {
        background-color: var(--ced-color-background-brand-focus);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .raise-icon{
        position: relative;
        bottom: 15px;
    }


    /*End Primary Button*/

    /***********Secondary Button***********/
    .secondary {
        position: relative;
        padding: 7px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px;
        border-radius: 6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-white) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-neutral-6);
        color: var(--ced-color-global-neutral-1);
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border: 1px solid var(--ced-color-border-neutral-dark);
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }

    .secondary:hover {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-border-neutral-dark);
    }

    .secondary:focus {
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .secondary:active {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }
    .text {
        position: relative;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px;
        border-radius: 6px;
        background-color: inherit;
        color: var(--ced-color-global-brand-2);
        font-weight: var(--ced-font-weight-semi-bold);
        font-size: 14px;
        line-height: 1;
        border: 1px solid inherit;
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }
    .danger{
        color: var(--ced-color-text-danger);
    }
    .quantity-value-input-only{
        height: 38px !important;
        width: 80px !important;
        border-radius: 6px !important;
    }
    .product-image{
        min-width: 40px;
    }
    .add-to-product-list button{
        font-weight: 400 !important;
        color: var(--ced-color-global-neutral-1) !important;
    }
    .remove-selection button{
        font-weight: 400 !important;
    }
   
    .proceed-to-cart .has-icon-before-centered{
        display: flex !important;
        gap: 8px !important;
        align-items: center !important;
        width: 100% !important;
        svg{
            margin-left: -12px;
        }
        .button-text{
            margin-left: -6px;
        }
    }
    .title-for-summary{
        font-size: 16px !important;
        height: 0 !important;
    }
    .close-icon{
        margin-right: 0 !important;
    }
}

.search-input-container {
    max-width: 100%;
    padding: var(--ced-spacing-medium);
    flex-grow: 1;
}

.search-input {
    width: 100%;
    display: block;
    border: none;
    height: 34px;
    position: relative;
    margin-top: 3px;
    padding-left: 38px;
    border-radius: var(--ced-border-radius-base);
    font-size: var(--ced-font-size-small);
    line-height: var(--ced-line-height-component-base);
    padding-left: 35px;
}    

.search-input-icon-container {
    position: relative;
    width:100%;
    display: block;
    height: 42px;
    background-color: var(--ced-color-global-white);
}

.search-input-icon {
    position: absolute;
    width: 24px;
    left: 12px;
    top: 12px;
}

.search-input-close {
    float: right;
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background-color: var(--ced-color-global-white);
}

.search-input:focus, .search-input:active {
    outline: 2px solid var(--ced-color-global-white);
    box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
}
.add-custom-product-modal{
    .product-label, .manufacturer-label{
        font-size: 14px !important;
    }
}
.lwr-loader__text{
    font-size:14px !important;
}

.modal-footer-styling{
    .primary{
        margin: 10px;
    }
}

@media (max-width: 1024px) {
    .greentech-project-bom.main-container{

        .bom-column-headers{
            p:nth-of-type(1){
                flex-basis: 650px;
            }
        }
        .invisible-mobile {
            display: none;
        }   
        .select-box-container {
            width: 60px;
        }     
    }
}
@media (max-width: 600px){
    .greentech-project-bom.main-container{
        .bom-column-headers{
            p:nth-of-type(2), p:nth-of-type(3){
                display: none;
            }
        }
        .product-container{
            flex-direction: column;

            .product-details-container{
                flex-basis: 0;
                .product-details{
                    padding-bottom: 0;
                }
            }
            .quantity-stock-container{
                padding-left: 50px;
                flex-basis: 0;
                .stock-indicator{
                    justify-content: flex-start;
                    padding-left: 4px;
                }
                .remove-button-container{
                    justify-content: flex-start;
                    padding-left: 50px;
                }
            }
            .ext-price-container{
                justify-content: flex-end;
                flex-basis: 0;
                padding-bottom: 16px;
            }
        }
        .summary-systemsize-container{
            width: 100%;
            max-width:600px;
        }
        .primary {
            width: 100% !important;
            margin-bottom: 10px;
        }
        .raise-icon{
            padding-left: 70px;
        }
        .invisible-mobile {
            display: none;
        }
        .select-box-container {
            width: 60px;
        }
        .proceed-to-cart .has-icon-before-centered{
            width: 100% !important;
        }
        .add-custom-product-cancel-modal .primary{
            margin-bottom: 0px;
        }
    }
}
