@import "../variables";

.admin-dashboard {
	table {
		margin-top: 15px;

		thead {
			tr {
				th {
					padding-left: 15px;
					padding-right: 15px;				
				}
			}			
		}

		tbody {
			tr {
				td {
					padding-left: 15px;
					padding-right: 15px;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			tr.empty-row {
				border-bottom: 1px solid transparent;
			}
		}
	}

	.see-all {
		font-size: 13px;
		font-weight: bold;
		line-height: 1.5;
		padding-top: 4px;
	}

	.admin-dashboard-section {
		margin-top: 35px;
	}

	.configuration-toggles {
		margin-top: 75px;
	}

	.config-toggle-title {
		margin-bottom: 15px;
	}

	.config-option {
		margin: 6px 0;
	}

	.checkbox {
		margin-top: 0;
		margin-bottom: 0;
	}

	.checkbox input[type="checkbox"] {
		opacity: 0;
	}

	.checkbox label {
		position: relative;
		display: inline-block;
		padding-left: 26px;
	}

	.checkbox label::before,
	.checkbox label::after {
		position: absolute;
		content: "";
		display: inline-block;
	}

	.checkbox label::before {
		height: 20px;
		width: 20px;
		border: 1px solid;
		left: 0px;
		top: 3px;
	}

	.checkbox label::after {
		height: 8px;
		width: 12px;
		border-left: 2px solid;
		border-bottom: 2px solid;
		transform: rotate(-45deg);
		left: 4px;
		top: 7px;
	}

	.checkbox input[type="checkbox"] + label {
		margin: 0;
	}

	.checkbox input[type="checkbox"] + label::after {
		content: none;
	}

	.checkbox input[type="checkbox"]:checked + label::after {
		content: "";
	}

	#updateConfigBtn {
		margin-top: 25px;
		text-transform: uppercase;
	}
}

.my-inbox {
	#myInboxTable {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		.downloadAll {
			margin-left: 8px;
		}

		.attachment {			
			a {
				padding-right: 16px;
				display: table-cell;
			}
			span {
				display: table-cell;
			}
		}
	}
}

/** med size **/
@media only screen and (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
	.admin-dashboard {
		.admin-dashboard-section {
			margin-top: 30px;

			.admin-dashboard-header {			
				padding-top: 15px;
				padding-left: 14px;
				padding-right: 45px;
				padding-bottom: 40px;
	    		position: relative;
	    		border: solid 1px #b9bbc0;
	    		border-bottom-width: 0;
			}

			.admin-dashboard-header::after {
				font-family: 'FontAwesome';
			    position: absolute;
			    right: 15px;
			    top: 16px;
			    content: "\f106";
			    font-size: 18px;
			}
		}

		.admin-dashboard-section.closed {
			.admin-dashboard-header {
				box-shadow: inset 0 1px 0 0 #b9bbc0;
				border: none;
			}

			.admin-dashboard-header::after {
				content: "\f107";
			}

			table {
				display: none;
			}
		}

		.admin-dashboard-section.closed + .admin-dashboard-section {
			margin-top: 0;
		}

		table {
			margin-top: 0;
			border: solid 1px #b9bbc0;

			thead {
				tr {
					box-shadow: none;
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.admin-dashboard {
		.admin-dashboard-section {
			margin-top: 30px;

			.admin-dashboard-header {			
				padding-top: 15px;
				padding-left: 14px;
				padding-right: 45px;
				padding-bottom: 40px;
	    		position: relative;
	    		border: solid 1px #b9bbc0;
	    		border-bottom-width: 0;
			}

			.admin-dashboard-header::after {
				font-family: 'FontAwesome';
			    position: absolute;
			    right: 15px;
			    top: 16px;
			    content: "\f106";
			    font-size: 18px;
			}
		}

		.admin-dashboard-section.closed {
			.admin-dashboard-header {
				box-shadow: inset 0 1px 0 0 #b9bbc0;
				border: none;
			}

			.admin-dashboard-header::after {
				content: "\f107";
			}

			table {
				display: none;
			}
		}

		.admin-dashboard-section.closed + .admin-dashboard-section {
			margin-top: 0;
		}

		table {
			margin-top: 0;
			border: solid 1px #b9bbc0;

			thead {
				tr {
					box-shadow: none;
				}
			}
		}
	}

	.admin-left-content #my-links-left-container {
		margin-top: 30px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-dashboard {
		.admin-dashboard-filter {
			margin-top: 20px;
		}

		.admin-dashboard-section {
			margin-top: 20px;
			padding-left: 0;
			padding-right: 0;

			.admin-dashboard-header {			
				padding-top: 15px;
				padding-left: 14px;
				padding-right: 45px;
				padding-bottom: 40px;
	    		position: relative;
	    		box-shadow: inset 0 1px 0 0 #b9bbc0;
			}

			.admin-dashboard-header::after {
				font-family: 'FontAwesome';
			    position: absolute;
			    right: 15px;
			    top: 16px;
			    content: "\f106";
			    font-size: 18px;
			}

			.mobile-dashboard-row {
				padding: 18px 15px 17px 15px;
				border-bottom: 1px solid #b9bbc0;				

				> div {
					font-size: 15px;
					line-height: 1.7;
					display: table;

					span {
						width: 125px;
						display: table-cell;						
					}

					div {
						display: table-cell;
					}
				}				
			}
		}

		.admin-dashboard-section.closed {
			.admin-dashboard-header {
				box-shadow: inset 0 1px 0 0 #b9bbc0;
				border: none;
			}

			.admin-dashboard-header::after {
				content: "\f107";
			}

			table, .mobile-data {
				display: none !important;
			}
		}

		.admin-dashboard-section.closed + .admin-dashboard-section {
			margin-top: 0;
		}
	}

	.admin-right-content.my-inbox {
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.buttons {
			margin-top: 16px;
		}

		#myInboxTable {
			.attachment {
				a {
					padding-right: 16px;
					display: inline;
				}
				span {
					display: inline;
				}
			}
		}
	}
}