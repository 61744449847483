@import "../variables";

.quick-order-page{
	padding-left: 95px;
	padding-right: 95px;

	.fa-times-circle{
		padding-top: 12px;
		cursor: pointer;
	}

	input[type="text"]:not(.spinner-qty){
		width: 100%;
	}

	input[type="file"] {
	    width: auto;
	    display: inline-block;
	}

	a.button-small{
		display: inline-block;
	}

	.field-error input, .field-error button{
		border: solid 1px $color-error;
	}

	.field-warn input, .field-warn button{
		border: solid 1px #bf9000;
	}

	.table-headings{
		padding: 10px 15px;
		background-color: #f4f4f4;
		box-shadow: inset 0 1px 0 0 #b9bbc0;
	}

	.quantity-column{
		padding-left: 15px;
		padding-right: 15px;
	}

	.button-row button{
		margin-bottom: 5px;
	}

}



#file-drop-box{
	margin-bottom: 30px;
	border-radius: 5px;
	
	border: solid 1px #2370B3;
}

.drag-leave{
	padding: 20px 0px;
	background-color: rgba(45, 119, 180, 0.1);
	box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, 0.1);
}

.drag-enter{
	padding: 100px 0px;

	background-color: #ffffff;
	box-shadow: 0 0 2px 0 #2370B3;
}

.mobile-field-label{
	display: none;
}

.quick-order-table .alert{
	margin-bottom: 0px;
}

.quick-order-table .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 100%;
}

.quick-order-table-panel{
	margin-top: 20px;
	margin-bottom: 30px;
}

//small and up
@media only screen and (min-width: $tablet-min-width ) {
	.quick-order-page .quantity-column{
		padding-left: 0px;
		padding-right: 0px;
	}

	body.solar-template .quick-order-solar .line-item{
		border-bottom: none;
	}

	.quick-order-solar{
		.line-item{
			border-bottom: none;
		}

		.quantity-column, .note-input-column{
			padding-left: 8px;
			padding-right: 8px;
		}

		.catalog-input-column{
			padding-right: 8px;
		}
	}
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width){

	.quick-order-page{
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media only screen and (max-width: $mobile-max-width){
	.mobile-field-label{
		display: block;
	}

	.text-xs-left {
		text-align: left;
	}

	.quick-order-page{
		padding-left: 15px;
		padding-right: 15px;
	}

	.button-row button{
		display: block;
		width: 100%;
	}

	.quick-order-table {
		border-top: solid 1px #b9bbc0;
	}

	.line-item > div, .button-row button{
		margin-bottom: 10px;
	}

	.bootstrap-select.btn-group .dropdown-menu{
		max-width: 100%;
	}

	.button-row{
		margin-top: 50px;
	}

	.quick-order-solar  .fa-times-circle{
		padding: 0px;
	}

	.line-item {
		.line-item-image {
			justify-content: left;
		}
	}
}

.quick-order-solar{

	.table-headings{
		background-color: transparent;
		box-shadow: none;
	}

	#file-drop-box{
		margin-bottom: 10px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.43;
		letter-spacing: 0.1px;
		color: #2692b5;
		padding: 34px 0;
	}
	.line-item{
		padding-bottom: 0;
	}

	.bulk-input{
		width: 100%;
		height: 144px;
	}

	.table-headings{
		padding: 10px 0px;
	}

	.quantity-column input{
		width: 100%;
		padding-right: 12px;
	}

	input[type="text"].new-row-count{
	    width: 170px;
		height: 40px;
		margin: 0;
		text-align: right;
		padding-left: 73px;
		padding-right: 8px;
	}

	.new-row-container{
		position: relative;

	}

	.new-row-container:before{
	    content: "Add Rows";
		position: absolute;
		left: 8px;
		top: 0px;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.67;
		letter-spacing: 0.1px;
	}

	.quick-order-page {
		padding-left: 15px;
		padding-right: 15px;
	}

	.field-error-msg{
		display: none;
	}

	.field-error,
	.field-warn {
		ul {
			list-style: none;
			padding-left: 0;
			margin-left: 0;
		}
	}
	.field-error .field-error-msg, 
	.field-warn .field-error-msg {
		display: block;
		font-size: 12px;
		line-height: 1.67;
		letter-spacing: 0.1px;
		
	}

	.field-error .field-error-msg{
		color: $color-error;
	}

	.field-warn .field-error-msg{
		color: #BF9000;
	}

	.field-error input, .field-error button{
		border: solid 1px $color-error;
	}

	.field-warn input{
		border: solid 1px #BF9000;
	}

	.top-error, .top-warn{
		border-radius: 2px;
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.1px;
		padding: 10px 16px 8px 8px;
	}

	.top-error{
		background-color: rgba(199, 92, 64, 0.15);
		border: solid 1px $color-error;
		color: $color-error;
	}

	.top-warn{
		background-color: rgba(191,144,0, 0.15);
		border: solid 1px #BF9000;
		color: #BF9000;
	}

	.line-item{
		padding-top: 8px;
	}

	h2{
		font-size: 32px;
		line-height: 1.25;
		letter-spacing: 0.3px;
		color: #2b3d41;
		margin-top: 24px;
		display: block;
	}

	.quick-order-table hr{
		margin: 0;
	}

	.fa-times-circle{
		color: #959ea0;
	}

	.input-column{
		padding-left: 8px;
		padding-right: 8px;
	}

	.input-column:first{
		padding-left: 15px;
	}

	.small-solar-button-secondary, .small-solar-button, .button-emphasis{
		display: inline-block;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.14;
		letter-spacing: 0.1px;
	}

	.new-row-row .small-solar-button, .button-row .small-solar-button{
		padding: 12px 18px 13px 18px;
	}

	

	.alert{
		display: inline-block;
	}

	.bulk-box{
		position: relative;
	}

	.bulk-box div{
		position: absolute;
		top: 8px;
		left: 12px;
		z-index: -1;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.2px;
		color: #959ea0;
	}

	.bulk-box textarea{
		background: transparent;
	}
}

body.solar-template .quick-order-page, body.solar-template .quote-req-page{
	.button-row .button-emphasis{
		font-size: 14px;
		font-weight: 600;
		line-height: 1.14;
		letter-spacing: 0.1px;
	}
}