@import "../variables";

.login {
	.title {
		margin-top: 30px;
		text-align: center;
		word-break: break-all;

	}

	.login-section {
		&.login-page > div{
			margin-top: 20px;
		}
		
		> div.forgot-password-field,  div.forgot-password-field + div.accept-field, > div.login-field {
			margin-top: 20px;
		}

		button, input {
			width: 100%;
		}

		.accept-field{
			input {
				width: auto;
			}

			label{
				font-weight: normal;
			}
		}

		.no-account-section {
			margin-top: 30px;
		}

		.login-error {
			font-size: 13px;
			line-height: 1.5;
			color: $color-error;
			padding: 15px;
			border-radius: 5px;
			border: solid 1px $color-error;
		}

		.forgot-username-error-icon {
			height: 20px;
			width: 20px;
			margin: 0 5px;
			fill: $color-error;
		}

		.forgot-username-success {
			font-size: 13px;
			line-height: 1.5;
			color: #4d9664;
			padding: 15px;
			border-radius: 5px;
			border: solid 1px #4d9664;
		}

		.forgot-username-success-icon {
			height: 20px;
			width: 20px;
			margin: 0 5px;
			fill: #4d9664;
		}
	}
}

.forgot-username {
	max-width: 500px;
	padding: 0 12px;
	margin: 20px auto 0 auto;
	word-break: break-all;

	.return {
		margin-bottom: 8px;
	}

	.login-section {

		.forgot-username-button {
			width: auto;
		}

		.forgot-username-input {
			width: 320px;
			margin-bottom: 10px;
		}

		.login-error {
			font-size: 13px;
			line-height: 1.5;
			color: $color-error;
			padding: 15px;
			border-radius: 5px;
			border: solid 1px $color-error;
		}

		.forgot-username-error-icon {
			height: 20px;
			width: 20px;
			margin: 0 5px;
			fill: $color-error;
		}

		.forgot-username-success {
			font-size: 13px;
			line-height: 1.5;
			color: #4d9664;
			padding: 15px;
			border-radius: 5px;
			border: solid 1px #4d9664;
		}

		.forgot-username-success-icon {
			height: 20px;
			width: 20px;
			margin: 0 5px;
			fill: #4d9664;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.login {
		div.row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}