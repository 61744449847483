@import "../variables";

.table-responsive > .fixed-column {
    position: absolute;
    display: inline-block;
    width: auto;
    border-right: 1px solid #ddd;
}

.table-responsive > .fixed-column td.cell-white {
    background-color: #fff;
    border-left: 1px solid #ddd;
}

td.cell-shading {
    background-color: #f8f8f8;
}

.mobile-table-responsive {
    margin-top: 20px;
}

.ampacity-container {
    max-width: 90%;
    overflow-x: auto;
    margin: 25px auto;

    .ampacity-text {
        margin-top: 10px;
    }

    #ampacity-table {
        td {
            text-align: center;
            padding: 0;
        }

        .size-block {
            min-width: 100px;
            vertical-align: bottom;
            font-size: 11px;
            padding-bottom: 10px;
    
            .size-label {
                text-decoration: underline;
                text-transform: uppercase;
            }
        }
    
        .types-table {
            width: 90%;
            margin: 0 auto 10px auto;

            tr {
                border-bottom: none;
            }

            td {
                line-height: 0.8em;
            }
        }

        .types-label {
            text-transform: uppercase;
            font-size: 10px;
        }

        .degrees-block {
            width: 14%;
        }
    }
}

@media(min-width:768px) {
    .table-responsive>.fixed-column {
        display: none;
    }
}

@media(max-width:767px) {
    #ampacity-table {
        .last-column {
            display: none;
        }
    }
}