@import "../variables";

.admin-sales-order-detail {
	.status-pod {
		float: left;
		margin-top: 20px;

		a {
			&.pod-button {
				display: inline-block;
				margin-left: 10px;
				text-align: right;
			}
		}
	}

	.order-footer-info {
		margin-bottom: 20px;
	}

	.buttons {
		margin-top: 18px;
		text-align: right;

		a:hover, a:active, a:focus {
			text-decoration: none;
		}

		a,
		button {
			display: inline-block;
			margin-left: 10px;
			font-size: 14px;

			&.half-button-left {
				width: none;
				float: none;
			}
		
			&.half-button-right {
				width: none;
				float: none;
			}
		}
	}

	.items-title {
		margin-top: 10px;

		h2 {
			font-size: 24px;
		}
	}

	.order-status {
		display: inline-block;
		padding: 0 10px;
		border-radius: 100px;
		border: 2px solid rgb(46, 119, 181);
		height: 32px;
		line-height: 28px;
	}

	.accordion-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.items-title {
		margin-top: 10px;
	}

	.price-col {
		text-transform: lowercase;
	}

	.relatedMemos {
		display: none;
	}

	#reorder-item-table {
		.kit-child {
			padding-left: 36px;
		}

		.kit-child-note {
			padding-left: 20px;
		}

		.kit-child-mobile {
			margin-left: 50px;
		}
	}

	.quickbooks-send,
	.quickbooks-resend {
		cursor: pointer;
		margin-right: 12px;
	}

	.notes-text {
		padding-left: 5px;
	}

	.order-details {
		padding-bottom: 15px;

		.tracking-block {
			padding: 0;
			margin: 20px 0 0 0 ;

			li {
				&.tracking {
					color: #2370B3;
				}
			}
		}
	}

	.order-details,
	.additional-info {
		padding-top: 10px;
		border-radius: 6px;
		border: 1px solid rgb(213, 216, 217);
		min-height: 151px;
		margin: 0 0 15px 0;
		line-height: 20px;

		.header {
			display: inline-block;
			font-weight: bold;
			padding-bottom: 5px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding-bottom: 3px;
			}
		}

		.summary-detail {
			.summary {
				> div {
					padding-bottom: 3px;
				}

				.value {
					padding-left: 0;
					text-align: right;
				}
			}

			.text {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.additional-info {
		border: none;
	}

	.collapsible-section {
		border-radius: 3px;
		border: 1px solid rgb(213, 216, 217);
		min-height: 40px;
		margin-bottom: 20px;

		.sp-section,
		.job-section {
			margin-top: 24px;
		}

		&.closed {
			.collapsible-header {
				background-color: #fff;

				h5 {
					color: rgb(43, 61, 65);
					font-size: 14px;
					font-weight: bold;
					height: 20px;
					letter-spacing: 0.13px;
					line-height: 20px;
				}

				&::after {
					font-family: 'FontAwesome';
					position: absolute;
					right: 30px;
					top: 10px;
					content: "\f067";
					font-size: 18px;
				}
			}
		}

		.collapsible-header {
			h5 {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: bold;
				height: 20px;
				letter-spacing: 0.13px;
				line-height: 20px;
			}

			&::after {
				font-family: 'FontAwesome';
				position: absolute;
				right: 30px;
				top: 16px;
				content: "\f068";
				font-size: 18px;
			}
		}
	}

	.order-footer-wrapper {
		padding-left: 0;

		.order-footer {
			padding-top: 10px;
			border-radius: 6px;
			border: 1px solid rgb(213, 216, 217);
			min-height: 151px;
			margin: 0;
			padding: 15px;

			.header {
				display: inline-block;
				font-weight: bold;
				padding-bottom: 5px;
			}

			.summary {
				> div {
					padding-bottom: 3px;
				}

				.value {
					padding-left: 0;
					text-align: right;
				}
			}

			.text {
				padding-right: 0;
			}
		}
	}

	.order-items {
		width: 100%;

		th, 
		td {
			border: 1px solid rgb(213, 216, 217);

			&.order {
				padding: 10px 5px;
				font-size: 14px;
				
			}
			&.show-more {
				padding: 5px;
			}
			&.kit-products {
				padding: 5px;
			}
		}

		thead { 
			tr {
				&.no-shadow {
					box-shadow: none;
				}
			}
		}

		.toggle-text, .kit-toggle-text {
			color: rgb(46, 119, 181);
			font-size: 12px;
			font-weight: normal;
			letter-spacing: 0.11px;
			line-height: 20px;
			cursor: pointer;
		}

		.show-more-link, .kit-products-link {
			display: inline-block;
			padding: 0 5px;
			margin: 0;
		}

		ul {
			list-style: none;
			margin: 10px 0 0 0;
			padding: 0;
			background: rgb(248, 249, 249);

			li {
				margin: 0;
				padding: 0 0 0 14px;
				color: rgb(96, 110, 113);
				font-size: 12px;
				font-weight: normal;
				letter-spacing: 0.11px;
				line-height: 20px;
			}
		}

		.more-text, .kit-text {
			display: none;
		}

		.kit-products-list {
			border-top: 1px solid rgb(213, 216, 217);
			padding: 5px 0;
			margin: 0 15px;
		}
	}
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.admin-right-content.admin-sales-order-detail {
		.status-pod {
			float: none;

			a {
				&.pod-button {
					display: inline-block;
					margin-left: 10px;
					text-align: right;
				}
			}
		}

		.buttons {
			margin-top: 18px;
			padding-left: 0;
			text-align: left;

			a {
				&.reorder {
					margin-left: 0;
				}
			}
		}

		.order-footer-info {
			margin-bottom: 20px;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-sales-order-detail {
		.status-pod {
			float: none;
			margin: 15px;

			a {
				&.pod-button {
					display: block;
					margin-left: 0;
					margin-top: 20px;
					text-align: center;
				}
			}
		}

		.order-details {
			margin: 15px;

			ul {
				&.ship-addr-block {
					margin-bottom: 0;
					border-bottom: none;
				}
			}

			.tracking-block {
				margin-top: 10px;
			}

			ul {
				border-bottom: 1px solid rgb(213, 216, 217);
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			> div {
				padding: 0;

				&:last-child {
					margin-bottom: 20px;
				}
			}

			.summary {
				padding: 0;
			}

			.text {
				padding-left: 15px;
			}
		}

		.additional-info {
			margin: 15px;

			ul {
				&.section {
					border-bottom: 1px solid rgb(213, 216, 217);
					padding-bottom: 20px;
					margin-bottom: 20px;
				}
			}

			> div {
				padding: 0;
			}

			.summary {
				padding: 0;
			}
		}

		.order-footer-wrapper {
			padding-left: 15px;

			.order-footer {
				margin-bottom: 20px;
				
				.summary {
					padding: 0;
				}

				.text {
					padding-right: 15px;
				}
			}
		}

		.collapsible-section {
			margin: 25px 15px 15px 15px;

			.sp-section,
			.job-section {
				margin-top: 15px;
			}
		}

		#approveButton {
			margin-left: 0;
		}

		padding-left: 0;
		padding-right: 0;

		div.row {
			padding-left: 15px;
			padding-right: 15px;
		}

		div.row.order-summary-row {
			padding-left: 0;
			padding-right: 0;

			div.row {
				> div {
					padding-left: 0;
					padding-right: 0;
				}
			}
			div.row.total-row {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.buttons {
			margin: 25px 15px 0 15px;
			text-align: left;

			a {
				width: 100%;
				text-align: center;
				margin-top: 20px;
				margin-left: 0;
				margin-bottom: 0;

				&.half-button-left {
					width: 31%;
					float: left;
				}
		
				&.half-button-right {
					width: 31%;
					float: right;
				}

				&.half-button-middle {
					width: 31%;
					margin-left: 12px;
				}
			}

			a:first-of-type {
				margin-top: 0px;
			}

			button {
				width: 100%;
				text-align: center;
				margin-top: 20px;
			}
		}

		#add-all-to-catalog-button {
			width: 100%;
			margin-top: 20px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.footable-row-detail-name {
			width: 140px;
			white-space: normal;
		}

		#reorder-item-table {
			.kit-child-note {
				padding-left: 0;
			}
		}
	}

	.part-number {
		display: none;
	}
}