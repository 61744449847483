@import "../variables";

.order-verification {
	.product-title {
	    font-size: 16px;
	    font-weight: bold;
	    line-height: 1.6;
	    padding-right: 10px;
	}

	.catalog-number {
	    margin-top: 10px;
	    word-wrap: break-word;
	    padding-right: 5px;
	}

	.line-item{
	    padding-left: 15px;
		padding-right: 15px;

		&:first-child {
			margin-top: 0;
		}

		.product-details{
			margin-top: 0px;
		}
	}

	.solar-line-price{
		text-transform: lowercase;
	}

	.price-per{
		text-transform: none;
	}

	.line-item-image img{
		max-width: 100%;
	}

	



	.order-verification-content {
		.order-verification-form {
			max-width: 1440px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 40px;

			.product-headings {
			    padding: 10px 15px;
			    background-color: #f4f4f4;
			    box-shadow: inset 0 1px 0 0 #b9bbc0;
			    margin-left: 0px;
			    margin-right: 0px;
			    margin-top: 60px;

			    font-size: 13px;
			    font-weight: bold;
			    line-height: 1.5;
			}

			> div.row.line-items{
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
			}

			.items-row {
				margin-top: 20px;

				img {
					max-width: 63px;
					max-height: 63px;
					vertical-align: top;
				}

				.product-image {
					width: 63px;
					display: inline-block;
	    			vertical-align: top;
	    			text-align: center;
				}

				.product-detail-info {
    				margin-left: 14px;
    				flex: 1;
					-webkit-box-flex: 1;
			  		-moz-box-flex:  1;
			  		-webkit-flex:  1;
			  		-ms-flex:  1;
					overflow: hidden;
					    width: 200px;

    				a {
    					font-size: 16px;
						font-weight: bold;
						line-height: 1.6;
    				}

    				div.item-number {
    					margin-top: 10px;
    					word-wrap: break-word;
    				}
				}

				.price-uom {
					white-space: nowrap;
				}
			}

			.edit-cart-row {
				margin-top: 20px;
				margin-bottom: 30px;

				.edit-cart {

					a {
						font-size: 13px;
						font-weight: bold;
						line-height: 1.5;
					}
				}
			}			

			.additional-receipts {
				margin-top: 50px;
				margin-bottom: 20px;
			}

			.button-hr {
				margin-top: 40px;

				hr {
					border-top: 1px solid #b9bbc0;
				}
			}

			.button-row {
				margin-top: 10px;

				.go-back {
					margin-top: 12px;
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
				}
			}

			.order-verification-info-block {
				padding: 30px 30px 35px 30px;
				border: solid 1px #b9bbc0;
				word-wrap: break-word;

				.heading {
					margin-top: 20px;
				}

				a {
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
					display: block;
				}
			}

			table {
				thead {
					tr {
						th {
							padding-left: 15px;
							padding-right: 15px;
						}
					}
				}

				tbody {
					tr {
						td {
							padding-left: 15px;
							padding-right: 15px;
							padding-top: 20px;
							padding-bottom: 25px;
							font-size: 16px;
							line-height: 1.6;
						}
					}
				}
			}
		}
	}
}

//small and up
@media only screen and (min-width: $tablet-min-width ) {
	.cart-page .line-item:first-child {
		border-top: none;
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.order-verification {
		.order-verification-content {

			.order-verification-form {
				margin-top: 30px;

				

				.edit-cart a, .additional-receipts, .additional-receipts-field, .button-row {
					padding-left: 15px;
					padding-right: 15px;
				}

				.order-verification-info {
					margin-left: 6px;
					margin-right: 6px;

					> div {
						padding-left: 9px;
						padding-right: 9px;
					}

					.order-verification-info-block {
						padding: 20px 15px 25px 15px;
					}
				}

				.mobile-order-summary {
					.total-row {
						margin-right: 15px;
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.order-verification {
		.order-verification-content {
			.order-verification-form {
				margin-top: 20px;

				.items-row{
					> div {
						padding-right: 0;
						padding-left: 0;
					}

					
				}

				> div.row.line-items{
					margin-left: -15px;
					margin-right: -15px;
				    margin-top: 20px;

				    .line-item{
				    	padding-left: 0;
				    	padding-right: 0;
				    	
						.lotQuoteItemsTable {
						    margin-left: 15px;
						    margin-right: 15px;
						}
					}
				}

				.mobile-verification-row {
					padding-bottom: 25px;
					padding-top: 20px;
					padding-left: 15px;
					padding-right: 15px;
					border-bottom: 1px solid #b9bbc0;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;

					.product-detail-info {
						flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
						overflow: hidden;
						    width: 200px;
					}
				}
				.mobile-verification-row:first-of-type {
					border-top: 1px solid #b9bbc0;
				}

				.edit-cart-row {
					margin-bottom: 50px;

					.edit-cart {
						margin-bottom: 30px;
					}
				}

				.order-verification-info-block {
					padding: 20px 15px 25px 15px;
					margin-bottom: 20px;
				}

				.additional-receipts, .button-hr {
					margin-top: 30px;
				}	

				.button-row {
					.go-next {
						width: 100%;
						float: none !important;
					}

					.go-back {
						margin-top: 20px;
						display: block;
					}
				}
			}
		}
	}
}