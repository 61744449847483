@import "../variables";
@import "../mixins";

.admin-profile {
	.buttons {
		a {
			margin-left: 20px;
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
			text-align: center;
		}
	}

	input[type="text"], input[type="password"] {
		width: 100%;
	}

	.non-edit {
		height: 45px;
	}

	.defaultaccountselectpicker {
		display: none;
	}

	.update-password-section-title {
		margin-top: 50px;
	}
	.savedAddresses {
		.titleSection {
			@include flexbox;
			@include flexalign(flex-end);
			@include flexwrap(wrap);

			.title {
				@include flex(1);

				button{
					font-size: 16px;
					font-weight: bold;
					margin-left: 12px;
				}
			}
			.tableKey {
				width: 100px;
				text-align: right;
			}
		}
		.editAddress {
			margin-right:8px;
		}		
	}	

	table.notification-table, #jobStatusUpdateSettings {
		margin-top: 25px;

		th:not(:first-child),
		td:not(:first-child) {
			text-align: center;
		}

		th:first-child,
		td:first-child {
			padding-left: 10px;
		}

		td.notification {
			padding-top: 5px;
			padding-bottom: 5px;
			vertical-align: middle;
		}

		.checkbox-larger {
			width: 30px;
			height: 16px;
			margin: auto;
		}
		
		.checkbox {
			margin-top: 10px;
			margin-bottom: 0;
		}

		.checkbox input[type="checkbox"] {
			opacity: 0;
		}

		.checkbox label {
			position: relative;
			display: inline-block;
		}

		.checkbox label::before,
		.checkbox label::after {
			position: absolute;
			content: "";
			display: inline-block;
		}

		.checkbox label::before {
			height: 20px;
			width: 20px;
			border: 1px solid;
			left: 0px;
			top: 0;
		}

		.checkbox label::after {
			height: 8px;
			width: 12px;
			border-left: 2px solid;
			border-bottom: 2px solid;
			transform: rotate(-45deg);
			left: 4px;
			top: 4px;
		}

		.checkbox input[type="checkbox"] + label {
			margin: 0;
		}

		.checkbox input[type="checkbox"] + label::after {
			content: none;
		}

		.checkbox input[type="checkbox"]:checked + label::after {
			content: "";
		}
	}

	.notifications-accounts {
		> div {
			padding-left: 5px;
		}
		
		.header {
			margin: 10px 0;
			color: #212121;
			background-color: #f4f4f4;
			border-bottom: 2px solid #ddd;
		}
		.acc-middle {
			margin-top: 100px;

			button {
				margin: 5px 0;
				width: 100%;
			}
		}
		select {
			width: 100%;
			height: 100%;
		}
	}
}
body.solar-template {
	.admin-profile {
		.savedAddresses {
			.titleSection {
				.title {
					a {
						font-size: 16px;
					}
				}
			}		
		}
	}
}
#editSavedAddress, #addNewSavedAddress {
	input[type="text"], select {
		width: 100%;
	}
	.checkboxLabel {
		display: inline-block;
		margin-right: 8px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-profile {
		.buttons {
			button, input[type="submit"] {
				width: 100%;
			}

			a {
				margin-top: 20px;
				display: block;
    			margin-left: 0;
			}
		}

		input[type="checkbox"]+label {
		    display: inline;
		}

		.savedAddresses {
			.titleSection {
				.tableKey {
					width: 100%;
				}
			}
		}

		.notifications-accounts {
			.acc-middle {
				margin-top: 5px;

				button {
					height: 40px;
				}
			}
		}
	}
}