@import "../variables";

body.solar-template {
	.order-confirmation {
		margin-top: 4px;
		padding-left: 0;
		padding-right: 0;

		.order-confirmation-content {

			.line-items {
					.line-item:last-of-type {
						border-bottom: none;
					}

					.line-item {
						padding: 16px 0;
						margin-left: 15px;
    					margin-right: 15px;

						.lotQuoteItemsTable {
							margin-left: 0px;
				    		margin-right: 0px;
						}

						.line-action-buttons {
							margin-top: 0;

							a {
								margin-right: 8px;
								margin-bottom: 0;
							}
						}

						.line-note-box, .line-action-buttons {
							> div {
								padding-left: 16px;
								margin-bottom: 16px;
							}
						}

						label {
							font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
						}

						.line-item-image {
							padding-right: 0;

							img {
								max-width: 100%;
								max-height: 96px;
							}
						}

						.product-details {
							padding-left: 16px;
							padding-right: 16px;
							margin-top: 0;
							margin-bottom: 16px;

							.product-name {
								font-size: 16px;
								font-weight: 600;
								line-height: 1.5;
								letter-spacing: 0.2px;
							}

							ul {
				  				list-style-type: none;
				  				margin: 0;
				  				padding: 0;

				  				li {
				  					display: block;
				  					font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									color: #606e71;
									margin: 0;
				  				}
				  			}
						}
					}
				}

			h1 {
				font-size: 32px;
				line-height: 1.25;
				letter-spacing: 0.3px;
				font-weight: 100;
			}

			.web-order-number {
				font-size: 18px;
				line-height: 1.56;
				letter-spacing: 0.2px;
			}

			#print-button {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.14;
				letter-spacing: 0.1px;
				padding: 8px 15px 8px 15px;
				border-radius: 2px;
			}

			.product-headings {				
			    background-color: #fff;
			    box-shadow: none;
			    margin-top: 24px;
			    font-size: 14px;
				font-weight: 600;
				line-height: 1.43;
				letter-spacing: 0.1px;

				> div > .row{
					margin-left: 0;
					margin-right: 0;
				}
			}

			.order-summary-row {
				h4 {
					font-size: 18px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.2px;
				}

				h6 {
					font-size: 14px;
					font-weight: 600;
					line-height: 1.43;
					letter-spacing: 0.1px;
				}

				.total-row {
					font-size: 14px;
					line-height: 1.43;
					letter-spacing: 0.1px;
				}

				.total-title {
					margin-bottom: 8px;
				}

				.order-summary-total-section {
					h6 {
						font-size: 14px;
					    line-height: 1.43;
					    letter-spacing: 0.1px;
					    font-weight: 100;
					}
				}
			}

			.order-confirmation-info {
				padding-left: 45px !important;

				.col-sm-4 {
					padding-left: 10px;
					padding-right: 10px;
				}

				.order-confirmation-info-block {
					padding: 16px;
					border-radius: 2px;

					h4 {
						font-size: 16px;
						font-weight: 600;
						line-height: 1.5;
						letter-spacing: 0.2px;
					}

					.heading {
						h6 {
							font-size: 14px;
							font-weight: 600;
							line-height: 1.43;
							letter-spacing: 0.1px;
						}
					}
				}
			}

			> div.row {
				padding-left: 40px;
    			padding-right: 40px;
    			margin-left: 0;
    			margin-right: 0;
			}

			> div.row.items-row {
				margin-top: 32px;

				table {
					thead {
						tr {
							box-shadow: none;
							background-color: #fff;

							th {
								font-size: 14px;
								font-weight: 600;
								line-height: 1.43;
								letter-spacing: 0.1px;
								padding: 10px 0px 10px 0px;
							}
						}
					}

					tbody {
						tr {
							td {
								padding: 16px 0px 16px 0px;
								font-size: 14px;
								line-height: 1.43;
								letter-spacing: 0.1px;

								h6 {
									font-size: 14px;
									font-weight: 600;
									line-height: 1.43;
									letter-spacing: 0.1px;
								}

								.product-image {
									width: 96px;

									img {
									    max-width: 96px;
										max-height: 96px;
									}
								}

								.product-detail-info {
									ul {
						  				list-style-type: none;
						  				margin: 0;
						  				padding: 0;

						  				li {
						  					display: block;
						  					font-size: 12px;
											line-height: 1.67;
											letter-spacing: 0.1px;
											color: #606e71;
											margin: 0;
						  				}
						  			}
								}
							}
						}
					}
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.order-confirmation {
			.order-confirmation-content {
				> div.row {
					margin-right: 0px;
					margin-left: 0px;
					padding-left: 0;
					padding-right: 0;
				}

				> div.row.items-row > div {
					padding-left: 15px;
    				padding-right: 15px;
				}

				.order-confirmation-info {
					padding-left: 5px !important;
					padding-right: 5px !important;
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.order-confirmation {
			.order-confirmation-content {
				> div.row {
					margin-right: 0px;
					margin-left: 0px;
					padding-left: 0;
					padding-right: 0;
				}

				.order-confirmation-info {
					padding-left: 5px !important;
					padding-right: 5px !important;
				}

				.line-items .line-item {
					margin-left: 0;
					margin-right: 0;
				}

				.solar-line, .line-action-buttons{
					margin-left: 0;
					margin-right: 0;
				}

				.items-row {
					.mobile-verification-row {
						padding: 16px;

						.product-image {
							width: 91px;

							img {
							    max-width: 91px;
								max-height: 91px;
							}
						}

						.product-detail-info {
							width: auto;
							margin-left: 7px;

							ul {
				  				list-style-type: none;
				  				margin: 0;
				  				padding: 0;

				  				li {
				  					display: block;
				  					font-size: 12px;
									line-height: 1.67;
									letter-spacing: 0.1px;
									color: #606e71;
									margin: 0;
				  				}
				  			}

				  			.item-number {
				  				margin-top: 8px;

				  				h6 {
				  					font-size: 18px;
									font-weight: 600;
									line-height: 1.56;
									letter-spacing: 0.2px;
				  				}
				  			}
						}									
					}
				}
			}
		}
	}
}

/* thin desktop */
@media only screen and (min-width: $tablet-max-width) and (max-width: 1280px) {
	body.solar-template {
		.order-confirmation {
			.order-confirmation-content {
				> div.row {
					padding-left: 16px;
	    			padding-right: 16px;
				}

				.order-confirmation-info {
					padding-left: 21px !important;
				}
			}
		}
	}
}