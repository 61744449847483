@import "../variables";

.admin-credit-memo-detail {
	.items-title {
		margin-top: 50px;
	}

	.price-col {
		text-transform: lowercase;
	}

	.print-button {
		text-decoration: none;
		cursor: pointer;
	}

	.buttons {
		a {
			display: inline-block;
			margin-right: 12px;
		}

		button {
			display: inline-block;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-credit-memo-detail {
		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.footable-row-detail-name {
			width: 140px;
			white-space: normal;
		}
	}
}