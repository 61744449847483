@import "../variables";

.vendorManagedInventoryLanding {
	.info-block {
		margin-top: 25px;
		color: rgb(43, 61, 65);

		p {
			margin-top: 10px;
			color: rgb(43, 61, 65);
		}
	}

	#warranty-nav-box{
		display: flex;
		//justify-content: space-between;
		flex-wrap: wrap;
	}

	.nav-button {
        position:relative;
        display: flex;
		max-width: 32%;
		min-width: 32%;
		margin-right: 1%;

		background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 100%);
		border: 1px solid #BBBDC6;
		box-shadow: inset 0 -1px 0 0 #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		padding: 16px;
		color: black;
		margin-bottom: 15px;

		.nav-icon{
			min-width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			border-width: 2px;
			border-style: solid;

			i {
				font-size: 1.2em;
			}
		}
		
		.nav-text {
			margin-left: 8px;
		}
        
        .overlay-disabled {
            background: rgb(244, 244, 244);
            height: 100%;
            width: 100%;
            opacity: 0.6;
            top: 0;
            left: 0;
            position: absolute;
            padding: 0;
            transition: opacity .5s;
        }
    }
    
    .navButtonHover {
        text-decoration: none;
        color: black;
        box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.75);

        .nav-icon{
            color: white;
            background: #2370B3;
        }
    }

	@media only screen and (min-width: $desktop-min-width) {
		.nav-button{
			label{
				margin-top: 3px;
			}
		}
	}

	@media only screen and (max-width: $tablet-max-width) {
		.nav-button{
			display: flex;

			.nav-icon{
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 8px;
			}
		}
	}

	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.nav-button{
			max-width: 49%;
			min-width: 49%;
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.nav-button{
			max-width: 100%;
			min-width: 100%;
		}
	}
}

.vendorManagedInventory {
	#warranty-nav-box{
		display: flex;
		//justify-content: space-between;
		flex-wrap: wrap;
	}

	.nav-button {
        position:relative;
        display: block;
		max-width: 24%;
		min-width: 24%;
		margin-right: 1%;

		background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 100%);
		border: 1px solid #BBBDC6;
		box-shadow: inset 0 -1px 0 0 #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		padding: 24px 16px;
		color: black;
		margin-bottom: 15px;

		.nav-icon{
			width:48px;
			height: 48px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			border-width: 2px;
			border-style: solid;
		}
        
        .overlay-disabled {
            background: rgb(244, 244, 244);
            height: 100%;
            width: 100%;
            opacity: 0.6;
            top: 0;
            left: 0;
            position: absolute;
            padding: 0;
            transition: opacity .5s;
        }
    }
    
    .navButtonHover {
        text-decoration: none;
        color: black;
        box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.75);

        .nav-icon{
            color: white;
            background: #2370B3;
        }
    }

	@media only screen and (min-width: $desktop-min-width) {
		.nav-button{
			label{
				margin-top: 8px;
			}
		}
	}

	@media only screen and (max-width: $tablet-max-width) {
		.nav-button{
			display: flex;

			.nav-icon{
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 8px;
			}
		}
	}

	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.nav-button{
			max-width: 49%;
			min-width: 49%;
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.nav-button{
			max-width: 100%;
			min-width: 100%;
		}
	}
}

.vmiAddToolCatalog {
	margin-left: 10px;

	.error-row {
		.alert {
			margin-bottom: 0;
		}
	}

	.cancelLink {
		font-weight: normal;
	}

	.addTool {
		margin-top: 10px;
	}

	h2 {
		color: rgb(43, 61, 65);
		font-size: 32px;
		line-height: 40px;
		font-weight: normal;
		letter-spacing: 0.23px;
	}

	h4 {
		color: rgb(43, 61, 65);
		font-size: 16px;
		margin-top: 8px;
		line-height: 24px;
		font-weight: normal;
		letter-spacing: 0.23px;
	}

	.formContent {
		margin-top: 20px;

		.required-field {
			color: $color-error;
		}

		h3 {
			color: rgb(43, 61, 65);
			font-size: 24px;
			font-weight: normal;
			letter-spacing: 0.23px;
			line-height: 28px;
			margin-left: 15px;
		}

		.catalogName,
		.emailAddress {
			margin-top: 15px;
		}

		.active,
		.emailReminder {
			margin-top: 15px;

			label {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: normal;
				letter-spacing: 0.13px;
				line-height: 20px;
			}
		}

		p {
			margin-top: 15px;
			margin-left: 15px;
			margin-right: 15px;
			color: rgb(43, 61, 65);
			font-size: 14px;
			font-weight: normal;
			letter-spacing: 0.13px;
			line-height: 20px;
		}
	}

	.createToolCatalog {
		border-radius: 0;
		font-weight: normal;
	}

	@media only screen and (max-width: $mobile-max-width) {
		h2 {
			font-size: 24px;
			line-height: 28px;
		}
	}
}
div.admin-right-content.vmiToolCheckout {
	.table-section {
		position: relative;
		margin-top: 20px;
		// max-height: 620px;

		.table-wrap-select,
		.table-wrap-verify,
		.table-wrap-confirm {
			height: 100%;
			overflow: auto;

			.admin-table {
				margin-top: 0;

				th {
					white-space: nowrap;
				}
			}
		}
	
		.shadow {
			width: 12px;
			background-image: linear-gradient(to right, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 101;
			pointer-events: none;

			&.shadow-left {
				background-image: linear-gradient(to left, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
				display: none;
				left: 0;
			}

			&.shadow-right {
				display: none;
			}
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.table-section {
			max-height: 100%;
			
			.shadow {
				display: none;
			}
		}

	}
}

div.admin-right-content.vmiToolCatalogList,
div.admin-right-content.vmiToolCatalog,
div.admin-right-content.vmiToolCheckout,
div.admin-right-content.vmiToolCatalogNewTool {
	.instruction { 
		margin: 10px 0;
		font-size: 14px;
		line-height: 18px;
	}

	.table-section {
		position: relative;
		margin-top: 20px;
		// max-height: 620px;

		.table-wrap-select {
			height: 100%;
			overflow: auto;

			.admin-table {
				margin-top: 0;

				th {
					white-space: nowrap;
				}
			}
		}
	}

	.shadow {
		width: 12px;
		background-image: linear-gradient(to right, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 101;
		pointer-events: none;

		&.shadow-left {
			background-image: linear-gradient(to left, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
			display: none;
			left: 0;
		}

		&.shadow-right {
			display: none;
		}
	}

	.run-report {
		cursor: pointer;
	}

	#toolVerification,
	#toolConfirmation {
		display: none;
	}

	#verifyToolsMsg,
	#maxLengthToolsMsg,
	#returnDateErrorMsg {
		display: none;
	}

	.addTools {
		margin-left: 5px;
	}

	.confirmationBlock {
		margin-top: 20px;

		a {
			text-decoration: none;
			color: #fff;
		}
	}

	.confirmationDetails {
		padding: 20px;
		border-radius: 3px;
		border: 1px solid rgb(213, 216, 217);
	}

	.collapsible-section.closed {
		border-bottom-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;

		.collapsible-header {
			padding: 10px;

			h5 {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.13px;
				line-height: 20px;
			}
		}

		.collapsible-header::after {
			top: 12px;
			content: "\2795";
		}
	}

	.collapsible-section {
		.collapsible-header {
			padding: 10px;

			h5 {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.13px;
				line-height: 20px;
			}
		}

		.collapsible-header::after {
			top: 12px;
			content: "\2796";
		}
	}

	.toolCatalog {
		margin-top: 15px;
		color: rgb(43, 61, 65);
		font-size: 32px;
		font-weight: normal;
		letter-spacing: 0.3px;
		line-height: 40px;
	}

	ul#toolsPagination {
		padding-left: 0;
		list-style-type: none;
		
		li {
			margin-left: 0;
			display: inline-block;
			vertical-align: top;

			a {
				display: block;
			}
		}
	}

	#numSelected {
		font-weight: normal;
	}

	.check-button,
	.add-button {
		width: 100%;
	}

	.check-button {
		color: #fff;
		background-color: #2370B3;
	}

	.browseCatalogHeader {
		margin-top: 10px;

		h1 {
			margin-bottom: 5px;
		}

		h4.sub-title {
			font-size: 14px;
			line-height: 30px;
		}

		a {
			font-weight: bold;
		}
	}

	table.admin-table {
		tr {
			border-left: 1px solid #b9bbc0;
			border-right: 1px solid #b9bbc0;
		}

		th, td {
			border-bottom: 1px solid #b9bbc0;
			border-right: 1px solid #b9bbc0;
		}

		.inactiveCatalog {
			color: rgb(149, 158, 160);
		}

		a.actionIcon {
			cursor: pointer;
			display: inline-block;
			width: 25px;
			text-align: center;
			padding: 0 10px;
		}

		td.edit-col {
			width: 50px;
		}

		.actionOn {
			background: rgb(46, 119, 181);
			border-radius: 2px;
			color: #fff;
		}
	}

	table#toolsTable,
	table#selectedToolsTable,
	table#confirmedToolsTable {
		thead {
			tr {
				th {
					padding-left: 15px;
				}

				th.add-column {
					text-align: center;
				}
			}
		}

		tbody {
			tr {
				td {
					padding-left: 15px;
					padding-right: 15px;
					padding-top: 10px;
					padding-bottom: 10px;
					white-space: nowrap;
					
					a {
						margin-left: 6px;
					}

					.related-orders-show {
						margin-left: 11px;
						cursor: pointer;
					}	

					div.tracking {
						a {
							margin-left: 0;
						}
					}				
				}	

				td.order-invoice-order-order-link-help {
					i.fa-mobile {
						width: 17px;
						font-size: 2.5em;
						vertical-align: bottom;
					}
				}			
			}

			tr.poRow, tr.jobRow {
				display: none;
			}

			tr.shown {
				border-bottom-width: 0;
			}

			tr.hasRelated {
				border-bottom: none;
			}

			tr.related-order-row-toggle {
				td {
					padding-top: 0;
    				padding-bottom: 5px;
				}
			}

			tr.related-order-row-toggle.open {
				border-bottom: none;
			}

			tr.child-row.first-child-row {	
				td {
					> div {
						border-top: 1px solid #6A6B6D;
					}
				}

				td.first-col {
					border-top: 0;

					> div {
						border-top: 1px solid #6A6B6D;						
					}
				}

				td.last-col {
					border-top: 0;

					> div {
						border-top: 1px solid #6A6B6D;
					}
				}
			}

			tr.child-row.last-child-row {
				border-bottom: 1px solid #b9bbc0;

				td {
					> div {
						border-bottom: none;
					}
				}

				td.first-col {
					> div {
						border-bottom: none;
						margin-bottom: 0;
					}
				}

				td.last-col {
					> div {
						border-bottom: none;
						margin-bottom: 0;
					}
				}
			}

			tr.child-row.open {
				display: table-row;
			}

			tr.child-row {
				border-bottom: none;
				display: none;

				td {
					padding: 0;
					vertical-align: top;

					> div {
						border-bottom: 1px solid #b9bbc0;
						padding-top: 5px;
						padding-bottom: 5px;
						padding-left: 15px;
					}

					a {
						font-weight: normal;
						margin-left: 0;
					}
				}

				td.first-col {
					border-bottom: none;
					padding: 0;

					> div {
						padding-top: 5px;
					    padding-bottom: 5px;
					    margin-left: 38px;				    
					    border-bottom: 1px solid #b9bbc0;
					}
				}

				td.last-col {
					padding: 0;
					border-bottom: none;

					> div {
						margin-right: 15px;
						padding-top: 5px;
					    padding-bottom: 5px;
					    border-bottom: 1px solid #b9bbc0;
					    margin-bottom: 0px;
					    padding-left: 15px;
					}
				}
			}
		}
	}

	#edit-modal {
		h2 {
			color: rgb(43, 61, 65);
			font-size: 24px;
			font-weight: normal;
			letter-spacing: 0.23px;
			line-height: 28px;
		}

		#catalog-error {
			color: $color-error;
		}
	}

	.formContent {
		.notifications {
			margin-top: 20px;
		}

		.required-field {
			color: $color-error;
		}

		h3 {
			color: rgb(43, 61, 65);
			font-size: 18px;
			font-weight: normal;
			letter-spacing: 0.23px;
			line-height: 28px;
			margin-left: 15px;
		}

		.catalogName,
		.emailAddress {
			margin-top: 15px;
		}

		.active,
		.emailReminder {
			margin-top: 15px;

			label {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: normal;
				letter-spacing: 0.13px;
				line-height: 20px;
			}
		}

		p {
			margin-top: 15px;
			margin-left: 15px;
			margin-right: 15px;
			color: rgb(43, 61, 65);
			font-size: 14px;
			font-weight: normal;
			letter-spacing: 0.13px;
			line-height: 20px;
		}
	}

	.filters {
		label {
			font-size: 14px;
		}
	}

	.goback-link {
		text-align: right;
	}

	.return-vmi-button {
		margin-top: 0;
		text-align: center;
	}

	@media only screen and (max-width: $mobile-max-width) {
		.table-section {
			max-height: 100%;
			
			.shadow {
				display: none;
			}
		}

		.goback-link {
			text-align: left;
		}

		.return-vmi-button {
			margin-top: 20px;
			width: 100%;
		}

		#edit-modal {
			.modal-header,
			.modal-footer {
				padding-top: 0;
			}
		}

		.formContent {
			.emailAddress,
			.active,
			.emailReminder {
				margin-top: 0;
			}

			p {
				margin-top: 0;
			}
		}

		table.admin-table {
			tr {
				border-left-width: 0;
				border-right-width: 0;
			}
	
			th, td {
				border-bottom-width: 0;
				border-right-width: 0;
			}
		}

		.emptyCatalogs {
			text-align: center;
		}
	
		table#toolsTable,
		table#selectedToolsTable,
		table#confirmedToolsTable {
			margin-top: 20px;

			thead {
				th {
					display: none;
					white-space: normal;
				}
			}

			tbody {
				border-top: 1px solid #b9bbc0;

				tr {
					td {
						white-space: normal;

						a {
							margin-left: 0;
						}
					}
				}

				tr.row-toggle {
					cursor: pointer;
					border: none;
				}

				tr.main-row {
					padding-bottom: 20px;
					display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

					td:nth-of-type(1):before { content: "Image"; }
					td:nth-of-type(2):before { content: "Catalog Number"; }
					td:nth-of-type(3):before { content: "Mfr Code"; }
					td:nth-of-type(4):before { content: "Asset Number"; }
					td:nth-of-type(5):before { content: "Description"; }
					td:nth-of-type(6):before { content: "Last Checkout"; }
					td:nth-of-type(7):before { 
						content: "Status"; 
						top: 4px;
					}
					td:nth-of-type(8):before { content: "Available"; }
					td:nth-of-type(9):before { content: "Add"; }
				}

				tr.overdue-row {
					padding-bottom: 20px;
					display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

					td:nth-of-type(1):before { content: "Customer Name"; }
					td:nth-of-type(2):before { content: "Account #"; }
					td:nth-of-type(3):before { content: "Catalog Name"; }
					td:nth-of-type(4):before { content: "Mfr Code"; }
					td:nth-of-type(5):before { content: "Catalog #"; }
					td:nth-of-type(6):before { content: "Description"; }
					td:nth-of-type(7):before { content: "Asset #"; }
					td:nth-of-type(8):before { content: "Last Checkout"; }
					td:nth-of-type(9):before { content: "Last Checkout By"; }
					td:nth-of-type(10):before { content: "Est. Return Date"; }
				}

				tr.available-row {
					padding-bottom: 20px;
					display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

					td:nth-of-type(1):before { content: "Customer Name"; }
					td:nth-of-type(2):before { content: "Account #"; }
					td:nth-of-type(3):before { content: "Catalog Name"; }
					td:nth-of-type(4):before { content: "Mfr Code"; }
					td:nth-of-type(5):before { content: "Catalog #"; }
					td:nth-of-type(6):before { content: "Description"; }
					td:nth-of-type(7):before { content: "Asset #"; }
					td:nth-of-type(8):before { content: "Bin Location"; }
					td:nth-of-type(9):before { content: "Status"; }
				}

				tr.maintenance-row {
					padding-bottom: 20px;
					display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

					td:nth-of-type(1):before { content: "Customer Name"; }
					td:nth-of-type(2):before { content: "Account #"; }
					td:nth-of-type(3):before { content: "Catalog Name"; }
					td:nth-of-type(4):before { content: "Mfr Code"; }
					td:nth-of-type(5):before { content: "Catalog #"; }
					td:nth-of-type(6):before { content: "Description"; }
					td:nth-of-type(7):before { content: "Asset #"; }
					td:nth-of-type(8):before { content: "Maintenance Type"; }
					td:nth-of-type(9):before { content: "Last Checkout"; }
					td:nth-of-type(10):before { content: "Last Check-in"; }
					td:nth-of-type(11):before { content: "Est. Return Date"; }
					td:nth-of-type(12):before { content: "Status"; }
				}

				.related-order-row-toggle {
					padding-bottom: 10px;
					display: block;
				}

				.related-orders-show {
					margin-left: 0;
				}

				tr.child-row.open {
					display: block;

					td {
						display: block;
						padding: 0px;
						position: relative;	

						br {
							display: none;
						}

						div {
							margin-left: 15px;
							padding: 0;
							border: none;

							span {
								padding-left: 125px;

								a {
									font-weight: bold;
								}
							}

							div {
								padding-left: 125px;
								margin-left: 0;
							}
						}
																	
					}

					td.first-col {
						div {	
							padding: 10px 0 0 0;						
							margin-right: 15px;
							border-top: 1px solid #b9bbc0;
							border-bottom: none;
						}
					}

					td.last-col {
						> div {
							padding: 0 0 10px 0;
							border-bottom: none;
						}
					}

					td.last-col:before {
						top: 4px;
					}

					td:before {
						position: absolute;
						left: 15px;
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;	
					}

					td:nth-of-type(1):before { content: "Order #"; }
					td:nth-of-type(5):before { content: "Order Date"; }
					td:nth-of-type(7):before { content: "Status"; }
				}

				tr.child-row.open.noPrice {
					td:nth-of-type(6):before { content: "Status"; }
				}

				tr.child-row.first-child-row {
					td {
						border: none;
					}

					td.last-col {
						> div {
							border-top: none;
						}
					}
				}

				tr.footable-row-detail {
					div.footable-row-detail-value {
						width: 100%;
					}
				}
			}
		}

		#toolsPagination {
			.page-selector {
				width: auto;
			}
		}
	}
}

div.admin-right-content.vmiTool {
	.admin-title-back {
		margin-top: 10px;
	}

	.formContent {
		margin-top: 20px;

		.required-field {
			color: $color-error;
		}

		.statusActive {
			font-weight: normal;
		}

		.toolDetails {
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.maintenance,
		.transactionDetails {
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.emailReminder {
			font-weight: normal;
		}

		.buttonRow {
			.cancelLink {
				font-weight: bold;
			}

			.button-emphasis {
				padding: 12px;
				border-radius: 2px;
			}
		}

		#file-drop-box {
			display: block;
			margin-top: 20px;
			margin-bottom: 10px;
			color: rgb(46, 119, 181);
			border: 2px solid rgb(46, 119, 181);
			height: 162px;
			border-radius: 2px;
			line-height: 125px;
			font-weight: bold;
		}

		.photo-upload-control {
			#photo-upload {
				width: 0.1px;
				height: 0.1px;
				opacity: 0;
				overflow: hidden;
				position: absolute;
				z-index: -1;
			}

			label {
				cursor: pointer;
				display: inline-block;
				font-weight: normal;
	
				span {
					color: rgb(46, 119, 181);
					font-weight: bold;
				}
			}
		}

		.image-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 3px;
			border: 1px solid rgb(213, 216, 217);
			height: 110px;
			width: 323px;
			padding: 8px;

			.tool-image{
				max-width: 94px;
				max-height: 94px;
			}
		}
	}

	@media only screen and (min-width: $desktop-min-width) {
		.formContent .image-container{
			width: 323px;
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.formContent {
			.status {
				margin-bottom: 10px;
			}

			#file-drop-box {
				display: none;
			}

			.photo-upload-control {
				text-align: center;
				margin: 15px 0;
				padding: 5px;
				border-radius: 3px;
				border: 1px solid rgb(46, 119, 181);

				#photo-upload {
					width: 0.1px;
					height: 0.1px;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					z-index: -1;
				}
	
				label {
					cursor: pointer;
					display: inline-block;
					font-weight: normal;
					color: rgb(46, 119, 181);
					padding-top: 5px;
				}
			}
		}
	}
}