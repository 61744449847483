@import "../variables";

.greentechProjectIntakeOverview {
    .button:not(.disabled),
    .button-emphasis,
    .button-emphasis-small {
        margin: 5px;
    }

    .incomplete-field, {
        stroke: $status-danger-1;
        margin-top: 2px;
        padding-top: 2px;
    }
    
    .incomplete-section-error {
        color: red
    }

    .loading-overlay {
        position: absolute;
        padding-top:12rem;
        text-align: center;
        left: 0;
        background-color: rgba(255,255,255,0.7);
        color: #000;
        height: 100%;
        width: 100%;
        z-index: 999;
        font-weight:bold;
        display: none;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }
    
        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    .heading-small {
        color: rgb(33, 33, 33);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15px;
        line-height: 24px;
    }

    .admin-right-content {
        margin-bottom: 30px;
        
        .title-heading {
            margin-bottom: 11px;

            > .intake-go-back {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;
            }
        }
    }

    .overview-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }

    .admin-right-content {
        margin-bottom: 30px;
        width: 100%;

        .title-heading-line-1 {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }
    
        .title-heading-line-2 {
            font-size: 24px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        .service-order-submit-button {
            width: 210px;
            height: 40px;
            border-radius: 0px;

            font-size: 14px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.13px;
            line-height: 16px;
        }

        ol.section-list {
            > li::marker {
                color: rgb(33, 33, 33);
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.17px;
                line-height: 28px;
            }
        }

        .service-order-intake-section {
            max-width: 525px;
            padding-left: 8px;

            h3.section-header {
                display: inline;
                margin-bottom: 8px;
                margin-right: 16px;
                color: rgb(33, 33, 33);
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.17px;
                line-height: 28px;
            }

            .service-order-intake-subsection {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-radius: 0;
                padding-top: 0;
                padding-bottom: 10px;

                > div {
                    margin-top: 10px;
                }

                .subsection-title {
                    margin-right: 16px;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.13px;
                    line-height: 20px;

                    .subsection-link {
                        display: inline;
                    }
                    .read-only {
                        display: none;
                    }
                }

                .subsection-status {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;

                    -webkit-flex-pack: center;
                    -webkit-justify-content: center;
                    -moz-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    -webkit-flex-line-pack: center;
                    -ms-flex-line-pack: center;
                    -webkit-align-content: center;
                    align-content: center;

                    -webkit-flex-direction: row;
                    -moz-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;

                    border: none;
                    border-radius: 12px;
                    padding: 5px 6px;

                    &::after {
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 14px;
                    }

                    svg.status-icon {
                        display: none;
                        margin-right: 5px;

                        &.complete {
                            fill: $status-success-2;
                        }

                        &.incomplete {
                            stroke: $status-danger-1;
                        }
                    }

                    &.complete {
                        background-color: $status-success-3;
                        &::after {
                            content: "Complete";
                        }
                        svg.status-icon.complete {
                            display: inline;
                        }
                    }

                    &.incomplete {
                        background-color: $status-danger-3;
                        &::after {
                            content: "Incomplete";
                        }
                        svg.status-icon.incomplete {
                            display: inline;
                        }
                    }

                    &.in-progress {
                        background-color: $status-warning-3;
                        &::after {
                            content: "In Progress";
                        }
                    }

                    &.not-started {
                        &::after {
                            content: "Not Started";
                        }
                    }

                    &.cant-start {
                        &::after {
                            content: "Can't Start Yet";
                        }

                        + .subsection-title {
                            .subsection-link {
                                display: none;
                            }
                            .read-only {
                                display: inline;
                            }
                        }
                    }
                }

                .subsection-summary {
                    padding: 8px;
                    margin-top: 10px;
                    border-radius: 3px;
                    cursor: pointer;

                    .summary-header {
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;

                        -webkit-flex-line-pack: center;
                        -ms-flex-line-pack: center;
                        -webkit-align-content: center;
                        align-content: center;

                        -webkit-flex-direction: row;
                        -moz-flex-direction: row;
                        -ms-flex-direction: row;
                        flex-direction: row;

                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                    }

                    .collapsed-icon,
                    .expanded-icon {
                        margin-right: 7px;
                    }

                    .collapsed-icon {
                        display: none;
                    }

                    .expanded-icon {
                        display: inline;
                    }

                    .summary-block {
                        > div:not(:last-of-type) {
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                            border-bottom: 1px #B9BBC0 solid;
                        }
                    }

                    .summary-group {
                        margin-top: 6px;
                        margin-bottom: 16px;
                        display: block;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .summary-group-heading {
                            color: rgb(33, 33, 33);
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0.15px;
                            line-height: 24px;
                        }

                        .summary-field {
                            margin-top: 4px;

                            .summary-field-label,
                            .summary-field-value {
                                display: inline-block;
                                vertical-align: top;
                                font-size: 14px;
                                letter-spacing: 0.13px;
                                line-height: 20px;
                                overflow-wrap: break-word;
                                hyphens: auto;
                            }

                            .summary-field-label {
                                color: rgb(33, 33, 33);
                                font-weight: bold;
                                margin-right: 4px;
                            }

                            .summary-field-value {
                                color: rgb(43, 61, 65);
                                font-weight: normal;
                            }
                        }
                    }

                    &.collapsed {
                        .collapsed-icon {
                            display: inline;
                        }

                        .expanded-icon {
                            display: none;
                        }

                        .summary-group {
                            display: none;
                        }
                    }
                }
            }
        }
        .terms-and-conditions-error, .residential-permit-package-error {
            color: rgb(182, 79, 53);
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.11px;
            line-height: 20px;
        }

        .checkboxes-error-message, .incomplete-error-message {
            background: rgba(242, 49, 1, 0.15);
            border-radius: 3px;
            border: 1px solid rgb(242, 49, 1);
            height: 40px;
            padding: 10px;
            color: rgb(33, 33, 33);
            margin-bottom: 5px;
            height: auto;

            .fa-exclamation-circle {
                color: rgb(242, 49, 1);
            }
        }

        .read-only-message {
            background: rgba(191, 144, 0, 0.15);
            border-radius: 3px;
            border: 1px solid rgb(191, 144, 0);
            height: 40px;
            padding: 10px;
            color: rgb(33, 33, 33);
            margin-bottom: 5px;
            height: auto;

            .fa-exclamation-circle {
                color: rgb(191, 144, 0);
            }
        }

        

        .residential-error, .terms-error {
            padding-left: 15px;
        }

        input[type=checkbox]+label {
            display: inline;
            margin: 0 0 0 10px;
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentechProjectIntakeOverview {
        .admin-right-content {
            .service-order-intake-section {
                max-width: 100%;
            }
        }

        .breadcrumbs {
            padding-left: 0px;
        }

        .overview-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentechProjectIntakeOverview {
        .breadcrumbs {
            padding-left: 0px;
        }

        .title-heading {
            padding: 0 15px;
        }

        .home-left-content {
            margin-left: 5px;
        }

        .subsection-status {
            display: block !important;
            float: none !important;
            height: 24px;
            width: 120px;
            padding: 2px 6px !important;
        }

        .admin-right-content {

            .title-heading-line-2 {
                font-size: 18px;
                padding-bottom: 0;
            }

            ol.section-list {
                list-style-type: none;
                padding-left: 0;
                counter-reset: css-counter 0;

                li {
                    counter-increment: css-counter 1;

                    .service-order-intake-section {
                        max-width: 100%;

                        .section-header:before {
                            content: counter(css-counter) ". ";
                        }
                    }
                }
            }
        }
    }
}
