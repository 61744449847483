body.solar-template {
	.customer-storage-list-page,
	.customer-shipping-memo-request-page {
		/* !!! Added by thunder team */
		c-lwr-dropdown-button {
			&.csl-hide {
				visibility: hidden;
				pointer-events: none;
			}

			.container {
				button {
					label {
						margin: 0;
						line-height: var(--ced-line-height-body);
					}

					c-lwr-icons {
						align-items: center;
					}
				}

				.menu {
					width: auto;
					padding: var(--ced-spacing-medium);

					c-lwr-icon-button {
						button {
							span.label {
								font-family: "Inter", sans-serif;
								font-variation-settings: normal;
								text-align: left;
								color: var(--ced-color-text);
								font-weight: var(--ced-font-weight-normal);
								font-size: var(--ced-font-size-small);
								padding: 0;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 300px) and (max-width: 600px) {
			.search-filter-container {
				display: flex;
				flex-wrap: wrap;
				width: auto;
			}

			.dropdown-element {
				/*padding-left: var(--ced-spacing-medium);*/
				bottom: 8px;
				width: 100%;
			}

			.pill-filters-container {
				/* padding: var(--ced-spacing-small);*/
			}

			.pill-container {
				padding: var(--ced-spacing-xxsmall);
			}

			// !!! Commented out for thunder team
			/* .dropdown-element:nth-child(3) {
				display: none;
			}

			.dropdown-element:nth-child(2) {
				display: none;
			} */
		}

		// !!! Added by thunder team
		c-lwr-search-filters {
			margin-bottom: var(--ced-spacing-large);
		}

		.note-dropdown {
			.container {
				padding: 0;
			}
		}

		.note-column {
			.menu {
				display: none;
			}
		}

		c-lwr-checkbox {
			.label {
				margin: 0;
				padding: var(--ced-spacing-xsmall) var(--ced-spacing-small);
				line-height: var(--ced-line-height-body-small);
			}
		}

		.input {
			height: 40px;
			border-width: var(--ced-border-width);
			border-style: solid;
			border-color: var(--ced-color-global-neutral-3);
			border-radius: var(--ced-border-radius-base);
			box-shadow: var(--ced-shadow-inset-input);
			font-size: var(--ced-font-size-small);
		}

		.textarea-field {
			border-width: var(--ced-border-width);
			border-style: solid;
			border-color: var(--ced-color-global-neutral-3);
			border-radius: var(--ced-border-radius-base);
			box-shadow: var(--ced-shadow-inset-input);
			font-size: var(--ced-font-size-small);
		}

		.search-filter-container {
			display: flex;
			background-color: var(--ced-color-global-neutral-6);
			/*border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);*/
			border-radius: var(--ced-border-radius-large);
			align-items: center;
		}

		.search-filter-container-bottom-border {
			border-radius: var(--ced-border-radius-large) var(--ced-border-radius-large)
				var(--ced-border-radius-none) var(--ced-border-radius-none);
			border-bottom: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
		}

		.pill-filters-container.pills {
			padding: var(--ced-spacing-xsmall);
		}

		.search-input-container {
			width: 100%;
			padding: var(--ced-spacing-medium);
			display: flex; // !!! Added by thunder team
		}

		// !!! Changed significantly by thunder team
		.search-input {
			// width: 90%;
			// display: inline-block;
			border: none;
			height: 34px;
			// position: relative;
			// bottom: 23px;
			// left: 38px;

			// border-radius: var(--ced-border-radius-base);
			font-size: var(--ced-font-size-small);
			font-family: "Inter", sans-serif;
			font-variation-settings: normal;
			text-align: left;
			color: var(--ced-color-text);
			font-weight: var(--ced-font-weight-normal);
			padding: var(--ced-spacing-small);
			flex-grow: 1;
		}

		.search-input-icon-container {
			width: 100%;
			display: inline-block;
			border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
			border-radius: var(--ced-border-radius-base);
			box-shadow: var(--ced-shadow-inset-input);
			height: 40px;
			background-color: var(--ced-color-global-white);
			display: flex;
			justify-content: flex-start;
			align-items: center;

			// !!! Removed some styles by thunder team
		}

		// !!! All changed by thunder team
		.search-input-icon {
			justify-content: center;
			align-items: center;
			padding: var(--ced-spacing-small);
		}

		.search-input-close {
			float: right;
			position: relative;
			// bottom: 16px; 		// !!! Commented out by thunder team

			right: 8px;
		}

		.search-input:focus,
		.search-input:active {
			outline: 2px solid var(--ced-color-global-white);
			box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
		}

		.pill-container {
			display: inline-block;
			padding: var(--ced-spacing-xxsmall);
		}

		// !!! All added by thunder team
		.pill-button {
			align-items: center;

			label {
				font-size: var(--ced-font-size-small);
				font-weight: var(--ced-font-weight-semi-bold);
				color: var(--ced-color-global-neutral-1);

				margin-bottom: 0;
			}
		}

		.pill-filters-container {
			display: flex;
			flex-wrap: wrap;
		}

		.all-filter-header {
			font-size: var(--ced-font-size-medium);
			font-weight: var(--ced-font-bold);
		}

		.all-filter-header-container {
			display: block;
			border-bottom: 1px solid var(--ced-color-global-neutral-4);
			padding: 0px;
			height: 39px;
			width: 100%;
			margin-bottom: var(--ced-spacing-medium);
		}

		.search-filter-close-icon {
			position: relative;
			bottom: 28px;
			left: 209px;
		}

		.all-filter-container {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: var(--ced-spacing-xsmall);
		}

		// !!! Modified significantly by thunder team
		.dropdown-element {
			position: relative;
			margin: 0;
			padding: var(--ced-spacing-medium);
			flex-shrink: 0;

			.container {
				padding: 0;

				.secondary {
					height: 40px;

					c-lwr-icons {
						align-items: center;
						height: 100%;
					}
				}
			}
		}

		.filter-drawer-button {
			width: 200px;
			margin-left: 7%;
			margin-right: 7%;
		}

		.filter-drawer-button-container {
			margin-top: var(--ced-spacing-medium);
		}

		.search-filter-line {
			position: relative;
			top: 1px;
			margin: 0px;
		}

		.search-filter-border {
			border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
			border-radius: var(--ced-border-radius-large);
		}

		.dropdown-element-border {
			display: flex;
			flex-wrap: wrap;
			border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
			border-radius: var(--ced-border-radius-small);
		}

		.all-filters-button {
			width: 200px;
		}

		.search-filter-all-filter-body {
			display: inline-block;
			width: 100%;
			margin-bottom: var(--ced-spacing-medium);
		}

		.clear-filters-button {
			font-family: var(--ced-font-family-bold);
			font-weight: var(--ced-font-weight-bold);
			font-size: var(--ced-font-size-small);
			letter-spacing: 0.3px;
			line-height: var(--ced-line-height-component-base);
			color: var(--ced-color-text-brand);
			padding: var(--ced-spacing-xsmall) 0;
			background: var(--ced-color-global-alpha-transparent);
			border: var(--ced-border-radius-none);
			float: right;
			white-space: nowrap;
		}

		.clear-filters-button:hover,
		.clear-filters-button:active {
			text-decoration: underline;
			text-decoration-thickness: var(--ced-text-link-underline-size);
			text-underline-offset: var(--ced-text-link-underline-size);
		}

		.clear-filters-button:focus {
			outline-offset: var(--ced-text-focus-indicator-size);
			outline-width: var(--ced-text-focus-indicator-size);
			outline-color: var(--ced-color-focus-indicator);
			outline-style: solid;
		}

		.results-filter-section {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.results-found-text {
			flex-grow: 1;
		}
	}

	.customer-shipping-memo-request-page {
		select.select {
			width: auto;
			box-shadow: none;
			height: 40px;
		}

		c-lwr-dropdown-button {
			.container {
				button {
					align-items: center;
				}
			}
		}

		.table-row {
			c-lwr-button {
				label {
					margin: 0;
					white-space: nowrap;
				}
			}

			.quantity-indicator-col {
				c-lwr-quantity-indicator {
					.info {
						display: none;
					}
				}

				c-lwr-button {
					label {
						color: var(--ced-color-global-danger-2);
					}
				}
			}
		}

		.submit-request-button {
			label {
				margin: 0;
				color: var(--ced-color-global-white);
				font-weight: var(--ced-font-weight-normal);
			}
		}
	}
}
