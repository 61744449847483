@import "../variables";
@import "../mixins";

body.solar-template {

    &.mobile-menu-open {
        overflow: hidden;
    }

    a.tour-step-backdrop {
        background-color: #fff;
    }

    header {
        background-color: #fafafa;
        border-bottom: 1px solid #c6c7c8;

        .greentech-content-section {
            max-width: 1440px;
            margin: auto;

            .search {
                flex: 1;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -webkit-flex: 1;
                -ms-flex: 1;

                #search-input,
                #mobile-search-input {
                    padding-left: 12px;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.2px;
                    width: 100%;
                    z-index: 3;
                    border-radius: 5px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;

                    &::placeholder {
                        color: #959ea0;
                    }
                }

                .search-input-container {
                    display: table-cell;
                    vertical-align: middle;
                    position: relative;
                    min-width: 40px;

                    #search-input-icon,
                    #mobile-search-input-icon {
                        position: absolute;
                        width: 36px;
                        height: 35px;
                        right: 2px;
                        top: 23px;
                        color: #959ea0;
                        background-color: #fff;
                        cursor: pointer;

                        i {
                            padding-top: 10px;
                            padding-left: 12px;
                        }
                    }
                }

                #search-box {
                    display: table;
                    width: 100%;
                    position: relative;
                    height: 80px;
                    padding-left: 48px;
                    padding-right: 48px;
                }

                #mobile-search-box {
                    display: table;
                    width: 100%;
                    position: relative;
                    height: 80px;
                }
            }
        }

        .greentech-header-top {
            height: 40px;
            color: black;
            background-color: #ededee;
            padding-right: 16px;
            border-bottom: 1px solid #c6c7c8;

            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.11px;
            line-height: 40px;

            a, a:hover {
                color: black;
            }

            .change-site {
                padding-left: 24px;

                .my-store {
                    font-weight: bold;
                }
            }

            .contact-us {
                padding-right: 24px;
            }

            #changeSiteTab{
                position: absolute;
                right: 0;
                top: 3px;
                width: 200px;
                background: white;
                color: black;
                height: 29px;
                padding-left: 6px;
                padding-top: 7px;
                z-index: 2;

                &::before{
                    border-radius: 8px 0 0 0;
                    left: -17px;
                    -webkit-transform: skew(-25deg, 0deg);
                    transform: skew(-25deg, 0deg);
                    display: block;
                    content: " ";
                    position: absolute;
                    top: 0;
                    height: 101%;
                    width: 25px;
                    background-color: #FFF;
                    z-index: 1;
                }

                a{
                    font-size: 13px;
                    color: rgba(43, 61, 65, 0.75);
                    font-weight: bold;
                }
            }
        }

        .greentech-header-middle {
            padding-left: 24px;
            padding-right: 24px;

            .greentech-content-section {
                height: 80px;
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                align-items: center;
                -ms-flex-align: center;

                .header-menu {
                    display: none;
                }

                > a {
                    &.greentech-register,
                    &.greentech-sign-in {
                        -moz-appearance: button;
                        -ms-appearance: button;
                        -o-appearance: button;
                        -webkit-appearance: button;
                        appearance: button;
                        padding: 11px 14px 11px 14px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1.5;
                        border-radius: 5px;
                        margin-right: 4px;
                        white-space: nowrap;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &.greentech-register {
                        border: none;
                        background: transparent;
                        color: #1c821c;

                        &:hover {
                            background-color: #efefef;
                        }
                    }

                    &.greentech-sign-in {
                        background-color: #1c821c;
                        color: #fff;
                    }
                }

                .header-image {

                    img.ced-logo {
                        max-width: 192px;
                        max-height: 40px;
                    }
                }

                .account {
                    .menuHeader {
                        padding: 2px 8px 0px 8px;

                        > button {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 14px;
                            letter-spacing: 0.1px;
                            position: relative;
                            cursor: pointer;
                            color: black;
                        }
                    }
                }

                .product-lists,
                .account,
                .cart,
                .mobile-menu {
                    a {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 0;
                        letter-spacing: 0.1px;
                        position: relative;
                        cursor: pointer;
                        color: black;
                    }

                    a:hover {
                        text-decoration: none;
                    }

                    .mobile-submenu-content {
                        .mobile-menu-container {
                            .mobile-menu-nav {
                                > ul {
                                    &.show {
                                        > li {
                                            &.greentechManufacturers {
                                                > a {
                                                    &.nav-item-link {
                                                        padding: 12px 0;
                                                    }
                                                }
                                            }
        
                                            &.navItem {
                                                padding: 8px 0;
                                                a {
                                                    font-weight: normal;
                                                    line-height: 34px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product-lists-link,
                    .account-link,
                    .cart-link,
                    .mobile-menu-link {
                        display: block;
                        text-align: center;
                        padding-top: 32px;
                        padding-bottom: 4px;
                    }
                }

                .product-lists {
                    width: 120px;
                    height: 48px;
                    margin-right: 4px;

                    .product-lists-link {
                        height: 100%;
                        
                        svg {
                            position: absolute;
                            left: 51px;
                            top: 4px;
                        }
                    }
                }

                .account {
                    margin-right: 4px;
                }

                .cart {
                    width: 64px;
                    height: 48px;

                    .cart-link {
                        height: 100%;

                        svg {
                            position: absolute;
                            left: 21px;
                            top: 4px;
                        }

                        .cart-count {
                            width: 20px;
                            height: 20px;
                            line-height: 18px;
                            border-radius: 50%;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            background: #1c821c;
                            position: absolute;
                            left: 38px;
                            top: -6px;
                        }
                    }
                }

                .mobile-menu {
                    display: none;

                    width: 64px;
                    height: 48px;

                    .mobile-menu-link {
                        svg {
                            position: absolute;
                            left: 20px;
                            top: 4px;
                        }
                    }

                    .mobile-menu-overlay {
                        z-index: 2000000010;
                        position: fixed;
                        display: none;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                    }

                    .mobile-menu-content,
                    .mobile-submenu-content {
                        position: fixed;
                        display: none;
                        top: 0;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        padding-left: 56px;
                        background: rgba(0, 0, 0, 0);

                        .mobile-menu-container {
                            background-color: #fff;
                            width: 100%;
                            height: 100%;
                            overflow: auto;

                            .mobile-menu-header {
                                height: 73px;
                                padding-left: 16px;
                                padding-right: 12px;
                                position: sticky;
                                top: 0;
                                border-bottom: 1px solid #CECFCD;
                                background-color: inherit;
                                opacity: 1;

                                display: flex;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: -webkit-flex;
                                align-items: center;
                                -ms-flex-align: center;

                                .profile-photo {
                                    display: block;
                                    border-radius: 50%;
                                    width: 40px;
                                    height: 40px;
                                    background-color: black;
                                    margin-right: 8px;
                                    background-color: transparent;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }

                                .profile-detail {
                                    flex: 1;
                                    -webkit-box-flex: 1;
                                    -moz-box-flex: 1;
                                    -webkit-flex: 1;
                                    -ms-flex: 1;
                                    min-width: 0;

                                    .guest-title {
                                        font-size: 14px;
                                        color: black;
                                        margin-top: 2px;
                                        margin-bottom: 2px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        display: block;
                                        text-overflow: ellipsis;
                                    }

                                    .guest-summary {
                                        font-size: 16px;
                                        font-weight: bold;
                                        color: black;
                                        margin-top: 2px;
                                        margin-bottom: 2px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        display: block;
                                        text-overflow: ellipsis;
                                    }

                                    .profile-title {
                                        font-size: 16px;
                                        font-weight: bold;
                                        color: black;
                                        margin-top: 2px;
                                        margin-bottom: 2px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        display: block;
                                        text-overflow: ellipsis;
                                    }

                                    .profile-summary {
                                        font-size: 14px;
                                        color: #6D6E6C;
                                        margin-top: 2px;
                                        margin-bottom: 2px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        display: block;
                                        text-overflow: ellipsis;
                                    }
                                }

                                .header-content {
                                    flex: 1;
                                    -webkit-box-flex: 1;
                                    -moz-box-flex: 1;
                                    -webkit-flex: 1;
                                    -ms-flex: 1;
                                    min-width: 0;
                                    height: 100%;

                                    .submenu-back {
                                        font-size: 16px;
                                        color: #212121;
                                        letter-spacing: 0.3px;
                                        line-height: 16px;
                                        font-weight: 700;
                                        text-align: left;
                                        text-decoration: none;

                                        display: flex;
                                        align-items: center;
                                        height: 100%;

                                        svg {
                                            margin-right: 4px;
                                        }
                                    }
                                }

                                .mobile-menu-close {
                                    width: 56px;
                                    height: 48px;
                                    background-color: rgba(33, 33, 33, 0.07);
                                    border-radius: 5px;
                                    margin-left: 8px;

                                    a {
                                        font-size: 14px;
                                        font-weight: 600;
                                        line-height: 0;
                                        letter-spacing: 0.1px;
                                        position: relative;
                                        cursor: pointer;
                                        color: black;
                                    }

                                    a:hover {
                                        text-decoration: none;
                                    }

                                    .mobile-menu-close-link {
                                        display: block;
                                        text-align: center;
                                        padding-top: 36px;
                                        padding-bottom: 6px;

                                        svg {
                                            position: absolute;
                                            left: 16px;
                                            top: 6px;
                                        }
                                    }
                                }
                            }

                            h1 {
                                display: block;
                                padding: 0 16px;
                                margin: 16px 0 8px 0;
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 28px;
                                color: #212121;
                            }

                            .mobile-menu-top-tasks {
                                padding: 8px 16px;
                                background-color: #FAFAFA;
                                border-bottom: 1px solid #CECFCD;

                                > ul {
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;

                                    > li {
                                        list-style: none;

                                        &:not(:last-child) {
                                            border-bottom: 1px solid #CECFCD;
                                        }

                                        a.nav-item-link {
                                            display: flex;
                                            align-items: center;
                                            text-decoration: none;
                                            font-weight: 400;
                                            padding: 16px 0;

                                            div.nav-item-icon {
                                                margin-right: 8px;
                                            }

                                            div.nav-item-title {
                                                line-height: 24px;
                                            }
                                        }
                                    }
                                }

                                a {
                                    &.greentech-register,
                                    &.greentech-sign-in {
                                        -moz-appearance: button;
                                        -ms-appearance: button;
                                        -o-appearance: button;
                                        -webkit-appearance: button;
                                        appearance: button;
                                        padding: 12px 14px;
                                        font-size: 16px;
                                        font-weight: 700;
                                        line-height: 1.5;
                                        border-radius: 6px;
                                        white-space: nowrap;
                                        width: 100%;
                                        display: block;
                                        text-align: center;

                                        &:hover, &:focus {
                                            text-decoration: none;
                                        }
                                    }

                                    &.greentech-register {
                                        background: transparent;
                                        border: 1px solid #8E908D;
                                        color: black;

                                        &:hover {
                                            background-color: #efefef;
                                        }
                                    }

                                    &.greentech-sign-in {
                                        background-color: #1c821c;
                                        color: #fff;
                                        margin-bottom: 12px;
                                    }
                                }
                            }

                            .mobile-menu-nav {
                                padding: 0 16px;

                                > ul {
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;

                                    > li {
                                        list-style: none;

                                        &:not(:last-child):not(.nav-item-header) {
                                            border-bottom: 1px solid #CECFCD;
                                        }

                                        &.nav-item-header {
                                            text-align: left;
                                            margin-top: 16px;
                                            margin-left: 0;
                                            padding: 0 0 8px 0;
                                            line-height: 24px;
                                            font-weight: 700;
                                            font-size: 16px;
                                            color: #212121;
                                        }

                                        a.nav-item-link {
                                            display: flex;
                                            align-items: center;
                                            text-decoration: none;
                                            font-weight: 400;
                                            padding: 16px 0;

                                            div.nav-item-icon {
                                                margin-right: 8px;
                                            }

                                            div.nav-item-title {
                                                flex: 1;
                                                min-width: 0;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                line-height: 24px;
                                            }

                                            div.nav-item-sub-arrow {
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            .mobile-menu-actions {
                                padding: 0 16px 16px 16px;

                                > ul,
                                > form > ul {
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;

                                    > li {
                                        list-style: none;
                                        border-top: 1px solid #CECFCD;

                                        a.nav-item-link {
                                            display: flex;
                                            align-items: center;
                                            text-decoration: none;
                                            font-weight: 600;
                                            padding: 16px 0;

                                            div.nav-item-icon {
                                                margin-right: 8px;
                                            }

                                            div.nav-item-title {
                                                flex: 1;
                                                min-width: 0;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                line-height: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .mobile-menu-content {
                        z-index: 2000000020;

                        .mobile-menu-header {
                            z-index: 2000000021;
                        }
                    }

                    .mobile-submenu-content {
                        z-index: 2000000030;

                        .mobile-menu-header {
                            z-index: 2000000031;
                        }
                    }

                    &.open {
                        > .mobile-menu-overlay {
                            display: block;
                        }

                        > .mobile-menu-content {
                            display: block;
                            -webkit-animation: tablet-menu-slide-open .25s forwards;
                            animation: tablet-menu-slide-open .25s forwards;

                            li.mobile-submenu-link {

                                &.open {
                                    > .mobile-submenu-content {
                                        display: block;
                                        -webkit-animation: tablet-menu-slide-open .25s forwards;
                                        animation: tablet-menu-slide-open .25s forwards;
                                    }
                                }
                            }
                        }

                        @-webkit-keyframes mobile-menu-slide-open {
                            0% {
                                left: 100%;
                            }
                            100% {
                                left: 0;
                            }
                        }

                        @keyframes mobile-menu-slide-open {
                            0% {
                                left: 100%;
                            }
                            100% {
                                left: 0;
                            }
                        }

                        @-webkit-keyframes tablet-menu-slide-open {
                            0% {
                                right: -360px;
                            }
                            100% {
                                right: 0px;
                            }
                        }

                        @keyframes tablet-menu-slide-open {
                            0% {
                                right: -360px;
                            }
                            100% {
                                right: 0px;
                            }
                        }
                    }
                }
            }
        }

        .greentech-header-bottom {
            padding-left: 24px;
            padding-right: 24px;

            .greentech-content-section {
                height: 56px;
                @include flexbox;
                @include flexwrap(nowrap);

                align-items: center;
                -ms-flex-align: center;

                ul.headerFlexMenu {
                    @include flex(1);
                    height: 46px;

                    .flexMenu-viewMore > a:after {
                        font-family: 'FontAwesome';
                        position: relative;
                        right: -5px;
                        top: -3px;
                        content: "\f078";
                        font-size: 0.8em;
                        color: #2b3d41;
                    }
                    .flexMenu-popup {
                        border-top: 1px solid #dde1e2;
                        border-right: 1px solid #dde1e2;
                        border-left: 1px solid #dde1e2;
                        z-index: 2;
                        background-color: #fff;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        width: 250px;

                        > li {
                            float: none;
                            display: block;

                            a {
                                display: block;
                                padding: 10px 20px;
                                text-align: left;
                            }
                        }
                    }
                    .flexMenu-popup > li > a:hover {
                        text-decoration: underline;
                    }
                }

                > ul {
                    min-height: 50px;
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    > li {
                        display: inline-block;

                        > a {
                            display: block;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.5;
                            letter-spacing: 0.2px;
                            padding: 15px 16px;
                        }

                        > button {
                            padding: 11px 14px 11px 14px;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.5;
                            border-radius: 5px;
                        }

                        button {
                            border: none;
                            background: transparent;
                        }

                        > a:hover {
                            text-decoration: none;
                        }

                        .button-emphasis{
                            position: relative;
                            top: -9px;
                            padding: 5px 12px;
                        }
                    }

                    /*New dropdown menu*/
                    li.dropdown-container{
                        position: relative;


                        > button:after {
                            font-family: 'FontAwesome';
                            position: relative;
                            right: -5px;
                            top: -3px;
                            content: "\f078";
                            font-size: 0.8em;
                        }

                        &.open{

                            > button {
                                background: #ededee;
                                border-bottom: 4px solid #1c821c;
                            }

                            > button:after {
                                content: "\f077";
                            }

                            #dropdownSub {
                                display: flex;

                            }
                        }

                        &.open-full{

                            #dropdownSub {
                                > ul{
                                    border-radius: 10px 0 0 10px;
                                    border-right: 1px #d5d8d9 solid;
                                }

                                #categorySubMenu{
                                    display: block;
                                }

                            }
                        }

                        /*The dropdown itself*/
                        #dropdownSub {
                            display: none;
                            position: absolute;
                            z-index: 2;
                            background-color: #fff;
                            margin: 0;
                            border: 1px #d5d8d9 solid;
                            border-radius: 10px;
                            top: 55px;
                            left: -8px;



                            > ul{
                                list-style-type: none;
                                margin: 0;
                                padding: 16px 18px;
                                width: 288px;
                                border-radius: 10px;

                                > li.children{
                                    border-top: 1px transparent solid;
                                }

                                > li.children:not(.open):not(:hover) + li.children:not(.open):not(:hover) {
                                    border-top: 1px #d5d8d9 solid;
                                }
                            }

                            #categorySubMenu{
                                width: 558px;
                                padding: 16px 28px;
                                display: none;

                                div {
                                    border-bottom: 1px #d5d8d9 solid;
                                    font-size: 18px;
                                    font-weight: bold;
                                }

                                div::after {
                                        font-family: 'FontAwesome';
                                        position: relative;
                                        right: -5px;
                                        top: -3px;
                                        content: "\f178";
                                        font-size: 0.8em;
                                }

                                ul {
                                    list-style-type: none;
                                    padding: 0;
                                }

                                li {
                                    margin-top: 16px;
                                }

                                a {
                                    padding: 0;
                                }
                            }

                            button, a{
                                text-align: left;
                                padding: 10px 10px;
                                width: 100%;
                            }

                            a {
                                display: block;
                            }

                            button {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-radius: 5px;

                                i {
                                    display: none;
                                }
                            }

                            /*Flyout menu*/
                            .children ul {
                                display: none;
                                position: absolute;
                                list-style-type: none;
                                padding: 0;
                                margin: 0;
                                left: 248px;
                                background-color: #fff;
                                top: -1px;
                                width: 260px;
                            }
                        }

                        button img {
                            max-width: 24px;
                            max-height: 24px;
                            margin-right: 5px;
                        }

                    }

                    > li.dropdown {
                        position: relative;

                        a img {
                            max-width: 24px;
                            max-height: 24px;
                            margin-right: 5px;
                        }

                        > a {
                            padding-right: 19px;
                        }

                        > ul.dropdown-sub {
                            display: none;
                            position: absolute;
                            z-index: 2;
                            background-color: #fff;
                            margin: 0;
                            padding: 0;
                            list-style-type: none;
                            width: 260px;

                            > li {
                                position: relative;

                                > form > a {
                                    display: block;
                                    padding: 10px 20px;
                                }

                                > a {
                                    display: block;
                                    padding: 10px 20px;
                                }
                                > ul {
                                    display: none;
                                    position: absolute;
                                    list-style-type: none;
                                    padding: 0;
                                    margin: 0;
                                    left: 248px;
                                    background-color: #fff;
                                    top: -1px;
                                    width: 260px;

                                    li {
                                        border-width: 0;

                                        a {
                                            display: block;
                                            padding: 4px 12px;
                                        }

                                        a:hover {
                                            background-color: #fff;
                                        }
                                    }

                                    li:first-child {
                                        a {
                                            padding: 14px 12px 4px 12px;
                                        }
                                    }

                                    li:last-child {
                                        a {
                                            padding: 4px 12px 14px 12px;
                                        }
                                    }
                                }
                            }

                            > li.children:after {
                                font-family: 'FontAwesome';
                                position: absolute;
                                right: 10px;
                                top: 13px;
                                content: "\f054";
                                font-size: 0.8em;
                            }

                            > li:hover {
                                > ul {
                                    display: block;
                                }
                            }
                        }

                        > a:after {
                            font-family: 'FontAwesome';
                            position: relative;
                            right: -5px;
                            top: -3px;
                            content: "\f0dd";
                            font-size: 0.8em;
                            color: #2b3d41;
                        }
                    }

                    > li.dropdown:hover {
                        > ul.dropdown-sub {
                            display: block;
                        }
                    }
                }

                .pay-invoices,
                .barcode-scan {
                    a {
                        -moz-appearance: button;
                        -ms-appearance: button;
                        -o-appearance: button;
                        -webkit-appearance: button;
                        appearance: button;
                        padding: 11px 14px 11px 14px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1.5;
                        border-radius: 5px;
                        margin-right: 4px;
                        white-space: nowrap;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .pay-invoices {
                    a {
                        border: none;
                        background: transparent;
                        color: #000;

                        &:hover {
                            background-color: #efefef;
                        }
                    }
                }

                .barcode-scan {
                    display: none;

                    margin-left: 16px;

                    a {
                        border: 1px solid #959ea0;
                        background: transparent;
                        color: #000;

                        &:hover {
                            background-color: #efefef;
                        }
                    }
                }

                .search {
                    display: none;
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    body.solar-template {

        .autocomplete-suggestions {
            width: 720px !important;
            transform: translate(-52%, 0);
            left: 50%;
            position: fixed !important;
            top: 84px;
        }

        header {
            .greentech-header-middle {
                .greentech-content-section {

                    .account,
                    .search {
                        display: none;
                    }

                    .header-menu {
                        display: block;
                    }

                    .header-image {
                        flex: 1;
                        -webkit-box-flex: 1;
                        -moz-box-flex: 1;
                        -webkit-flex: 1;
                        -ms-flex: 1;
                    }

                    .cart {
                        margin-right: 4px;
                    }

                    .mobile-menu {
                        .mobile-menu-content,
                        .mobile-submenu-content {
                            width: 360px;
                            height: 100%;
                            padding-left: auto;
                        }

                        &.open {
                            > .mobile-menu-content {
                                display: block;
                                -webkit-animation: tablet-menu-slide-open .25s forwards;
                                animation: tablet-menu-slide-open .25s forwards;

                                li.mobile-submenu-link {

                                    &.open {
                                        > .mobile-submenu-content {
                                            display: block;
                                            -webkit-animation: tablet-menu-slide-open .25s forwards;
                                            animation: tablet-menu-slide-open .25s forwards;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .greentech-header-bottom {
                padding-bottom: 8px;

                .greentech-content-section {
                    .pay-invoices,
                    .greentech-nav-menu {
                        display: none;
                    }

                    .search,
                    .barcode-scan {
                        display: block;
                    }
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    body.solar-template {
        header {
            .greentech-header-top {
                .contact-us {
                    display: none;
                }
            }

            .greentech-header-middle {
                .greentech-content-section {
                    .account,
                    .search,
                    .product-lists,
                    .greentech-register,
                    .greentech-sign-in {
                        display: none;
                    }

                    .header-menu {
                        display: block;
                    }

                    .header-image {
                        flex: 1;
                        -webkit-box-flex: 1;
                        -moz-box-flex: 1;
                        -webkit-flex: 1;
                        -ms-flex: 1;
                    }

                    .cart {
                        margin-right: 4px;
                    }

                    .mobile-menu {
                        .mobile-menu-content,
                        .mobile-submenu-content {
                            width: 100%;
                            height: 100%;
                            padding-left: 56px;
                        }

                        &.open {
                            > .mobile-menu-content {
                                display: block;
                                -webkit-animation: mobile-menu-slide-open .25s forwards;
                                animation: mobile-menu-slide-open .25s forwards;

                                li.mobile-submenu-link {

                                    &.open {
                                        > .mobile-submenu-content {
                                            display: block;
                                            -webkit-animation: mobile-menu-slide-open .25s forwards;
                                            animation: mobile-menu-slide-open .25s forwards;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .greentech-header-bottom {
                padding-bottom: 8px;

                .greentech-content-section {
                    .pay-invoices,
                    .greentech-nav-menu {
                        display: none;
                    }

                    .search,
                    .barcode-scan {
                        display: block;
                    }
                }
            }
        }
    }
}

.greentech-nav-menu {
    list-style: none;
    margin: 8px;
    padding: 0;

    >li {
        list-style: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }
}

.dropdown {
    display: inline-block;
    .menuHeader{
        display: inline-block;
        width: auto;
        border-radius: 6px;
        margin: 0 4px;
        padding: 12px 8px 0 8px;
        button {
            background: none;
            border: none;
            color: rgba(33,33,33,1);
            font: inherit;
            font-size:16px;
            line-height: 16px;
        }
    }

    &.collapsed{
        .menuHeader{
            background-color: rgba(33,33,33, 0);
            span[id$="expandIcon"],
            .expandIcon {
                display: inline;
            }
            span[id$="collapseIcon"],
            .collapseIcon {
                display: none;
            }
            #bottomBorder{
                display: none;
            }
            .bottom-border {
                display: block;
                background-color: rgba(76,156,76, 1);
                height: 4px;
                width: auto;
                margin-top: 2px;
                border-radius: 2px;
                visibility: hidden;
            }
        }
        .menu{
            position: absolute;
            z-index: 9999;
            width: 0;

            .banner,
            #navList{
                display: none;
            }
        }
    }

    &.expanded{
        .menuHeader{
            background-color: rgba(33,33,33, 0.07);
            span[id$="expandIcon"],
            .expandIcon {
                display: none;
            }
            span[id$="collapseIcon"],
            .collapseIcon {
                display: inline;
            }
            #bottomBorder {
                display: block;
                background-color: rgba(76,156,76, 1);
                height: 4px;
                width: auto;
                margin-top: 8px;
                border-radius: 2px;
            }
            .bottom-border {
                display: block;
                background-color: rgba(76,156,76, 1);
                height: 4px;
                width: auto;
                margin-top: 2px;
                border-radius: 2px;
            }
            .bottom-border {
                visibility: visible;
            }
        }

        .menu{
            position: absolute;
            z-index: 9999;
            min-width: 256px;
            margin-top: 4px;
            padding: 8px;
            border-style: round;
            border-radius: 6px;
            border: 1px solid rgba(206,207,205, 1);
            opacity: 1;
            background-color: rgba(255,255,255, 1);
            box-shadow: 0 0 8px 3px #f4f4f4;
			white-space: nowrap;

            .banner {
                height: 73px;
                padding-left: 5px;
                padding-right: 5px;
                top: 0;
                border-bottom: 1px solid #CECFCD;

                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                align-items: center;
                -ms-flex-align: center;

                .profile-photo {
                    display: block;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                .profile-detail {
                    flex: 1;
                    -webkit-box-flex: 1;
                    -moz-box-flex: 1;
                    -webkit-flex: 1;
                    -ms-flex: 1;
                    min-width: 0;

                    .profile-title {
                        font-size: 16px;
                        font-weight: bold;
                        color: black;
                        margin-top: 2px;
                        margin-bottom: 2px;
                        white-space: nowrap;
                        overflow: hidden;
                        display: block;
                        text-overflow: ellipsis;
                    }

                    .profile-summary {
                        font-size: 14px;
                        color: #6D6E6C;
                        margin-top: 2px;
                        margin-bottom: 2px;
                        white-space: nowrap;
                        overflow: hidden;
                        display: block;
                        text-overflow: ellipsis;
                    }
                }
            }

            #navList{
                display: inline;
                width: auto;
                list-style: none;
                text-align: left;
                padding-inline-start: 0;
                .navItem{
                    .navItemLink {
                        display: flex;
                        color: rgba(33,33,33,1);
                        line-height: 24px;
                        font-weight: 400;
                    }
                    text-align: left;
                    margin-left: 0;
                    padding: 5px;
                    line-height:24px;
                    font-weight: 400;
                    justify-content: center;
                    a[id$="itemLink"]{
                        font-size: 16px;
                        padding-left: 8px;
                        padding-right: 4px;
                        text-decoration: none;
                    }

                    .navLabel {
                        margin-left: 8px;

                        &.navBold {
                            font-weight: bold;
                        }
                    }

                    &.header {
                        font-weight: bold;
                    }
                }

                li {
                    .navItemSeparator {
                        margin: 8px;
                    }
                }
            }
        }
    }
}

.account {
    .greentech-nav-menu {
        .dropdown.expanded {
            .menu {
                right: 0;
                left: auto;
            }
        }
    }
}

#user-management-access-location-account {

    .panel-group .panel + .panel {
        margin-top: 20px;
    }

    .location-accounts-panel {
        padding: 10px 15px;
    }

    .location-accounts-heading {

        font-weight: bold;

        a.location-accounts-toggle {
            text-decoration: none;
            color: black;
            display: block;
            width: 100%;

            &.collapsed {
                .collapse-icon {
                    display: none;
                }
                .expand-icon {
                    display: inline;
                }
            }

            &:not(.collapsed) {
                .collapse-icon {
                    display: inline;
                }
                .expand-icon {
                    display: none;
                }
            }
        }
    }
}

