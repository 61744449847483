@import "../variables";

div.admin-right-content.admin-orders-invoices table.admin-table tbody tr td a {
	font-weight: bold;
}

#main-content-overlay {
	background-color: rgba(255,255,255,0.7);
	.loadingOrdersMessage {
	    text-align: center;
	    position: fixed;
	    width: 100%;
	    top: 200px;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    padding-top: 200px;
	    font-weight: 600;
	}
}

#orderExportModal, #jobStatusExportModal, #jobExportModal, #inventoryExportModal, #toolCatalogExportModal {
	.exclude-include-buttons {
		margin-top: 40px;

		button {
			width: 100%;
			padding: 0;
			margin-bottom: 5px;

			i {
				font-size: 20px;
			}
		}
	}

	#excluded-fields, #included-fields,
	#line-excluded-fields, #line-included-fields {
		padding: 0;
	}

	hr {
		border-top-color: #b9bbc0;
		margin-top: 20px;
		margin-bottom: 0;
	}

	#field-list-required-error, #format-error, #selected-field-error, #line-format-error, #modal-error {
		color: $color-error;
	}

	#field-list-delete,
	#line-field-list-delete {
		font-weight: 100;
	    color: $color-error;
	    font-size: 13px;
	    position: absolute;
	    top: 5px;
	    right: 15px;
	}
}

.admin-orders-invoices {	
	.filters {
		.buttons {
			button {
				width: 100%;
			}

			input[type="text"] {
				visibility: hidden;
			}
		}
	}

	.order-icons {
		margin-top: 17px;

		.orderIcon {
			margin-right: 8px;
			white-space: nowrap;
		}

		i {
			margin-left: 15px;
			margin-top: 13px;
		}
		i:first-of-type {
			margin-left: 0;
		}		
	}

	.pod-button {
		cursor: pointer;
	}
	
	ul#orders-pagination {
		padding-left: 0;
		list-style-type: none;
		
		li {
			margin-left: 0;
			display: inline-block;
			vertical-align: top;

			a {
				display: block;
			}
		}
	}

	.invoices-table {
		tbody {
			tr {
				td {
					a {
						margin-left: 0;						
					}

					button.invoice-button {
						margin-right: 15px;
					}
				}
			}
		}
	}

	table#adminOrdersTable, table.invoices-table {
		thead {
			tr {
				th {
					padding-left: 15px;
				}
			}
		}

		tbody {
			tr {
				td {
					padding-left: 15px;
					padding-top: 10px;
					padding-bottom: 10px;
					white-space: nowrap;
					
					a {
						font-weight: bold;
						margin-left: 6px;
					}

					.related-orders-show {
						margin-left: 11px;
						cursor: pointer;
					}	

					div.tracking {
						a {
							margin-left: 0;
						}
					}				
				}	

				td.order-invoice-order-order-link-help {
					i.fa-mobile {
						width: 17px;
						font-size: 2.5em;
						vertical-align: bottom;
					}
				}			
			}

			tr.poRow, tr.jobRow {
				display: none;
			}

			tr.shown {
				border-bottom-width: 0;
			}

			tr.hasRelated {
				border-bottom: none;
			}

			tr.related-order-row-toggle {
				td {
					padding-top: 0;
    				padding-bottom: 5px;
				}
			}

			tr.related-order-row-toggle.open {
				border-bottom: none;
			}

			tr.child-row.first-child-row {	
				td {
					> div {
						border-top: 1px solid #6A6B6D;
					}
				}

				td.first-col {
					border-top: 0;

					> div {
						border-top: 1px solid #6A6B6D;						
					}
				}

				td.last-col {
					border-top: 0;

					> div {
						border-top: 1px solid #6A6B6D;
					}
				}
			}

			tr.child-row.last-child-row {
				border-bottom: 1px solid #b9bbc0;

				td {
					> div {
						border-bottom: none;
					}
				}

				td.first-col {
					> div {
						border-bottom: none;
						margin-bottom: 0;
					}
				}

				td.last-col {
					> div {
						border-bottom: none;
						margin-bottom: 0;
					}
				}
			}

			tr.child-row.open {
				display: table-row;
			}

			tr.child-row {
				border-bottom: none;
				display: none;

				td {
					padding: 0;
					vertical-align: top;

					> div {
						border-bottom: 1px solid #b9bbc0;
						padding-top: 5px;
						padding-bottom: 5px;
						padding-left: 15px;
					}

					a {
						font-weight: 100;
						margin-left: 0;
					}
				}

				td.first-col {
					border-bottom: none;
					padding: 0;

					> div {
						padding-top: 5px;
					    padding-bottom: 5px;
					    margin-left: 38px;				    
					    border-bottom: 1px solid #b9bbc0;
					}
				}

				td.last-col {
					padding: 0;
					border-bottom: none;

					> div {
						margin-right: 15px;
						padding-top: 5px;
					    padding-bottom: 5px;
					    border-bottom: 1px solid #b9bbc0;
					    margin-bottom: 0px;
					    padding-left: 15px;
					}
				}
			}
		}
	}	

	.table-section {
		position: relative;
		margin-top: 20px;

		.table-wrap-select {
			height: 100%;
			overflow: auto;

			.admin-table {
				margin-top: 0;

				th {
					white-space: nowrap;
				}
			}
		}
	
		.shadow {
			width: 12px;
			background-image: linear-gradient(to right, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 101;
			pointer-events: none;

			&.shadow-left {
				background-image: linear-gradient(to left, rgba(140, 141, 143, 0), rgba(140, 141, 143, 0.15) 36%, rgba(140, 141, 143, 0.5));
				display: none;
				left: 0;
			}

			&.shadow-right {
				display: none;
			}
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.table-section {
			max-height: 100%;
			
			.shadow {
				display: none;
			}
		}

	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.admin-orders-invoices {
		#adminOrdersTable {
			thead {
				th#poHeader, th#jobHeader {
					display: none;
				}
			}

			tbody {
				tr {
					td {
						.related-orders-show {
							margin-left: 42px;
							padding-top: 5px;
						}
					}
				}

				td.webOrderColumn, td.poColumn, td.jobColumn, td.relatedPo, td.relatedJob {
					display: none;
				}

				tr.poRow, tr.jobRow {
					display: none;
				}

				tr.poRow.open, tr.jobRow.open {
					display: table-row;
				}

				tr.sub-row, tr.main-row.open {
					border-bottom-width: 0;
				}

				tr.main-row {
					td {
						i.sub-row-toggle {						
						    margin-right: 10px;
	    					font-size: 20px;
	    					cursor: pointer;
	    				}
					}					
				}

				

				tr.sub-row {
					td {
						padding-top: 0;
						padding-bottom: 0;
					}
				}

				tr.sub-row.last-row {
					border-bottom-width: 1px;
				}

				.sub-header {
					margin-left: 54px;
					width: 96px;
					display: inline-block;
				}

				tr.child-row {
					td.first-col {
						> div {
							margin-left: 69px;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-orders-invoices {
		padding-left: 0;
		padding-right: 0;

		a.billTrustButton {
			margin-left: 15px;
		    margin-right: 15px;
		    margin-top: 8px;
		    display: block;
		    float: none !important;
		    text-align: center;
		}

		h1, .order-icons {
			padding-left: 15px;
		}

		ul.nav-tabs {
			margin-left: 15px;
			margin-right: 15px;
		}

		hr {
			border-top-color: #b9bbc0;
			margin-top: 20px;
			margin-bottom: 0;
		}

		.collapsible-section {
			.collapsible-content {
				padding-bottom: 0;
			}
		}

		#adminOrdersTable {
			thead {
				th {
					display: none;
				}
			}

			tbody {
				border-top: 1px solid #b9bbc0;

				tr.main-row {
					padding-top: 15px;
				    padding-bottom: 20px;
				    display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 0px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

					td:nth-of-type(1):before { content: "Order #"; }
					td:nth-of-type(2):before { content: "Web Order #"; }
					td:nth-of-type(3):before { content: "Customer PO"; }
					td:nth-of-type(4):before { content: "Job Name"; }
					td:nth-of-type(5):before { content: "Account #"; }
					td:nth-of-type(6):before { content: "Order Date"; }
					td:nth-of-type(7):before { 
						content: "Status"; 
						top: 4px;
					}
				}

				tr.main-row.show-prices {
					td:nth-of-type(1):before { content: "Order #"; }
					td:nth-of-type(2):before { content: "Web Order #"; }
					td:nth-of-type(3):before { content: "Customer PO"; }
					td:nth-of-type(4):before { content: "Job Name"; }
					td:nth-of-type(5):before { content: "Account #"; }
					td:nth-of-type(6):before { content: "Order Date"; }
					td:nth-of-type(7):before { content: "Total"; }
					td:nth-of-type(8):before { 
						content: "Status"; 
						top: 4px;
					}
				}

				tr.main-row.hasRelated {
					padding-bottom: 10px;
				}

				.related-order-row-toggle {
					padding-bottom: 10px;
    				display: block;
				}

				.related-orders-show {
					margin-left: 0;
				}

				tr.child-row.open {
					display: block;

					td {
						display: block;
						padding: 0px;
						position: relative;	

						br {
							display: none;
						}

						div {
							margin-left: 15px;
							padding: 0;
							border: none;

							span {
								padding-left: 125px;

								a {
									font-weight: bold;
								}
							}

							div {
								padding-left: 125px;
								margin-left: 0;
							}
						}
																	
					}

					td.first-col {
						div {	
							padding: 10px 0 0 0;						
							margin-right: 15px;
							border-top: 1px solid #b9bbc0;
							border-bottom: none;
						}
					}

					td.last-col {
						> div {
							padding: 0 0 10px 0;
							border-bottom: none;
						}
					}

					td.last-col:before {
						top: 4px;
					}

					td:before {
						position: absolute;
						left: 15px;
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;	
					}

					td:nth-of-type(1):before { content: "Order #"; }
					td:nth-of-type(5):before { content: "Order Date"; }
					td:nth-of-type(7):before { content: "Status"; }
				}

				tr.child-row.open.noPrice {
					td:nth-of-type(6):before { content: "Status"; }
				}

				tr.child-row.first-child-row {
					td {
						border: none;
					}

					td.last-col {
						> div {
							border-top: none;
						}
					}
				}
			}
		}

		#orders-pagination {
			.page-selector {
				width: auto;
			}
		}

		.invoices-table {			
			tbody {
				tr {
					td {
						a {
							margin-left: 0;
						}
					}
				}

				a.pod-button-file-path {
					display: inline-block;
					width: 52%;
				}

				.footable-row-detail {
					.footable-row-detail-cell {
						.footable-row-detail-inner {
							.footable-row-detail-row {
								.button-small {
									margin-top: 10px;
								}
							}
							.footable-row-detail-row:last-of-type {
								.footable-row-detail-name {
									display: none;
								}
								.footable-row-detail-value {
									position: relative;
		    						height: 50px;

		    						button {
		    							position: absolute;
									    top: 5px;
									    width: 140px;
		    						}
								}
							}
						}
					}
				}
			}
		}

		.nav-tabs {
			> li {
				width: 50%;
    			text-align: center;

    			a {
    				margin-right: 0;
    			}
			}
		}
	}
}