@media (min-width: 1300px) {
    .top-sellers {
        .col-md-3 {
            width: 20%;
        }

        .col-sm-push-1 {
            left: 10%;
        }

        .col-md-push-2 {
            left: 20%;
        }

        .col-md-push-3 {
            left: 30%;
        }

        .col-md-push-4 {
            left: 40%;
        }

        .top-sellers-large {
            display: flex;
        }

        .top-sellers-small {
            display: none;
        }

        .top-sellers-container {
            width: 168px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 1299px) {
    .top-sellers {
        .col-md-3 {
            width: 25%;
        }

        .col-md-push-1 {
            left: 12.3%;
        }

        .top-sellers-large {
            display: none;
        }

        .top-sellers-small {
            display: flex;
        }
    }
}

@media (max-width: 1023px) {
    .top-sellers {
        .col-md-push-1 {
            left: 0;
        }
    }
}