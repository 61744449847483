@import "../variables";

.quote-request-confirmation {
    
    .line-item-image {
        justify-content: left;

        img {
            max-width: 100px;
        }
    }

    .quote-line-section {
        max-width: 1412px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }

    .requestFieldHeader {
        font-weight: bold;
    }

    /* xs size (mobile) */
    @media only screen and (max-width: $mobile-max-width) {
        .mobile-field-label {
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 15px;
        }

        .line-item-image {
            padding-left: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid #B9BBC0;
        }

        .line-item {
            border-bottom: none;

            .line-item-cell {
                padding-left: 0;
                padding-bottom: 15px;
                line-height: 20px;
            }

            .line-item-cell {
                border-bottom: 1px solid #B9BBC0;
            }
        }

        .quote-line-section {
            margin-top: 25px;
        }
    }

}