@import "../variables";

/** start footer **/
footer {
	box-shadow: inset 0 1px 0 0 #b9bbc0;
	/*position: absolute;*/
	/*bottom: 0;*/
	width: 100%;

	.footer-address {
		display: block;

		a {
			margin-left: 6px;
		}
	}

	.content.checkout-footer {
		height: 205px;
		padding-top: 50px;
		font-size: 13px;
		line-height: 1.5;

		.ced-logo {
			max-width: 109px;
			max-height: 35px;
		}

		> div.row {
			> div {
				text-align: center;

				> div {
					margin-top: 20px;
				}

				div.contact-info {
					span:last-of-type {
						padding-right: 0;
					}
					span {
						padding-right: 10px;

						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.content {
		max-width: 1440px;
		height: 320px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 60px;
		padding-bottom: 60px;
		padding-left: 60px;

		.ced-logo {
			max-width: 124px;
			max-height: 89px;
		}

		label {
			width: 133px;
			height: 20px;
			font-family: 'Open Sans', sans-serif;
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
		}

		.footer-column {
			div {
				padding-top: 5px;
				padding-bottom: 5px;
				font-family: 'Open Sans', sans-serif;
				font-size: 13px;
				line-height: 1.5;
			}

			a {
				height: 20px;
				font-family: 'Open Sans', sans-serif;
				font-size: 13px;
				line-height: 1.5;
				text-decoration: underline;
				word-break: break-all;
			}
		}
	}

	.followUsOn {
		color: rgb(77, 77, 77);
		font-size: 13px;
		font-family: 'Open Sans', sans-serif;
		font-weight: normal;
		line-height: 20px;
		margin-top: 20px;
	}

	.socialMediaIcons {
		display: flex;
		justify-content: left;
		margin: 5px 0;

		a.socialMediaIcon {
			display: block;
			width: 32px;
			height: 32px;
			background: linear-gradient(-180deg, rgb(46, 119, 181) 0%, rgb(39, 100, 152) 100%);
			border-radius: 16px;
			margin: 3px 6px 0 0;
	
			> div {
				width: 32px;
				height: 32px;
				text-align: center;

				i {
					color: rgb(255, 255, 255);
					font-size: 16px;
    				line-height: 32px;
				}
			}	
		}

		.socialHorizontal {
			display: inline-block;
		}
	}	
}
/** end footer **/

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	footer {
		.content {
			height: auto;

			label {
				margin-top: 20px;
			}
		}

		.content.checkout-footer {
			height: auto;

			.ced-logo {
				max-width: 124px;
				max-height: 89px;
			}

			> div.row {
				> div {
					text-align: left;
					
					> div {
						margin-top: 10px;
					}

					div.contact-info {
						
						span {
							padding-right: 0px;
							display: block;
							margin-top: 10px;
						}
						span:first-of-type {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}