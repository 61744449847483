@import "../variables";

.admin-quote-request-detail {
	.buttons {
		a:hover, a:active, a:focus {
			text-decoration: none;
		}

		a {
			display: inline-block;
		}
	}

	.items-title {
		margin-top: 50px;
	}

	.repairAttachmentImage {
		max-width: 100px;
		max-height: 100px;
	}

	table:not(.table-condensed):not(.radio-options) tbody tr.repairWarrantyProductRow {
		border-bottom: 0;
	}

	.additionalNotes {
		font-size: 16px;
		line-height: 25px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-quote-request-detail {
		padding-left: 0;
		padding-right: 0;

		.admin-title-back {
			.text-right {
				text-align: left;
			}
		}

		.repairAttachmentImage {
			max-width: 80px;
			max-height: 80px;
		}

		.additionalNotes {
			margin-left: 15px;
			margin-right: 15px;
			font-size: 15px;
		}

		div.row {
			padding-left: 15px;
			padding-right: 15px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		#add-all-to-catalog-button {
			width: 100%;
			margin-top: 20px;
		}

		.buttons {
			a {
				width: 100%;
				text-align: center;
			}
		}

		.footable-row-detail-name {
			width: 140px;
			white-space: normal;
		}

		table.repairWarrantyPendingProductTable tbody tr {
			border-bottom: 0;
		}
	}
}