@import "../variables";
#search-box-search-page.extra-spacing {
	margin-top: 24px;
}
.show-grid{
	display: none;
}
#search-page-search {
	display: table;
	width: 80%;
	position: relative;
	height: 72px;
	padding-right: 15px;

	.autocomplete-suggestions {
		margin-left: 0;
		width: 100% !important;
	}

	.search-categories {
		display: table-cell;
		width: 200px;
		vertical-align: middle;
	}

	.tablet-cancel {
		display: none;
	}

	#search-input-search {
        height: 46px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
		background-color: transparent;
		border-width: 0;
		padding-left: 32px;
		font-size: 16px;
		letter-spacing: -0.4px;					
		width:100%;
		z-index: 1;
		position: relative;

		&.no-categories{
			border-left: 1px solid #ff0000;
			border-top-left-radius: 24px;
			border-bottom-left-radius: 24px;
		}
    }

    #search-input-search:focus {
    	outline: none;
    	-moz-appearance: none;
        -webkit-appearance: none;
    }

    .search-input-container {
    	display: table-cell;
    	vertical-align: middle;
    	position: relative;

    	#search-background-search {
    		position: absolute;
    		top: 13px;
		    width: 100%;
		    background: #fff;
		    z-index: 0;
		    height: 46px;
		    padding-top: 10px;
		    border-top-right-radius: 24px;
		    border-bottom-right-radius: 24px;
    	}

    	#search-input-icon-search {
    		position: absolute;
		    left: 14px;
		    top: 24px;
		    color: #9b9b9b;
		    display: none;
		    font-size: 16px;
			letter-spacing: -0.4px;
    	}

    	label {
    		position: absolute;
		    top: 13px;
		    width: 100%;
		    border-top-right-radius: 24px;
    		border-bottom-right-radius: 24px;
		    height: 46px;
		    padding-top: 10px;
			transition: all 0.2s ease-in-out;
			white-space: nowrap;
			text-align: center;
			font-size: 16px;
			letter-spacing: -0.4px;
			color: #9b9b9b;
			padding-left: 14px;
			font-weight: 100;
			-webkit-touch-callout: none;
		    -webkit-user-select: none;
		    -khtml-user-select: none;
		    -moz-user-select: none;
		    -ms-user-select: none;
		    user-select: none;
		    background-color: transparent;
		    z-index: 0;
    	}
    }    

    .focus-area {
        display: none;
        background-color: #fff;
	    padding-top: 9px;
	    padding-bottom: 9px;
	    padding-right: 9px;
	    border-top-right-radius: 20px;
	    border-bottom-right-radius: 20px;
	    position: absolute;
	    top: 16px;
	    right: 2px;
	    z-index: 4;
    }

    .focus-area i {
        cursor: pointer;
        margin-right: 10px;	                
        padding: 4px;
    }

    a.go {
        margin: 0;
        border-radius: 20px;
        border: none;
        padding-left: 18px;
        padding-right: 18px;
        margin-left: -3px;
        padding-top: 6px;
        padding-bottom: 6px;
        color: #fff;
        height: 31px;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.5;
    }

    a.go:hover {
    	text-decoration: none;
    }

	.styled-select {
    	height: 46px;
		border-top-left-radius: 24px;
		border-bottom-left-radius: 24px;					
		display: inline-block;
		width: 200px;
		vertical-align: middle;
	    overflow: hidden;
	    cursor: pointer;
	    position: relative;
	    margin-left: 10px;

        select {
            background: transparent;
            border: 0;
            border-radius: 0;
            -webkit-appearance: none;
            width: 200px;
            top: 0;
            left: 10px;
            background-color: white;
            height: 42px;
            margin-top: 0;
            position: absolute;
            z-index: 2;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            -khtml-opacity: 0;
            -moz-opacity: 0;
            opacity: 0;
            cursor: pointer;
            padding-right: 0;
            color: #212121;
        }

        div.select-output-search {
            z-index: -1;
            height: 42px;
            overflow: hidden;
            padding: 1.2rem 1.7rem;
            cursor: pointer;
            width: 140px;
            white-space: nowrap;
        }
    }

    .styled-select:after {
        font-family: 'FontAwesome';
        position: relative;
        right: -175px;
        top: -33px;
        content: "\f107";
        font-size: 2rem;
    }
}

#search-page-search.open {
	#search-input-search + label {
		width: 0;
	}
}

#search-page-search.open #search-input-search {               
    outline:none;
    padding-right: 96px;
}

#search-page-search.open #search-input-search ~ .focus-area {
    display: inline-block;
}

.jstree-default{
	& > .jstree-no-dots .jstree-closed > .jstree-ocl{
		background: transparent;
		font-style: normal;
	}

	& > .jstree-no-dots .jstree-closed > .jstree-ocl:before{
		font-family: 'FontAwesome';
		content: "\f107";
	}

	& > .jstree-no-dots .jstree-open > .jstree-ocl{
		background: transparent;
		font-style: normal;
	}

	& > .jstree-no-dots .jstree-open > .jstree-ocl:before{
		font-family: 'FontAwesome';
		content: "\f106";
	}

	/*& > .jstree-no-dots .jstree-node.jstree-open{
		background-color: #f4f4f4;
	}*/

	.jstree-wholerow-hovered{
		background: transparent;
	}

	/*& > .jstree-wholerow-ul .jstree-anchor.jstree-hovered{
		color: #2370B3;
	}*/

	& > .jstree-wholerow-ul .jstree-anchor{
		font-size: 16px;
		line-height: 35px;
		/*color: #212121;*/
		height: 35px;
	}

	/*.jstree-wholerow-clicked{
		background: #2370B3;
		border: solid 1px #2370B3;
	}*/

	.jstree-wholerow{
		/*border-bottom: solid 1px #dde1e2;*/
		height: 35px;
	}

	& > .jstree-wholerow-ul .jstree-clicked, & > .jstree-wholerow-ul .jstree-clicked.jstree-hovered{
		color: #fff;
	}

	.jstree-icon, .jstree-icon:empty {
	    width: 35px;
	    height: 35px;
	    line-height: 35px;
	}
}

.tree-container{
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #b9bbc0;
	overflow: auto;
}

.search-contents {
	.search-container {
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		flex-grow: 1;
		-ms-flex: 1 0 auto;

		.search-content {
			flex: 1;
			-webkit-box-flex: 1;
	  		-moz-box-flex:  1;
	  		-webkit-flex:  1;
	  		-ms-flex:  1;
	  		padding-left: 25px;
	  		padding-right: 15px;
		}

		.search-facets {
			width: 300px;
			padding: 0px;
		}
	}


	fieldset {
		display: inline-block;

		legend {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.43;
			letter-spacing: 0.1px;
			border: none;
			margin-bottom: 5px;
		}
	}
	
}

#add-my-catalogs .modal-body .row{
	margin-bottom: 20px;
}

#add-my-catalogs .subtitle, #add-keyword .subtitle{
	font-size: 16px;
	line-height: 1.6;
	display: block;
	margin: 0;
}

.search-contents{
	display: block;
}

.toggle-filter-bottom{
	background: transparent;
}

.toggle-filter-bottom:hover{
	background: transparent;
	color: #6A6B6D;
}

.description-overflow{
	display:none;
}

.product-description-for-modal{
	display:none;
}

#item-quick-view{
	.product-description-for-modal{
		display:block;
		margin-bottom: 15px;
	}

	.product-title{
		font-size: 27px;
		line-height: 1.3;
		font-weight: normal;
	}

	.product-mfr{
		font-size: 15px;
		line-height: 1.7;
	}

	.item-availability{
		position: absolute;
		right: 0px;
		bottom: 20px;
	}

	.add-to-catalog{
		display: inline-block;
		margin: 10px 0px;
	}

	.quick-view-price{
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 15px;
		border-bottom: thin solid #b9bbc0;
		margin-bottom: 15px;
	}

	.quick-view-img{
		text-align: center;
	}

	.quick-view-details{
		font-size: 15px;
		line-height: 1.7;
	}

	.line-item{
		padding-top: inherit;
	    padding-bottom: inherit;
	    border-bottom: none;
	}
}

#item-quick-view .quick-view-buttons, #item-quick-view .quick-view-buttons button,
.line-item-button, .product-buttons, .product-buttons button{
	text-transform: uppercase;
}

/** begin search **/
/*  HEADER  */
h1.search-term{
	font-size: 27px;
	line-height: 1.3;
	margin-bottom: 10px;
	display: inline-block;
}

.total-item-count{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
	margin-right: 25px;
}

.item-totals{
	margin-bottom: 30px;
}

.breakdown-item-count{
	font-size: 16px;
	line-height: 1.6;
}

.breakdown-item-count + .breakdown-item-count::before{
    content: " | ";
    margin: 0px 10px;
}

/*  FACET ITEMS */

#narrow-result-header{
	padding: 20px 15px;

	span{
		font-size: 19px;
	}

	i{
		font-size: 15px;
	}
}


.facet-form{
	label{
		height:auto;
		font-weight: unset;
	}

	ul{
		list-style: none;
		padding-left: 0px;

		li{
			margin-left: 0;
		}
	}
}

a.filter-section-title-container{
	box-shadow: inset 0 1px 0 0 #b9bbc0;
	padding: 15px 45px 15px 30px;
	position: relative;
	display: block;
	text-decoration: none;
}

a.advanced-section-title-container{
	text-decoration: none;
}

a.filter-section-title-container:hover, a.advanced-section-title-container:hover{
	text-decoration: none;
}

.filter-section-title{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.6;
}

.filter-value{
	font-size: 15px;
	line-height: 1.7;
	font-weight: normal;
}

.filter-value > span{
	text-decoration: underline;
}

.hide-element{
	display: none;
}

.facet-see-more, .facet-see-less{
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
}

div.filter-section{
	padding-left: 30px;
	padding-top: 15px;
    padding-bottom: 25px;

    #mfr-filter{
    	position: relative;
    	i{
		    position: absolute;
		    color: #9b9b9b;
		    top: 17px;
		    left: 10px;
	    }

	    #mfr-filter-box{
		    display: block;
			margin-top: 10px;
			width: 90%;
			padding-left: 35px;
	    }

    }
    

    ul:not(.showing-all) li:nth-child(n+6),
	ul:not(.showing-all) li:nth-child(n+6) input {
    	display: none;
    }
}

.filter-label{
	cursor:pointer;
}

.clear-facet{
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
}

.open-facets:after{
	font-family: 'FontAwesome';
	position: absolute;
	right: 30px;
	top: 15px;
	content: "\f106";
	font-size: 2rem;
}

.closed-facets:after{
	font-family: 'FontAwesome';
	position: absolute;
	right: 30px;
	top: 15px;
	content: "\f107";
	font-size: 2rem;
}

/*  BREADCRUMBS  */
#search-category-breadcrumbs li a{
    
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
	line-height: 1.7;
}

#search-category-breadcrumbs li.clickable {
    cursor: pointer;
}

.search-category-breadcrumb{
	display: inline-block;
}

.search-category-breadcrumb + .search-category-breadcrumb::before{
    content: "\f105";
    background-color: #FFF;
    font-family: 'FontAwesome';
    color: #b9bbc0;
}

/*  ACCOUNT SELECTION  */
.account-section{
	padding: 8px 15px ;
	box-shadow: inset 0 -1px 0 0 #b9bbc0, inset -1px 0 0 0 #b9bbc0;
	font-size: 13px;
	position: relative;
	padding-right: 40px;
	min-width: 200px;
}

.hello-account:after{
	font-family: 'FontAwesome';
	position: absolute;
	right: 15px;
	content: "\f107";
	font-size: 2rem;
	bottom: 3px;
}

.account-name, .account-number{
	font-size: 15px;
}

.account-number{
	font-weight: bold;
}

.login-text{
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
}

/** Pagination **/
.pagination-container{
	margin-top:5px;
}

.pagination-container label {
    display: inline-block;
}

.pagination-containerselect {
    width: auto;
}

.pagination-container ul.pagination-select {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
}

.pagination-container ul.pagination-select li{
	display: inline-block;
	margin: 0px;
	vertical-align: middle;

	.large-circle-button.prev-button.disabled,
	.large-circle-button.next-button.disabled {
		cursor: default;
	}

	button.previous-page-button,
	button.next-page-button {
		border-radius: 50%;
		width: 32px;
		height: 32px;
		border: none;

		i {
			font-weight: bold;
		}
	}
}

.pagination-container ul.pagination-select li + li{
	display: inline-block;
}

.pagination-select .unavailable.arrow{
	color: #b9bbc0;
	cursor: auto;
}

.pagination-select .arrow{
    cursor: pointer;
}

.toggle-button-box{
	 display: inline-block;

	.toggle-button{
		font-size: 14px;
		font-weight: normal;
		line-height: 1;
		padding: 12px 10px;

		&.toggle-button-right{
			margin-right: 20px;
		}
	}

	input{
		margin-left: -2rem;
		opacity: 0;
		position: absolute;
	}
}

.view.bottom-view{
	position: absolute;
    top: 5px;
    right: 10px;
}

.top-search-controll{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.pagination-container{
		width: 100%;
	    order: 3;
	    text-align: center;
	}

	.view{
		width:330px;
	}
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 180px;
}

div.hidden-select {
	font-size: 15px;
	line-height: 1.7;
	overflow: hidden;
	position: relative;
}

div.hidden-select div{
	overflow:hidden;
	width: 160px;
	height: 30px;
    font-size: 15px;
    line-height: 1.7;
}   

div.hidden-select select {
	background: transparent;
	border: 0;
	border-radius: 0;
	-webkit-appearance: none;
	width: 160px;
	top: 0;
	background-color: white;
	height: 34px;
	margin-top: 0;
	position: absolute;
	z-index: 2; 
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-khtml-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	cursor:pointer;
	padding-right: 0;
   
}


.img-wrapper{
	display: block;
	text-align: center;
	width: 100%;
}

.quick-view-wrapper{
	padding-top: 10px;
}


.spinner-qty{
	text-align: center;
	margin: 0 2px;
	padding-left: 0 !important;
	padding-right: 0;
	-moz-appearance:textfield;
	border:1px solid gray;
	border-radius:3px;
	text-align:center;
	width:calc(100px - 2em);
} 
.spinner-qty:focus,
.spinner-qty:hover {
	appearance: textfield;
}
.spinner-qty::-webkit-outer-spin-button,
.spinner-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.loading-overlay {
    position: absolute;
    padding-top:12rem;
    text-align: center;
    left: 0;
    background-color: rgba(255,255,255,0.7);
    color: #000;
    height: 100%;
    width: 100%;
    z-index: 999;
    font-weight:bold;
    display: none;
}

.line-item{
	padding-top: 20px;
	padding-bottom: 25px;
	border-bottom: solid 1px #b9bbc0;

	&:first-child{
		border-top: solid 1px #b9bbc0;
		margin-top: 32px;
	}

	.button-small{
		padding: 7px 12px;
	}

	.product-title{
		font-size: 19px;
		font-weight: bold;
		line-height: 1.3;
	}
}

.show-item-quick-view i{
	font-size: 13px;
	line-height: 1.2;
}

.show-item-quick-view span{
	font-size: 13px;
	line-height: 1.5;
}

.product-title{
	font-size: 19px;
	font-weight: bold;
	line-height: 1.3;
}

.catalog-number{
	display: block;
}

.block-on-xs, .block-on-sm{
	display: inline-block;
}

.product-details{
	margin-top: 10px;
	margin-bottom: 10px;
}

.product-description{
	font-size: 13px;
	line-height: 1.5;
}

.product-buttons{
	margin-top: 10px;
	margin-bottom: 20px;
}

.price-column{
	margin-bottom: 20px;
	padding-left: 0px;
	padding-right: 0px;
}

.quantity-column{
	padding-left: 0px;
	padding-right: 0px;
}

.sell-price{
	font-size: 27px;
	font-weight: bold;
	line-height: 1.3;
}

.list-price{
	font-size: 13px;
	line-height: 1.5;
}

.list-price-content{
	text-decoration: line-through;
}

.uom{
	font-size: 16px;
	font-weight: normal;
	line-height: 2.2;
}

.line-item-image {
	display: flex;
	justify-content: left;
	align-items: left;
}

.product-image-link img{
	max-width: 100%;
	max-height: 155px;
}

.line-item-button{
	margin-left: 21px;
}

.add-to-catalog{
	margin-right: 15px;
}

.close-filter-box{
	padding: 20px 15px 25px 15px;
}

.close-filter-box button{
	width: 100%;
}

.toggle-filter-wrapper{
	padding-top: 1px;
	margin-right: 14px;
	float: left;
}

.toggle-filter + .bootstrap-select::before{
	margin-right: 14px;
}

.add-to-cart-success .contents{
	height: 70px;
	width: 100%;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 #1D5D95;
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	text-align: center;
	z-index: 3;
	transition: all .5s;
}

.closed .contents{
	top: -70px;
}

.add-to-cart-success .contents > span{
	font-size: 19px;
	line-height: 1.3;
	color: #ffffff;
	margin-top: 22px;
	display: inline-block;
}

.add-to-cart-success a{
	height: 34px;
	border-radius: 3px;
	border: solid 1px #ffffff;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
	color: #fff;
	background: transparent;
	margin-left: 20px;
	padding: 7px;
}

.add-to-cart-success a:hover{
	color: #fff;
	text-decoration: none;
}

.advanced-search-box{
	background-color: #f4f4f4;
	border: solid 1px #b9bbc0;
	padding: 30px 40px;
    margin-bottom: 20px;
}

.advanced-search-box .row input, .advanced-search-box .row .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
	width: 100%;
}

.advanced-search-box .bootstrap-select button{
	background: white;
}

.advanced-search-box .open-facets, .advanced-search-box .closed-facets{
	display: block;
	position: relative;
}

.advanced-search-box .open-facets label, .advanced-search-box .closed-facets label{
	display: inline-block;
}

.advanced-search-box .open-facets:after, .advanced-search-box .closed-facets:after{
	top: 0;
}

.search-header, .search-results{
	width: 100%;
    padding-left: 325px;
    float: right;
    padding-right: 15px;
}

/*.search-facets{
	width: 300px;
    position: absolute;
}*/

.compare-container{
	display: none;
}

.compare-open #getting-started{
	bottom: 140px;
}
.compare-open .compare-container{
	display: block;
	height: 110px;
	background-color: rgba(43,61,65,.8);
	position: fixed;
	width: 100%;
	bottom: 0px;
	z-index: 255;
	font-size: 12px;
}

.compare-product-box{
	margin: 20px 0px;
	height: 70px;
	background-color: #fff;
	padding: 8px 12px;
	position: relative;

	.compare-title{
		height: 3em;
		overflow: hidden;
	}
}

.compare-product-box.empty-product{
	background-color: #2b3d41;
}

.compare-product-box .product-image-link{
	float: left;
}

.compare-product-box .product-image-link img{
	max-height: 44px;
	margin-right: 16px;
}

.compare-product-box .product-info a{
	display: block;
}

.compare-product-box i{
	position: absolute;
    top: 10px;
    right: 8px;
}

#do-compare{
	margin-right: 20px;
}

.compare-button-box{
	vertical-align: middle;
	margin: 20px 0px;
	height: 60px;
	text-align: center;
}

.hideNone,
.hideSmall,
.hideMedium,
.hideLarge {
	display: block;
	margin-right: 17px;
}

.category-overflow {
	display: none;
}

.category-show-more,
.category-show-less {
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.5;
}

.category-show-less {
	display: none;
}

.show-more-link {
	margin-bottom: 15px;
}

@media (min-width: $tablet-min-width) {
	#item-quick-view .modal-dialog{
		width: 738px;
	}

	.search-contents {
		.line-items.grid{
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;

			.hidden-grid{
				display: none;
			}

			.row{
				margin: 0;
			}

			.show-grid{
				display: block;
			}

			.line-item{
				width: 33.33%;
				border: solid 1px #b9bbc0;
				margin-top: -1px;
				margin-right: -1px;
				padding-top: 10px;
				padding-bottom: 0px;
				flex-direction: column;

				.add-to-catalog-grid{
					position: absolute;
					top: 5px;
					right: 20px;

					.button-small{
						padding: 2px 7px;
						margin-right: 0;
						display: block;
					}
				}

				.line-item-image{
				    align-items: unset;

					.img-wrapper{
						padding-top: 25px;

						.product-image-link{
							height: 155px;
							display: flex;

							img{
								align-self: center;
								margin: auto;
							}
						}
					}
				}


				> div{
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					flex: 0 0 auto;
				}

				.line-info{
				    flex-grow: 1;
					flex-direction: column;
					display: flex;
				}

				.line-item-data > div:not(.line-details){
					width: 100%;
					padding-left: 0px;
					padding-right: 0px;
					
				}

				.price-quantity-block-wrapper{
					flex: 1 0 auto;
				}

				.line-item-data{
					flex-grow: 1;
				    display: flex;
				    flex-wrap: wrap;
				    flex-direction: column;
				}

				.compare{
					position: absolute;
					top: 0;
					left: 20px;
				}

				.line-item-button {
	    			margin-left: 0px;
				}

				.price-quantity-block{
					padding-bottom:70px;

					.show-grid .spec-sheet-help-section{
						margin-top: 8px;
						margin-bottom: 8px;
					}

					.quantity-column{
						position: absolute;
						bottom: 0;
					
				
						.quantity-column-wrapper{
							text-align: center;
						    padding-bottom: 10px;
						    padding-top: 10px;
						    background-color: #eee;
						    border-top: solid 1px #b9bbc0;

						    .button-emphasis {
							    padding: 12px 15px 13px 15px;
							}
						}
					}
				}

				.item-availability{
					margin-top: 0px; 
				}


				.price-column, .line-details {
					padding-left: 15px;
					padding-right: 15px;
					width: 100%;
					flex: 0 0 auto;
				}

			}
			
		}
	}
}

/** end search **/

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {

	.block-on-sm{
		display: block;
	}

	.pager-box{
		text-align: left;
	}

	.price-column{
		margin-bottom: 0px;
	}

	.line-item-button {
	    margin-left: 15px;
	}

	.filter-section-title-container{
		padding-left: 15px;
	}

	.advanced-search-box{
		padding: 20px;
	}

	.search-header, .search-results{
		width: 100%;
	    padding-left: 225px;
	    float: right;
	}

	/*.search-facets{
		width: 200px;
	    position: absolute;
	}*/

	.search-contents {
		.search-container {
			.search-facets {
				width: 200px;
				padding: 0px;
			}
		}

		.line-items.grid{
			.line-item{
				width: 50%;

				.price-quantity-block>div{
					width:100%;
				}
			}
		}
	}

	.compare-open .compare-container{
		height: 175px;
	}

	.compare-open #getting-started{
		bottom: 205px;
	}

	.compare-product-box{
		margin: 15px 5px 15px 5px;
		height: 65px;
	}

	.compare-product-box .product-image-link img{
		max-height: 34px;
	}

}

@media only screen and (max-width: $mobile-max-width) {

	.advancedSearchCategory, .advancedSearchManufacturer {
		.filter-option {
			overflow: hidden;
			max-width: 248px;
			text-overflow: ellipsis;
		}
	}

	#item-quick-view .item-availability{
		position: static;
	}

	#item-quick-view .quick-view-quantity{
		text-align: center;
	}

	#item-quick-view .add-to-cart-individual-improved{
		width: 100%;
		margin: 0;
	}

	#item-quick-view .product-title{
		font-size: 19px;
		line-height: 1.6;
	}
	#item-quick-view .quick-view-buttons button{
		float: right;
	}

	#item-quick-view .add-to-catalog{
		margin: 0;
	}

	#item-quick-view .quick-view-price{
		border: none;
	}

	#item-quick-view .quick-view-add-cart .row{
		border-top: thin solid #b9bbc0;
		border-bottom: thin solid #b9bbc0;
		margin-top: 5px;
	    padding-top: 10px;
	    padding-bottom: 10px;
	}

	#item-quick-view .product-description-for-modal{
		border-bottom: thin solid #b9bbc0;
		padding-top: 10px;
    	padding-bottom: 10px;
	}


	.block-on-xs{
		display: block;
	}

	form.show-xs-anyway{
		display:block !important;
	}

	.breakdown-item-count + .breakdown-item-count::before{
        content: "";
        margin: 0px;
    }

    .pagination-select{
    	width: 100%;
    }

    .page-selector{
    	width: 60%;
    }

    div.hidden-select select{
    	width: 100%;
    }

    div.hidden-select div{
    	width:100%;
    }

    .price-column{
		margin-bottom: 0px;
	}

	.show-item-quick-view i{
		font-size: 16px;
		line-height: 0.9;
	}

	.quick-view-wrapper{
		text-align: left;
	}

	.filter-section-title-container{
		padding-left: 15px;
	}

	.add-to-cart-success span{
		font-size: 16px;
		line-height: 1.6;
	}

	.col-sm-clear{
		clear: both;
	}

	.advanced-search-box{
		padding: 20px 15px;
	    margin-left: -15px;
	    margin-right: -15px;
	}

	.advanced-search-box .button-emphasis{
		width: 100%;
	}

	.search-header{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.search-results{
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	/*.search-facets{
		width: 100%;
		position: relative;
		clear: both;
	}*/

	.search-contents {
		.search-container {			
			.search-content {
		  		padding-left: 0;
		  		padding-right: 0;

		  		.search-top-content {
		  			padding-left: 15px;
		  			padding-right: 15px;
		  		}
			}

			.search-facets {
				width: 0;

				.search-facets-content {
					width: 100%;
				    position: absolute;
				    background-color: #fff;
				    top: 0;
				    z-index: 2;
				}
			}
		}
	}

	#search-page-search {
		width: 100%;
		padding-right: 0;

		.autocomplete-suggestions {
			margin-left: 0;
			width: 100% !important;
		}

		.search-categories {
			display: none;
		}

		#search-input-search {
	        border-top-right-radius: 24px;
	        border-bottom-right-radius: 24px;
	        border-top-left-radius: 24px;
	        border-bottom-left-radius: 24px;			
	    }

	    .search-input-container {

	    	#search-background-search {
	    		position: absolute;
	    		top: 13px;
			    width: 100%;
			    background: #fff;
			    z-index: 0;
			    height: 46px;
			    padding-top: 10px;
			    border-top-right-radius: 24px;
			    border-bottom-right-radius: 24px;
	    	}

	    	#search-input-icon-search {
	    		position: absolute;
			    left: 14px;
			    top: 24px;
			    color: #9b9b9b;
			    display: none;
			    font-size: 16px;
				letter-spacing: -0.4px;
	    	}

	    	label {
	    		position: absolute;
			    top: 13px;
			    width: 100%;
			    border-top-right-radius: 24px;
	    		border-bottom-right-radius: 24px;
			    height: 46px;
			    padding-top: 10px;
				transition: all 0.2s ease-in-out;
				white-space: nowrap;
				text-align: center;
				font-size: 16px;
				letter-spacing: -0.4px;
				color: #9b9b9b;
				padding-left: 14px;
				font-weight: 100;
				-webkit-touch-callout: none;
			    -webkit-user-select: none;
			    -khtml-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;
			    background-color: transparent;
			    z-index: 0;
	    	}
	    }    

	    .focus-area {
	        display: none;
	        background-color: #fff;
		    padding-top: 9px;
		    padding-bottom: 9px;
		    padding-right: 9px;
		    border-top-right-radius: 20px;
		    border-bottom-right-radius: 20px;
		    position: absolute;
		    top: 16px;
		    right: 2px;
		    z-index: 4;
	    }

	    .focus-area i {
	        cursor: pointer;
	        margin-right: 10px;	                
	        padding: 4px;
	    }

	    a.go {
	        margin: 0;
	        border-radius: 20px;
	        border: none;
	        padding-left: 18px;
	        padding-right: 18px;
	        margin-left: -3px;
	        padding-top: 6px;
	        padding-bottom: 6px;
	        color: #fff;
	        height: 31px;
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
	    }

	    a.go:hover {
	    	text-decoration: none;
	    }

		.styled-select {
	    	height: 46px;
			border-top-left-radius: 24px;
			border-bottom-left-radius: 24px;					
			display: inline-block;
			width: 200px;
			vertical-align: middle;
		    overflow: hidden;
		    cursor: pointer;
		    position: relative;
		    margin-left: 10px;

	        select {
	            background: transparent;
	            border: 0;
	            border-radius: 0;
	            -webkit-appearance: none;
	            width: 200px;
	            top: 0;
	            left: 10px;
	            background-color: white;
	            height: 42px;
	            margin-top: 0;
	            position: absolute;
	            z-index: 2;
	            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	            filter: alpha(opacity=0);
	            -khtml-opacity: 0;
	            -moz-opacity: 0;
	            opacity: 0;
	            cursor: pointer;
	            padding-right: 0;
	            color: #212121;
	        }

	        div.select-output-search {
	            z-index: -1;
	            height: 42px;
	            overflow: hidden;
	            padding: 1.2rem 1.7rem;
	            cursor: pointer;
	            width: 140px;
	            white-space: nowrap;
	        }
	    }

	    .styled-select:after {
	        font-family: 'FontAwesome';
	        position: relative;
	        right: -175px;
	        top: -33px;
	        content: "\f107";
	        font-size: 2rem;
	    }
	}
}

@media only screen and (min-width: 1120px) and (max-width: 1320px) {
	.hideLarge {
		display: none;
	}
}

@media only screen and (min-width: 900px) and (max-width: 1119px) {
	.hideMedium,
	.hideLarge {
		display: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
	.hideSmall,
	.hideMedium,
	.hideLarge {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.hideSmall,
	.hideMedium,
	.hideLarge {
		display: block;
	}

	.show-more-link {
		margin-left: 16px;
		margin-right: 16px;
	}
}