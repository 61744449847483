@import "../variables";

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-quotes {
		padding-left: 0;
		padding-right: 0;		

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		ul.nav-tabs {
			margin-left: 15px;
			margin-right: 15px;
		}

		.top-row {
			margin-left: 0;
			margin-right: 0;
		}

		.buildAQuoteButton {
			width: 100%;
			text-align: center;
		}

		#approved-quotes-table {
			.footable-row-detail {
				.footable-row-detail-cell {
					.footable-row-detail-inner {
						.footable-row-detail-row:last-of-type {
							.footable-row-detail-name {
								display: none;
							}
							.footable-row-detail-value {
								position: relative;
	    						height: 50px;

	    						button {
	    							position: absolute;
								    top: 5px;
								    width: 55px;
	    						}
							}
						}
					}
				}
			}
		}
	}
}