@import "../variables";

.view-all {
	margin-top: 40px;
	padding-left: 30px;
	padding-right: 30px;
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.view-all {
		margin-top: 30px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.view-all {
		margin-top: 30px;
		padding-left: 0;
		padding-right: 0;

		h1 {
			padding-left: 15px;
		}

		> div.row {
			margin-right: 0;
		}
	}
}