@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex($num) {
	flex: $num;
	-webkit-box-flex: $num;
	-moz-box-flex:  $num;
	-webkit-flex:  $num;
	-ms-flex:  $num;
}

@mixin flexwrap($wrap) {
	flex-wrap: $wrap;
	-webkit-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
}

@mixin flexalign($align) {
	align-items: $align; 
	-ms-flex-align: $align;
}

@mixin flexdirection($dir) {
	-ms-flex-direction: $dir;
    -webkit-flex-direction: $dir;
    flex-direction: $dir;
}

@mixin clearfix() {
	&::after {
	  display: block;
	  content: "";
	  clear: both;
	}
}