.main-container{
    margin: 40px !important;
    max-width: none !important;
    gap: 32px !important;
    font-family: "Inter" !important;
}
.project-transfer-landing{
    color: var(--ced-color-global-neutral-1);
    h1{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    h2{
        margin-top: 0 !important;
    }
    p{
        font-size: 14px !important;
        margin: 0 !important;
    }
    .link-icon{
        position: absolute;
        padding-top: 5px;
    }

    .configure-url{
        font-size: 14px !important;
        color: var(--ced-color-global-brand-2) !important;
        word-break: break-all !important;
        padding-left: 20px;
        display: block;
    }
    .page-nav a{
        font-size: 14px !important;
        color: var(--ced-color-global-brand-2);
    }
    .purchase-materials{
        min-width: 156px !important;
    }
    .create-project{
        min-width: 125px !important;
    }
    .search-existing{
        min-width: 193px !important;
    }
    .update-project{
        min-width: 180px !important;
    }
    .field-label{
        margin-bottom: 4px !important;
    }
    .project-details-container .inner-container{
        margin-top: 16px !important;
    }

    .column-headers-row{
        grid-template-rows: repeat(1, [row-start] 56px [row-end]) !important;
    }
    .data-row{
        grid-template-rows: repeat(1, [row-start] 89px [row-end]) !important;
    }
    .header-cell{
        padding: 12px !important;
        padding-left: 16px !important;
    }
    .header-p{
        font-weight: 700 !important;
    }
    .products-container .product-details{
        display: flex !important;
        flex-direction: column !important;
        gap: 4px !important;
    }
    button span{
        white-space: nowrap !important;
    }

    .product-image{
        margin-right: 12px !important;
    }

    /* Primary Button **/
    .primary {
        position: relative;
        padding: 12px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px !important;
        border-radius:6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-alpha-white-3) inset, 0px -1px 0px var(--ced-color-global-brand-1) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-brand-2);
        color: #FFFFFF;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border-width: 0;
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }

    .primary:hover {
        background-color: var(--ced-color-background-brand-hover)
    }

    .primary:focus {
        background-color: var(--ced-color-background-brand);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .primary:active {
        background-color: var(--ced-color-background-brand-focus);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }



    /*End Primary Button*/

    /***********Secondary Button***********/
    .secondary {
        position: relative;
        padding: 12px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px;
        border-radius: 6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-white) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-neutral-6);
        color: var(--ced-color-global-neutral-1);
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border: 1px solid var(--ced-color-border-neutral-dark);
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }

    .secondary:hover {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-border-neutral-dark);
    }

    .secondary:focus {
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .secondary:active {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }
    
    .search-input {
        width: 100%;
        padding-left: 36px;
    }
    
    .project-transfer-modal {
        font-family: "Inter" !important;
        width: var(--ced-modal-size-xlarge);
        height: Hug (1,058px)px;
        padding: 64px var(--ced-spacing-medium) 88px var(--ced-spacing-medium);
        gap: var(--ced-spacing-large);
        opacity: 0px;
        max-width: 100% !important;
    }
    
    .slds-modal__container {
        max-width: 100% !important;
        width: var(--ced-modal-size-xlarge) !important;
    }
    
    .title {
        font-size: var(--ced-font-size-xlarge) !important;
        font-weight: 600 !important;
        line-height: var(--ced-line-height-heading-xlarge);
        letter-spacing: 0.003em;
        text-align: left;
    }
    
    .icon {
        float: right;
    }
    
    .radio-button {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        appearance: none;
        border-radius: 50%;
        background: var(--ced-color-global-neutral-6);
        box-shadow: inset 0px 0px 0 2px var(--ced-color-border-neutral-dark);
    }
    
    .upload-instructions {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: var(--ced-line-height-body);
        letter-spacing: 0.003em;
        text-align: left;
    }
    
    .active-button {
        box-shadow: 0px 0px 0 2px var(--ced-color-global-brand-2);
        border: 2px solid var(--ced-color-global-neutral-6);
        background: var(--ced-color-global-brand-2);
    }
    
    .error-message {
        color: var(--ced-color-icon-danger) !important;
        display: flex;
    }
    .upload-instructions, .button-text, h3{
        font-size: 14px !important;
    }
    .showing-count, .no-projects-found {
        font-size: 14px !important;
    }
    .project-suggestion-error-message {
        color: red;
        font-weight: bold;
        font-size: 15px;
    }
    /***********Text Button***********/
    .text {
        position: relative;
        padding: var(--ced-spacing-small);
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px;
        border-radius: var(--ced-border-radius-base);
        background-color: inherit;
        color: var(--ced-color-global-brand-2);
        font-weight: var(--ced-font-weight-semi-bold);
        font-size: var(--ced-font-size-small);
        line-height: var(--ced-line-height-component-base);
        border: var(--ced-border-width) solid inherit;
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
        margin-top: 5px;
    }
    
    .text:hover {
        background-color: var(--ced-color-global-neutral-5);
    }
    
    .text:focus {
        background-color: inherit;
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }
    
    .text:active {
        background-color: var(--ced-color-global-neutral-5);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }
    /*End text Button*/
    c-lwr-radio-button .title-label {
        font-weight: var(--ced-font-weight-bold);
        font-size: 15px;
        margin-left: 0;
        font-weight: var(--ced-font-weight-bold);
        font-size: 15px;
        margin-top: 4px;
        padding-top: 0.2em;
        padding-left: 0.6em;
    }
    .suggested-projects-info {
        margin-left: 34px;
        margin-top: -10px;
    }

    .label[c-lwrCheckbox_lwrCheckbox] {
        font-size: 15px;
    }
}