@import "../variables";

body.solar-template {
	.payment-information {
		.payment-information-content {
			padding-right: 0;
			padding-left: 0;

			.payment-information-form {
				margin-top: 24px;

				.title-row {
					h1 {
						font-size: 32px;
						line-height: 1.25;
						letter-spacing: 0.3px;
						font-weight: 100;
					}
				}

				label {
					font-size: 14px;
					font-weight: 600;
					line-height: 1.43;
					letter-spacing: 0.1px;
					margin-bottom: 0;
				}

				.button-row {
					.go-back {
						color: #657878;
					}
				}

				.solar-checkout-breadcrumb {
					padding-right: 40px;
					padding-left: 40px;
				}

				> div.row.credit-card-row, > div.row {
					margin-top: 16px;
					padding-right: 40px;
					padding-left: 40px;
				}

				hr {
					margin-top: 31px;
					margin-bottom: 24px;
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {
		.payment-information {
			.payment-information-content {
				.payment-information-form {
					margin-top: 16px;

					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row.credit-card-row, > div.row {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}

					hr {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		.payment-information {
			.payment-information-content {
				.payment-information-form {
					margin-top: 24px;

					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row.credit-card-row, > div.row {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}

					.title-row {
						margin-top: 0;
					}

					.button-row {
						.go-back {
							text-align: left;
						}
					}

					hr {
						margin-right: 0px;
						margin-left: 0px;
						padding-left: 0;
						padding-right: 0;
					}					
				}
			}
		}
	}
}

@media only screen and (min-width: $tablet-max-width) and (max-width: 1280px) {
	body.solar-template {
		.payment-information {
			.payment-information-content {
				.payment-information-form {
					.solar-checkout-breadcrumb {
						padding-right: 16px;
						padding-left: 16px;
					}

					> div.row.credit-card-row, > div.row {
						padding-right: 16px;
						padding-left: 16px;
					}
				}
			}
		}
	}
}