@import "../variables";


#main-content{
    min-height: 200px !important;
    padding-bottom: 0px;
}
.greentech-projects-container{
    background-color: none;
    padding-left: 40px;
    padding-right: 40px;
    min-width: fit-content;

    .all-projects-heading{
        display: flex;
        justify-content: flex-start;
        align-self: center;
        margin: 12px 0px;
        font-weight: normal;
        h1{
            display: inline;
        }
    }

    .status {
        background: rgba(88, 152, 21, 0.15);
        border-radius: 3px;
        border: 1px solid rgb(90, 177, 118);
        height: 40px;
        padding: 10px; 
        color: rgb(33, 33, 33);  
        #switchToArchivedTab, #switchToActiveTab {
            color: rgb(33, 33, 33);
            text-decoration: underline;
        } 
    }

    .fa-check-circle {
        color: #589815;
    }

    .fa-times {
        color: rgb(33, 33, 33);
        float: right;
    }

    .greentech-breadcrumb{
        display: flex;
        justify-content: flex-start;
        background-color: none;
        margin: 20px 0px 12px 0px;
        line-height: 20px;
        font-size: 12px;
        font-weight: normal;
        
        p{
            margin-left: 9px;
        }
    }

    #newProject{
            position: absolute;
            right: 40px;
            align-self: center;
            border: none;
            border-radius: 2px;
            padding: 12px 16px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
        }
 
    .projectsTabs{
        display:flex;
        margin: 12px 0px;
        border-bottom: solid 1px;
        padding-inline-start: 0px;
        list-style: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px; 
        li{
            z-index: 1;
            margin-top: 12px;
            border-radius: 4px 4px 0px 0px;
            padding: 12px 8px;
            a{
                padding: 4px;
            }
        }
        #activeTabItem {
            text-transform: uppercase;
            a {
                color: rgb(106, 107, 109);
            }
        }

        #archivedTabItem {
            text-transform: uppercase;
            a {
                color: rgb(106, 107, 109);
            }
        }
         
        .active {
            border: 1px solid rgb(213, 216, 217);
            border-bottom: none; 
            margin-bottom: -1px;
            a { 
                color: rgb(30, 129, 67) !important; 
            }
        }
    }

    #projects-table-container{
        margin: 12px 0px;
        width: 100%;
        min-width: fit-content;
        
        #filters-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 3px;
            border: 1px solid;
            padding: 10px;

            #filters-top, #filters-bottom{
                display: inherit;
                flex-direction: row;
            }
            #filters-top{
                align-items: center;
                justify-content: space-between;
                #filters-heading{
                    margin: 0px;
                    font-weight: bold;
                    font-size: 16px;
                    height: 20px;
                }
            }

            #filters-bottom{
                justify-content: center;
                align-items: center;
                gap: 20px;
            }
            &.expanded{
                #filters-bottom{
                    display: inherit;
                }
                #filters-collaspe{
                    display:inherit;
                }
                #filters-expand{
                    display:none;
                }
            }
            &.collapsed{
                #filters-bottom{
                    display: none;
                }
                #filters-collapse{
                    display: none;
                }
                #filters-expand{
                    display:inherit;
                }
                .projectFilter{
                    display: none;
                }
                #clearAllFilters{
                    display: none;
                }
            }
            .projects-filter{
                display:inherit;
                flex-direction: column;
                

                .filter-label{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
                input, select{
                    height: 40px;
                    padding: 8px;
                    border: 1px solid;
                    border-radius: 3px;
                }

                button{
                    display: flex;
                    border: none;
                    font-size: 14px;
                    font-weight: bold !important;
                    padding: 10px 10px 10px 0px;
                }
            }

            //Store
            .store-filter{
                fieldset{
                    display: flex;
                    flex-direction: column;
                    align-content: space-between;
                }
                flex-basis: 201px;
                flex-grow: 1;
            }
            //Search
            .search-filter{
                flex-basis: 541px;
                flex-grow: 2;
                flex-shrink: 2;
            }
            //Status
            .status-filter{
                fieldset{
                    display: flex;
                    flex-direction: column;
                    align-content: space-between;
                }
                flex-basis: 201px;
                flex-grow: 1;
            }
            //Date
                .date-range-container{
                    display: flex;
                    flex-basis: 427px;
                    flex-direction: row;
                    flex-grow: 1;
                    flex-shrink: 1;
                    gap: 8px;
                    .filter-label{
                        margin-bottom: 5px;
                    }
                    div{
                        display: flex;
                    }
                    #date-type{
                        flex-direction: column;
                    }
                    fieldset{
                        display: flex;
                        flex-direction: column;
                        align-content: space-between;
                    }
                    div:nth-of-type(2),
                    div:nth-of-type(3){
                        align-self: flex-end;
                        input{
                            max-width:139px;
                        }
                    }
                    .calendar-container{
                        position: relative;
                        .greentech-calendar{
                            position: absolute;
                            top:10px;
                            right: 8px;
                            padding: 3px 0px;
                        }
                    }
                    
                }
        
            //Clear All
            .clear-all{
                align-self: flex-end;
            }

        }        

        #allSelectBox{
            margin-right: 12px;
        }
    
        #all-projects-table-header-mobile{
            display:none;
        }
        
        #showing-of-text{
            margin-top: 12px;
        }
        #showing-of-text-unarchived{
            margin-top: 12px;
        }
        
        #archive-selected{
            margin-left: 16px;
            border-radius: 3px;
            background: none;
            padding: 12px;
            &.inactive{
                cursor: not-allowed;
                border: 2px solid;
            }
            &.active{
                cursor: pointer;
                border: 2px solid;
            }
        }
        #unarchive-selected{
            margin-left: 16px;
            border-radius: 3px;
            background: none;
            padding: 12px;
            &.inactive{
                cursor: not-allowed;
                color:rgb(213, 216, 217);
                border: 2px solid rgb(213, 216, 217);
            }
            &.active{
                cursor: pointer;
                color: rgb(30, 129, 67);
                border: 2px solid rgb(30, 129, 67);
            }
        }
        #projects-table{
            margin: 12px 0px;
            border: 1px solid;
        
            th{
                border: 1px solid;
                border-left: 0px;
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
            }
            
            td{
                border: 1px solid;
                border-left: 0px;
                padding: 10px;
                font-size: 14px;
                line-height: 20px;    
            }

            tr.footable-detail-show {
                background-color: white;
            }

            th:first-child, td:first-child{
                width: 14px;
                border-right: 0px;
                border-left: 1px solid;
                padding: 10px 0px 10px 16px;
            }

            th:nth-of-type(2), td:nth-of-type(2){
                padding-left: 0px;
            }
            
            .projectNameCol{
                font-weight: bold;
            }

            .installDateCol{
                width: 115px;
            }

            .updatedDateCol{
                width: 180px;
            }

            .createdDateCol{
                width: 100px;
            }
            
        }

        #projectsPagination{
            display: flex;
            justify-content: center;
            list-style: none;
            .chevron-container{
                border: 2px solid;
                border-radius: 50%;
                height: 32px;
                width: 32px;
            }
        }

        #projects-table {
            width: 100%;
        }
        #noProjectsFound{
            position: relative;
            left: -25px;
            border-right: 0px !important; 
        }
    }
    #updated-time-hyphen{
        display: inline;
    }
}



/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentech-projects-container{
        margin: 16px;
        padding-left: 0px;
        padding-right: 0px;
        #projects-table-container {
            #filters-container{
                padding: 16px;
                #filters-bottom{
                    flex-direction: column;
                    align-items: flex-start;
                    .projects-filter{
                        flex-basis: 0;
                        flex-grow: 0;
                    }
                    .store-filter,
                    .search-filter,
                    .status-filter,
                    #projectsSearch{
                        display: block;
                        width: 100%;
                        max-width: 100%;
                    }
                    .date-filter{
                        .date-range-container{
                            gap: 16px;
                            select,input{
                                min-width: 165px;
                            }
                        }
                    }
                    .clear-all{
                        align-self: flex-start;
                    }
                }
            }

            #projects-table {
                .installDateCol{
                    width: 104px;
                }
            
                .updatedDateCol{
                    width: 100px;
                }
            
                .createdDateCol{
                    width: 100px;
                }
            }
        }
    }

    #updated-time-hyphen{
        display: none;
    }
   
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	
    .greentech-breadcrumb{
        margin: 12px 0px;
    }
    .greentech-projects-container {
        margin: 16px;
		padding-left: 0;
		padding-right: 0;

		hr {
			margin: 12px 0px;
			border-top: 1px solid;
		}

        .all-projects-heading{
            margin: 12px 0px;
            display: block !important;
            h1, #newProject{
                display: inherit;
                position: inherit;
                margin: 12px 0px;
                width: 100% ;              
            }
        }



        #projects-table-container{
            #filters-container{
                padding: 16px;
                #filters-bottom{
                    margin-top: 16px;
                    flex-direction: column;
                    align-items: flex-start;
                    .projects-filter{
                        flex-basis: 0;
                        flex-grow: 0;
                    }
                    .store-filter,
                    .search-filter,
                    .status-filter,
                    #projectsSearch{
                        display: block;
                        width: 100%;
                        max-width: 100%;
                    }
                    .date-filter{
                        width: 100%;
                        .date-range-container{
                            flex-direction: column;
                            gap: 16px;
                            div{
                                width: 100%;
                            }
                            select,input{
                                min-width: 100%;
                            }
                        }
                    }
                    .clear-all{
                        align-self: flex-start;
                    }
                }
            }      
            #projectsPagination {
                padding-left: 0;

                .page-selector {
                    width: unset;
                }
            }

            #newProject{
                line-height: 20px;
            }

            .footable-toggle{
                display: none !important; 
            }

            th{
                border-right: 1px solid;
            }
            td{
                border: none !important;
            }

            #showing-of-text{
                display: block;
                margin: 12px 0px;
                #archive-selected{
                    display: inherit;
                    width: 100%;
                    margin-left: 0px;
                    margin: 12px 0px;
                    padding: 12px;
                    &.inactive{
                        cursor: not-allowed;
                    }
                    &.active{
                        cursor: pointer;
                    }
                }
            }
            #showing-of-text-unarchived{
                display: block;
                margin: 12px 0px;
                #unarchive-selected{
                    display: inherit;
                    width: 100%;
                    margin-left: 0px;
                    margin: 12px 0px;
                    padding: 12px;
                    &.inactive{
                        cursor: not-allowed;
                        color:rgb(213, 216, 217);
                        border: 2px solid rgb(213, 216, 217);
                    }
                    &.active{
                        cursor: pointer;
                        color: rgb(30, 129, 67);
                        border: 2px solid rgb(30, 129, 67);
                    }
                }
            }
            #all-projects-table-header-mobile{
                display: inline !important;
                margin: 12px 0px;
                font-weight: bold;
            }

            #projects-table {
                margin: 24px 0px;
                border: none;
                .project-link{
                    font-weight: normal;
                }
               
                thead {
                    th {
                        padding: 5px 10px;
                        border-right: 1px solid !important;
                        border-color: #b9bbc0 !important;
                    }
                    th:nth-of-type(2),
                    th:nth-of-type(3),
                    th:nth-of-type(4),
                    th:nth-of-type(5),
                    th:nth-of-type(6),
                    th:nth-of-type(7) {
                        display: none;
                    }
                
                }

                &.noProjectsFound{
                    thead{
                        display: none;
                    }
                }
    
                tbody {
                    tr.footable-detail-show {
                        border-bottom: 1px solid;
                        padding-top: 5px;
                        padding-bottom: 10px;
                        display: block;
    
                        td:not(:first-child) {
                            border: none;
                            position: relative;
                            padding-left: 150px;
                            display: block;
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }
    
                        td:before {
                            position: absolute;
                            left: 10px;	
                            font-size: 13px;
                            line-height: 1.5;	
                            bottom: 4px;
                            top: 1px;				
                        }
    
                        td:nth-of-type(1):before { content: ""; }
                        td:nth-of-type(2):before { content: "Project Name"; }
                        td:nth-of-type(3):before { content: "Installation Address"; }
                        td:nth-of-type(4):before { content: "Install Date"; }
                        td:nth-of-type(5):before { content: "Updated"; }
                        td:nth-of-type(6):before { content: "Created"; }
                        td:nth-of-type(7):before { content: "Status"; }

                        td.checkboxCol {
                            padding: 3px 16px;
                        }

                        td.projectNameCol {
                            font-weight: normal;
                        }
                    }
                }

                .installDateCol{
                    width: unset;
                }
            
                .updatedDateCol{
                    width: unset;
                }
            
                .createdDateCol{
                    width: unset;
                }
            }
        }  
        #updated-time-hyphen{
            display: inline;
        }
        #noProjectsFound{
            padding-left: 13px !important;
        }
	}
}
