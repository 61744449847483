@import "../variables";

.approved-quotes-page{
	.line-item:first-child{
		border-top: none;
		margin-top: 0px;
	}

	.line-items{
		clear: both;
	}

	.pagination-container{
		margin-top: 30px;
		margin-bottom: 20px;
	}
}

.quote-filters .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .quote-filters input[type="text"]{
	width: 100%;
}

.quote-filters .filter-button-column{
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.quote-filters{
	border-left: solid 1px #b9bbc0;
	border-bottom: solid 1px #b9bbc0;
	border-right: solid 1px #b9bbc0;
	margin-top: 30px;

	&>div{
		padding-right: 15px;
	    padding-left: 15px;
	    padding-bottom: 25px;
	}
}

.sidebar{
	width: 300px;
	position: absolute;
}

.left-content{
	width: 100%;
	padding-left: 325px;
	float: right;
	padding-right: 15px;
}

.approved-quotes-left-nav{
	max-height: 300px;
    overflow: scroll;
    border-bottom: solid 1px #b9bbc0;
    margin-top: 15px;

    ul{
		list-style: none;
		padding-left: 0px;
	}

	li{
		margin-left: 0px;
		font-size: 15px;
		line-height: 1.7;
		/*color: #2370B3;*/
		border-bottom: solid 1px #b9bbc0;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	/*li.selected{
		background-color: #2370B3;
		border-color: #2370B3;
		color: #fff;
	}*/

	li:first-child{
		border-top: solid 1px #b9bbc0;
	}
}

#quotes-view-help-section, .approved-quotes-left-nav li{
	padding-left:30px;
}

.quote-item-count{
	display: inline-block;
}

.quote-item-expire::before{
    content: " | ";
    margin: 0px 10px;
}

.detail-head{
	position: relative;

	.go-back{
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {

	.left-content{
		width: 100%;
	    padding-left: 204px;
	    float: right;
	}

	.sidebar{
		width: 186px;
	    position: absolute;
	}

	#quotes-view-help-section, .approved-quotes-left-nav li{
		padding-left:15px;
	}

	.quote-filters>div{
		padding-right: 5px;
	    padding-left: 5px;
	}

	.quotes-catalog-filter-button{
		width: 100%;
	}
}

@media only screen and (max-width: $mobile-max-width) {

	.left-content{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		clear: both;
	}

	.sidebar{
		width: 100%;
		position: relative;
		clear: both;
	}
	.approved-quotes-page{
		.line-items{
			margin-left: 0;
			margin-right: 0;
		}
	}

	.lower-left{
		padding-left: 0;
		padding-right: 0;

		.button-emphasis{
			width: 100%;
		}
	}

	.approved-quotes-list-box{
		margin-top: 30px;
	}

	#quotes-view-help-section, .approved-quotes-left-nav li{
		padding-left:15px;
	}

	.quote-filters>div{
		padding-right: 0;
		padding-left: 0;
	}

	.quote-filters>div>div{
		margin-top: 20px;
	}

	.quote-item-count{
		display: block;
	}

	.quote-item-expire::before{
	    content: "";
	    margin: 0px;
	}

	.discussions-title{
		padding-right: 15px;
		padding-left: 15px;
	}

	.detail-head .go-back{
		display: block;
		position: relative;
	}

	.visible-xs-block.add-quote-to-cart{
		padding: 20px 15px;
	}

}