@import "../variables";

.table-responsive > .fixed-column {
    position: absolute;
    display: inline-block;
    width: auto;
    border-right: 1px solid #ddd;
}

.table-responsive > .fixed-column td.cell-white {
    background-color: #fff;
    border-left: 1px solid #ddd;
}

.conduit-fill-container {
    max-width: 90%;
    overflow-x: scroll;
    margin: 25px auto;
}

.conduit-fill-container td {
    text-align: center;
}

td.cell-shading {
    background-color: #f8f8f8;
}

.conduit-fill-text {
    margin-top: 10px;
}

.conduit-fill-list {
    margin-bottom: 25px;
}

.mobile-table-responsive {
    margin-top: 20px;
}

@media(min-width:768px) {
    .table-responsive>.fixed-column {
        display: none;
    }
}