@import "../variables";
@import "../mixins";

body.solar-template {
	a.tour-step-backdrop {
		background-color: #fff;
	}	

	header {
		#selectWebsiteActive{
			font-size: 12px;
		}

		.solar-content-section {
			max-width: 1440px;
			margin: auto;
		}

		.mobile-search {
			display: none;
		}

		.solar-secure-checkout {
			text-align: center;
			font-size: 12px;
			font-weight: 600;
			line-height: 1.67;
			letter-spacing: 0.1px;
		}

		.solar-header-top {
			height: 32px;
			color: #fff;
			padding-right: 16px;
			padding-top: 7px;

			font-size: 12px;
			font-weight: normal;
			letter-spacing: 0.11px;
			line-height: 20px;

			a, a:hover {
				color: #fff;
			}	

			.need-help {
				padding-left: 16px;
			}	
			
			#changeSiteTab{
				position: absolute;
				right: 0;
				top: 3px;
				width: 200px;
				background: white;
				color: black;
				height: 29px;
				padding-left: 6px;
				padding-top: 7px;
				z-index: 2;

				&::before{
					border-radius: 8px 0 0 0;
					left: -17px;
					-webkit-transform: skew(-25deg, 0deg);
					transform: skew(-25deg, 0deg);
					display: block;
					content: " ";
					position: absolute;
					top: 0px;
					height: 101%;
					width: 25px;
					background-color: #FFF;
					z-index: 1;
				}

				a{
					font-size: 13px;
					color: rgba(43, 61, 65, 0.75);
					font-weight: bold;
				}
			}
		}

		.solar-header-middle {
			.solar-content-section {
				height: 80px;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				align-items: center;
				-ms-flex-align: center;

				.header-menu {
					display: none;
				}

				.mobile-menu {
					position: relative;

					> .mobile-menu-content {
						position: absolute;
				    	display: none;
				    	left: 0px;
					    top: 48px;
					    background-color: #fff;
					    z-index: 101;
					    overflow: hidden;
				    	
				    	ul {
				    		margin: 0 0 0 0;
				    		padding: 0;
				    		list-style-type: none;
				    		position: relative;
				    		width: 320px;
				    		background-color: #fff;

				    		li {				    		
				    			a {
				    				display: block;
				    				padding: 12px 16px;
				    				cursor: pointer;
				    				font-weight: 100;
				    				text-align: left;
				    				font-size: 14px;
									line-height: 1.7;
									letter-spacing: 0.1px;
									position: relative;
				    			}

				    			ul {
				    				display: none;
				    			}
				    		}

				    		li {
				    			a.menu-header {
				    				padding-left: 44px;
				    				font-weight: 600;
				    			}
				    			a.menu-header:before {
				    				font-family: 'FontAwesome';
				    				position: absolute;
				    				content: "\f053";
				    				top: 13px;
				    				left: 18px;
				    			}
				    		}

				    		li.children.open {
				    			> ul {
				    				display: block !important;
				    			}
				    		}

				    		li.children ul {
				    			position: absolute;
				    			top: -1px;
				    			left: 320px;
				    		}

				    		li.children > a:after {
				    			font-family: 'FontAwesome';
					            position: absolute;
					            right: 10px;
					            top: 16px;
					            content: "\f054";
					            font-size: 0.8em;
				    		}
				    	}
					}
				}

				.mobile-menu.open {
					> .mobile-menu-content {
						display: block !important;
					}
				}

				.header-image {
					width: 157px;

					img.ced-logo {
						max-width: 117px;
						max-height: 48px;
						margin-left: 16px;
					}
				}	

				.my-account {
	            	width:190px;

	            	.my-account-mobile {
	            		display: none;
	            	}	            	

	            	.my-account-link {
	            		display: inline-block;
	            		font-size: 12px;
						font-weight: 600;
						line-height: 1.67;
						letter-spacing: 0.1px;
						padding-top: 6px;
						position: relative;
						padding-left: 29px;
					    padding-right: 8px;
					    padding-bottom: 6px;
					    cursor: pointer;
					    border-radius: 2px;	
					    z-index: 1;				    

					    .my-account-menu {
					    	position: absolute;
					    	display: none;
					    	left: -62px;
							top: 31px;
							background-color: #fff;
					    	
					    	ul {
					    		margin: 3px 0 0 0;
					    		padding: 0;
					    		list-style-type: none;
					    		width: 240px;
					    		position: relative;

					    		li {
					    			
					    			a {
					    				display: block;
					    				padding: 12px;
					    				cursor: pointer;
					    				font-weight: 100;
					    				font-size: 14px;
										line-height: 1.43;
										letter-spacing: 0.1px;
					    			}

					    			.my-account-name {
					    				font-size: 16px;
										font-weight: normal;
										letter-spacing: 0.15px;
										line-height: 24px;
										padding: 16px;
					    			}

					    			.my-account-account {
										font-size: 14px;
										line-height: 20px;
										letter-spacing: 0.13px;
										color: #606e71;
										padding-left: 16px;
										padding-right: 16px;
										font-weight: bold;
					    			}

					    			.my-account-change-link {
					    				a {
						    				font-size: 12px;
											line-height: 1.67;
											letter-spacing: 0.1px;
											padding-left: 16px;
											padding-top: 0;
										}
					    			}
					    		}
					    	}

					    	ul:before {
					    		content: "";
							    position: absolute;
							    margin: auto;
							    left: 0;
							    right: 0;
							    width: 10px;
							    height: 10px;
							    background: #fff;
							    top: -6px;
							    border-top: 1px solid #d5d8d9;
							    border-right: 1px solid #d5d8d9;
							    tranform: rotate(-45deg);
							    -moz-transform: rotate(-45deg);
							    -webkit-transform: rotate(-45deg);
					    	}
					    }
	            	}

	            	.my-account-link:hover {
	            		.my-account-menu {
					    	display: block;
					    }
	            	}

	            	.my-account-link:before {
	            		font-family: 'FontAwesome';
					    position: absolute;
					    left: 8px;
					    top: -2px;
					    content: "\f007";
					    font-size: 1.7em;
	            	}

	            	.my-account-link:after {
	            		font-family: 'FontAwesome';
					    position: relative;
					    right: 0;
					    top: -2px;
					    content: "\f0dd";
					    width: 6px;
					    height: 3px;
					    color: #959ea0;
	            	}            	

	            	a.logout {
	            		font-size: 12px;
						line-height: 1.67;
						letter-spacing: 0.1px;
						margin-right: 15px;
						margin-left: 10px;
	            	}

	            	.seperator {
	            		width: 1px;
						height: 32px;
	            	}
	            }

	            .login {
	            	width: 199px;
	            	text-align: right;

	            	> div {
	            		display: inline-block;  

					    a {
					    	padding-top: 6px;
					    	padding-left: 6px;
					    	padding-right: 6px;
					    	padding-bottom: 6px;				    	
					    	font-size: 12px;
							line-height: 1.67;
							letter-spacing: 0.1px;
							display: block;
					    }	
	            	}

	            	div:last-of-type {
	            		padding-right: 9px;
	            	}

	            	.seperator {
	            		width: 1px;
						height: 32px;
	            	}
	            }

	            .cart {
	            	width:115px;
	            	padding-left: 40px;
	            	
	            	a {
		            	font-size: 12px;
						font-weight: 600;
						line-height: 1.67;
						letter-spacing: 0.1px;
						position: relative;
						cursor: pointer;
					}

					.cart-link, .cart-link-mobile {
						.cart-icon {
							position: absolute;
						}
					}

					.cart-link {
						.cart-icon {
							font-size: 1.7em;
							left: -26px;
							top: -2px;
						}
					}

	            	.cart-link-mobile {
	            		display: none;

						.cart-icon {
							font-size: 2.7em;
							left: 12px;
							top: 4px;
						}
	            	}
	            }		

				.search {
					flex: 1;
					-webkit-box-flex: 1;
			  		-moz-box-flex:  1;
			  		-webkit-flex:  1;
					-ms-flex:  1;
					  
					.barcode-scan-icon {
						display: none;
					}

					#search-input {
		                height: 38px;
						padding-left: 12px;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.2px;	
						width:100%;
						z-index: 3;
				    	-moz-appearance: none;
				        -webkit-appearance: none;
		            }

		            #search-input::placeholder {
						color: #959ea0;
		            }

		            .search-input-container {
		            	display: table-cell;
		            	vertical-align: middle;
		            	position: relative;

		            	#search-input-icon {
		            		position: absolute;
		            		width: 36px;
							height: 35px;
						    right: 1px;
						    top: 23px;
						    color: #959ea0;
						    background-color: #fff;
						    cursor: pointer;

						    i {
						    	padding-top: 10px;
	    						padding-left: 12px;
						    }
		            	}
		            }	     

		            #search-box {
		            	display: table;
		            	width: 100%;
		            	position: relative;
		            	height: 80px;
		            	padding-right: 15px;

		            	.search-categories {
		            		display: table-cell;
		            		width: 140px;
		            		vertical-align: middle;
		            	}
		            }	            

		            .advanced-search {
		            	height: 20px;
						text-decoration: none;
						display: table-cell;
						width: 140px;
						padding-left: 16px;
						vertical-align: middle;
						font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;
						font-size: 12px;
						line-height: 1.67;
						letter-spacing: 0.1px;
		            }

		            .styled-select {
		            	height: 38px;					
						display: inline-block;
						width: 140px;
						vertical-align: middle;
					    overflow: hidden;
					    cursor: pointer;
					    position: relative;
					    margin-left: 41px;
					    border-top-left-radius: 2px;
					    border-bottom-left-radius: 2px;
					    border-top: 1px solid #959ea0;
						border-left: 1px solid #959ea0;
						border-bottom: 1px solid #959ea0;				    

			            select {
			                background: transparent;
			                border: 0;
			                border-radius: 0;
			                -webkit-appearance: none;
			                width: 140px;
			                top: 0;
			                left: 0px;
			                background-color: white;
			                height: 38px;
			                margin-top: 0;
			                position: absolute;
			                z-index: 2;
			                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			                filter: alpha(opacity=0);
			                -khtml-opacity: 0;
			                -moz-opacity: 0;
			                opacity: 0;
			                cursor: pointer;
			                padding-right: 0;		                
			            }

			            div.select-output {
			                z-index: -1;
			                height: 38px;
			                overflow: hidden;
			                padding: 1rem 1.7rem;
			                cursor: pointer;
			                width: 140px;
			                white-space: nowrap;
			                font-size: 14px;
							font-weight: 600;
							line-height: 1.14;
							letter-spacing: 0.1px;
							color: #657878;
			            }
			        }

			        .styled-select:after {
			            font-family: 'FontAwesome';
			            position: relative;
			            right: -116px;
					    top: -34px;
					    content: "\f0dd";
					    padding-top: 7px;
					    padding-bottom: 15px;
					    padding-right: 10px;
					    padding-left: 5px;
			        }
				}
			}
		}

		.solar-header-bottom {
			height: 56px;
			padding-left: 16px;

			.solar-content-section {
				@include flexbox;
				@include flexwrap(nowrap);
				
				ul.headerFlexMenu {
					@include flex(1);
					height: 46px;
										
					.flexMenu-viewMore > a:after {
						font-family: 'FontAwesome';
						position: relative;
						right: -5px;
						top: -3px;
						content: "\f0dd";
						font-size: 0.8em;
						color: #2b3d41;
					}
					.flexMenu-popup {
						border-top: 1px solid #dde1e2;
						border-right: 1px solid #dde1e2;
						border-left: 1px solid #dde1e2;
						z-index: 2;
						background-color: #fff;
						margin: 0;
						padding: 0;
						list-style-type: none;
						width: 250px;

						> li {
							float: none;
							display: block;

							a {
								display: block;
								padding: 10px 20px;
								text-align: left;
							}
						}
					}
					.flexMenu-popup > li > a:hover {
						text-decoration: underline;
					}
				}

				> ul {
					margin: 0;
					padding: 0;
					list-style-type: none;

					> li {
						display: inline-block;

						> a {
							display: block;
							font-size: 16px;
							font-weight: 600;
							line-height: 1.5;
							letter-spacing: 0.2px;
							padding: 15px 16px;
						}

						> button {
							padding: 11px 14px 11px 14px;
							font-size: 16px;
							font-weight: 600;
							line-height: 1.5;
							border-radius: 5px;
						}

						button {
							border: none;
							background: transparent;
						}

						> a:hover {
							text-decoration: none;						
						}

						.button-emphasis{
							position: relative;
							top: -9px;
							padding: 5px 12px;
						}
					}

					/*New dropdown menu*/
					li.dropdown-container{
						position: relative;
						

						> button:after {
							font-family: 'FontAwesome';
				            position: relative;
				            right: -5px;
				            top: -3px;
				            content: "\f0dd";
				            font-size: 0.8em;
						}

						&.open{

							> button:after {
								top: 2px;
								content: "\f0de";
							}

							#dropdownSub {
								display: flex;
								
							}
						}

						&.open-full{

							#dropdownSub {
								> ul{
									border-radius: 10px 0 0 10px;
									border-right: 1px #d5d8d9 solid;
								}

								#categorySubMenu{
									display: block;
								}
								
							}
						}

						/*The dropdown itself*/
						#dropdownSub {
							display: none;
							position: absolute;
							z-index: 2;
	    					background-color: #fff;
	    					margin: 0;
							border: 1px #d5d8d9 solid;
							border-radius: 10px;
							top: 55px;
							left: -8px;

							

							> ul{
								list-style-type: none;
								margin: 0;
								padding: 16px 18px;
								width: 288px;
								background-color: #f8f9f9;
								border-radius: 10px;

								> li.children{
									border-top: 1px transparent solid;
								}

								> li.children:not(.open):not(:hover) + li.children:not(.open):not(:hover) {
									border-top: 1px #d5d8d9 solid;
								}
							}

							#categorySubMenu{
								width: 558px;
								padding: 16px 28px;
								display: none;

								div {
									border-bottom: 1px #d5d8d9 solid;
									font-size: 18px;
									font-weight: bold;
								}

								div::after {
										font-family: 'FontAwesome';
										position: relative;
										right: -5px;
										top: -3px;
										content: "\f178";
										font-size: 0.8em;
								}

								ul {
									list-style-type: none;
									padding: 0;
								}

								li {
									margin-top: 16px;
								}

								a {
									padding: 0;
								}
							}

							button, a{
								text-align: left;
								padding: 10px 10px;
								width: 100%;
							}

							a {
								display: block;
							}

							button {
								display: flex;
								justify-content: space-between;
								align-items: center;
								border-radius: 5px;

								i {
									display: none;
								}
							}

							/*button:after {
								font-family: 'FontAwesome';
								position: relative;
								right: -5px;
								top: -3px;
								content: "\f054";
								font-size: 0.8em;
							}*/

							/*Flyout menu*/
							.children ul {
								display: none;
								position: absolute;
								list-style-type: none;
								padding: 0;
								margin: 0;
								left: 248px;
								background-color: #fff;
								top: -1px;
								width: 260px;
							}
						}

						button img {
							max-width: 24px;
							max-height: 24px;
							margin-right: 5px;
						}

					}

					> li.dropdown {
						position: relative;

						a img {
							max-width: 24px;
							max-height: 24px;
							margin-right: 5px;
						}

						> a {
							padding-right: 19px;			
						}

						> ul.dropdown-sub {
							display: none;
							position: absolute;
							z-index: 2;
	    					background-color: #fff;
	    					margin: 0;
	    					padding: 0;
	    					list-style-type: none;
	    					width: 260px;

	    					> li {    
	    						position: relative;

	    						> form > a {
	    							display: block;
	    							padding: 10px 20px;
	    						}

	    						> a {
	    							display: block;
	    							padding: 10px 20px;
	    						}
	    						> ul {
	    							display: none;
	    							position: absolute;
	    							list-style-type: none;
	    							padding: 0;
	    							margin: 0;
	    							left: 248px;
								    background-color: #fff;
								    top: -1px;
								    width: 260px;

								    li {
								    	border-width: 0;

								    	a {
								    		display: block;
								    		padding: 4px 12px;	
								    	}	

								    	a:hover {
								    		background-color: #fff;
								    	}						    	
								    }

								    li:first-child {
		    							a {
		    								padding: 14px 12px 4px 12px;
		    							}
		    						}

		    						li:last-child {
		    							a {
		    								padding: 4px 12px 14px 12px;
		    							}
		    						}							    
	    						}    						
	    					}

	    					> li.children:after {
	    						font-family: 'FontAwesome';
					            position: absolute;
					            right: 10px;
					            top: 13px;
					            content: "\f054";
					            font-size: 0.8em;
	    					}

	    					> li:hover {
	    						> ul {
	    							display: block;
	    						}
	    					}
						}

						> a:after {
							font-family: 'FontAwesome';
				            position: relative;
				            right: -5px;
				            top: -3px;
				            content: "\f0dd";
				            font-size: 0.8em;
							color: #2b3d41;
						}
					}

					> li.dropdown:hover {
						> ul.dropdown-sub {
							display: block;
						}
					}

					> li.account-select{
						max-width: 200px;
						margin-left: 33px;
						padding-top: 9px;
						margin-right: 16px;

						.select-header{
							font-size: 12px;
							color: #606e71;
						}
					}

					.my-account-account{
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 185px;
						max-height: 15px;
						display: inline-block;

						a{
							color: #2b3d41;
						}

						&::before {
							font-family: 'FontAwesome';
							float:right;
							margin-left: 5px;
							content: "\f0dd";
							font-size: 0.8em;
							color: #2b3d41;
						}
					}
				}
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	body.solar-template {

		.autocomplete-suggestions {
			width: 720px !important;
			transform: translate(-52%, 0);
			left: 50%;
			position: fixed !important;
			top: 84px;
		}

		header {
			.solar-header-top {
				.solar-content-section {
					padding-right: 16px;

					.need-help {
						padding-left: 16px;
					}
				}		
			}

			.solar-header-middle {
				.solar-content-section {
					height: 48px;

					.mobile-menu {
						position: relative;

						> .mobile-menu-content {
							position: absolute;
					    	display: none;
					    	left: 0px;
						    top: 48px;
						    background-color: #fff;
						    z-index: 101;
						    overflow: hidden;
					    	
					    	ul {
					    		margin: 0 0 0 0;
					    		padding: 0;
					    		list-style-type: none;
					    		position: relative;
					    		width: 320px;
					    		background-color: #fff;

					    		li {				    		
					    			a {
					    				display: block;
					    				padding: 12px 16px;
					    				cursor: pointer;
					    				font-weight: 100;
					    				text-align: left;
					    				font-size: 14px;
										line-height: 1.7;
										letter-spacing: 0.1px;
										position: relative;
					    			}

					    			a img {
										max-width: 24px;
										max-height: 24px;
										margin-right: 5px;
									}

					    			ul {
					    				display: none;
					    			}
					    		}

					    		li {
					    			a.menu-header {
					    				padding-left: 44px;
					    				font-weight: 600;
					    			}
					    			a.menu-header:before {
					    				font-family: 'FontAwesome';
					    				position: absolute;
					    				content: "\f053";
					    				top: 13px;
					    				left: 18px;
					    			}
					    		}

					    		li.children.open {
					    			> ul {
					    				display: block !important;
					    			}
					    		}

					    		li.children ul {
					    			position: absolute;
					    			top: -1px;
					    			left: 320px;
					    		}

					    		li.children > a:after {
					    			font-family: 'FontAwesome';
						            position: absolute;
						            right: 10px;
						            top: 16px;
						            content: "\f054";
						            font-size: 0.8em;
					    		}
					    	}
						}
					}

					.mobile-menu.open {
						> .mobile-menu-content {
							display: block !important;
						}
					}

					.header-menu {
						display: block;
						width: 64px;
						height: 48px;

						> a {
							display: block;
							font-size: 10px;
							font-weight: 600;
							line-height: 1.2;
							letter-spacing: 0.1px;
							text-align: center;
							text-decoration: none;
							height: 48px;

							i {
								display: block;
								width: 100%;
								height: 32px;
								text-align: center;
								font-size:2.4em;
								padding-top: 7px;
							}
						}

						a:hover {
							text-decoration: none;
						}
					}

					.header-image {
						width: 78px;

						img.ced-logo {
							max-width: 78px;
							max-height: 32px;
							margin-left: 11px;
						}
					}

					.search {	            
						.advanced-search {
							display: none;
						}

						.barcode-scan-icon {
							width: 33px;
							display: table-cell;
							vertical-align: middle;

							a {
								display: block;
								color: #606e71;

								i {
									padding-left: 13px;
								}
							}
						}
					}

					.my-account, .cart, .login {
						width: 64px;
						height: 48px;	

						a {
							font-size: 10px;
							font-weight: 600;
							line-height: 1.2;
							letter-spacing: 0.1px;
						}				
					}

					.my-account {
						.my-account-link, .logout {
							display: none;
						}

						.my-account-mobile {
							display: block;
							position: relative;

							a {
								display: block;
								text-align: center;
								padding-top: 32px;
								padding-bottom: 4px;
							}

							a:hover {
								text-decoration: none;
							}

							.my-account-menu-mobile {
						    	position: absolute;
						    	display: none;
						    	right: -1px;
							    top: 48px;
							    background-color: #fff;
							    z-index: 100;
						    	
						    	ul {
						    		margin: 0 0 0 0;
						    		padding: 0;
						    		list-style-type: none;
						    		width: 320px;
						    		position: relative;

						    		li {
						    			
						    			a {
						    				display: block;
						    				padding: 8px 10px;
						    				cursor: pointer;
						    				font-weight: 100;
						    				text-align: left;
						    				font-size: 14px;
											line-height: 1.43;
											letter-spacing: 0.1px;
						    			}

						    			.my-account-name {
						    				font-size: 16px;
											font-weight: normal;
											letter-spacing: 0.15px;
											line-height: 24px;
											padding: 16px;
						    			}

						    			.my-account-account {
											font-size: 14px;
											line-height: 20px;
											letter-spacing: 0.13px;
											color: #606e71;
											font-weight: bold;
						    			}

						    			.my-account-change-link {
						    				a {
							    				font-size: 12px;
												line-height: 1.67;
												letter-spacing: 0.1px;
												padding: 0;
											}
						    			}
						    		}
						    	}

						    	ul:before {
						    		display: none;
						    	}
						    }
						}

						.my-account-mobile.open {
							.my-account-menu-mobile {
								display: block;
							}
						}

						.my-account-mobile:before {
		            		font-family: 'FontAwesome';
						    position: absolute;
						    left: 22px;
							top: 3px;
						    content: "\f007";
						    font-size: 1.7em;
		            	}
					}

					.cart {
						padding-left: 0;

						.cart-link {
							display: none;
						}

						.cart-link-mobile {
		            		display: block;
		            		text-align: center;
							padding-top: 32px;
							padding-bottom: 4px;						
		            	}

		            	a:hover {
		            		text-decoration: none;
		            	}

		            	a:before {
		            		left: 19px;
							top: 5px;
							font-size: 2.4rem;
		            	}
					}

					.login {
						.login-link {
							width: 100%;

							a {
								display: block;
							    text-align: center;
							    padding-top: 32px;
							    padding-bottom: 3px;
							    padding-left: 0;
							    padding-right: 0;
							    font-size: 10px;
								font-weight: 600;
								line-height: 1.2;
								letter-spacing: 0.1px;
							}
							a:hover {
								text-decoration: none;
							}

							a:before {
								left: 22px;
								top: 5px;
								font-size: 2.4em;
							}
						}
					}

					.seperator {
						display: none;
					}
				}
			}

			.solar-header-bottom { 
				display: none;
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	body.solar-template {
		header {
			.solar-header-top {
				display: none;		
			}

			.solar-header-middle.checkout-header {
				.header-image {
					width: 90px;
					flex: 0;
				    -webkit-box-flex: 0;
				    -moz-box-flex: 0;
				    -webkit-flex: 0;
				    -ms-flex: 0;

					img.ced-logo {
						max-width: 78px;
						max-height: 32px;
						margin-left: 11px;
					}
				}

				.search {
					display: block;
				}
			}

			.solar-header-middle {
				.solar-content-section {
					height: 48px;

					.mobile-menu {
						position: relative;

						> .mobile-menu-content {
							position: absolute;
					    	display: none;
					    	left: 0px;
						    top: 48px;
						    background-color: #fff;
						    z-index: 101;
						    overflow: hidden;
					    	
					    	ul {
					    		margin: 0 0 0 0;
					    		padding: 0;
					    		list-style-type: none;
					    		position: relative;
					    		width: 320px;
					    		background-color: #fff;

					    		li {				    		
					    			a {
					    				display: block;
					    				padding: 12px 16px;
					    				cursor: pointer;
					    				font-weight: 100;
					    				text-align: left;
					    				font-size: 14px;
										line-height: 1.7;
										letter-spacing: 0.1px;
										position: relative;
					    			}

					    			a img {
										max-width: 24px;
										max-height: 24px;
										margin-right: 5px;
									}

					    			ul {
					    				display: none;
					    			}
					    		}

					    		li {
					    			a.menu-header {
					    				padding-left: 44px;
					    				font-weight: 600;
					    			}
					    			a.menu-header:before {
					    				font-family: 'FontAwesome';
					    				position: absolute;
					    				content: "\f053";
					    				top: 13px;
					    				left: 18px;
					    			}
					    		}

					    		li.children.open {
					    			> ul {
					    				display: block !important;
					    			}
					    		}

					    		li.children ul {
					    			position: absolute;
					    			top: -1px;
					    			left: 320px;
					    		}

					    		li.children > a:after {
					    			font-family: 'FontAwesome';
						            position: absolute;
						            right: 10px;
						            top: 16px;
						            content: "\f054";
						            font-size: 0.8em;
					    		}
					    	}
						}
					}

					.mobile-menu.open {
						> .mobile-menu-content {
							display: block !important;
						}
					}

					.header-menu {
						display: block;
						width: 64px;
						height: 48px;

						> a {
							display: block;
							font-size: 10px;
							font-weight: 600;
							line-height: 1.2;
							letter-spacing: 0.1px;
							text-align: center;
							text-decoration: none;
							height: 48px;

							i {
								display: block;
								width: 100%;
								height: 32px;
								text-align: center;
								font-size:2.4em;
								padding-top: 7px;
							}
						}

						a:hover {
							text-decoration: none;
						}
					}

					.header-image {
						flex: 1;
					    -webkit-box-flex: 1;
					    -moz-box-flex: 1;
					    -webkit-flex: 1;
					    -ms-flex: 1;

						img.ced-logo {
							max-width: 78px;
							max-height: 32px;
							margin-left: 11px;
						}
					}

					.search {	            
						display: none;
					}				

					.my-account, .cart, .login {
						width: 64px;
						height: 48px;	

						a {
							font-size: 10px;
							font-weight: 600;
							line-height: 1.2;
							letter-spacing: 0.1px;
						}				
					}

					.my-account.has-cart {
						.my-account-mobile {
							.my-account-menu-mobile {
								right: -64px;
							}
						}
					}

					.my-account {
						.my-account-link, .logout {
							display: none;
						}

						.my-account-mobile {
							display: block;
							position: relative;

							a {
								display: block;
								text-align: center;
								padding-top: 32px;
								padding-bottom: 4px;
							}

							a:hover {
								text-decoration: none;
							}

							.my-account-menu-mobile {
						    	position: absolute;
						    	display: none;
						    	right: 0px;
							    top: 48px;
							    background-color: #fff;
							    z-index: 100;
						    	
						    	ul {
						    		margin: 0 0 0 0;
						    		padding: 0;
						    		list-style-type: none;
						    		width: 320px;
						    		position: relative;

						    		li {
						    			
						    			a {
						    				display: block;
						    				padding: 8px 10px;
						    				cursor: pointer;
						    				font-weight: 100;
						    				text-align: left;
						    				font-size: 14px;
											line-height: 1.43;
											letter-spacing: 0.1px;
						    			}

						    			.my-account-name {
						    				font-size: 16px;
											font-weight: normal;
											letter-spacing: 0.15px;
											line-height: 24px;
											padding: 16px;
						    			}

						    			.my-account-account {
											font-size: 14px;
											line-height: 20px;
											letter-spacing: 0.13px;
											color: #606e71;
											font-weight: bold;
						    			}

						    			.my-account-change-link {
						    				a {
							    				font-size: 12px;
												line-height: 1.67;
												letter-spacing: 0.1px;
												padding: 0;
											}
						    			}
						    		}
						    	}

						    	ul:before {
						    		display: none;
						    	}
						    }
						}

						.my-account-mobile.open {
							.my-account-menu-mobile {
								display: block;
							}
						}

						.my-account-mobile:before {
		            		font-family: 'FontAwesome';
						    position: absolute;
						    left: 22px;
							top: 3px;
						    content: "\f007";
						    font-size: 1.7em;
		            	}
					}

					.cart {
						padding-left: 0;

						.cart-link {
							display: none;
						}

						.cart-link-mobile {
		            		display: block;
		            		text-align: center;
							padding-top: 32px;
							padding-bottom: 4px;						
		            	}

		            	a:hover {
		            		text-decoration: none;
		            	}

		            	a:before {
		            		left: 19px;
							top: 5px;
							font-size: 2.4rem;
		            	}
					}

					.login {
						.login-link {
							width: 100%;

							a {
								display: block;
							    text-align: center;
							    padding-top: 32px;
							    padding-bottom: 3px;
							    padding-left: 0;
							    padding-right: 0;
							    font-size: 10px;
								font-weight: 600;
								line-height: 1.2;
								letter-spacing: 0.1px;
							}
							a:hover {
								text-decoration: none;
							}

							a:before {
								left: 22px;
								top: 5px;
								font-size: 2.4em;
							}
						}
					}

					.seperator {
						display: none;
					}
				}
			}

			.solar-header-bottom { 
				display: none;
			}

			.mobile-search {
				display: block;

				.mobile-search-box {
					.mobile-search-input-container {
						padding: 7px 8px;
						@include flexbox;

						.mobile-search-input-box {
							@include flex(1);
							position: relative;

							#mobile-search-input-icon {
								position: absolute;
								right: 9px;
								top: 1px;
								padding-top: 6px;
								padding-bottom: 6px;
								padding-left: 5px;
								padding-right: 5px;
								background-color: #fff;
								color: #959ea0;
							}
	
							#mobile-search-input {
								width: 100%;
								height: 34px;
								font-size: 16px;
								line-height: 1.5;
								letter-spacing: 0.2px;
								border-color: #959ea0;
							}
							#mobile-search-input::placeholder {
								color: #959ea0;
							}
						}

						.barcode-scan-icon {
							width: 40px;

							a {
								display: block;
								color: #606e71;

								i {
									padding-top: 12px;
									padding-left: 13px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1280px) {
	body.solar-template {
		header {
			.solar-header-middle {
				.search {	            
					.advanced-search {
					    position: absolute;
					    top: 60px;
					    right: 7px;
					}
				}
			}
		}
	}
}