.greentech-integrations{
    .content-container{
        padding: 16px;
    }
    .dashboard-layout{
        display: flex;
        gap: 16px;
    }
    .category, .description, .int-projects-container{
        font-size: 14px !important;
        font-weight: 400;
    }
    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: .11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;
        a, span {
            padding: 10px;
        }
    }

}
.greentech-my-links-left-container{
    a{
        justify-content: flex-start !important;
    }
}

@media only screen and (max-width: $tablet-max-width) {

    .nav-and-content-container{
        display: flex;
        flex-direction: column;
    }
    .ipw-container{
        width: 100% !important;
        flex-basis: unset !important;
        // padding-right: 32px;
    }
    .home-left-content{
        margin: 16px 0px 16px 16px !important;
    }
}
