@import "../variables";

.copper-charts,
.aluminum-charts {
    margin-top: 25px;
    text-align: center;

    .copper-chart,
    .aluminum-chart {
        margin-top: 25px;
    }
}

.chart-container {
    position: relative;
    overflow: hidden;
    margin: 35px auto;
    max-width: 600px;
    font-size: 0;
    display: flex;

    iframe {
        a {
            display: none;
        }
    }
}
