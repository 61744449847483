@import "../variables";

@media print {
	body.solar-template #pageWrapper  {
		.overlay-container {
			align-items: stretch;

			.main-content {
				max-width: none;
				margin-left: 0;
				margin-right: 0;
				width: 1000px;
			}
		}
	}
}

.background-shade {
	background: rgb(248, 249, 249);
}


.admin-web-order-detail {
	.admin-title-back {
		margin-bottom: 10px;
	}

	.order-status {
		display: inline-block;
		padding: 0 10px;
		border-radius: 100px;
		border: 2px solid rgb(46, 119, 181);
		height: 32px;
		line-height: 28px;
	}

	.buttons {
		margin-top: 0;
		text-align: right;

		a:hover, a:active, a:focus {
			text-decoration: none;
		}

		a {
			display: inline-block;
			margin-left: 10px;
			font-size: 14px;

			&.half-button-left {
				width: none;
				float: none;
			}
		
			&.half-button-right {
				width: none;
				float: none;
			}
		}
	}

	.items-title {
		margin-top: 10px;

		h2 {
			font-size: 24px;
		}
	}

	.price-col {
		text-transform: lowercase;
	}

	.webOrderItemAckSection {
		white-space: nowrap;
	}

	.order-details,
	.additional-info {
		padding-top: 10px;
		border-radius: 6px;
		border: 1px solid rgb(213, 216, 217);
		min-height: 151px;
		margin: 0 0 15px 0;
		line-height: 20px;

		.header {
			display: inline-block;
			font-weight: bold;
			padding-bottom: 5px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding-bottom: 3px;
			}
		}

		.summary-detail {
			.summary {
				> div {
					padding-bottom: 3px;
				}

				.value {
					padding-left: 0;
					text-align: right;
				}
			}

			.text {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.additional-info {
		border: none;
	}

	.collapsible-section {
		border-radius: 3px;
		border: 1px solid rgb(213, 216, 217);
		min-height: 40px;
		margin-bottom: 20px;

		&.closed {
			.collapsible-header {
				background-color: #fff;

				h5 {
					color: rgb(43, 61, 65);
					font-size: 14px;
					font-weight: bold;
					height: 20px;
					letter-spacing: 0.13px;
					line-height: 20px;
				}

				&::after {
					font-family: 'FontAwesome';
					position: absolute;
					right: 30px;
					top: 16px;
					content: "\f067";
					font-size: 18px;
				}
			}
		}

		.collapsible-header {
			h5 {
				color: rgb(43, 61, 65);
				font-size: 14px;
				font-weight: bold;
				height: 20px;
				letter-spacing: 0.13px;
				line-height: 20px;
			}

			&::after {
				font-family: 'FontAwesome';
				position: absolute;
				right: 30px;
				top: 16px;
				content: "\f068";
				font-size: 18px;
			}
		}
	}

	.order-footer-wrapper {
		padding-left: 0;

		.order-footer {
			padding-top: 10px;
			border-radius: 6px;
			border: 1px solid rgb(213, 216, 217);
			min-height: 151px;
			margin: 0;
			padding: 15px;

			.header {
				display: inline-block;
				font-weight: bold;
				padding-bottom: 5px;
			}

			.summary {
				> div {
					padding-bottom: 3px;
				}

				.value {
					padding-left: 0;
					text-align: right;
				}
			}

			.text {
				padding-right: 0;
			}
		}
	}

	.order-items {
		width: 100%;

		th, td {
			border: 1px solid rgb(213, 216, 217);

			&.order {
				padding: 10px 5px;
				font-size: 14px;
				
			}
			&.show-more {
				padding: 5px;
			}
		}

		.toggle-text {
			color: rgb(46, 119, 181);
			font-size: 12px;
			font-weight: normal;
			letter-spacing: 0.11px;
			line-height: 20px;
			cursor: pointer;
		}

		.show-more-link {
			display: inline-block;
			padding: 0 5px;
			margin: 0;
		}

		ul {
			list-style: none;
			margin: 10px 0 0 0;
			padding: 0;
			background: rgb(248, 249, 249);

			li {
				margin: 0;
				padding: 0 0 0 14px;
				color: rgb(96, 110, 113);
				font-size: 12px;
				font-weight: normal;
				letter-spacing: 0.11px;
				line-height: 20px;
			}
		}

		.more-text {
			display: none;
		}
	}

	.tracking-number {
		color: #2370B3;
	}

	#linkShareSection {
		color: #2370B3;
	}

	.approveRejectBox {
		background-color: #f4f4f4;
		border: solid 1px #b9bbc0;
		padding: 15px;
		margin: 20px 0 0 0;

		.header {
			display: block;
			font-weight: bold;
			padding-bottom: 5px;
		}

		.text {
			display: inline-block;
			margin-top: 5px;
			padding: 0;
		}

		.buttonDiv {
			display: inline-block;
			margin-top: 20px;
			padding: 0;

			.button-emphasis{
				padding: 7px 12px;
				margin-right: 5px;
			}
		}

		.alertIcon{
			margin-left: -17px;
			padding-right: 5px;
		}
	}
}

#copySuccess {
	display: none;
	padding: 10px;
	margin-bottom: 10px;
	background-color: #1f643f;
	color: #fff;
	border-radius: 3px;
}

#linkShare {
	width: 92%;
	padding: 10px;
}

.copy-link-btn {
	display: inline;
	padding: 12px;
	margin-left: 6px;
	border-radius: 3px;
	border: solid 1px #2370B3;
	background: #fff;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.5;
	color: #2370B3;
}

#webOrderDetailPrint {
    width: 98%;

	.ced-logo {
		max-height: 50px;
		max-width: 350px;
	}

    .heading-row {
    	margin-top: 50px;
    }

    .printTopRight {
        border: 1px solid #000;

        .printTopRightTitle {
            font-weight: bold;
            border-bottom: 1px solid #000;
            height: 30px;
        }
    }

    .printLeftSpace {
        margin-left: 1rem;
    }
    .printRow {
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        margin-left: 0;
        margin-right: 0;

        div[class^="col-"] {
            padding: 5px;
            border-right: 1px solid #000;
        }
    }
    .tableWrapper {
        border: 1px solid #000;
        margin-left: 0;
        margin-right: 0;
    }
    table {
        width: 100%;
        border: none;
        margin-bottom: 0;
        page-break-inside: auto;

        thead {
            background: #fff;

            th {
                font-weight: normal;
                border-bottom: 1px solid #000;
            }
        }

        tbody {
            tr {
                page-break-inside: avoid;
                page-break-after: auto;
            }

            td {
            	padding: 2px;
            }
        }
    }
}

@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.admin-right-content.admin-web-order-detail {
		.buttons {
			margin-top: 15px;
			padding-left: 6px;
			text-align: left;
		}

		.order-footer-info {
			width: 64%
		}

		.order-footer-wrapper {
			width: 35%;
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-web-order-detail {
		.order-details {
			margin: 15px;

			ul {
				border-bottom: 1px solid rgb(213, 216, 217);
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			> div {
				padding: 0;

				&:last-child {
					margin-bottom: 20px;
				}
			}

			.summary {
				padding: 0;
			}

			.text {
				padding-left: 15px;
			}
		}

		.additional-info {
			margin: 15px;

			ul {
				&.section {
					border-bottom: 1px solid rgb(213, 216, 217);
					padding-bottom: 20px;
					margin-bottom: 20px;
				}
			}

			> div {
				padding: 0;
			}

			.summary {
				padding: 0;
			}
		}

		.order-footer-wrapper {
			padding-left: 15px;

			.order-footer {
				.summary {
					padding: 0;
				}

				.text {
					padding-right: 15px;
				}
			}
		}

		.collapsible-section {
			margin: 25px 15px 15px 15px;
		}

		padding-left: 0;
		padding-right: 0;

		div.row {
			padding-left: 15px;
			padding-right: 15px;
		}

		div.row.order-summary-row {
			padding-left: 0;
			padding-right: 0;

			div.row {
				> div {
					padding-left: 0;
					padding-right: 0;
				}
			}
			div.row.total-row {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.buttons {
			margin-top: 40px;
			text-align: left;

			a {
				width: 100%;
				text-align: center;
				margin-top: 20px;
				margin-left: 0;

				&.half-button-left {
					width: 48%;
					float: left;
				}
		
				&.half-button-right {
					width: 48%;
					float: right;
				}
			}

			a:first-of-type {
				margin-top: 0px;
			}
		}

		#add-all-to-catalog-button {
			width: 100%;
			margin-top: 20px;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.footable-row-detail-name {
			width: 140px;
			white-space: normal;
		}
	}

	.delivery-fee-line {
		font-size: 12px;
		line-height: 20px;
	}

	#linkShare {
		width: 100%;
	}

	.copy-link-btn {	
		display: block;
		margin-top: 10px;
		margin-left: 0;
		text-align: center;
		font-size: 18px;
	}
}