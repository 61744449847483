@import "../variables";

body.solar-template {
    .solar-home-logged-in {
        margin-top: 24px;

        #videoModal {
            .modal-body {
                min-height: 400px;

                iframe {
                    min-height: 400px;
                }
            }
        }

        .top-row.home-categories-11 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 50px;
						max-height: 50px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-13 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 45px;
						max-height: 45px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-15 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 40px;
						max-height: 40px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-17 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 35px;
						max-height: 35px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
		.top-row.home-categories-19 {
			@include flexalign(center);

			#home-categories-row {
				a {
					img {
						max-width: 30px;
						max-height: 30px;
					}
				}

				div.catcont-solar {
					margin-bottom: 6px;
				}

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 80%;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 13px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
		}
        
        .top-row {
            margin-left: 16px;
			margin-right: 16px;
			display: -webkit-box;
		    display: -moz-box;
		    display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-box-orient: horizontal;
		    -moz-box-orient: horizontal;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    justify-content: center;
            -ms-flex-pack: center;
            flex-wrap: wrap;
            
            .welcome-section {
                position: relative;

                padding: 20px;
                width: 50%;
		    	order: 1;
                -ms-flex-order: 1;
                
                background: rgb(248, 249, 249);
                height: 110px;
                border: 1px solid rgb(213, 216, 217);
                border-radius: 3px;

                .welcome-content-wrapper {
                    .border {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background-color: #2370B3;
                    }

                    .welcome-back {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 28px;
                    }

                    .welcome-check {
                        display: inline-block;
                        height: 24px;
                        width: 24px;
                    }

                    h4 {
                        color: rgb(43, 61, 65);
                        font-size: 18px;
                        font-family: 'Open Sans';
                        font-weight: normal;
                        letter-spacing: 0.23px;
                        line-height: 28px;
                        vertical-align: middle;
                    }

                    p {
                        padding-top: 4px;

                        a {
                            font-weight: bold;
                        }
                    }

                    .view-account {
                        display: inline;
                    }
                }
            }

            .banner-section {
                padding: 0 0 0 20px;
                width: 50%;
		    	order: 2;
                -ms-flex-order: 2;

                #banner-container {
                    width: 100%; 

                    .tablet-desktop {
                        display: block;
                        height: 110px;
                    }
                    .mobile {
                        display: none;
                    }
                    
                    .lSSlideWrapper {
                        max-height: 110px;
                    }

                    .lSPager {
                        margin-top: 0;
                        text-align: right;

                        li {
                            border: 1px solid rgb(213, 216, 217);
                            height: 12px;
                            width: 12px;
                            border-radius: 50%;
                            margin: 0;
                            margin-left: 5px;
                             
                            a {
                                background-color: #fff;
                                color: #fff;
                                width: 0;
                            }

                            &.active {
                                background-color: #2370B3;
                            }
                        }
                    }

                    a { 
                        display: block;
                    }
                }
            }

            .quick-links-section {
                margin-top: 0; 
                margin-left: 10px; 
                margin-right: 15px; 
                width: 27%;
		    	order: 2;
                -ms-flex-order: 2;

                #quick-links {
                    padding: 0;
                    list-style-type: none;

                    #view-more {
                        display: block;
                    }
                }
                
                h5, .section-header {
                    display: inline-block;
                    margin-bottom: 16px;
                    height: 28px;
                    color: rgb(43, 61, 65);
                    font-size: 18px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                    letter-spacing: 0.17px;
                    line-height: 28px;
                }

                .quick-link-content {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -moz-box-orient: horizontal;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    justify-content: center;
                    -ms-flex-pack: center;
                    flex-wrap: wrap;

                    margin-bottom: 6px;
                    padding: 5px;
                    width: 100%;
                    height: 48px;
                    border: 1px solid rgb(213, 216, 217);
                    border-radius: 3px;
                    color: rgb(43, 61, 65);
                    font-size: 14px;
                    font-family: 'Open Sans';
                    letter-spacing: 0.13px;
                    line-height: 36px;
                    text-decoration: none;

                    div.quick-link-action-icon {
                        padding: 0 5px;
                        width: 30px;
                    }

                    div.quick-link-text {
                        width: 186px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        flex: 10;
						-webkit-box-flex: 10;
				  		-moz-box-flex:  10;
				  		-webkit-flex:  10;
				  		-ms-flex:  10;
                    }

                    div.quick-link-navigate-icon {
                        padding: 0 5px;
                        text-align: right;
                        flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
                    }
                }

                .view-more-section {  
                    margin-top: 16px;       
                    height: 20px;
                    color: rgb(38, 146, 181);
                    font-size: 14px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }    

                .viewing-fewer{
                    #view-more-button{
                        display:block;
                    }
                    #view-fewer-button{
                        display:none;
                    }
                }

                .viewing-more{
                    #view-more-button{
                        display:none;
                    }
                    #view-fewer-button{
                        display:block;
                    }
                }
            }
            
            .quick-links-viewing-more{
                li:nth-child(n+7) {
                    display: block;  
                }
            }            
            .quick-links-viewing-fewer{
                li:nth-child(n+7) {
                    display: none;  
                }
            }    

            .home-categories-section {
                margin-top: 0;
                padding-left: 20px;
                width: 70%;
		    	order: 3;
                -ms-flex-order: 3;
                
                h5, .section-header {
                    display: inline-block;
                    margin-bottom: 16px;
                    height: 28px;
                    color: rgb(43, 61, 65);
                    font-size: 18px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                    letter-spacing: 0.17px;
                    line-height: 28px;
                }

                .view-more-section {  
                    margin-top: 16px;       
                    height: 20px;
                    color: rgb(38, 146, 181);
                    font-size: 14px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }
            }

            #home-categories-row {
				padding-right: 8px;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;

			    div {
			    	width: 33%;
			    }

                a.topcatanchor-solar {
                    font-weight: bold;
                }

				a {
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 0.2px;
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					align-items: center;
					-ms-flex-align: center;

					span {
						flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
				  		display: block;
					}

					img {
						max-width: 50px;
						max-height: 50px;
						margin-right: 12px;
						margin-left: 8px;
					}
				}

				#view-all-categories {
					margin-top: 16px;
					display: none;
				}

                div.catcont-solar {
                    margin-bottom: 0;
                }

				div.catimg-solar {
					float: left;
					width: 20%;
					padding-right: 10px;
				}

				div.catlabel-solar {
					float: left;
					width: 75%;
					padding-left: 10px;

					a.topcatanchor-solar {
						padding-bottom: 0;
					}
				}

				div.subcatcont-solar {
					width: 100%;
					margin-bottom: 10px;
				}

				a.subcat-solar {
					font-size: 12px;
					display: inline;
				}

				#home-products-row-button {
					width: 100%;
					margin: 0 0 20px;
				}
			}
        }

        .middle-row { 
            margin: 18px 18px 0  18px;
            
            background: rgb(248, 249, 249);
            border: 1px solid rgb(213, 216, 217);
            border-radius: 6px;
            padding: 24px 60px 24px 24px;
            position: relative;

			@include flexbox;
            @include flexdirection(row);
            align-items: center;
            overflow: hidden;

            .tile-nav{
                width: 40px;
                height: 40px;
                z-index: 9999;
                border-radius: 20px;
                cursor: pointer;
                text-decoration: none;
                text-align: center;
                color: #fff;
                font-size: 26px;
                position: absolute;
                

                &.next{
                    right: 34px;
                }

                &.prev{
                    left: 275px
                }
            }

            .available-websites-info{
                width:240px;
                padding-right: 19px;
                border-right: 1px solid rgb(213, 216, 217);
                margin-right: 60px;
                flex-shrink: 0;

                .header {
                    display: block;
                    height: auto;
                    margin: 0 0 4px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .available-websites-section{
                @include flexbox;

                .site-tile{
                    width:325px;
                    height: 152px;
                    padding: 12px;
                    border: 1px solid rgb(213, 216, 217);
                    border-radius: 3px;
                    font-size:12px;
                    @include flexbox;
                    flex-shrink: 0;
                    background-color: #fff;

                    .site-logo{
                        width:48px;
                        height:48px;
                        margin-right: 10px;

                        img{
                            max-width: 48px;
                            max-height: 48px;
                        }
                    }

                    .site-dba{
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .site-address{
                        margin-top: 5px;
                        opacity: 75%;
                    }

                    .active-login{
                        margin-top:15px
                    }
                }
            }
        }

        .bottom-row {
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 18px;
			display: -webkit-box;
		    display: -moz-box;
		    display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-box-orient: horizontal;
		    -moz-box-orient: horizontal;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    justify-content: space-between;
            -ms-flex-pack: center;
            flex-wrap: wrap;

            .links-section {
                order: 1;
                -ms-flex-order: 1;

                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                justify-content: center;
                -ms-flex-pack: center;
                flex-wrap: wrap;
                flex: 1;

                .links-item:nth-child(odd) {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }

                .links-item {
                    display: block;
                    cursor: pointer;
                    text-decoration: none;

                    width: 48%;
                    height: 116px;
                    border: 1px solid rgb(213, 216, 217);
                    border-radius: 3px;

                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -moz-box-orient: horizontal;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    justify-content: center;
                    -ms-flex-pack: center;
                    flex-wrap: wrap;
                    
                    .link-image {
                        width: 96px;
                        background-position: left 0 top 0;
                        background-size: 96px 116px; 
                        background-repeat: no-repeat;

                        img {
                            width: 100%;
                        }
                    }

                    .link-content {
                        flex: 1;
                        margin-left: 14px;
                        margin-right: 14px;

                        .header {
                            margin-top: 16px;

                            color: rgb(43, 61, 65);
                            font-size: 14px;
                            font-family: 'Open Sans';
                            font-weight: bold;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                        }

                        .content {   
                            color: rgba(43, 61, 65, 0.75);
                            font-size: 12px;
                            font-family: 'Open Sans';
                            font-weight: normal;
                            letter-spacing: 0.11px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .quick-order-section {
                height: 254px;
                order: 2;
                -ms-flex-order: 2;
                flex: 1;

                .content-wrapper {
                    padding: 20px;
                    background: rgb(248, 249, 249);
                    border: 1px solid rgb(213, 216, 217);
                    border-radius: 3px;
                    margin-left: 16px;

                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -moz-box-orient: horizontal;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    justify-content: center;
                    -ms-flex-pack: center;
                    flex-wrap: wrap;

                    .quick-order-verbiage {
                        width: 35%;
                        order: 2;
                        -ms-flex-order: 2;

                        p {          
                            margin-right: 10px;           
                            color: rgb(43, 61, 65);
                            font-size: 14px;
                            font-family: 'Open Sans';
                            font-weight: normal;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                        }
                    }

                    .quick-order-form {
                        width: 65%;
                        order: 2;
                        -ms-flex-order: 2;
                    }

                    .title {
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.56;
                        letter-spacing: 0.2px;
                        margin-bottom: 10px;
                    }

                    .input-row {
                        margin-bottom: 6px;	
                        display: flex;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        
                        .header {
                            font-weight: bold;
                        }

                        .product-number {
                            width: 282px;
                        }

                        > input {
                            margin-right: 10px;
                            width: 300px;
                            height: 40px;
                            border-radius: 3px;
                            border: 1px solid rgb(149, 158, 160);
                        }	
                        
                        .qty-input {
                            input  {
                                width: 100px;
                                height: 40px;
                                padding-left: 10px;
                                text-align: left;
                            }
                        }
                    }

                    .button-row {
                        margin-top: 12px;
                        
                        button {
                            height: 40px;
                            width: 125px;
                            margin-right: 16px;
                            border-radius: 0;
                            color: rgb(255, 255, 255);
                            font-size: 13px;
                            font-family: 'Open Sans';
                            font-weight: 600;
                            text-align: center;
                            text-transform: uppercase;
                            letter-spacing: 0.13px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }

        .home-bottom-row {
            .solar-selector {
                text-align: center;
            }
        }
    }
}

/** lg size (desktop) **/
@media only screen and (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
    body.solar-template {
        .solar-home-logged-in {
            .top-row {
                .welcome-section {
                    width: 29%;
                    
                    h4 {
                        font-size: 14px;
                    }
                    
                    p {
                        font-size: 12px;
                    }
                }

                .banner-section {
                    width: 70%;

                    #banner-container {
                        .tablet-desktop {
                            display: block;
                        }
                        .mobile {
                            display: none;
                        }
                    }
                }

                .home-categories-section {
                    #home-categories-row {
                        > div {
                            width: 49%;
                        }

                        div.catcont-solar {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .bottom-row {
                .links-section {
                    width: 48%;
                    margin-left: 2px;

                    .links-item:nth-child(odd) {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    .links-item {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 14px;
                    }
                }

                .quick-order-section {
                    width: 48%;
                    height: auto;
                   
                    .content-wrapper {
                        justify-content: start;
                        -webkit-box-orient: vertical;
                        -moz-box-orient: vertical;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column;

                        .quick-order-verbiage {
                            width: 100%;
                            margin-bottom: 14px;
                        }
        
                        .quick-order-form {
                            width: 100%;
                        }
                    
                        .input-row {
                            .product-number {
                                width: 312px;
                            }
                        }

                        .button-row {
                            button {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
}

/** tablet and below **/
@media only screen and (max-width: $tablet-max-width) {
    body.solar-template {
        .solar-home-logged-in {
            .middle-row {
                flex-wrap: wrap;

                .available-websites-info {
                    width: 100%;
                    flex: 1;
                    border-right: none;
                    margin-bottom: 16px;
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    body.solar-template {
        .solar-home-logged-in {
            .top-row {
                .welcome-section {
                    width: 100%;
                    
                    h4 {
                        font-size: 14px;
                    }
                    
                    p {
                        font-size: 12px;
                    }
                }

                .banner-section {
                    width: 100%;
                    margin-top: 16px;
                    padding-left: 0;

                    #banner-container {
                        .tablet-desktop {
                            display: block;
                        }
                        .mobile {
                            display: none;
                        }
                    }
                }

                .home-categories-section {
                    width: 69%;

                    #home-categories-row {
                        div {
                            width: 49%;
                            padding-left: 8px;
                            padding-right: 16px;
                        }
                        a {						
                            img {
                                max-width: 40px;
                                max-height: 40px;
                                margin-right: 12px;
                                margin-left: 8px;
                            }
                        }

                        #view-all-categories {
                            margin-top: 16px;
                            display: inline-block;
                            margin-left: 16px;
                        }

                        div.catcont-solar {
                            position: relative;
                            margin-bottom: 5px;

                            div.catimg-solar {
                                padding-right: 10px;
                            }

                            div.catlabel-solar {
                                position: absolute;
                                width: 86%;
                                top: 50%;
                                transform: translateY(-60%);
                                margin-left: 60px;
                            }
                        }

                        #home-products-row-button {
                            margin: 0 0 20px 20px;
                        }
                    }
                }
            }

            .top-row.home-categories-11 {
				#home-categories-row {
					div.catcont-solar {
                        position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 86%;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 60px;
						}
					}

					#home-products-row-button {
						margin: 0 0 20px 20px;
					}
				}
            }

            .middle-row{
                padding: 24px 60px;

                .available-websites-info {
                    width: 100%;
                    flex: 1;
                    margin: 0 -44px 16px -44px;
                    padding-right: 0;
                }

                .available-websites-section .site-tile{
                    width: 288px;
                }

                .tile-nav{
                    bottom: 85px;
    
                    &.prev{
                        left: 16px
                    }

                    &.next{
                        right: 20px
                    }
                }
            }
            
            .bottom-row {
                .links-section {
                    width: 48%;
                    margin-left: 5px;

                    .links-item:nth-child(odd) {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    .links-item {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 14px;
                    }
                }

                .quick-order-section {
                    width: 48%;
                    height: auto;
                    margin-left: 14px;
                    margin-right: 5px;

                    .content-wrapper {
                        margin-left: 0;

                        justify-content: start;
                        -webkit-box-orient: vertical;
                        -moz-box-orient: vertical;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column;

                        .quick-order-verbiage {
                            width: 100%;
                            margin-bottom: 14px;
                        }
        
                        .quick-order-form {
                            width: 100%;
                        }
                    
                        .input-row {
                            .product-number {
                                width: 312px;
                            }
                        }

                        .button-row {
                            margin-top: 16px;

                            button {
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    body.solar-template {
        .solar-home-logged-in {
            #videoModal {
                .modal-body {
                    min-height: 200px;
    
                    iframe {
                        min-height: 200px;
                    }
                }
            }

            ul, ol {
                margin: 0;
                padding: 0;
            }
            
            margin-top: 16px;

            .top-row.home-categories-11 {
				#home-categories-row {
					.catcont-solar {
						position: relative;

						div.catimg-solar {
							width: 10%;
							padding-right: 10px;
						}

						div.catlabel-solar {
							position: absolute;
							width: 86%;
							top: 50%;
							transform: translateY(-60%);
							margin-left: 50px;
						}
					}
				}
            }
            
            .top-row {
                -webkit-box-orient: vertical;
			    -moz-box-orient: vertical;
			    -webkit-flex-direction: column;
			    -ms-flex-direction: column;
			    flex-direction: column;

                .welcome-section {
                    width: 100%;
                    height: auto;
                }

                .banner-section {
                    padding: 0;
                    margin: 0;
                    margin-top: 15px;
                    width: 100%;

                    #banner-container {
                        .lSSlideWrapper {
                            max-height: none;
                        }
                        .tablet-desktop {
                            display: none;
                        }
                        .mobile {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                        .lSPager {
                            text-align: center;
                        }
                    }
                }
    
                .quick-links-section {
                    width: 100%;
                    margin-left: 0;
                }
    
                .home-categories-section {
                    width: 100%;
                    padding-left: 0;
                    margin-bottom: 10px;

                    #home-categories-row {
                        .catcont-solar {
                            margin-bottom: 0;

                            .catlabel-solar {
                                padding-left: 0;
                            }
                        }
                        div.catimg-solar {
                            width: 75px;
                        }
                    }
                }

                #home-categories-row {
                    div {
                        width: 100%;
                    }
                }
            }

            .middle-row{
                padding: 24px 16px;

                .available-websites-info{
                    margin-right:0;
                    padding-right:0;
                }

                .available-websites-section .site-tile{
                    width: 239px;

                    .site-logo{
                        display:none;
                    }
                }

                .tile-nav{
                    display: none;
                }
            }

            .bottom-row {
                -webkit-box-orient: vertical;
			    -moz-box-orient: vertical;
			    -webkit-flex-direction: column;
			    -ms-flex-direction: column;
			    flex-direction: column;

                .links-section {
                    width: 100%;

                    .links-item:nth-child(odd) {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    .links-item {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }
    
                .quick-order-section {
                    width: 100%;
                    height: auto;
                    margin-left: 0;
                    
                    .content-wrapper {
                        margin-left: 0;

                        -webkit-box-orient: vertical;
                        -moz-box-orient: vertical;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column;

                        .quick-order-verbiage {
                            width: 100%;
                            margin-bottom: 14px;
                        }
        
                        .quick-order-form {
                            width: 100%;
                        }
                    
                        .input-row {
                            .product-number {
                                width: 312px;
                            }
                        }

                        .button-row {
                            button {
                                display: inline;
                            }
                        }
                    }
                }
            }

        }
    }
}