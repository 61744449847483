@import "../variables";

.vmiToolCatalogNewTool {
    .admin-table {
        .photo-col {
            min-width: 150px;
        }
    }

	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        .admin-table {
            .photo-col {
                min-width: 10px;
            }
        }

		.image-container {
            .photo-text {
                display: none;
            }
        }
	}

	@media only screen and (max-width: $mobile-max-width) {
		.error-text {
			margin-top: 20px;
		}

		.alert {
			margin-bottom: 0;
		}

		.image-container {
            .photo-text {
                display: inline;
            }
        }

        .footable-row-detail-value {
            padding-bottom: 10px;
        }

        table#tool-rows {
			margin-top: 20px;

			thead {
				th {
					display: none;
					white-space: normal;
				}
			}

			tbody {
				tr {
                    border-top: 1px solid #b9bbc0; 

					td {
						white-space: normal;

						a {
							margin-left: 0;
						}
					}
				}

				tr.row-toggle {
					cursor: pointer;
					border: none;
				}

				tr.line {
                    padding-top: 20px;
					padding-bottom: 20px;
					display: block;

					td {
						border: none;
						position: relative;
						padding-left: 140px;
						display: block;
						padding-top: 0px;
						padding-bottom: 10px;
					}

					td:before {
						position: absolute;
						left: 15px;	
						font-size: 13px;
						line-height: 1.5;	
						bottom: 4px;				
					}

                    td.remove-line {
                        margin-top: 10px;

                        a {
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                    }

					td:nth-of-type(1):before { content: "Photo"; top: 10px; }
					td:nth-of-type(2):before { content: "Catalog Number"; top: 10px; }
					td:nth-of-type(3):before { content: "Mfr Code"; top: 10px; }
					td:nth-of-type(4):before { content: "Asset Number"; top: 10px; }
					td:nth-of-type(5):before { content: "Bin Location"; top: 10px; }
					td:nth-of-type(6):before { content: "Description"; top: 10px; }
					td:nth-of-type(7):before { content: "";  }
				}
			}
		}
	}
}
