@import "../variables";
@import "../mixins";

#scanner {
	position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100%;
	z-index: 999999;
	display: none;

	.scanner-dialog {
		.scanner-content {
			.scanner-header {
				position: relative;
				height: 48px;

				button.closeBarcodeScan {
					position: absolute;
					font-size: 27px;
					font-weight: bold;
					line-height: 1;
					padding: 0;
					cursor: pointer;
					background: transparent;
					border: 0;
					-webkit-appearance: none;
					top: 23px;
					right: 23px;
					opacity: 1;
					color: #959ea0;
				}

				h3.scanner-title {
					font-size: 24px;
					line-height: 1.17;
					letter-spacing: 0.2px;
					text-align: center;
					display: block;
					margin-top: 0;
					padding-top: 24px;
				}
			}

			.scanner-body {
				#barcodeImage {
					text-align: center;

					img {
						max-width:200px;
					}
					
					#barcodeResult {
						margin-top: 8px;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.2px;
						text-align: center;
						border-radius: 2px;
						border: solid 1px #959ea0;
						width: 200px;
						display: block;
						margin-left: auto;
						margin-right: auto;
					}

					#barcodeReviewText {
						font-size: 12px;
						line-height: 1.67;
						letter-spacing: 0.1px;
						text-align: center;
						color: #606e71;
						margin-top: 8px;
						width: 200px;
						margin-left: auto;
						margin-right: auto;
					}

					#barcodeButtons {
						margin-top: 25px;
						margin-left: 16px;
						margin-right: 16px;

						label {
							display: inline-block;
							margin-right: 8px;
							width: 45%;
							border-radius: 2px;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.14;
							letter-spacing: 0.1px;
							text-align: center;
							padding: 12px 16px;
						}

						button {
							display: inline-block;
							width: 45%;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.14;
							letter-spacing: 0.1px;
							padding: 12px 16px;
						}
					}
				}

				#scanFile {
					width: 0.1px;
					height: 0.1px;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					z-index: -1;
				}

				#scanFile+label {
					font-size: 16px;
					font-weight: 600;
					line-height: 1.5;
					letter-spacing: 0.2px;
					text-align: center;
					position: fixed;
					bottom: 56px;
					width: 100%;

					.scanIcon {
						width: 120px;
						height: 120px;
						border-radius: 100px;
						border: solid 2px #2692b5;
						margin-bottom: 16px;
						margin-left: auto;
						margin-right: auto;

						i {
							font-size: 3em;
							margin-top: 35px;
						}
					}
				}
			}
		}
	}
}
#scanner.open {
	display: block;
}

/** start header **/
header {
	.secure-checkout {
		text-align: center;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.5;
		color: #ffffff;
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.top-content.checkout-top-content {
		min-height: 80px;	

		#headerTop {
			.checkout-links {
				margin-top: 28px;

				a {
					font-size: 13px;
					line-height: 1.5;
				}
			}

			.ced-logo {
				max-width: 95px;
				max-height: 31px;
				margin-top: 15px;
			}

			.doing-business-as-title {
				margin-top: 5px;
			}
		}
	}

	.top-content {
		min-height: 108px;		
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);

		#headerTop {
			margin-left: auto;
			margin-right: auto;

			@include flexbox;
			
			.siteImage {
				max-width: 430px;
				overflow: hidden;
			}
			.headerTopRight {
				@include flex(1);

				.headerRightBottom {
					@include flexbox;
					justify-content: flex-end;
					-ms-flex-pack: end;

					.headerMyLinks {
						width: 120px;
						margin-left: 15px;

						ul {
							list-style-type: none;
							padding: 0;
							margin: -5px 0 0 0;
						}
					}

					.headerFlexMenu {
						height: 1.8em;
						overflow: visible;
						line-height: 1;
						list-style-type: none;
						padding: 0;

						li {
							float: left;
							margin: 0 15px 0 0;

							a {
								display: block;							
							}
							a.services-link {
								padding: 3px 3px 0 3px;
							}
							a.min {
								text-align: center;
								min-width: 75px;
							}

							a > .stf-item:after{
								content: ', '
							}

							a > .stf-item:last-of-type:after{
								content: ''
							}

							a > .stf-item:last-of-type:before{
								content: '& '
							}

							.service-menu-dropdown{
								margin: 0;
							    min-width: 150px;
							    border-radius: 3px;
							    background-color: #ffffff;
							    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
							    padding: 20px;
							    z-index: 50;
							    list-style-type: none;
							    text-align: left;

							    li{
									float: none;
								}
							}

							
						}
						li.flexMenu-viewMore {
							margin-right: 0;
						}

						li.flexMenu-viewMore a {
							border-radius: 3px 3px 0 0;
							padding: 0 5px 5px 5px;
							display: block;
						}
						li.flexMenu-viewMore a:hover {
							text-decoration: none;
							border-bottom: none;
						}
						.flexMenu-viewMore > a:after {
							display: inline-block;
							font-family: 'FontAwesome';
							content:"\f107";
							margin-left:0.4em;  
							position: relative;
							top: -.1em;
						}
						li.flexMenu-viewMore li#tools-container > #tools-sub-menu {
							position: initial;
							box-shadow: none;	
							padding: 0;			    
						    

						    li {
								float: none;
								height: auto;
						    }
						}
						.flexMenu-popup {
							margin: 0;
							min-width: 250px;
							border-radius: 3px;
							background-color: #ffffff;
							box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
							padding: 20px;
							z-index: 50;
							list-style-type: none;
							text-align: left;
							right: 0px;
						}
						.flexMenu-popup {
							> li {
								display: block;
								float: none;
								margin-left: 0;
		
								> a {
									display: block;
									text-decoration: none;
									font-size: 15px;
									line-height: 1.7;
									cursor: pointer;
									text-align: left;
								}
		
								> a:hover {
									text-decoration: none;
									border: none;
								}
							}
						}
						.flexMenu-popup > li > a, .flexMenu-popup > li {
							display: block;
							float: none;
						}
						.flexMenu-popup > li > a:hover {
							text-decoration: none;
						}
					}
				}
			}

			.ced-logo {
				max-width: 125px;
				max-height: 89px;
				margin-top: 9px;
			}

			.doing-business-as-title {
				height: 20px;
				font-size: 13px;
				line-height: 1.5;
				margin-top: 10px;
			}

			.headerRightTop {
				margin-top: 15px;

				.help-text {
					height: 20px;
					font-size: 13px;
					line-height: 1.5;
				}

				a {
					height: 20px;
					font-size: 13px;
					line-height: 1.5;
					text-decoration: underline;
				}
			}

			.headerRightBottom {
				margin-top: 24px;

				li {
					a {
						height: 25px;
						font-size: 16px;
						font-weight: 600;
						line-height: 1.6;
						text-transform: uppercase;
					}

					a:hover {						
						text-decoration: none;
					}

					a.my-links {
						width: 116px;
						height: 34px;
						border-radius: 3px;
						font-size: 16px;
						font-weight: 600;
						line-height: 2;
						text-decoration: none;
						display: block;
	    				text-align: center;
	    				cursor: pointer;
					}					
				}				

				li#my-links-container, li#more-container {
					position: relative;
				}

				li#my-links-container > #my-links-flyout {
					position: absolute;				    
				    margin: 0;
				    padding: 5px 0 5px 0;
				    list-style-type: none;
				    text-align: left;
				    width: 270px;
				    right: 4px;
				    top: 32px;
				    z-index: 100;
				    display: none;
				    border-top-left-radius: 3px;
				    border-bottom-left-radius: 3px;
				    border-bottom-right-radius: 3px;

				    li {
				    	margin: 0;
				    	height: 35px;

				    	a {
				    		display: block;
				    		text-decoration: none;
				    		font-size: 16px;
							line-height: 1.6;
							color: #fff;
							font-weight: 100;
							padding-top: 4px;
							padding-left: 17px;
							height: 35px;
							text-transform: none;
				    	}

				    	a:hover {
				    		text-decoration: none;
				    		border-bottom: none;
				    		background-color: rgba(0, 0, 0, 0.2);
				    		cursor: pointer;
				    	}
				    }
				}

				li#my-links-container:hover > #my-links-flyout {
					display: block;
				}

				
				/*li#more-container {
					border-radius: 3px 3px 0 0;
					padding: 5px;
				}*/

				li#more-container > #more-flyout {
					position: absolute;
					margin: 0;
					min-width: 250px;
					border-radius: 3px;
					background-color: #ffffff;
					box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
					padding: 20px;
					display: none;
					z-index: 50;
					list-style-type: none;
				    text-align: left;
				    top: 28px;
				    right: 0px;

					li {
						margin: 0;

						a {
							display: block;
							text-decoration: none;
							font-size: 15px;
							line-height: 1.7;
							cursor: pointer;
						}

						a:hover {
							text-decoration: none;
							border: none;
						}
					}
				}

				li#more-container:hover {
					background-color: #fff;
					box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
				}

				li#more-container:hover > #more-flyout {
					display: block;
				}

				li#more-container:hover > a.more {
					background-color: #fff;					
					text-decoration: none;
				}

				li#more-container:hover > a.more:hover {
					text-decoration: none;
					border: none;
				}

				li#services-container, li#more-container {
					position: relative;
				}

				li#services-container {
					> a {
						padding: 0 6px;
					}
				}

				li#services-container > #services-flyout {
					position: absolute;				    
				    margin: 0;
				    padding: 5px 0 5px 0;
				    list-style-type: none;
				    text-align: left;
				    width: 210px;
				    right: 0;
				    top: 25px;
				    z-index: 50;
				    display: none;
				    border-top-left-radius: 3px;
				    border-bottom-left-radius: 3px;
				    border-bottom-right-radius: 3px;

				    li {
				    	margin: 0;
						height: 35px;
						clear: both;
						float: right;
						margin-right: 15px;

				    	a {
				    		display: block;
				    		text-decoration: none;
				    		font-size: 16px;
							line-height: 1.6;
							color: #fff;
							font-weight: 100;
							padding-top: 4px;
							padding-left: 17px;
							height: 35px;
							text-transform: none;
				    	}

				    	a:hover {
				    		text-decoration: none;
				    		border-bottom: none;
				    		cursor: pointer;
				    	}
				    }
				}

				li#services-container:hover {
					
					> #services-flyout {
						display: block;
					}
				}

				li#tools-container, li#more-container {
					position: relative;
				}

				li#tools-container {
					> a {
						padding: 0 6px;
					}

					> a:after {
						display: inline-block;
						font-family: 'FontAwesome';
						content: "\f107";
						margin-left: 0.4em;
						position: relative;
						top: -.1em;
					}
				}

				li#tools-container > #tools-sub-menu {
					position: absolute;				    
				    margin: 0;
				    padding: 20px 12px 20px 0;
				    list-style-type: none;
				    text-align: left;
				    width: 278px;
				    right: -200px;
				    top: 25px;
				    z-index: 50;
				    display: none;
				    border-top-left-radius: 3px;
				    border-bottom-left-radius: 3px;
					border-bottom-right-radius: 3px;
					background-color: #fff;
					box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);

				    li {
				    	margin: 0;
						/*height: 35px;*/
						clear: both;
						float: left;
						margin-left: 20px;

						a {
							font-size: 15px;
    						line-height: 1.7;
						}
				    }
				}

				li#tools-container:hover {
					
					color: #E1ECF2;
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;

					> #tools-sub-menu {
						display: block;
					}
				}
			}
		}
	}

	.bottom-content {		
		height: 72px;
		padding-left: 0;
		padding-right: 0;

		#headerBottom {
			margin-left: auto;
			margin-right: auto;
			position: relative;

			.search {
				overflow: hidden;

				&.no-categories{
					#search-input{
						border-top-left-radius: 24px;
	                	border-bottom-left-radius: 24px;
					}

					.search-input-container #search-background {
						border-top-left-radius: 24px;
					    border-bottom-left-radius: 24px;
					}
				}

				#search-input {
	                height: 46px;
	                border-top-right-radius: 24px;
	                border-bottom-right-radius: 24px;
					/*background-color: #ffffff;*/
					background-color: transparent;
					border-width: 0;
					padding-left: 32px;
					font-size: 16px;
					letter-spacing: -0.4px;					
					width:100%;
					z-index: 3;
					position: relative;
	            }

	            .search-input-container {
	            	display: table-cell;
	            	vertical-align: middle;
	            	position: relative;

	            	#search-background {
	            		position: absolute;
	            		top: 13px;
					    width: 100%;
					    background: #fff;
					    z-index: 0;
					    height: 46px;
					    padding-top: 10px;
					    border-top-right-radius: 24px;
					    border-bottom-right-radius: 24px;
	            	}

	            	#search-input-icon {
	            		position: absolute;
					    left: 14px;
					    top: 24px;
					    color: #9b9b9b;
					    display: none;
					    font-size: 16px;
						letter-spacing: -0.4px;
	            	}

	            	label {
	            		position: absolute;
					    top: 13px;
					    width: 100%;
					    border-top-right-radius: 24px;
                		border-bottom-right-radius: 24px;
					    height: 46px;
					    padding-top: 10px;
    					transition: all 0.2s ease-in-out;
    					white-space: nowrap;
    					text-align: center;
    					font-size: 16px;
						letter-spacing: -0.4px;
						color: #9b9b9b;
	    				padding-left: 14px;
	    				font-weight: 100;
	    				-webkit-touch-callout: none;
					    -webkit-user-select: none;
					    -khtml-user-select: none;
					    -moz-user-select: none;
					    -ms-user-select: none;
					    user-select: none;
					    background-color: transparent;
					    z-index: 0;
	            	}
	            }
	           
	            #search-box.open {
            		#search-input + label {
            			width: 0;
            		}
            	}

	            #search-box {
	            	display: table;
	            	width: 100%;
	            	position: relative;
	            	height: 72px;
	            	padding-right: 15px;

	            	.search-categories {
	            		display: table-cell;
	            		width: 200px;
	            		vertical-align: middle;
	            	}

	            	.tablet-cancel {
						display: none;
					}
	            }

	            #search-box.open #search-input {               
	                padding-right: 96px;
	            }

	            #search-box.open #search-input ~ .focus-area {
	                display: inline-block;
	            }

	            .focus-area {
	                display: none;
	                background-color: #fff;
				    padding-top: 9px;
				    padding-bottom: 9px;
				    padding-right: 9px;
				    border-top-right-radius: 20px;
				    border-bottom-right-radius: 20px;
				    position: absolute;
				    top: 16px;
				    right: 0;
				    z-index: 4;
	            }

	            .focus-area i {
	                cursor: pointer;
	                margin-right: 10px;	                
	                padding: 4px;
	            }

	            a.go {
	                margin: 0;
	                border-radius: 20px;
	                border: none;
	                padding-left: 18px;
	                padding-right: 18px;
	                margin-left: -3px;
	                padding-top: 6px;
	                padding-bottom: 6px;
	                color: #fff;
	                height: 31px;
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
	            }

	            a.go:hover {
	            	text-decoration: none;
				}
				
				.barcode-scan-icon {
					display: none;
				}

	            .advanced-search {
	            	height: 25px;
					font-size: 16px;
					line-height: 1.6;
					color: #ffffff;
					text-decoration: underline;
					display: table-cell;
					width: 145px;
					padding-left: 15px;
					vertical-align: middle;
	            }

	            .styled-select {
	            	height: 46px;
					border-top-left-radius: 24px;
					border-bottom-left-radius: 24px;					
					display: inline-block;
					width: 200px;
					vertical-align: middle;
				    overflow: hidden;
				    cursor: pointer;
				    position: relative;
				    margin-left: 10px;

		            select {
		                background: transparent;
		                border: 0;
		                border-radius: 0;
		                -webkit-appearance: none;
		                width: 200px;
		                top: 0;
		                left: 10px;
		                background-color: white;
		                height: 42px;
		                margin-top: 0;
		                position: absolute;
		                z-index: 2;
		                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		                filter: alpha(opacity=0);
		                -khtml-opacity: 0;
		                -moz-opacity: 0;
		                opacity: 0;
		                cursor: pointer;
		                padding-right: 0;
		                color: #212121;
		            }

		            div.select-output {
		                z-index: -1;
		                height: 42px;
		                overflow: hidden;
		                padding: 1.2rem 1.7rem;
		                cursor: pointer;
		                width: 140px;
		                white-space: nowrap;
		            }
		        }

		        .styled-select:after {
		            font-family: 'FontAwesome';
		            position: relative;
		            right: -175px;
		            top: -33px;
		            content: "\f107";
		            font-size: 2rem;
		        }
			}

			.header-cart {
				width: 147px;
				height: 72px;
				box-shadow: -1px 0 0 0 rgba(255,255,255,0.07),inset 1px 0 0 0 rgba(0,0,0,0.3);

				a {
					display: block;
					/*width: 147px;*/
					height: 72px;
					color: #fff;
					text-align: center;
					text-decoration: none;
					padding-top: 23px;					
					font-size: 16px;
					line-height: 1.6;
					padding-right: 15px;
				}

				.cart-count {
					text-decoration: underline;
				}
			}

			.browse-products {
				width: 300px;
				height: 72px;
				box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.07), inset -1px 0 0 0 rgba(0, 0, 0, 0.3);
				position: relative;

				> a {
					font-size: 16px;
					font-weight: bold;
					line-height: 1.6;
					color: #ffffff;
					padding-left: 30px;
					padding-top: 23px;
					display: block;
					width: 300px;
					height: 72px;
				}

				a:hover, a:active, a:visited, a:focus {
		            text-decoration: none;
		        }

				i {
					margin-right: 30px;
					margin-top: 4px;
				}

				#browse-products-flyout {
		            position: absolute;
		            top:70px;
		            left:0;		            
		            z-index:999;
		            display:none;	
		            border-top: 2px solid #3a3a3b;	 


		            ul {
		                list-style-type: none;		                
		                padding: 0;

		                > li {
		                	margin-left: 0;		 
		                	position: relative;               	

		                    a {
		                        display: block;
		                        padding-left: 3rem;
		                        padding-right: 3rem;
		                        padding-top: 1rem;
		                        padding-bottom: 0;
		                        font-size: 1.5rem;
		                        width: 300px; 

		                        i {
		                        	position: absolute;
    								right: 10px;
    								visibility: hidden;
    								margin-right: 0;
		                        }

		                        hr {								    
								    margin-top: 1rem;
								    margin-bottom: 0;
		                        }
		                    }

		                    ul {
		                    	display: none;
		                    	position: absolute;
		                    	top: -10px;
							    left: 300px;
							    box-shadow: inset 1px 0 1px 0 rgba(0, 0, 0, 0.1);
							    width: 500px;
							    padding-top: 30px;
							    padding-bottom: 35px;
		                    }
		                }

		                > li:hover {
		                	> a {
			                	color: #fff;
			                	border-bottom: none;

			                	i {
			                		visibility: visible;
			                	}
			                }

			                > ul {
			                	display: block;
			                }
		                }
		            }
		        }
			}

			.browse-products.open {
				background-color: #3a3a3b;

		        #browse-products-flyout {
		            display: block;
		        }
		    }
		}
	}
}
/** end header **/

/** start impersonation **/
#impersonation-header {
    text-align:center;
}
#change-customer-settings {
	font-family: 'Open Sans', sans-serif;

	.modal-body {
		> div.row {
			margin-bottom: 20px;
		}

		.limited-section {
			div.row {
				margin-bottom: 20px;
			}

			table.impersonation-table {
				margin-top: 10px;

				thead {
					tr {
						box-shadow: inset 0 1px 0 0 #b9bbc0;

						th {
							font-size: 13px;
							font-weight: bold;
							line-height: 1.5;
							padding: 10px 2px;
						}
					}					
				}

				tbody {
					tr {
						border-bottom: 1px solid #b9bbc0;

						td {
							padding: 15px 2px;
						}
					}
				}
			}
		}

		.impersonation-checkbox {
			font-weight: 100;
		}		
	}

	.modal-dialog .modal-content .modal-footer a.cancel {
		margin-top: 32px;
	}

	.impersonation-warning {
		text-align: left;
		font-size: 13px;
		line-height: 1.5;
		color: $color-error;
		display: flex;
		border-radius: 5px;
		background-color: #ffffff;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
		padding: 20px;
		border: 1px solid $color-error;

		.impersonation-change-button {
			width: 240px;
			text-align: right;
			padding-left: 2px;

			button {
				white-space: nowrap;
			}
		}
	}
}
/** end impersonation **/

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	header {
		.top-content {
			#headerTop {
				.siteImage {
					width: 360px;
				}
				.doing-business-as-title {
					font-size: 11px;
				}
				.ced-logo {
					max-height: 60px; /*60*/
					max-width: 108px;
				}

				.headerRightBottom {
					ul.list-inline {
						li {
							margin-left: 15px;
							padding-left: 0;
							padding-right: 0;

							a {
								font-size: 15px;
							}
						}
					}

					li#my-links-container > #my-links-flyout {
						right: 0;

						li {
							a {
								font-size: 15px;
							}
						}
					}

					li#more-container {
						padding: 3px;
					}					
				}
			}
		}
		.bottom-content {
			#headerBottom {
				.header-cart {
					width: 136px;
				}

				.browse-products {
					width: 186px;

					a {
						width: 186px;
						padding-left: 15px;

						i {
							margin-right: 15px;
						}
					}

					#browse-products-flyout {
						ul {
							li {
								a {
									width: 250px;
								}
							}
						}

						ul {
							> li {
								> ul {
									left: 250px;
									> li {
										a {
											width: 500px;
										}
									}
								}
							}
						}
					}
				}

				.search {
					.barcode-scan-icon {
						width: 33px;
						display: table-cell;
						vertical-align: middle;

						a {
							display: block;
							color: #fff;

							i {
								padding-left: 13px;
							}
						}
					}
					.advanced-search {
						display: none;
					}
					#search-box {
						.search-categories {
							width: 130px;
						}
					}

					#search-box.open {
						.tablet-cancel {
							display: table-cell;
							vertical-align: middle;
						    width: 50px;
						    text-align: center;

							a {
								color: #fff;
								text-decoration: underline;
							}
						}
					}

					.styled-select {
						width: 130px;

						select {
							width: 130px;
						}

						div.select-output {
							width: 130px;
						}
					}

					.styled-select:after {
						right: -109px;
					}

					.search-input-container {

						#search-input {
							padding-right: 0;
							font-size: 15px;
						}

						#search-input:focus {
							padding-right: 85px;
						}

						label {
							padding-right: 25px;
						}
					}

					.search-input-container:before {
						left: 151px;
					}

					.focus-area {
						right: 10px;
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	header {
		.top-header.checkout-header {
			height: 54px;

			.ced-logo {
				max-height: 25px;
				max-width: 77px;
				margin-top: 14px;
				margin-left: 10px;
			}

			#mobile-account {
				font-size: 13px;
				font-weight: bold;
				line-height: 1.5;
				color: #4a4a4a;
				border-radius: 0;
				border: none;	
				height: 54px;
				width: 84px;
    			background: transparent;
			}

			#mobile-account:hover, #mobile-account:active, #mobile-account:focus {
				outline:none;
			}
		}

		.top-header {
			position: relative;

			.ced-logo {
				max-height: 25px;
				max-width: 77px;
				margin-top: 10px;
				margin-left: 10px;
			}

			.cart {
				margin-right: 18px;
			}

			#mobile-menu {
				width: 60px;
				height: 44px;
				box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.07);	
				color: #4a4a4a;
				font-family: 'Open Sans', sans-serif;
				font-size: 13px;
				font-weight: bold;
				line-height: 1.5;	
				border-radius: 0;
				border: none;		
			}			

			#mobile-menu-content {
				position: absolute;
				padding: 20px 15px;
				width: 270px;
				top: 45px;
				z-index: 100;
				right: 0;
				display: none;
				min-height: 562px;

				.hello {
					font-size: 15px;
					line-height: 1.7;
				}

				.hello-account, .hello-account-mobile {
					font-size: 16px;
					font-weight: bold;
					line-height: 1.6;
				}

				hr {
					height: 1px;					
					margin-top: 15px;
					margin-bottom: 9px;
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;

					li {
						margin: 0;
						padding: 6px 0;

						a {
							display: block;
							font-size: 15px;
							line-height: 1.7;
						}
					}
				}

				.mobile-services-sub-menu-link {
					padding-left: 15px;
				}

				.mobile-tools-link:after {
					display: inline-block;
					font-family: 'FontAwesome';
					content: "\f107";
					margin-left: 0.4em;
					position: relative;
					top: -.1em;
				}
				.mobile-tools-sub-menu {
					display: none;

					> li {
						padding-left: 15px;
					}
				}

				.mobile-sub-menu-show {
					display: block;
				}
			}

			#mobile-menu-content.open {
				display: block;
			}
		}

		.bottom-header {
			display: table;
			width: 100%;
			height: 50px;

			#mobile-browse.open {
				#mobile-browse-button {
					background-color: #3a3a3b;
				}

				#mobile-browse-content {
					display: block;		

					> ul.children {
						margin-left: -270px;

						> li {
							> ul.current-category {
								display: block;
								top: 0;
    							left: 270px;
							}
						}
					}			
				}
			}

			#mobile-browse {
				display: table-cell;
				width: 75px;
				height: 50px;
				vertical-align: middle;

				#mobile-browse-button {
					color: #fff;
					width: 75px;
					height: 50px;
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
    				padding: 0;
					margin: 0;
					border-radius: 0;
					border: none;
					box-shadow: 1px 0 0 0 rgba(255,255,255,0.07),inset -1px 0 0 0 rgba(0,0,0,0.3);
				}

				#mobile-browse-content {
					position: absolute;
					width: 270px;
					margin: 0;
					padding: 10px 0;
					z-index: 100;		
					display: none;

					> ul {
						list-style-type: none;	
						margin: 0;
						padding: 0;	
						width: 270px;	
						position: relative;					

						> li {
							margin: 0;
							padding: 0;			

							> a {
								padding: 8px 0;
								margin: 0 15px;
								font-size: 15px;
								line-height: 1.7;
								display: block;								
							}

							> ul {
								list-style-type: none;
								margin: 0;
								padding: 0;
								position: absolute;
								display: none;
								width: 270px;

								li.mobile-browse-parent-cat {

									a {
										font-size: 15px;
										font-weight: bold;
										line-height: 1.7;
										color: #fff;
										padding: 8px 15px;
										margin: 0;
									}
								}

								> li {
									margin: 0;
									padding: 0;

									a.mobile-browse-back-all {
										font-size: 13px;
										line-height: 1.5;
									}

									> a {
										padding: 8px 0;
										margin: 0 15px;
										font-size: 15px;
										line-height: 1.7;
										display: block;
									}
								}
							}
						}
					}
				}
			}

			.mobile-search {				
				overflow: hidden;

				.mobile-search-box {
	            	display: table;
	            	width: 100%;
	            	position: relative;
	            	height: 50px;
	            	box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.07), inset -1px 0 0 0 rgba(0, 0, 0, 0.3);

	            	.mobile-cancel {
						display: none;
					}

					.barcode-scan-icon {
						width: 40px;
						display: table-cell;
						vertical-align: middle;

						a {
							display: block;
							color: #fff;

							i {
								padding-left: 0;
								padding-right: 10px;
							}
						}
					}
				}

				.mobile-search-box.open {
					.mobile-cancel {
						display: table-cell;
						vertical-align: middle;
						padding-right: 15px;

						a {
							color: #fff;
							text-decoration: none;
							font-size: 13px;
							line-height: 1.5;
						}
					}
				}

				.mobile-search-box.open #mobile-search-input ~ .focus-area-mobile {
	                display: inline-block;
	            }

	            .mobile-search-box.open {
            		#mobile-search-input + label {
            			width: 0;
            		}
            	}

				.mobile-search-input-container {
					display: table-cell;
    				width: 100%;
    				padding-left: 10px;
    				padding-right: 10px;
    				height: 50px;
    				vertical-align: middle;
    				position: relative;

	            	#mobile-search-input-icon {
	            		position: absolute;
					    left: 16px;
					    top: 13px;
					    color: #9b9b9b;
					    display: none;
					    font-size: 16px;
						letter-spacing: -0.4px;
	            	}

	            	label {
	            		position: absolute;
					    top: 2px;
					    width: 100%;
					    border-top-right-radius: 24px;
                		border-bottom-right-radius: 24px;
					    height: 35px;
					    padding-top: 10px;
    					transition: all 0.2s ease-in-out;
    					white-space: nowrap;
    					text-align: center;
    					font-size: 16px;
						letter-spacing: -0.4px;
						color: #9b9b9b;
	    				padding-left: 6px;
	    				font-weight: 100;
	    				padding-right: 32px;
	            	}

    				.focus-area-mobile {
		                display: none;
		                background-color: #fff;
					    padding-top: 5px;
					    padding-bottom: 6px;
					    padding-right: 6px;
					    border-top-right-radius: 20px;
					    border-bottom-right-radius: 20px;
					    position: absolute;
					    top: 9px;
					    right: 10px;
		            }

		            .focus-area-mobile i {
		                cursor: pointer;
		                margin-right: 6px;
		                padding: 4px;
		            }

		            a.mobile-go {
		                margin: 0;
		                border-radius: 20px;
		                border: none;
		                padding-left: 14px;
		                padding-right: 14px;
		                margin-left: -3px;
		                padding-top: 4px;
		                padding-bottom: 4px;
		                color: #fff;
		                height: 27px;
						font-size: 13px;
						font-weight: bold;
						line-height: 1.5;
		            }

		            a.mobile-go:hover {
		            	text-decoration: none;
		            }
    			}

				#mobile-search-input {
					height: 35px;
					border-radius: 20px;
					box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
					font-size: 16px;
					line-height: 1.2;
					padding-left: 23px;
    				border-width: 0;
    				width: 100%;
    				padding-bottom: 0;
				}
			}

			.mobile-my-links {
				display: table-cell;
				width: 82px;
				height: 50px;
				vertical-align: middle;

				button#mobile-my-links {
					color: #fff;
					height: 50px;
					width: 82px;
					font-size: 13px;
					font-weight: bold;
					line-height: 1.5;
					padding: 0;
					margin: 0;
					border-radius: 0;
					border: none;	
				}

				#mobile-my-links-flyout {
					position: absolute;
					z-index: 100;
				    right: 0;
				    width: 270px;
				    list-style-type: none;
				    margin: 0;
				    padding: 8px 0;
				    min-height: 495px;
				    display: none;

				    li {
				    	margin: 0;
				    	padding: 0;

				    	a {
				    		display: block;
				    		font-size: 15px;
				    		font-weight: 100;
				    		text-align: left;
				    		height: auto;
    						padding: 10px 0;
						    margin: 0 15px 0 15px;
						    border-bottom-width: 1px;
						    border-bottom-style: solid;
						    color: #fff;
				    	}
				    }
				}
			}

			.mobile-my-links.open {
				#mobile-my-links-flyout {
					display: block;
				}
			}
		}
	}
}