@import "../variables";

.line-action-buttons a{
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.line-action-buttons{
	margin-top: 20px;
}

.line-action-buttons a:hover, .line-action-buttons a:active{
	text-decoration: none;
}

.breadcrumbs{
	font-size: 15px;
}

.cart-project-detail {
	/* styles for CartProjectDetail visualforce component */
	display: flex;
	border: 1px solid rgb(213, 216, 217);
	border-radius: 6px;
	background: rgb(248, 249, 249);
	margin-top: 16px;
	margin-bottom: 16px;
	padding: 16px;
	
	span {
		width: 50%;
	}

	.project-info, .loan-info {
		h4 {
			color: rgb(33, 33, 33);
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.15px;
			line-height: 24px;
			display: block;
			margin: 0;
		}
	}

	.project-info {
		border-right: 1px solid rgb(213, 216, 217);
	}

	.loan-info {
		padding-left: 24px;

		.cart-loan-detail {
			.cart-loan-name {
				color: rgb(33, 33, 33);
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.13px;
				line-height: 20px;
			}
		}
	}
}
body.solar-template .cart-page .checkout-content .line-items .line-item.project-cart-line:last-of-type {
	border-bottom: 1px solid #dde1e2; /*override style for project cart*/
}
.cart-page{

	padding: 20px 30px;

	.line-items{
		margin-bottom: 30px;
	}

	.line-item{
		padding-left: 15px;
		padding-right: 15px;

		&:first-child {
			margin-top: 0;
		}

		.product-details {
			margin-top: 0px;
		}

		
	}

	.page-buttons {
		.project-cart-remove {
			border-color: rgb(182, 79, 53);
			color: rgb(182, 79, 53);
			background: #fff;
		}
		#add-all-to-catalog-button {
			background: #fff;
			border: 1px solid rgb(28, 130, 28);
		}
	}
	.line-item.project-cart-line {
		.line-action-buttons {
			.remove-button {
				border-color: rgb(182, 79, 53);
				color: rgb(182, 79, 53);
				background: #fff;
			}
			.add-to-catalog {
				background: #fff;
				border: 1px solid rgb(28, 130, 28);
			}
			.note-toggle {
				background: #fff;
				border: 1px solid rgb(28, 130, 28);
			}
		}
	}	

	.line-item-image img{
		max-width: 100%;
		flex-shrink: 0;
	}

	.product-title{
		font-size: 16px;
		font-weight: bold;
		line-height: 1.6;
	    padding-right: 10px;
	}

	.product-manufacturer{
		font-size: 13px;
		line-height: 1.5;
	}


	.product-headings{
		padding: 10px 15px;
		background-color: #f4f4f4;
		box-shadow: inset 0 1px 0 0 #b9bbc0;
		font-weight: bold;
		font-size: 13px;
	}

	.bottom-proceed-button{
		display: block;
	    margin-right: 0px;
    	margin-left: auto;
	    margin-bottom: 10px;
	}

	.catalog-number{
		margin-top: 10px;
		word-wrap: break-word;
		padding-right: 5px;
	}

	.tooltip{
		ul{
			list-style: none;
			margin-bottom: 0px; 

			span{
				font-weight: bold;
			}
		}

		li{
			margin-left: 0px; 
		}
	}

	.line-note-box input{
		width: 100%;
	}

}

.order-verification {
	.line-note-box input {
		width: 100%;
	}
}

.choose-method, .carrier-block, .choose-shipping {
	margin-bottom:20px;
}

.spinner-qty {
	-moz-appearance:textfield;
	border:1px solid gray;
	border-radius:3px;
	text-align:center;
	width:calc(100px - 2em);
}
.spinner-qty:focus,
.spinner-qty:hover {
	appearance: textfield;
}
.spinner-qty::-webkit-outer-spin-button,
.spinner-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.carrier-block{
	background-color: #f4f4f4;
	border: solid 1px #b9bbc0;
	padding: 35px 15px 20px 45px;
}

.carrier-block{

	.carrier-title{
		font-size: 19px;
		line-height: 1.3;
		margin-bottom: 20px;
	}

	label{
		font-size: 16px;
		font-weight: bold;
		line-height: 1.6;
	}

	input{
		width: 100%;
		margin-bottom: 20px;
	}
}

.page-buttons {
	margin-bottom: 40px;
}

.cart-page .line-action-buttons .button-neutral, .page-buttons .button-neutral, .button-neutral.return-all-items{
	height: 34px;
	padding: 7px 12px;
}




.full-label{
	height: auto;
}

.ship-options{
	td{
		display: flex;

		label{
			display: inline-block;
		    margin-left: 10px;
			font-weight: normal;
			height: auto;

			span{
				display: block;
			    color: #6A6B6D;
			    font-size: 12px;
			}
		}
	}
}

.option-box{
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: inset 0 2px 1px 0 #ffffff;
	border: solid 1px #b9bbc0;
	padding: 15px;
}

.open-options a:after{
	font-family: 'FontAwesome';
	position: absolute;
	right: 30px;
	top: 15px;
	content: "\f106";
	font-size: 2rem;
}

.closed-options a:after{
	font-family: 'FontAwesome';
	position: absolute;
	right: 30px;
	top: 15px;
	content: "\f107";
	font-size: 2rem;
}

.new-catalog{
	display: none;
}

#add-all-to-my-catalogs .subtitle {
	font-size: 16px;
	line-height: 1.6;
	display: block;
	margin: 0;
}

.wizard-area{
	margin-bottom: 20px;
}

input.quantity-error{
	border: solid 1px $color-error;
}

.createNewCatalogName{
	width: 100%;
}

.choose-method, .carrier-block-outer, .choose-shipping{
	padding: 0;
}

.saved-header{
	margin-top: 50px;
}

.qtyError {
	display: none;
	background-color: #f00;
	color: #fff;
	font-size: 0.8em;
	margin: 8px 0;
	padding: 5px;
	text-align: center;
}

.qtyErrorSmall {
	font-size: 0.8em;
}

.qtyErrorMobile {
	width: 100%;
	margin: 0 10px;
}

.qtyErrorCartMobile {
	width: 96%;
	margin: 0 10px;
}

.qty-input {
	vertical-align: top;
}

.tooltip {
	.tooltip-inner {
		min-width: 115px;
		text-align: center;
	}
}

.cart-success-close {
	font-weight: bold;
	color: #fff;
	font-size: 1.75em;
	padding-right: 8px;
}
.find-loan-link {
	margin-top: 20px;
	margin-bottom: 5px;

	.find-loan-button {
		text-align: center;
	}
	.find-loan-button:hover {
		color: #fff;
		text-decoration: none;
	}
	.find-loan-button:focus {
		color: #fff !important;
		text-decoration: none;
		background: #3DB05D !important;
	}
}

//medium and up
@media only screen and (min-width: $desktop-min-width ){
	.carrier-block .input-container{
		margin-right: 15px;
	}
}

//small and up
@media only screen and (min-width: $tablet-min-width ) {
	.cart-page .line-item:first-child {
		border-top: none;
	}

	.button-neutral.return-all-items {
	    margin-left: 5px;
	    margin-bottom: 5px;
	}
}

@media only screen and (min-width: $tablet-min-width ) and (max-width: $tablet-max-width) {
	.carrier-block{
		padding: 20px 30px 0px 30px;
	}

	.cart-page{
		padding: 20px 0;

		.back-to-project-link {
			margin-top: 20px;
			margin-left: 16px;
			display: block;
		}

		.message-box-wrapper{
	    	padding-left: 15px;
			padding-right: 15px;
		}

		.breadcrumbs{
			padding: 0 15px;
		}

		.checkout-container{
			flex-wrap: wrap;

			.checkout-content{
				@include flex(100%);
				padding-left: 15px;
				padding-right: 15px;
			}

			.payment-order-summary{
			    margin-right: 15px;
			    margin-left: auto;

			}

		}
	}

	.mobile-proceed-panel{
		flex: 100%;
	}

	.carrier-block-outer{
		padding-top: 30px;
	}

	.checkout-container .checkout-content{
		
	}

	.choose-method .bootstrap-select{
		width: 50% !important;
	}

	.carrier-block{
		margin-left: 0;
		margin-right: 0;
	}

	.choose-method {
		border-top: 1px solid #b9bbc0;
		padding-top: 30px;
		margin-top: 40px;
	}

	.page-buttons{
		margin-bottom: 30px;
	}

	.choose-method, .carrier-block-outer, .choose-shipping{
		padding-left: 15px;
		padding-right: 15px;
	}

}

@media only screen and (max-width: $mobile-max-width)
{
	.cart-project-detail {
		/* styles for CartProjectDetail visualforce component */
		flex-wrap: wrap;
	
		.project-info, .loan-info {
			width: 100%;
		}
	
		.project-info {
			border-bottom: 1px solid rgb(213, 216, 217);
			border-right: none;
			padding-bottom: 16px;
		}
	
		.loan-info {
			padding-left: 0px;
			padding-top: 16px;
	
			.cart-loan-detail {
				.cart-loan-name {
					color: rgb(33, 33, 33);
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 0.13px;
					line-height: 20px;
				}
			}
		}
	}

	.cart-page{
		padding: 0;

		.back-to-project-link {
			margin-top: 20px;
			margin-left: 16px;
			display: block;
		}

		.message-box-wrapper{
	    	padding-left: 15px;
			padding-right: 15px;
		}

		.checkout-container{
			flex-wrap: wrap;

			.payment-order-summary{
				flex: 100%;
				margin: 0 15px;
			}
		}
	}

	.mobile-proceed-panel{
		flex: 100%;
	}

	.carrier-block{
		padding: 20px 30px 0px 30px;
	}

	.checkout-container .checkout-content{
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}

	.choose-method {
		border-top: 1px solid #b9bbc0;
		padding-top: 30px;
		margin-top: 40px;
	}

	.page-buttons{
		margin-bottom: 30px;
	}

	.page-buttons button, .bottom-proceed-button, .top-proceed-button{
		width:100%;
	}

	.top-proceed-button{
		margin-top: 30px;
	}

	.page-buttons button{
		margin-bottom: 10px;
	}

	.text-xs-center {
		text-align: center;
	}

	.line-item .lotQuoteItemsTable {
		margin-left: 0;
		margin-right: 0;
	}

	.line-note-box{
		padding-left: 15px;
	}

	.cart-page .product-details{
		margin-bottom: 20px;
	}

	.price-per{
		font-size: 16px;
		font-weight: bold;
		line-height: 1.6;
	}

	.button-neutral.return-all-items {
	    margin-bottom: 5px;
	}
}
	