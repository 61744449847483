@import "../variables";

@mixin media-searchfilter-md {
	@media screen and (min-width: 300px) and (max-width: 600px) {
	    @content;
	}
}
@mixin media-searchfilter-lg {
	@media screen and (min-width: 601px) {
	    @content;
	}
}
@mixin media-drawer-sm {
	@media (max-width: 392px) {
		@content;
	}
}
@mixin media-drawer-lg {
	@media (min-width: 393px) {
		@content;
	}
}
@mixin media-dropdownbutton-md {
	@media (max-width: 16rem) and (min-width: 10rem) {
		@content;
	}
}
@mixin media-dropdownbutton-sm {
	@media (max-width: 10rem) {
		@content;
	}
}
@mixin media-dropdownbutton-lg {
	@media (max-width: 37.5em) {
		@content;
	}
}
@mixin media-datatable-sm {
	@media (max-width: 37.5em) {
		@content;
	}
}
@mixin media-datatable-lg {
	@media (min-width: 37.5em) {
		@content;
	}
}
@mixin media-pagination-lg {
	@media (min-width: 600px) {
		@content;
	}
}
@mixin media-pagination-sm {
	@media (max-width: 599px) {
		@content;
	}
}

#main-content {
	min-height: 200px !important;
	padding-bottom: 0px;
}

.greentech-planset-requests-container {
	background-color: none;
	padding-left: 40px;
	padding-right: 40px;
	/*min-width: fit-content;*/

	.greentech-breadcrumb {
		display: flex;
		justify-content: flex-start;
		background-color: none;
		margin: 20px 0px 12px 0px;
		line-height: 20px;
		font-size: 12px;
		font-weight: normal;

		p {
			margin-left: 9px;
		}
	}

	.all-plansets-heading {
		display: flex;
		justify-content: flex-start;
		align-self: center;
		margin: 12px 0px;
		font-weight: normal;
		h1 {
			display: inline;
		}
	}

	#lightningvf {
		font-size: 16px;

		ul, ol {
			list-style: none;
		}

		c-lwr-search-filters {
			display: block;
			margin-bottom: var(--ced-spacing-large);
		}

		c-lwr-search-filters {
			@include media-searchfilter-md {
				.search-filter-container {
					display: flex;
					flex-wrap: wrap;
					width:auto;
				}
			
				.dropdown-element {
					bottom: 8px;
					width: 100%;
				}
			
				.pill-container {
					padding: var(--ced-spacing-xxsmall);
				}
			
				.dropdown-element:nth-child(3):not(.all-filters-button) {
					display: none;
				}
			
				.dropdown-element:nth-child(2) {
					display: none;
				}
			
				.all-filters-button:not(.base) {
					display: none;
				}
			
				.hide-on-mobile{
					display: none;
				}
			}
			
			@include media-searchfilter-lg {
				.all-filters-button:not(.full) {
					display: none;
				}
			}
			
			.search-filter-container {
				display: flex;
				background-color: var(--ced-color-global-neutral-6);
				border-radius: var(--ced-border-radius-large); 
				align-items: center;
			}
			
			.search-filter-container-bottom-border {
				border-radius: var(--ced-border-radius-large) var(--ced-border-radius-large) var(--ced-border-radius-none) var(--ced-border-radius-none);
				border-bottom: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
			}
			
			.search-input-container {
				max-width: 100%;
				padding: var(--ced-spacing-medium);
				flex-grow: 1;
			}
			
			.search-input {
				width: 100%;
				display: block;
				border: none;
				height: 34px;
				position: relative;
				margin-top: 3px;
				padding-left: 38px;
				border-radius: var(--ced-border-radius-base);
			}    
			
			.search-input-icon-container {
				position: relative;
				width:100%;
				display: block;
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-inset-input);
				height: 42px;
				background-color: var(--ced-color-global-white);
			}
			
			.search-input-icon {
				position: absolute;
				width: 24px;
				left: 8px;
				top: 8px;
			}
			
			.search-input-close {
				float: right;
				position: absolute;
				top: 8px;
				right: 8px;
			}
			
			.search-input:focus, .search-input:active {
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.pill-container {
				display:inline-block;
				padding: var(--ced-spacing-xxsmall);
			}
			
			.pill-filters-container {
				display: flex;
				flex-wrap: wrap;
			}
			
			.all-filter-header {
				font-size: var(--ced-font-size-medium);
				font-weight: var(--ced-font-bold);
			}
			
			.all-filter-header-container {
				display: block;
				border-bottom: 1px solid var(--ced-color-global-neutral-4);
				padding: 0px;
				height: 39px;
				width: 100%;
				margin-bottom: var(--ced-spacing-medium);
			}
			
			.search-filter-close-icon {
				position: relative;
				bottom: 28px;
				left: 209px;
			}
			
			.all-filter-container {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.dropdown-element {
				position: relative;
				margin: var(--ced-spacing-small);
			}
			
			.filter-drawer-button {
				width: 200px;
				margin-left: 7%;
				margin-right: 7%;
			}
			
			.filter-drawer-button-container {
				margin-top: var(--ced-spacing-medium);
			}
			
			.search-filter-line {
				position:relative;
				top:1px;
				margin: 0px;
			}
			
			.search-filter-border {
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
				border-radius: var(--ced-border-radius-large);
			}
			
			.dropdown-element-border {
				display: flex;
				flex-wrap: wrap;
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				border-radius: var(--ced-border-radius-small);
			}
			
			.all-filters-button {
				white-space: nowrap;
			}
			
			.search-filter-all-filter-body{
				display: inline-block;
				width: 100%;
				margin-bottom: var(--ced-spacing-medium);
			}
			
			.clear-filters-button{
				font-family: var(--ced-font-family-bold);
				font-weight: var(--ced-font-weight-bold);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-component-base);
				color: var(--ced-color-text-brand);
				padding: var(--ced-spacing-xsmall) 0;
				background: var(--ced-color-global-alpha-transparent);
				border: var(--ced-border-radius-none);
				float: right;
				white-space: nowrap;
			}
			
			.clear-filters-button:hover, .clear-filters-button:active{
				text-decoration: underline;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
			
			.clear-filters-button:focus{
				outline-offset: var(--ced-text-focus-indicator-size);
				outline-width: var(--ced-text-focus-indicator-size);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
			}
			
			.results-filter-section{
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			
			.results-found-text{
				flex-grow: 1;
			}
			
			.search-filter-icon-button {
				border: none;
				background: none;
			}
		}

		c-lwr-icons {
			display: flex;
			
			.clickable {
				cursor: pointer;
			}
			
			.hidden {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				white-space: nowrap;
				border-width: 0;
			}
		}

		c-lwr-button {
			/* Primary Button **/
			.primary {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-primary);
				background-color: var(--ced-color-global-brand-2);
				color: var(--ced-color-text-inverse);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border-width: 0;
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.primary:hover {
				background-color: var(--ced-color-background-brand-hover)
			}

			.primary:focus {
				background-color: var(--ced-color-background-brand);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.primary:active {
				background-color: var(--ced-color-background-brand-focus);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}



			/*End Primary Button*/

			/***********Secondary Button***********/
			.secondary {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-neutral);
				background-color: var(--ced-color-global-neutral-6);
				color: var(--ced-color-global-neutral-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.secondary:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
			}

			.secondary:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.secondary:active {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			/***********End Secondary Button***********/

			.text {
				position: relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: inherit;
				color: var(--ced-color-global-brand-2);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid inherit;
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.text:hover {
				background-color: var(--ced-color-global-neutral-5);
			}

			.text:focus {
				background-color: inherit;
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.text:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.danger{
				color: var(--ced-color-text-danger);
			}
			.danger-text{
				color: var(--ced-color-text-danger);
			}

			.thin-text{
				padding: var(--ced-spacing-small) 0;
			}
			/** Disabled button styling **/

			/***********Danger Button***********/
			.danger:not(.text){
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				/* box-shadow: var(--ced-shadow-button-neutral); */
				box-shadow: 0px -2px 0px rgba(255,255,255, 0.5) inset;
				background-color: var(--ced-color-global-danger-4);
				color: var(--ced-color-global-danger-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-1);
				display: inline-block;
				justify-content: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.danger:hover {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-1);
			}

			.danger:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.danger:active {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			/***********End Danger Button***********/

			.lwr-input_loader {
				margin: auto;
				display: flex;
				max-width: 1.5rem;
				justify-content: space-between;
			}

			.lwr-input_loader span {
				width: 4px;
				height: 4px;
				border-radius: 2px;
				background: var(--ced-color-global-brand-3);
				color: var(--ced-color-global-brand-3);
				display: block;
				box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
				will-change: animation;
			}


			.lwr-input_loader .circle-1 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: .25s;
			}

			.lwr-input_loader .circle-2 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: .75s;
			}

			.lwr-input_loader .circle-3 {
				animation: dot-pulse .75s infinite linear;
				animation-delay: 1.25s;
			}

			.visually-hidden {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				white-space: nowrap;
				border-width: 0;
			}

			button[aria-busy="true"] {
				cursor: wait;
				display: flex;
			}

			button[aria-busy="true"] .button-text {
				visibility: hidden;
			}

			button .lwr-input_loader {
				width: 1.5rem;
				margin: 0 auto;
				position: absolute;
				left: 50%;
				margin-left: -.75rem;
				top: 50%;
				margin-top: -2px;
			}

			@keyframes dot-pulse {
				0% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}

				40% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-2), 0 0 0 3px var(--ced-color-global-brand-2);
					background: var(--ced-color-global-brand-2);
				}

				70% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}

				100% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-3), 0 0 0 0 var(--ced-color-global-brand-3);
					background: var(--ced-color-global-brand-3);
				}
			}

			@keyframes dot-pulse-white {
				0% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-4), 0 0 0 0 var(--ced-color-global-brand-4);
					background: var(--ced-color-global-brand-4);
				}

				40% {
					box-shadow: inset 0 0 10px var(--ced-color-global-white), 0 0 0 3px var(--ced-color-global-white);
					background: var(--ced-color-global-white);
				}

				70% {
					box-shadow: inset 0 0 10px var(--ced-color-global-brand-4), 0 0 0 0 var(--ced-color-global-brand-4);
					background: var(--ced-color-global-brand-4);
				}

				100% {
					box-shadow: inset 0 0 10px var(--ced-color-global-white), 0 0 0 0 var(--ced-color-global-white);
					background: var(--ced-color-global-white);
				}
			}


			button .lwr-input_loader .circle-1 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: .25s;
			}

			button .lwr-input_loader .circle-2 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: .75s;
			}

			button .lwr-input_loader .circle-3 {
				animation: dot-pulse-white .75s infinite linear;
				animation-delay: 1.25s;
			}
			.display-none{
				display: none;
			}
		}

		c-lwr-drawer {
			.modal-root{
				position: fixed;
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100vw;
				height: 100vh;
				display: flex;
				overflow-y: initial !important
			}

			.modal {
				all: unset;
			}
			
			@include media-drawer-sm {
				.modal{
					flex-grow: 1;
				}
			
				.outside-drawer{
					width: 72px;
				}
			}
			
			@include media-drawer-lg {
				.modal{
					width: 320px;
				}
			
				.outside-drawer{
					flex-grow: 1;
				}
			}
			
			.modal{
				height: 100vh;
				background-color: var(--ced-color-global-white);
				display: flex;
				flex-direction: column;
				overflow-y: auto;
			}
			
			.outside-drawer{
				height: 100vh;
				background-color: var(--ced-color-global-alpha-neutral-2);
			}
			
			.header{
				display: flex;
				align-items: center;
				box-shadow: 1px -1px 0px var(--ced-color-global-neutral-4) inset;
				padding: 0 var(--ced-spacing-xsmall) var(--ced-spacing-medium) var(--ced-spacing-medium);
			}
			
			.title{
				font-weight: var(--ced-font-weight-bold);
				font-size: var(--ced-font-size-medium);
				line-height: var(--ced-line-height-heading-medium);
				color: var(--ced-color-text);
				text-align: left;
				margin-top: var(--ced-spacing-medium);
			}
			
			.description{
				font-weight: var(--ced-font-weight-normal);
				font-size: var(--ced-font-size-xsmall);
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-xsmall);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			.close-button{
				padding: 6px;
				border-radius: var(--ced-border-radius-base);
				border-width: 0;
				background-color: var(--ced-color-background-transparent);
				margin-top: var(--ced-spacing-small);
				margin-left: var(--ced-spacing-xsmall);
				height: fit-content;
				width: fit-content;
			}
			
			.close-button:hover {
				background-color: var(--ced-color-global-neutral-5);
			}
			
			.close-button:focus {
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.close-button:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			
			.content{
				flex-grow: 1;
			}
			
			.padded-content{
				padding: var(--ced-spacing-medium);
				margin-bottom: 72px;
			}
			
			.flush-content{
				padding-bottom: var(--ced-spacing-medium);
			}
			
			.footer{
				box-shadow: 1px 1px 0px var(--ced-color-global-neutral-4) inset;
				background-color: var(--ced-color-background-neutral);
				padding: var(--ced-spacing-medium);
				position:fixed;
				bottom: 0;
				width: 320px;
			}
			
			.button{
				width: 100%;    
			}
			
			.title:focus{
				outline: 0;
			}
			
			.text-area{
				flex-grow: 1;
			}
		}

		c-lwr-pill-button {
			.pill-button{
				padding: var(--ced-spacing-xsmall) var(--ced-spacing-small) var(--ced-spacing-xsmall) var(--ced-spacing-small);
				font-size: 1.5rem;
				font-weight: var(--ced-font-weight-semi-bold);
				line-height: var(--ced-line-height-component-base);
				box-sizing: border-box;
				border-radius: 20px;
				border-width: 0;
				background: var(--ced-color-background-neutral-dark);
				border-color: none;
				color: var(--ced-color-global-neutral-1);
				transition: background var(--ced-transition-hover);
				display: flex;
				letter-spacing: .12px;
			}
			
			.pill-label{
				margin-right: var(--ced-spacing-xxsmall);
			}
			
			.pill-button:hover{
				background-color: var(--ced-color-background-neutral-dark-hover);
			}
			
			.pill-button:focus{
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}
			
			.pill-button:active{
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
				background-color: var(--ced-color-background-neutral-dark-hover);
			}
			
			.visually-hidden {
				clip: rect(0 0 0 0); 
				clip-path: inset(100%); 
				height: 1px; 
				overflow: hidden; 
				position: absolute; 
				white-space: nowrap; 
				width: 1px; 
			}
		}

		c-lwr-filter-drawer {
			.filter-drawer-divider {
				margin-top: var(--ced-spacing-medium) !important;
				margin-bottom: var(--ced-spacing-medium) !important;
			}
			
			.filter-drawer-border {
				border-top: 1px solid var(--ced-color-global-neutral-4);
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
		}

		c-lwr-filterable-checklist {
			.hidden {
				display: none;
			}
			
			.promoted-filter.filterable-checklist-header {
				padding: var(--ced-spacing-medium);
			}
			
			.all-filter.filterable-checklist-header {
				padding: var(--ced-spacing-xsmall) 0 var(--ced-spacing-medium) 0;
			}
			
			.search-input-container {
				padding: var(--ced-spacing-medium);
			}
			
			.filterable-search-input {
				border-radius: var(--ced-border-radius-base);
			}
			
			.option-list {
				position:relative;
				padding-left: var(--ced-spacing-medium);
			}
			
			.option-container {
				position:relative;
				padding: var(--ced-spacing-xxsmall) var(--ced-spacing-none) var(--ced-spacing-xxsmall) var(--ced-spacing-none);
			}
			
			.filterable-option-label {
				padding-left:4px;
			}
			
			.horizontal-line {
				margin-top: var(--ced-spacing-xsmall);
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.clear-filter-button {
				font-family: var(--ced-font-family-bold);
				font-weight: var(--ced-font-weight-bold);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-component-base);
				color: var(--ced-color-text-brand);
				padding: var(--ced-spacing-xsmall) 0;
				background: var(--ced-color-global-alpha-transparent);
				border: var(--ced-border-radius-none);
				float: left;
				white-space: nowrap;
			}
			
			.filterable-checklist {
				position: relative;
				overflow: auto;
				max-height: 300px;
			}
			
			.filterable-checklist-scrollable {
				position: relative;
				overflow: scroll;
				max-height: 300px;
			}
			
			.uncapped-height {
				max-height: none;
			}			
		}

		c-lwr-dropdown-button {
			/*Firefox specific css*/
			@-moz-document url-prefix() {
				.menu{
					width: 25rem;
					border-radius: var(--ced-border-radius-base);
					border: var(--ced-border-width) solid var(--ced-color-border-neutral);
					box-shadow: 0px -2px 0px var(--ced-color-global-white) inset;
					background-color: var(--ced-color-global-white);
					margin-top: var(--ced-spacing-xxsmall);
					padding: var(--ced-spacing-xsmall);
					position: absolute;
					top: 57px;
					z-index: 1;
				}
			}    
			/***********Secondary Button***********/
			.secondary{
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				box-shadow: var(--ced-shadow-button-neutral);
				background-color: var(--ced-color-global-neutral-6);
				color: var(--ced-color-global-neutral-1);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				display:flex;
				justify-content: center;
				align-items: center;
				transition: background var(--ced-transition-hover);
			}

			.secondary:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral-dark);
			}

			.secondary:focus {
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}

			.secondary:active {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-brand-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
			}
			/***********End Secondary Button***********/

			/***********Danger Button***********/

			.danger {
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-background-danger-light);
				color: var(--ced-color-text-danger);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-2);
				display:flex;
				justify-content: center;
				align-items: center;
				transition: background var(--ced-transition-hover);
				width: inherit;
			}

			.danger:hover {
				background-color: var(--ced-color-global-danger-3);
				border: var(--ced-border-width) solid var(--ced-color-global-danger-2);
			}

			.danger:focus {
				background-color: var(--ced-color-background-danger-light);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.danger:active {
				background-color: var(--ced-color-global-danger-3);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}
			/***********End Danger Button***********/

			/***********Navigational Button***********/
			.navigational{
				width: inherit;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-large);
				box-shadow: var(--ced-shadow-card);
				background-color: var(--ced-color-global-white);
				color: var(--ced-color-global-neutral-1);
				font-weight: var(--ced-font-weight-bold);
				font-size: var(--ced-font-size-xsmall);
				line-height: var(--ced-line-height-heading-xsmall);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral);
				display:flex;
				justify-content: space-between;
				transition: background var(--ced-transition-hover);
			}

			.navigational:hover {
				background-color: var(--ced-color-global-neutral-5);
			}

			.navigational:focus {
				outline: 2px solid var(--ced-color-global-brand-3);
			}

			.navigational:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
			}
			/***********End Navigational Button***********/

			.text {
				position:relative;
				padding: var(--ced-spacing-small);
				box-sizing: border-box;
				text-align: center;
				letter-spacing: 0.3px;
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-global-white);
				color: var(--ced-color-global-brand-2);
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: 1.5rem;
				line-height: var(--ced-line-height-component-base);
				border: var(--ced-border-width) solid var(--ced-color-global-white);
				display:flex;
				justify-content: center;
				align-items: center;
				transition: background var(--ced-transition-hover);
			}

			.text:hover {
				background-color: var(--ced-color-global-neutral-5);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-5);
			}

			.text:focus {
				background-color: var(--ced-color-global-white);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.text:active {
				background-color: var(--ced-color-global-neutral-5);
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3); 
			}

			.icon{
				margin-left: var(--ced-spacing-xxsmall);
			}

			@include media-dropdownbutton-md{
				.menu{
					width: 100vw !important;
				}
			}

			@include media-dropdownbutton-sm{
				.menu{
					width: 10rem !important;
				}
			}

			@include media-dropdownbutton-lg{
				.text {
					width: inherit;
				}

				.secondary{
					width: inherit;
				}
			}

			.menu{
				width: 25rem;
				border-radius: var(--ced-border-radius-base);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral);
				box-shadow: 0px -2px 0px var(--ced-color-global-white) inset;
				background-color: var(--ced-color-global-white);
				margin-top: var(--ced-spacing-xxsmall);
				padding: var(--ced-spacing-xsmall);
				position: absolute;
				z-index: 1;
			}

			.menu-right{
				right: 0;
			}

			.block-menu{
				width: inherit;
				border-radius: var(--ced-border-radius-base);
				border: var(--ced-border-width) solid var(--ced-color-border-neutral);
				box-shadow: 0px -2px 0px var(--ced-color-global-white) inset;
				background-color: var(--ced-color-global-white);
				margin-top: var(--ced-spacing-xxsmall);
				padding: var(--ced-spacing-xxsmall);
			}

			.container{
				width: 100%;
				position: relative;
				padding-left: 0;
				padding-right: 0;
			}
		}

		c-lwr-checkbox {
			display: flex;
			align-items: center;

			input[type=checkbox] {
				-webkit-appearance: none;
				appearance: none;
				background: #FAFAFA; 
				border: 2px solid var(--ced-color-border-neutral-dark);
				height: 1.5em;
				width: 1.5em;
				min-height: 1.5em;
				min-width: 1.5em;
				display: inline-block;
				border-radius: 100%;
				vertical-align: text-bottom;
				position: relative;
			
				&[disabled],
				&[aria-disabled=true] {
					opacity: .4;
				}
			
				&::before {
					content: '';
					position: absolute;
					margin: auto;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;
					top: 0;
				}
			
				&:focus {
					outline: 2px solid var(--ced-color-global-brand-3);
					outline-offset: 2px;
				}
			}
			
			input[type=checkbox] {
				border-radius: 6px;
			}
			  
			input[type=checkbox]:indeterminate::before,
			input[type=checkbox][aria-checked=mixed]::before {
				border: 2px solid var(--ced-color-global-brand-3);
				height: 0;
				width: 40%;
			}
			
			.default-checkbox:checked::before {
				border-right: 2px solid #FFFFFF;
				border-bottom: 2px solid #FFFFFF;
				height: 70%;
				width: 40%;
				transform: rotate(45deg) translateY(-7%) translateX(-12%);
			}
			
			.minus-checkbox:checked::before {
				border-right: 2px solid #FFFFFF;
				border-bottom: 0px solid #FFFFFF;
				height: 70%;
				width: 40%;
				transform: rotate(90deg) translateY(3%) translateX(-25%);
			}
			
			input[type=checkbox].error-checkbox {
				border: 2px solid #D34A35;
			}
			
			input[type=checkbox]:checked {
				background: var(--ced-color-global-brand-2);
			}
			
			.checkbox-label {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				margin-left: 8px;
				vertical-align: top;
			}
			
			.label {
				font-weight: var(--ced-font-weight-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				margin-left: var(--ced-spacing-xsmall);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			.hidden {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				white-space: nowrap;
				border-width: 0;
			}
		}

		c-lwr-filter-drawer-section {
			.drawer-section-icon-number {
				border: 1px solid var(--ced-color-global-neutral-4);
				border-radius: var(--ced-border-radius-full);
				height: 24px;
				width: 24px;
				text-align: center;
				color: var(--ced-color-global-neutral-1);
				background-color: var(--ced-color-global-neutral-4);
				font-weight: var(--ced-font-bold);
				padding-left: 2px;
				padding-right: 2px;
				font-size: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			.drawer-section-icon {
				position: relative;
				top: 4px;
				height: var(--ced-spacing-large) /*24px*/;
				width: var(--ced-spacing-large) /*24px*/;
				text-align: center;
				padding-left: 2px;
				padding-right: 2px;
				margin-left: var(--ced-spacing-medium);
			}
			
			.drawer-section-icon-container {
				display: flex;
				float: right;
			}
			
			.filter-drawer-section-title {
				font-weight: var(--ced-font-bold);
				font-size: 1.5rem;
				display: block;
				float: left;
			}
			
			.hideSection {
				visibility: hidden;
				height:0;
			}
			
			.filter-drawer-section-button {
				background: none;
				color: inherit;
				border: none;
				padding: 0;
				font: inherit;
				cursor: pointer;
				width: 100%;
			}			
		}

		c-lwr-range-filter {
			.hidden {
				display: none;
			}
			
			.filterable-range-header {
				padding: var(--ced-spacing-xsmall) 0 var(--ced-spacing-medium) 0;
			}
			
			.input-field {
				border-radius: 6px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset, 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
				height: 40px;
				margin-top: 8px;
				width: 90%;
			}
			
			.input-error {
				border-radius: 6px;
				border: 2px solid rgba(211,74,53, 1) !important;
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
			}
			
			.error-message-input-content {
				color: rgba(169,23,0,1);
				font-size: 14px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 16px;
				display: table;
			}
			
			.error-logo-input {
				width: 16px;
				height: 16px;
				display: inline-block;
				float: left;
				margin-right: 6px;
			}
			
			.input-field-min {
				margin-bottom: 16px;
			}
			
			.input-field-max {
				margin-bottom: 18px;
			}
			
			.apply-button {
				border-radius: 6px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(250,250,250, 1);
				margin-top: 8px;
				margin-bottom: 16px;
				height: 40px;
				width: 100%;
			}
			
			.input-field-legend {
				margin-top: 4px;
				opacity: 1;
				color: rgba(109,110,108,1);
				font-size: 14px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
			}
			
			.input-field-error {
				margin-top: 4px;
			}
			
			.input-field-label {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.12px;
				text-align: left;
				line-height: 16px;
			}
			
			.title {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
				margin-top: 8px;
				margin-bottom: 16px;
			}
			
			.clear-filters-button{
				font-family: var(--ced-font-family-bold);
				font-weight: var(--ced-font-weight-bold);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-component-base);
				color: var(--ced-color-text-brand);
				padding: var(--ced-spacing-xsmall) 0;
				background: var(--ced-color-global-alpha-transparent);
				border: var(--ced-border-radius-none);
				float: right;
				white-space: nowrap;
			}
			
			.clear-filters-button:hover, .clear-filters-button:active{
				text-decoration: underline;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
			
			.clear-filters-button:focus{
				outline-offset: var(--ced-text-focus-indicator-size);
				outline-width: var(--ced-text-focus-indicator-size);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
			}

			.range-filter {
				display: block;
			}
			
			.range-filter-flex-container {
				display:flex;
				gap: 8px;
			}
			.range-filter-flex-container.stacked {
				flex-wrap: wrap;
			}
			.range-filter-flex-container.stacked .input-field-min,
			.range-filter-flex-container.stacked .input-field-max {
				flex-basis: 100%;
			}
			.range-filter-flex-container.stacked .input-field {
				width: 100%;
			}
			
			.option-container {
				padding: 10px 0 10px 0;
			}

			.option-list {
				position: relative;
				padding-left: var(--ced-spacing-medium);
			}
		}

		c-lwr-notification {
			.container{
				flex-grow: 1;
				width: 100%;
				height: auto;
				padding-top: 12px;
				padding-bottom: 12px;
				padding-left: 12px;
				border-radius: 6px;
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				display: inline-block;
			}
			
			.content {
				display: inline-block;
				vertical-align: top;
				width: 75%;
				overflow-wrap: anywhere;
			}
			
			.link-info {
				text-decoration: none;
				opacity: 1;
				color: var(--ced-color-global-brand-2) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-success {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-success-1) !important;
				opacity: 1;
				color: var(--ced-color-global-success-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-warning {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-warning-1) !important;
				opacity: 1;
				color: var(--ced-color-global-warning-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.link-error {
				text-decoration: underline;
				text-decoration-color: var(--ced-color-global-danger-1) !important;
				opacity: 1;
				color: var(--ced-color-global-danger-1) !important;
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			ul.circle-bullet {
				list-style-type: disc;
				margin-left: 1em;
			}
			
			ul li::before {
				font-weight: bold;
				display: inline-block; 
				width: 1em;
				margin-left: -1em;
			}
			
			.dismissable-notification {
				padding-right: 8px;
			}
			
			.type-icon {
				height: 24px;
				margin-right: 8px;
				display: inline-block;
			}
			
			.close-icon {
				display: inline-block;
				float: right;
				margin-right: 14px;
			}
			
			.close-icon:hover {
				border-radius: 6px;
				opacity: 1;
				background-color: rgba(33,33,33, 0.07);
			}
			
			.close-icon:focus {
				border-radius: 8px;
				border: 2px solid rgba(125,142,207, 1);
				box-shadow: 0px 0px 0px 2px rgba(255,255,255, 1) inset;
				opacity: 1;
			}
			
			.close-icon:active {
				border-radius: 8px;
				border: 2px solid rgba(125,142,207, 1);
				box-shadow: 0px 0px 0px 2px rgba(255,255,255, 1) inset;
				opacity: 1;
			}
			
			.title {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
				display: inline-block;
				vertical-align: top;
			}
			
			.title-for-summary {
				font-weight: 700;
				letter-spacing: 0px;
			}
			
			.summary {
				opacity: 1;
				color: rgba(33,33,33,1);
				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: left;
				line-height: 24px;
			}
			
			.info {
				border: 1px solid var(--ced-color-global-neutral-4);
				background-color: var(--ced-color-global-neutral-6);
			}
			
			.success {
				border: 1px solid var(--ced-color-global-success-2);
				background-color: var(--ced-color-global-success-3);
			}
			
			.warning {
				border: 1px solid var(--ced-color-global-warning-2);
				background-color: var(--ced-color-global-warning-3);
			}
			
			.error {
				border: 1px solid var(--ced-color-global-danger-2);
				background-color: var(--ced-color-global-danger-4);
			}
			
			.actions-container {
				display: inline-block;
				margin-top: 16px;
			}
			
			.action {
				width: 141px;
				height: 40px;
				border-radius: 6px;
				box-shadow: 0px -2px 0px rgba(255,255,255, 0.25) inset, 0px -1px 0px rgba(34,46,114, 1) inset;
				opacity: 1;
				background-color: rgba(80,103,190, 1);
				opacity: 1;
				color: rgba(255,255,255,1);
				font-size: 16px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
				vertical-align: top;
				margin-right: 16px;
			}
			
			.cancel {
				width: 80px;
				height: 40px;
				border-radius: 6px;
				opacity: 1;
				background-color: rgba(33,33,33, 0);
				opacity: 1;
				color: rgba(80,103,190,1);
				font-size: 16px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: 0.3px;
				text-align: center;
				line-height: 16px;
				vertical-align: top;
			}
		}

		c-lwr-radio-button {
			display: flex;
			align-items: center;
			width: fit-content;
			
			.radio-button {
				width: 24px;
				height: 24px;
				min-width: 24px;
				min-height: 24px;
				appearance: none;
				border-radius: 50%;
				background: var(--ced-color-global-neutral-6);
				box-shadow: inset 0px 0px 0 2px var(--ced-color-border-neutral-dark);
			}
			
			.focus-button {
				box-shadow: inset 0px 0px 0 2px var(--ced-color-border-neutral-dark), 0px 0px 0px 2px var(--ced-color-focus-indicator-offset), 0px 0px 0px 4px var(--ced-color-focus-indicator);
				outline-width: 0;
			}
			
			.active-button {
				box-shadow: 0px 0px 0 2px var(--ced-color-global-brand-2);
				border: 2px solid var(--ced-color-global-neutral-6);
				background: var(--ced-color-global-brand-2);
			}
			
			.error-button {
				box-shadow: inset 0px 0px 0 2px var(--ced-color-border-danger);
			}
			
			.error-focus-button {
				box-shadow: inset 0px 0px 0 2px var(--ced-color-border-danger), 0px 0px 0px 2px var(--ced-color-focus-indicator-offset), 0px 0px 0px 4px var(--ced-color-focus-indicator);
				outline-width: 0;
			}
			
			.label {
				font-weight: var(--ced-font-weight-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				margin-left: var(--ced-spacing-xsmall);
				color: var(--ced-color-text);
				text-align: left;
			}
		}

		c-lwr-progress-bar {
			.lwr-loader[aria-hidden=true] {
				display: none;
			}
			
			.lwr-loader {
				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				padding: var(--ced-spacing-xxlarge) 0;
				cursor: progress;
			}
			
			.lwr-loader__icon_container {
				align-items: center;
				display: flex;
				height: 96px;
				justify-content: center;
				position: relative;
				width: 96px;
			}
			
			.lwr-loader__icon_container:before {
				background: var(--ced-color-global-brand-4);
				border-radius: 99999em;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 0;
			}
			
			.lwr-loader__icon {
				background: var(--ced-color-global-white);
				border-radius: var(--ced-border-radius-base, .375rem);
				box-shadow: inset 0 0 0 3px var(--ced-color-global-neutral-3);
				display: flex;
				flex-direction: column;
				/* height: 32px; */
				justify-content: space-between;
				overflow: hidden;
				padding: var(--ced-spacing-small, 12px) var(--ced-spacing-small, 12px);
				/* width: 40px; */
				width: 64px;
				height: 56px;
				z-index: 0;
			}
			
			.lwr-loader__line {
				border-radius: 2px;
				display: block;
				height: 6px;
				width: 40px;
				will-change: transform;
			}
			
			.lwr-loader__line_1 {
				background: var(--ced-color-global-brand-2);
			}
			
			.lwr-loader__line_2 {
				background: var(--ced-color-global-brand-3);
			}
			
			.lwr-loader__line_3 {
				background: var(--ced-color-global-brand-4);
			}
			
			/* apply animation only if the user has not set a reduced-motion setting in their browser */
			@media (prefers-reduced-motion: no-preference) {
				.lwr-loader__line {
					background: var(--ced-color-global-brand-3);
				}
			
				.lwr-loader__line:nth-child(3) {
					animation: loading 3s ease-out infinite;
				}
			
				.lwr-loader__line:nth-child(2) {
					animation: loading 3s ease-out infinite .3s;
				}
			
				.lwr-loader__line:nth-child(1) {
					animation: loading 3s ease-out infinite .6s;
				}
			}
			
			/* CSS animation keyframes */
			@keyframes loading {
				0% {
					background: var(--ced-color-global-neutral-5);
					transform: scale(95%);
				}
			
				3% {
					background: var(--ced-color-global-neutral-5);
				}
			
				33% {
					transform: scale(100%);
					background: var(--ced-color-global-brand-3);
				}
			
				73% {
					background: var(--ced-color-global-brand-3);
					transform: scale(100%);
				}
			
				98% {
					background: var(--ced-color-global-neutral-5);
				}
			
				100% {
					background: var(--ced-color-global-neutral-5);
					transform: scale(95%);
			
				}
			}
			
			.lwr-loader__text {
				display: block;
				text-align: center;
			}
		}

		c-lwr-data-table {
			table {
				width: 100%;
			}
			
			.slds-table td, .slds-table th {
				border: 1px solid;
				padding: 10px;
			}
			
			td {
				vertical-align: baseline;
			}
			
			.slds-table th {
				text-align: left;
			}
			
			.slds-hint-parent:hover {
				background-color: #f3f2f2;
				cursor: pointer;
			}
			
			.link-text {
				color: var(--ced-color-global-brand-2);
				padding-left: var(--ced-spacing-xsmall);
			}
			
			@include media-datatable-lg {
				.header {
					font-size: var(--ced-font-size-xxlarge);
					line-height: var(--ced-line-height-heading-xxlarge);
				}
			}
			
			@include media-datatable-sm {
				.header {
					font-size: var(--ced-font-size-xlarge);
					line-height: var(--ced-line-height-heading-large);
				}
			}
			.primary-text a {
				color: var(--ced-color-text-brand);
				font-weight: var(--ced-font-weight-semi-bold);
			}
			
			.primary-text[data-emphasize="true"] {
				font-weight: var(--ced-font-bold);
			}
			
			.breadcrumbs{
				left: -1em;
				position: relative;
			}
			
			.header {
				font-family: var(--ced-font-family-bold);
				color: var(--ced-color-text);
				text-align: left;
				margin-bottom: var(--ced-spacing-medium);
				margin-top: var(--ced-spacing-xsmall);
				flex-grow: 1;
			}
			
			.subheading-text{
				margin-bottom: var(--ced-spacing-large);
				font-family: var(--ced-font-family-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-medium);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			.notification{
				display: flex;
				margin-bottom: var(--ced-spacing-large);
			}
			
			.button-area{
				display: flex;
			}
			
			.action-button{
				margin-left: var(--ced-spacing-medium);
			}
			
			.underline{
				height: 1px;
				display: block;
				background-color: var(--ced-color-global-neutral-4);
				top: -17px;
				position: relative;
				margin-bottom: var(--ced-spacing-xsmall);
			}
			
			.results{
				display: block;
				margin-bottom: var(--ced-spacing-large);
				font-family: var(--ced-font-family-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			.header-row {
				box-shadow: 0px -1px 0px var(--ced-color-global-neutral-4) inset;
				background-color: var(--ced-color-background-neutral);
				font-family: var(--ced-font-family-bold);
				font-size: var(--ced-font-size-xsmall);
				letter-spacing: 0.3px;
				line-height: 1.143;
				text-align: left;
				width: fit-content;
			}
			
			.table-header-first-column {
				padding: var(--ced-spacing-small) var(--ced-spacing-xsmall) var(--ced-spacing-small) var(--ced-spacing-medium);
			}
			
			/* Give to first column if it has a checkbox */
			.table-header-checkbox {
				padding: var(--ced-spacing-xsmall) var(--ced-spacing-xsmall) var(--ced-spacing-xsmall) var(--ced-spacing-medium);
				display: flex;
				align-items: center;
			}
			
			.checkbox {
				margin-right: var(--ced-spacing-medium);
			}
			
			.table-header-middle-columns {
				padding: var(--ced-spacing-small) var(--ced-spacing-xsmall) var(--ced-spacing-small) var(--ced-spacing-xsmall);
				text-align: left;
			}
			
			.table-header-last-column {
				padding: var(--ced-spacing-small) var(--ced-spacing-medium) var(--ced-spacing-small) var(--ced-spacing-xsmall);
			}
			
			.sortable-header {
				padding: var(--ced-spacing-xxsmall) 0;
			}
			
			.sortable-button {
				border: 0;
				background-color: var(--ced-color-global-alpha-transparent);
				padding: var(--ced-spacing-xsmall) var(--ced-spacing-xxsmall) var(--ced-spacing-xsmall) var(--ced-spacing-xsmall);
				border-radius: var(--ced-border-radius-small);
				display: flex;
				align-items: center;
			}
			
			.sortable-button:hover {
				background-color: var(--ced-color-background-transparent-hover);
			}
			
			.sortable-button:focus {
				border-radius: var(--ced-border-radius-base);
				outline: var(--ced-border-width-large) solid var(--ced-color-focus-indicator);
				box-shadow: 0px 0px 0px 2px var(--ced-color-global-white) inset;
			}
			
			.active-header {
				background-color: var(--ced-color-background-brand-light);
				box-shadow: 0px -4px 0px 0px var(--ced-color-global-brand-2) inset;
			}
			
			.sort-icon {
				margin-left: var(--ced-spacing-xxsmall);
			}
			
			.tooltip-icon {
				margin-right: var(--ced-spacing-xxsmall);
			}
			
			.tooltip-header {
				position: relative;
			}
			
			.tooltip {
				top: calc(-50% + 8px);
				left: calc(-50% + 20px);
			}
			
			.table-row-first-column {
				padding: var(--ced-spacing-large) var(--ced-spacing-xsmall) var(--ced-spacing-large) var(--ced-spacing-medium);
				text-align: left;
				display: flex;
			}
			
			.table-row-middle-columns {
				padding: var(--ced-spacing-large) var(--ced-spacing-xsmall) var(--ced-spacing-large) var(--ced-spacing-xsmall);
				text-align: left;
			
			}
			
			.table-row-last-column {
				padding: var(--ced-spacing-large) var(--ced-spacing-medium) var(--ced-spacing-large) var(--ced-spacing-xsmall);
				text-align: right;
			}
			
			.sortable-button-last-column{
				float: right;
			}
			
			.table-row {
				box-shadow: 0px -1px 0px var(--ced-color-global-neutral-4) inset;
			}
			
			.table-body {
				font-family: var(--ced-font-family-normal);
				font-size: var(--ced-font-size-small);
				letter-spacing: 0.12px;
				line-height: 1.25;
				color: var(--ced-color-text);
			}
			
			.table-border {
			
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-4);
			
			}
			
			/* Line clamps description after 2 lines */
			@include media-datatable-sm {
				.directly-related-content {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
			
			.directly-related-content {
				margin-top: var(--ced-spacing-xxsmall);
			}
			
			.indirectly-related-content {
				display: block;
				margin-top: var(--ced-spacing-xsmall);
			}
			
			.main-image {
				width: 40px;
				height: 40px;
				margin-right: var(--ced-spacing-small);
			}
			
			.button-area {
				display: flex;
				margin-top: var(--ced-spacing-medium);
			}
			
			.button {
				margin-right: var(--ced-spacing-medium);
			}
			
			/* Add shadows for responsive behavior */
			.table-responsive {
				overflow-x: auto;
				max-width: 100vw;
				/* background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0)); */
				/* Shadows */
				/* Shadow covers */
				background-position: left center, right center, left center, right center;
				background-repeat: no-repeat;
				background-color: var(--ced-color-global-white);
				background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
				background-attachment: local, local, scroll, scroll;
			
				padding-bottom: var(--ced-spacing-medium);
			}
			.shadows{
				position: relative;
			}
			
			.shadows:before {
				opacity: 0;
				background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
				content: "";
				display: block;
				height: 100%;
				pointer-events: none;
				position: absolute;
				left: 0px;
				top: 0px;
				width: 12px;
				z-index: 2;
			}
			
			.shadows:after {
				opacity: 0;
				background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
				content: "";
				display: block;
				height: 100%;
				pointer-events: none;
				position: absolute;
				right: 0px;
				top: 0px;
				width: 12px;
				z-index: 2;
			}
			
			.table-shadow-left:before{
				opacity: 1 !important;
			}
			
			.table-shadow-right:after{
				opacity: 1 !important;
			}
			
			.secondary-text{
				font-size: 14px;
			}
			
			.quantity-minus {
				width: 40px;
				height: 40px;
				border-radius: 6px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(250,250,250, 1);
				display: inline-block;
				margin-right: 9px;
				padding: 8px;
			}
			
			.quantity-plus {
				width: 40px;
				height: 40px;
				border-radius: 6px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(250,250,250, 1);
				display: inline-block;
				padding: 8px;
			}
			
			.quantity-value {
				width: 62px;
				height: 40px;
				border-radius: 6px;
				border: 1px solid rgba(142,144,141, 1);
				box-shadow: 0px 2px 1px rgba(33,33,33, 0.07) inset, 0px -2px 0px rgba(255,255,255, 1) inset;
				opacity: 1;
				background-color: rgba(255,255,255, 1);
				display: inline-block;
				margin-right: 9px;
				padding: 8px;
				vertical-align: top;
				text-align: center;
			}
			
			.image {
				display: inline-block;
				vertical-align: top;
			}
			
			.image-text {
				display: inline-block;
				margin-left: var(--ced-spacing-xxsmall);
			}
			
			@media print {
				.table-row-print {
					border-bottom: 1px solid var(--ced-color-global-neutral-4);
				}
			
				.header-row-print {
					border-bottom: 1px solid var(--ced-color-global-neutral-4);
				}
			
				.header-print {
					font-size: var(--ced-font-size-xxlarge);
					font-weight: var(--ced-font-weight-bold);
				}
			}
			
			.merged-row-title {
				font-weight: var(--ced-font-weight-bold);
			}
			
			.quantity-save-button {
				margin-top: 8px;
			}
			
			.visually-hidden {
				height: 0;
				visibility: hidden;
			}
			
			.results{
				display: block;
				margin-bottom: var(--ced-spacing-large);
				font-weight: var(--ced-font-weight-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				text-align: left;
			}
			
			a,a:link:not(.slds-button,.slds-dropdown__item>a){
				color: var(--ced-color-text-brand);
			}
			.rel-orders-section {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
			.rel-orders-background {
				padding: var(--ced-spacing-xsmall) var(--ced-spacing-xxsmall);
				background-color: var(--ced-color-background-brand-light);
			}
			.rel-orders-table {
				display: flex;
				flex-direction: column;
			}
			.rel-orders-title {
				font-weight: var(--ced-font-weight-semi-bold);
				font-size: var(--ced-font-size-large);
				margin-bottom: var(--ced-spacing-xsmall);
			}
			.rel-orders-table th, .rel-orders-table td{
				width: 33%;
				padding-left: var(--ced-spacing-medium);
			}
			.rel-orders-table .header-row{
				display: flex;
				align-items: baseline;
				width: 100%;
				padding-top: var(--ced-spacing-xxsmall) 0;
			}
			.rel-orders-table .table-row{
				display: flex;
				align-items: baseline;
				width: 100%;
				padding: var(--ced-spacing-large) 0;
			}
			
			.rel-orders-table .sortable-button{
				padding-left: 0;
			}
			
			.order-link:hover{
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
			}
			
			.order-link:focus, .order-link:active{
				text-decoration: underline;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: var(--ced-text-link-underline-size);
				text-underline-offset: var(--ced-text-link-underline-size);
				outline-color: var(--ced-color-focus-indicator);
				outline-style: solid;
				outline-width: var(--ced-text-focus-indicator-size);
				outline-offset: var(--ced-text-focus-indicator-size);
			}
			
			.table-body {
				font-weight: var(--ced-font-weight-normal);
				font-size: var(--ced-font-size-small);
				letter-spacing: 0.12px;
				line-height: 1.25;
				color: var(--ced-color-text);
			}
			
			.table-row {
				box-shadow: 0px -1px 0px var(--ced-color-global-neutral-4) inset;
			}
			.active-row {
				background-color: var(--ced-color-background-brand-light);
				box-shadow: 4px 0px 0px 0px var(--ced-color-global-brand-2) inset, 0px -1px 0px var(--ced-color-global-neutral-4) inset;
			}
			.icon-cell{
				width: 24px;
			}
			
			.section-header {
				background-color: var(--ced-color-background-neutral);
			}
			
			.kit-component {
				margin-right: 24px;
			}
		}

		c-lwr-pagination {
			@include media-pagination-lg {
				.mobile{
					display: none !important;
				}
			}
			
			@include media-pagination-sm {
				.desktop{
					display: none !important;
				}
			}
			
			.mobile{
				display: inline-block;
				font-weight: var(--ced-font-weight-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				margin-left: var(--ced-spacing-xsmall);
				min-width: 176px;
				text-align: center;
			}
			
			.hide-arrow{
				visibility: hidden;
			}
			
			.pagination{
				display: flex;
				justify-content: center;
			}
			
			.arrow{
				padding: var(--ced-spacing-small) var(--ced-spacing-medium);
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-background-neutral);
				border: var(--ced-border-width) solid var(--ced-color-global-neutral-3);
				box-shadow: var(--ced-shadow-button-neutral);
			}
			
			.right-arrow{
				margin-left: var(--ced-spacing-xsmall);
			}
			
			.button{
				margin-left: var(--ced-spacing-xsmall);
				border-radius: var(--ced-border-radius-base);
				background-color: var(--ced-color-global-alpha-transparent);
				padding: var(--ced-spacing-xsmall);
				font-weight: var(--ced-font-weight-normal);
				font-size: 1.5rem;
				letter-spacing: 0.3px;
				line-height: var(--ced-line-height-heading-small);
				color: var(--ced-color-text);
				border-width: 0;
				display: inline-block;
				text-align: center;
				min-width: 40px;
			}
			
			.page-button:hover{
				background-color: var(--ced-color-background-transparent-hover);
			}
			
			.arrow:hover{
				background-color: var(--ced-color-background-transparent-hover);
				box-shadow: none;
			}
			
			.arrow:focus{
				outline: 2px solid var(--ced-color-global-white);
				box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
				border-radius: var(--ced-border-radius-large-inner);
			}
			
			.button-active-underline{
				padding-bottom: calc(var(--ced-spacing-xsmall) - 4px);
				border-bottom: solid;
				border-bottom-color: var(--ced-color-global-brand-3);
				border-bottom-width: 4px;
			}
			
			.button-active{
				padding-bottom: 0;
				background-color: var(--ced-color-background-brand-light);
			}
			
			.page-button:focus{
				border-radius: var(--ced-border-radius-large-inner);
				border: var(--ced-border-width-large) solid var(--ced-color-background-brand-light-focus);
				box-shadow: 0px 0px 0px 2px var(--ced-color-focus-indicator-offset) inset;
				outline-width: 0;
				min-width: 44px;
			}
		}
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.greentech-planset-requests-container {
		margin: 16px;
		padding-left: 0px;
		padding-right: 0px;
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.greentech-breadcrumb {
		margin: 12px 0px;
	}

	.greentech-planset-requests-container {
		margin: 16px;
		padding-left: 0;
		padding-right: 0;

		.all-projects-heading {
			margin: 12px 0px;
			display: block !important;
			h1 {
				display: inherit;
				position: inherit;
				margin: 12px 0px;
				width: 100%;
			}
		}
	}
}