@import "../variables";

.admin-users {
	table {
		tbody {
			tr.inactive {
				td {
					color: #b9bbc0;
				}				
			}

			tr {
				td.username-col {
					overflow-wrap: break-word;
					word-wrap: break-word;

					-ms-word-break: break-all;
					word-break: break-all;
					word-break: break-word;
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	.admin-right-content.admin-users {
		padding-left: 0;
		padding-right: 0;

		hr {
			margin-top: 20px;
			margin-bottom: 0;
			border-top: 1px solid #b9bbc0;
		}

		.top-row {
			margin-left: 0;
			margin-right: 0;
		}

		#customer-users-add-user {
			width: 100%;
		}

		.footable-row-detail-value {
			overflow-wrap: break-word;
			word-wrap: break-word;

			-ms-word-break: break-all;
			word-break: break-all;
			word-break: break-word;
		}
	}
}