@import "../variables";

.repairWarranty{
	#warranty-nav-box{
		display: flex;
		//justify-content: space-between;
		flex-wrap: wrap;
	}

	.nav-button{
		max-width: 24%;
		min-width: 24%;
		margin-right: 1%;

		background: linear-gradient(-180deg, #FFFFFF 0%, #FCFCFC 100%);
		border: 1px solid #BBBDC6;
		box-shadow: inset 0 -1px 0 0 #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		padding: 24px 16px;
		color: black;
		margin-bottom: 15px;

		&:hover{
			text-decoration: none;
			color: black;
			box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.75);

			.nav-icon{
				color: white;
			}
		}

		.nav-icon{
			width: 48px;
			height: 48px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			border-width: 2px;
			border-style: solid;
		}
	}

	@media only screen and (min-width: $desktop-min-width) {
		.nav-button{
			label{
				margin-top: 8px;
			}
		}
	}

	@media only screen and (max-width: $tablet-max-width) {
		.nav-button{
			display: flex;

			.nav-icon{
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 8px;
			}
		}
	}

	@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
		.nav-button{
			max-width: 49%;
			min-width: 49%;
		}
	}

	@media only screen and (max-width: $mobile-max-width) {
		.nav-button{
			max-width: 100%;
			min-width: 100%;
		}
	}
}