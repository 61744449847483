@import "../variables";

.greentech-financing {
    .button:not(.disabled),
    .button-emphasis,
    .button-emphasis-small {
        margin: 5px;
    }

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 15px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }

        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    .admin-right-content {
        margin-bottom: 30px;
        width: 100%;

        .title-heading-line-1 {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
        }

        .title-heading-line-2 {
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        .project-settings-button {

            padding: 8px 12px;

            &.desktop-button {
                float: right;
            }

            &.mobile-button {
                display: none;
            }

            a {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;
                border-radius: 5px;
                margin-right: 10px;
                text-decoration: none;
            }
        }

        .success-message,
        .danger-message {
            display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            align-items: center;
            -ms-flex-align: center;

            
            border-radius: 3px;
            padding: 10px 0;
            margin-top: 4px;
            margin-bottom: 20px;

            .status {
                flex: 1;
            }

            .icon {
                width: 16px;
                height: 16px;
                margin: 0 13px;
                flex: 0;
            }
        }

        .success-message {
            border: 1px solid $status-success-2;
            background: $status-success-3;

            .icon {
                svg {
                    fill: $status-success-2;
                }
            }
        }

        .danger-message {
            border: 1px solid $status-danger-2;
            background: $status-danger-3;

            .icon {
                svg {
                    stroke: $status-danger-2;
                }
            }
        }

        .add-loan-section {
            max-width: 557px;

            margin-top: 4px;

            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;

            .section-title {
                margin-bottom: 8px;

                color: rgb(33, 33, 33);
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.15px;
                line-height: 24px;
            }

            button.add-loan-button {
                display: flex;
                align-items: center;

                margin-top: 23px;

                color: rgb(255, 255, 255);
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.13px;
                line-height: 16px;

                svg {
                    stroke: #fff;
                    margin-right: 4px;
                }
            }
        }

        .existing-loan-section {
            max-width: 100%;

            .section-title {
                margin-top: 40px;
                margin-bottom: 16px;

                color: rgb(33, 33, 33);
                font-size: 24px;
                font-weight: normal;
                letter-spacing: 0.23px;
                line-height: 28px;
            }

            table.loan-table-lg,
            table.loan-table-md {
                width: 100%;

                tr {
                    border: none;
                    box-shadow: none;
                }

                th, td {
                    border: 1px solid #d5d8d9;
                    padding: 10px 16px;

                    font-size: 14px;
                    letter-spacing: 0.13px;
                    line-height: 20px;
                }

                th,
                td.show-more {
                    background-color: #f8f9f9;
                    font-weight: bold;
                }

                td {
                    font-weight: normal;

                    &:first-child {
                        font-weight: bold;
                    }
                }
            }

            table.loan-table-md {
                .toggle-text {
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 0.11px;
                    line-height: 20px;
                    cursor: pointer;
                }

                .show-more-link {
                    display: inline-block;
                    padding: 0 5px;
                    margin: 0;
                }

                ul {
                    list-style: none;
                    margin: 10px 0 0 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0 0 0 14px;
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: 0.11px;
                        line-height: 20px;

                        .loan-field {
                            display: flex;
    
                            .loan-field-label {
                                width: 100px;
                                min-width: 100px;
                                max-width: 100px;
    
                                color: rgb(106, 107, 109);
                                font-size: 12px;
                                font-weight: normal;
                                letter-spacing: 0.11px;
                                line-height: 20px;
                            }
    
                            .loan-field-value {
                                margin-left: 8px;

                                color: rgb(33, 33, 33);
                                font-size: 12px;
                                font-weight: normal;
                                letter-spacing: 0.11px;
                                line-height: 20px;
                            }
                        }
                    }
                }

                .more-text {
                    display: none;
                }
            }

            .loan-table-sm {
                width: 100%;

                .loan-table-row {
                    padding-left: 16px;
                    padding-right: 16px;
                    margin-top: 16px;
                    margin-bottom: 16px;

                    .loan-field {
                        display: flex;

                        .loan-field-label {
                            width: 115px;
                            min-width: 115px;
                            max-width: 115px;

                            color: rgb(106, 107, 109);
                            font-size: 12px;
                            font-weight: normal;
                            letter-spacing: 0.11px;
                            line-height: 20px;
                        }

                        .loan-field-value {
                            margin-left: 15px;

                            color: rgb(43, 61, 65);
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0.13px;
                            line-height: 20px;

                            .remove-loan-button {
                                margin-top: 10px;
                            }
                        }

                        &:first-child {
                            .loan-field-value {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            table.loan-table-lg {
                display: table;
            }

            table.loan-table-md,
            .loan-table-sm {
                display: none;
            }
        }
    }

    .overview-layout {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        .greentech-my-links-left-container {
            .menu-item {
                a {
                    padding-left: 5px;
                    justify-content: left;
                }
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentech-financing {

        .breadcrumbs {
            padding-left: 0px;
        }

        .overview-layout {
            .home-left-content {
                width: 68px;

                .greentech-my-links-left-container {
                    background-color: #fff;

                    .menu-item {
                        width: unset;
                    }
                }
            }
        }

        .admin-right-content {

            .existing-loan-section {
                table.loan-table-lg,
                .loan-table-sm {
                    display: none;
                }

                table.loan-table-md {
                    display: table;
                }
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentech-financing {
        .breadcrumbs {
            padding-left: 0px;
        }

        .home-left-content {
            margin-left: 5px;
        }

        .admin-right-content {

            .title-heading-line-2 {
                font-size: 24px;
                padding-bottom: 0;
            }

            .project-settings-button {

                padding-left: 0;
                padding-right: 0;

                &.desktop-button {
                    display: none;
                }

                &.mobile-button {
                    display: block;
                    margin-left: 0%;
                    padding-top: 31px;
                }
            }

            .add-loan-section {
                max-width: 100%;
            }

            .existing-loan-section {
                table.loan-table-lg,
                table.loan-table-md {
                    display: none;
                }

                .loan-table-sm {
                    display: block;
                }
            }
        }
    }
}
