@import "../variables";


#main-content{
    min-height: 200px !important;
    padding-bottom: 0px;
}
.greentech-loans-container{
    background-color: none;
    padding-left: 40px;
    padding-right: 40px;
    min-width: fit-content;

    .all-loans-heading{
        display: flex;
        justify-content: flex-start;
        align-self: center;
        margin: 12px 0px;
        font-weight: normal;
        h1{
            display: inline;
        }
    }

    .fa-check-circle {
        color: #589815;
    }

    .fa-times {
        color: rgb(33, 33, 33);
        float: right;
    }

    .greentech-breadcrumb{
        display: flex;
        justify-content: flex-start;
        background-color: none;
        margin: 20px 0px 12px 0px;
        line-height: 20px;
        font-size: 12px;
        font-weight: normal;
        
        p{
            margin-left: 9px;
        }
    }

    #loans-table-container{
        margin: 12px 0px;
        width: 100%;
        min-width: fit-content;

        .noLoansFound{
            background-color: none;
        }

        #filters-container{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            border-radius: 3px;
            border: 1px solid;
            padding: 16px;
            // base styles for filters section structure
            #filters-top, #filters-middle, #filters-bottom{
                display: inherit;
                flex-direction: row;
            }
            // base styles for inner elements of filters sections
            .loans-filter{
                display:inherit;
                flex-direction: column;
                gap: 8px;
                .filter-label{
                    margin-bottom: 0px !important;
                }

                .filter-label{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                }
                input, select{
                    height: 40px;
                    padding: 8px;
                    border: 1px solid;
                    border-radius: 3px;
                }

                button{
                    display: flex;
                    border: none;
                    font-size: 14px;
                    font-weight: bold !important;
                    padding: 10px 10px 10px 0px;
                }
            }
            // more narrow styling for each of the 3 rows in loans-filters-container
            #filters-top{
                align-items: center;
                justify-content: space-between;
                #filters-heading{
                    margin: 0px;
                    font-weight: bold;
                    font-size: 16px;
                    height: 20px;
                }
            }
            #tablet-row-container{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 24px;
            }
            #filters-middle{
                justify-content: space-between;
                gap: 24px;

                select{
                    min-width: 200px;
                }
                .loans-filter{
                    flex-basis: auto;
                    flex-grow: 1;
                }
                #store-filter,
                #loanStatusFilter, 
                #availabilityFilter{
                    fieldset{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }  
                }
                #search-filter{
                    flex-basis: 425px;
                }
            }
           
            #filters-bottom{
                justify-content: flex-start;
                align-content: center;
                gap: 24px;
                
                #origination-date-filter, #total-materials-filter{
                    display: flex;
                    flex-direction: column;        
                   
                    div{
                        display: flex;
                    }
                    
                    div:nth-of-type(2){
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 8px;

                        .calendar-container{
                            position: relative;
                            .greentech-calendar{
                                position: absolute;
                                top:10px;
                                right: 8px;
                                padding: 3px 0px;
                            }
                        }                
                    }
                }

                #clearAllFilter{
                    align-self: flex-end;
                }
    
            }
            //handles expanding and collapsing of filters section
            &.expanded{
                #filters-middle, #filters-bottom{
                    display: inherit;
                }
                #filters-collapse{
                    display:inherit;
                }
                #filters-expand{
                    display:none;
                }
            }
            &.collapsed{
                #filters-middle, #filters-bottom{
                    display: none;
                }
                #filters-collapse{
                    display: none;
                }
                #filters-expand{
                    display:inherit;
                }
                #clearAllFilters{
                    display: none;
                }
            }
        }        
        
        #showing-of-text{
            margin-top: 12px;
        }
        
        #loans-table{
            margin: 12px 0px;
            border: 1px solid;
        
            th{
                border: 1px solid;
                border-left: 0px;
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
            }
            
            td{
                border: 1px solid;
                border-left: 0px;
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
                vertical-align: top;    
            }

            tr.footable-detail-show {
                background-color: white;
            }
            
            .loanIdCol{
                font-weight: bold;
            }

            .show-more-info{
                display: none;
                .showMoreCol{
                    cursor: pointer;
                }
            }
        }

        #loansPagination{
            display: flex;
            justify-content: center;
            list-style: none;
            .chevron-container{
                border: 2px solid;
                border-radius: 50%;
                height: 32px;
                width: 32px;
            }
        }

        #loans-table {
            width: 100%;
        }
        #noLoansFound{
            border-right: 0px !important; 
        }
    }
}



/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentech-loans-container{
        .noLoansFound{
            background-color: none;
        }
        margin: 16px;
        padding-left: 0px;
        padding-right: 0px;
        #loans-table-container {
            #filters-container.expanded{
                padding: 16px;
                gap: 0px;
                #filters-top{
                    margin-bottom: 20px;
                }
                #filters-middle .loans-filter{
                    display:flex;
                    margin-bottom: 20px;
                }
                #filters-middle, #filters-bottom{
                    display: block !important;
                    .loans-filter, .filter-input{
                        flex-basis: auto !important;
                        width: 100%;
                    }
                    .date-picker-container div{
                        flex-grow: 1;
                        flex-basis: auto;
                    }
                    #clearAllFilter{
                        width: auto;
                        min-width: 70px;
                    }
                }
            }

            #loans-table {
                thead{
                    th:nth-of-type(1){
                        min-width: 150px;
                    }
                    th:nth-of-type(6),
                    th:nth-of-type(7){
                        display: none;
                    }
                }
                tr{
                    td:nth-of-type(6),
                    td:nth-of-type(7){
                        display: none;
                    }
                }
                tbody{
                    tr.show-more-info{
                        border-bottom: 0px !important;
                        padding-top: 5px;
                        padding-bottom: 10px;
                        display: block;
                        &.collapsed{
                            height: 32px;
                            td:first-child{
                                i:nth-of-type(1){
                                    display: inline-block;
                                }
                                i:nth-of-type(2){
                                    display: none;
                                }
                            }
                            td:nth-of-type(2),
                            td:nth-of-type(3){
                                display: none;
                            }
                        }

                        &.expanded{
                            height: 100px;
                            td:first-child{
                                i:nth-of-type(1){
                                    display: none;
                                }
                                i:nth-of-type(2){
                                    display: inline-block;
                                }
                            }
                            td:nth-of-type(2),
                            td:nth-of-type(3){
                                display: block;
                            }
                        }
                        td{
                            border: none;
                            display: block;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            font-size: 12px;
                        }
                        i{
                            padding-left: 12px;
                            margin-right: 3px;
                        }
                        td:before {
                            position: absolute;
                            left: 40px;	
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 1.5;	
                            bottom: 4px;
                            top: 1px;				
                        }
                        td:nth-of-type(2):before { 
                            z-index: 1;
                            content: "Loan Status:"; 
                        }
                        td:nth-of-type(3):before { 
                            content: "Project Address:";
                            padding-top:24px
                         }
                        
                        td:nth-of-type(2){
                            position: absolute;
                            z-index: 1;
                            padding-left: 150px;
                        }
                        td:nth-of-type(3){
                            position: absolute;
                            padding-left: 150px;
                            padding-top: 24px;
                        }
                        
                        
                    }
                }
            }
        }
    }
}

    /* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	
    .greentech-breadcrumb{
        margin: 12px 0px;
    }
    .greentech-loans-container {
        margin: 16px;
		padding-left: 0;
		padding-right: 0;

        .noLoansFound{
            background-color: none;
        }

		hr {
			margin: 12px 0px;
			border-top: 1px solid;
		}

        #loans-table-container{

            #filters-container{
                padding: 16px;
                #filters-middle, 
                #filters-bottom, 
                #tablet-row-container,
                .date-picker-container,
                .date-picker-container div,
                 .date-picker-container div input,
                 #total-materials-filter,
                 #total-materials-filter div,
                 #total-materials-filter div input{
                     flex-direction: column !important;
                     flex-grow: 1;
                     flex-basis: auto;
                 }
                #search-filter{
                    flex-basis: auto !important;
                }
                #clearAllFilter{
                    align-self: flex-start !important;
                }
            }     

            #loansPagination {
                padding-left: 0;

                .page-selector {
                    width: unset;
                }
            }

            .footable-toggle{
                display: none !important; 
            }

            th{
                border-right: 1px solid;
            }
            td{
                border: none !important;
            }

            #showing-of-text{
                display: block;
                margin: 12px 0px;
            }
            
            #all-loans-table-header-mobile{
                display: inline !important;
                margin: 12px 0px;
                font-weight: bold;
            }

            #loans-table {
                margin: 24px 0px;
                border: none;
               
                thead {
                    th {
                        display: none;
                    }
                
                }
    
                tbody {
                    tr.footable-detail-show {
                        border-bottom: 1px solid;
                        padding-top: 5px;
                        padding-bottom: 30px;
                        display: block;
    
                        td {
                            border: none;
                            position: relative;
                            padding-left: 150px;
                            display: block;
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }
                        
                        td:before {
                            position: absolute;
                            left: 30px;	
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 1.5;	
                            bottom: 4px;
                            top: 1px;				
                        }
    
                        td:nth-of-type(1):before { content: "Loan ID"; }
                        td:nth-of-type(2):before { content: "Finance Address"; }
                        td:nth-of-type(3):before { content: "Lender"; }
                        td:nth-of-type(4):before { content: "Origination Date"; }
                        td:nth-of-type(5):before { content: "Total Materials"; }
                        td:nth-of-type(6):before { content: "Loan Status"; }
                        td:nth-of-type(7):before { content: "Project Address"; }

                        td.loanIDCol {
                            font-weight: bold;
                        }
                    }
                }
            }
        }  
        #noLoansFound{
            padding-left: 13px !important;
        }
	}
}
