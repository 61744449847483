@import "../variables";

.greentech-billing {
    margin-left: 10px;

    .breadcrumbs {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.11px;
        line-height: 20px;
        padding-left: 5px;
        margin-top: 20px;
        display: block;

        a {
            padding: 10px;
        }

        .bc-name {
            padding-left: 10px;
            color: rgb(106, 107, 109);
        }
    }

    ul#orders-pagination {
		padding-left: 0;
		list-style-type: none;
		
		li {
			margin-left: 0;
			display: inline-block;
			vertical-align: top;

			a {
				display: block;
			}
		}
	}

    .admin-right-content {
        margin-bottom: 30px;
        width: 100%;

        .title-heading-line-1 {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.13px;
            line-height: 20px;
            color: rgb(106, 107, 109);
        }

        .title-heading-line-2 {
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.3px;
            line-height: 40px;
        }

        .showing {
            color: rgb(33, 33, 33);
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.13px;
            margin-bottom: 16px;
            display: none;
        }
        .salesOrderShowing {
            display: block;
        }

        .nav-tabs {
            margin-top: 4px;
        }

        .tab-content {
            margin-top: 24px;
        }

        .invoices-table, #adminOrdersTable {
            thead {
                tr {
                    th {
                        background: rgb(248, 249, 249);
                        border-radius: 0px;
                        border: 1px solid rgb(213, 216, 217);
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        padding-left: 15px;
                    }
                }
            }

            tbody {
                tr {
                    border: none;

                    td {
                        border-radius: 0px;
                        border: 1px solid rgb(213, 216, 217);
                        color: rgb(33, 33, 33);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0.13px;
                        line-height: 20px;
                        padding-left: 15px;

                        &.invoice-button-column {
                            padding-right: 0px;
                            padding-left: 0px;
                        }

                        &.noOrdersMessage {
                            color: rgb(33, 33, 33);
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0.13px;
                            line-height: 20px;
                        }

                        .order-invoice-order-order-link-help-mobile {
                            font-weight: bold;
                        }

                        .related-orders-show-project {
                            font-size: 12px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                        }
                    }

                    td.sub-row-toggle-row {
                        padding-top: 6px;
                        padding-bottom: 6px;
                        font-size: 12px;
                    }
                }

                tr.sub-row {
                    td {
                        font-size: 12px;
                        padding-left: 29px;

                        .small-text {
                            font-size: 12px;
                        }
                    }
                }

                tr.poRow, tr.jobRow {
                    display: none;
                }
    
                tr.shown {
                    border-bottom-width: 0;
                }
    
                tr.hasRelated {
                    border-bottom: none;
                }
    
                tr.related-order-row-toggle {
                    td {
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                }
    
                tr.related-order-row-toggle.open {
                    border-bottom: none;
                }
    
                tr.child-row.first-child-row {	
                    td {
                        > div {
                            border-top: 1px solid rgb(213, 216, 217);
                        }
                    }
    
                    td.first-col {
                        border-top: 0;
    
                        > div {
                            border-top: 1px solid rgb(213, 216, 217);	
                            padding-left: 0;			
                        }
                    }
    
                    td.last-col {
                        border-top: 0;
    
                        > div {
                            border-top: 1px solid rgb(213, 216, 217);
                        }
                    }
                }
    
                tr.child-row.last-child-row {
                    border-bottom: 1px solid rgb(213, 216, 217);
    
                    td {
                        > div {
                            border-bottom: none;
                        }
                    }
    
                    td.first-col {
                        > div {
                            border-bottom: none;
                            margin-bottom: 0;
                        }
                    }
    
                    td.last-col {
                        > div {
                            border-bottom: none;
                            margin-bottom: 0;
                        }
                    }
                }

                tr.related-order-row-toggle.open {
                    td {
                        border-bottom: none;
                    }
                }
    
                tr.child-row.open {
                    display: table-row;
                }
    
                tr.child-row {
                    border-bottom: none;
                    display: none;
    
                    td {
                        padding: 0;
                        vertical-align: top;
                        border-right: none;
                        border-left: none;
                        border-top: none;
                        border-bottom: none;
    
                        > div {
                            border-bottom: 1px solid rgb(213, 216, 217);
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 15px;
                        }
    
                        a {
                            font-weight: 100;
                            margin-left: 0;
                        }
                    }
    
                    td.first-col {
                        border-bottom: none;
                        padding: 0;
                        border-left: 1px solid rgb(213, 216, 217);
    
                        > div {
                            padding-top: 5px;
                            padding-bottom: 5px;
                            margin-left: 30px;				    
                            border-bottom: 1px solid rgb(213, 216, 217);
                        }
                    }
    
                    td.last-col {
                        padding: 0;
                        border-bottom: none;
                        border-right: 1px solid rgb(213, 216, 217);
    
                        > div {
                            margin-right: 15px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            border-bottom: 1px solid rgb(213, 216, 217);
                            margin-bottom: 0px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }

    }

    .greentech-my-links-left-container {
        .menu-item {
            a {
                padding-left: 5px;
                justify-content: left;
            }
        }
    }
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .greentech-billing {      
        #adminOrdersTable {
			thead {
				th#poHeader, th#jobHeader {
					display: none;
				}
			}

			tbody {
				tr {
					td {
						.related-orders-show {
							margin-left: 42px;
							padding-top: 5px;
						}
					}                    
				}

                tr.open {
                    td.sub-row-toggle-row {
                        border-bottom-width: 0;
                    }
                }

				td.webOrderColumn, td.poColumn, td.jobColumn, td.relatedPo, td.relatedJob {
					display: none;
				}

				tr.poRow, tr.jobRow {
					display: none;
				}

				tr.poRow.open, tr.jobRow.open {
					display: table-row;

                    td {
                        border-top-width: 0;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
				}

                tr.color8-bg:not(.open) {
                    background-color: #fff;
                }

                tr.related-order-row-toggle.open {
                    td {
                        border-bottom: none;
                    }
                }

                tr.sub-row, tr.main-row.open {
					border-bottom-width: 0;
				}
            }
        }
    }
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
    .greentech-billing {
        margin-left: 0px;

        .greentech-my-links-left-container {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 24px;
        }

        .title-heading-line-2 {
            padding-bottom: 0;
        }

        .admin-right-content {
            padding-left: 0;
            padding-right: 0;

            h1, .order-icons {
                padding-left: 15px;
            }

            #adminOrdersTable {
                thead {
                    th {
                        display: none;
                    }
                }

                tbody {
                    border-top: 1px solid rgb(213, 216, 217);

                    tr {
                        td {
                            border: none;
                        }
                    }

                    tr.main-row {
                        padding-top: 15px;
                        padding-bottom: 20px;
                        display: block;
                        border-bottom: 1px solid rgb(213, 216, 217);

                        td {
                            border: none;
                            position: relative;
                            padding-left: 140px;
                            display: block;
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }

                        td:before {
                            position: absolute;
                            left: 15px;	
                            font-size: 13px;
                            line-height: 1.5;	
                            top: 0px;				
                        }

                        td:nth-of-type(1):before { content: "Order #"; }
                        td:nth-of-type(2):before { content: "Web Order #"; }
                        td:nth-of-type(3):before { content: "Customer PO"; }
                        td:nth-of-type(4):before { content: "Account #"; }
                        td:nth-of-type(5):before { content: "Order Date"; }
                        td:nth-of-type(6):before { 
                            content: "Status"; 
                        }
                    }

                    tr.main-row.show-prices {
                        td:nth-of-type(1):before { content: "Order #"; }
                        td:nth-of-type(2):before { content: "Web Order #"; }
                        td:nth-of-type(3):before { content: "Customer PO"; }
                        td:nth-of-type(4):before { content: "Account #"; }
                        td:nth-of-type(5):before { content: "Order Date"; }
                        td:nth-of-type(6):before { content: "Total"; }
                        td:nth-of-type(7):before { 
                            content: "Status"; 
                            top: 4px;
                        }
                    }

                    tr.main-row.hasRelated {
                        padding-bottom: 10px;
                        border-bottom: none;
                    }

                    .related-order-row-toggle {
                        padding-bottom: 10px;
                        display: block;
                    }    
                    
                    tr.related-order-row-toggle:not(.open) {
                        border-bottom: 1px solid rgb(213, 216, 217);
                    }

                    .related-orders-show {
                        margin-left: 0;
                    }

                    tr.child-row.open {
                        display: block;

                        td {
                            display: block;
                            padding: 0px;
                            position: relative;	

                            br {
                                display: none;
                            }

                            div {
                                margin-left: 15px;
                                padding: 0;
                                border: none;

                                span {
                                    padding-left: 125px;

                                    a {
                                        font-weight: bold;
                                    }
                                }

                                div {
                                    padding-left: 125px;
                                    margin-left: 0;
                                }
                            }
                                                                        
                        }

                        td.first-col {
                            div {	
                                padding: 10px 0 0 0;						
                                margin-right: 15px;
                                border-top: 1px solid rgb(213, 216, 217);
                                border-bottom: none;
                            }
                        }

                        td.last-col {
                            > div {
                                padding: 0 0 10px 0;
                                border-bottom: none;
                            }
                        }

                        td.last-col:before {
                            bottom: 10px;
                        }

                        td:before {
                            position: absolute;
                            left: 15px;
                            font-size: 13px;
                            line-height: 1.5;	
                            bottom: 0px;	
                        }

                        td:nth-of-type(1):before { content: "Order #"; }
                        td:nth-of-type(4):before { content: "Order Date"; }
                        td:nth-of-type(6):before { content: "Status"; }
                    }

                    tr.child-row.open.noPrice {
                        td:nth-of-type(5):before { content: "Status"; }
                    }

                    tr.child-row.first-child-row {
                        td {
                            border: none;
                        }

                        td.last-col {
                            > div {
                                border-top: none;
                            }
                        }
                    }
                }
            }

            #orders-pagination {
                .page-selector {
                    width: auto;
                }
            }

            .invoices-table {			
                tbody {
                    tr {
                        td {
                            a {
                                margin-left: 0;
                            }
                        }
                    }

                    a.pod-button-file-path {
                        display: inline-block;
                        width: 52%;
                    }

                    .footable-row-detail {
                        .footable-row-detail-cell {
                            .footable-row-detail-inner {
                                .footable-row-detail-row {
                                    .button-small {
                                        margin-top: 10px;
                                    }
                                }
                                .footable-row-detail-row:last-of-type {
                                    .footable-row-detail-name {
                                        display: none;
                                    }
                                    .footable-row-detail-value {
                                        position: relative;
                                        height: 50px;

                                        button {
                                            position: absolute;
                                            top: 5px;
                                            width: 140px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}