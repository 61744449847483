.main-container{
    margin: 40px !important;
    max-width: none !important;
    gap: 32px !important;
}
.project-transfer-existing-merge{
    h1{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    h2{
        margin-top: 0 !important;
    }
    p{
        font-size: 14px !important;
        margin: 0 !important;
    }

    .create-project-top, .create-project-bottom{
        min-width: 113px !important;
    }
    .search-existing-top, .search-existing-bottom{
        min-width: 216px !important;
    }
    .field-label{
        margin-bottom: 4px !important;
    }
    .project-details-container .inner-container{
        margin-top: 16px !important;
    }

    .column-headers-row{
        grid-template-rows: repeat(1, [row-start] 56px [row-end]) !important;
    }
    .data-row{
        grid-template-rows: repeat(1, [row-start] 89px [row-end]) !important;
    }
    .header-cell{
        padding: 12px !important;
        padding-left: 16px !important;
    }
    .header-cell p{
        font-weight: 700 !important;
    }
    .products-container .product-details{
        display: flex !important;
        flex-direction: column !important;
        gap: 4px !important;
    }
    button span{
        white-space: nowrap !important;
    }

    .product-image{
        margin-right: 12px !important;
    }

    /* Primary Button **/
    .primary {
        position: relative;
        padding: 12px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px !important;
        border-radius:6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-alpha-white-3) inset, 0px -1px 0px var(--ced-color-global-brand-1) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-brand-2);
        color: #FFFFFF;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border-width: 0;
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }

    .primary:hover {
        background-color: var(--ced-color-background-brand-hover)
    }

    .primary:focus {
        background-color: var(--ced-color-background-brand);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .primary:active {
        background-color: var(--ced-color-background-brand-focus);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }



    /*End Primary Button*/

    /***********Secondary Button***********/
    .secondary {
        position: relative;
        padding: 12px !important;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: 0.3px;
        border-radius: 6px !important;
        box-shadow: 0px -2px 0px var(--ced-color-global-white) inset, 0px 3px 6px -2px var(--ced-color-global-alpha-neutral-4), 0px 1px 2px var(--ced-color-global-alpha-neutral-4) !important;
        background-color: var(--ced-color-global-neutral-6);
        color: var(--ced-color-global-neutral-1);
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 1 !important;
        border: 1px solid var(--ced-color-border-neutral-dark);
        display: inline-block;
        justify-content: center;
        transition: background var(--ced-transition-hover);
        width: inherit;
    }

    .secondary:hover {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-border-neutral-dark);
    }

    .secondary:focus {
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }

    .secondary:active {
        background-color: var(--ced-color-global-neutral-5);
        border: 1px solid var(--ced-color-global-brand-3);
        outline: 2px solid var(--ced-color-global-white);
        box-shadow: 0px 0px 0px 4px var(--ced-color-global-brand-3);
    }
    .page-nav a{
        font-size: 14px !important;
    }
    .bom-container h2{
        font-size: 24px !important;
        font-weight: 600;
    }
    .checkbox{
        margin-left: 0px !important;
    }
    .number-input {
        font-size: 14px !important;
    }
    .button-text {
        font-size: 14px !important;
    }
    .bom-column-headers p {
        align-self: center;
        font-weight: 600 !important;
    }
}