@media (min-width: 1300px) {
    .featured-products {
        .col-md-3 {
            width: 20%;
        }

        .col-sm-push-1 {
            left: 10%;
        }

        .col-md-push-2 {
            left: 20%;
        }

        .col-md-push-3 {
            left: 30%;
        }

        .col-md-push-4 {
            left: 40%;
        }

        .featured-products-large {
            display: flex;
        }

        .featured-products-small {
            display: none;
        }

        .featured-product-container {
            margin: 0 auto;
        }
    }
}

@media (max-width: 1299px) {
    .featured-products {
        .col-md-3 {
            width: 25%;
        }

        .col-md-push-1 {
            left: 12.3%;
        }

        .featured-products-large {
            display: none;
        }

        .featured-products-small {
            display: flex;
        }
    }
}

@media (max-width: 1023px) {
    .top-sellers {
        .col-md-push-1 {
            left: 0;
        }
    }
}