@import "../variables";

#catalogTreeNew {
	overflow: auto;
	height: 400px;
}

#create-catalog.modal {
	.solar-modal-input.solar-align-start {
		align-items: flex-start;
		-ms-flex-align: flex-start;
	}
	.solar-modal-input {
		display: flex; 
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		align-items: center; 
		-ms-flex-align: center;

		.hidden-label {
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.1px;
			font-weight: 100;
		}

		> label {
			display: block;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.43;
			letter-spacing: 0.1px;
			width: 150px;
		}

		> input[type="text"], .solar-modal-input-field {
			flex: 1;
			-webkit-box-flex: 1;
			  -moz-box-flex:  1;
			  -webkit-flex:  1;
			  -ms-flex:  1;
		}
	}	

	.new-my-catalogs-filter-row, .new-my-catalogs-filter-row-new, .add-all-my-catalogs-filter-row-new, .add-all-my-catalogs-filter-row {
		padding: 16px;
		position: relative;

		input {
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.2px;
			padding: 8px 28px 8px 12px;	
			width: 100%;				
		}
	}

	#catalogTreeNew, #catalogTreeNewCatalog, #catalogTreeAll, #catalogTreeAllParent {
		height: 228px;
		border-top-width: 0;
		max-width: 388px;
		overflow: auto;
	}
}

.my-catalogs {
	margin-top: 40px;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;

	.my-catalogs-left-content {
		width: 240px;

		.my-catalogs-title-row {
			padding-left: 30px;

			h4 {
				margin-top: 0;
			}

			#create-new-catalog {
				margin-top: 10px;
			}
		}

		.no-catalogs-row {
			padding-left: 30px;
		}

		.my-catalogs-filter-row {
			margin-top: 15px;
			padding: 15px;
			border-top: 1px solid #b9bbc0;
			border-right: 1px solid #b9bbc0;
			border-bottom: 1px solid #b9bbc0;

			input {
				width: 100%;
			}
		}

		.my-catalogs-tree-row {
			border-top: 1px solid #b9bbc0;
			border-right: 1px solid #b9bbc0;
			border-bottom: 1px solid #b9bbc0;
			height: 327px;
			overflow: auto;
			margin-top: 1px;
		}		

		.my-catalogs-tree-row.empty-tree {
			box-shadow: none;
			height: 0px;
		}
	}

	.my-catalogs-right-content {
		flex: 1;
		-webkit-box-flex: 1;
  		-moz-box-flex:  1;
  		-webkit-flex:  1;
  		-ms-flex:  1;
		overflow: hidden;
		padding-left: 30px;
		padding-right: 30px;

		.catalog-link {
			margin-left: 15px;
			font-size: 13px;
			font-weight: bold;
			line-height: 1.5;
		}

		.buttons {
			button {
				margin-left: 13px;
			}
		}

		.user-section {
			.selectedUserName {
				margin-left: 10px;
				font-size: 16px;
    			line-height: 1.6;
			}

			label {
				font-weight: normal;
				display: inline-block;
				margin-left: 10px;
			}
		}

		.my-catalogs-items {
			overflow: hidden;

			.my-catalogs-item-top {
				margin-top: 50px;

				.number-items {
					margin-top: 6px;
				}
			}

			.my-catalogs-item-row {
				.my-catalogs-item-details {
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;

					.image-section {
						text-align: center;
						width: 155px;

						img {
							max-width: 155px;
							max-height: 155px;
						}

						.product-image-link-large {
							display: none;
						}
					}

					.product-detail-section {
						flex: 1;
						-webkit-box-flex: 1;
				  		-moz-box-flex:  1;
				  		-webkit-flex:  1;
				  		-ms-flex:  1;
						overflow: hidden;
						vertical-align: top;
						margin-left: 15px;

						.product-name {
							a {
								font-size: 19px;
								font-weight: bold;
								line-height: 1.3;
							}
						}

						.button-section {
							height: 30px;

							a {
								margin-right: 8px;
							}
						}
					}
				}

				.search-price {
					font-size: 27px;
					font-weight: bold;
					line-height: 1.3;
				}

				.my-catalogs-product-quantity {
					margin-left: 5px;
				}				
			}

			.buttons {
				margin-top: 30px;
			}
		}				
	}
}

#item-quick-view-my-catalogs {
	.quick-view-img {
		max-width: 258px;
		text-align: center;

		img {
			max-width: 100%;
			max-height: 258px;
		}
	}

	.quick-view-title {
		a {
			font-size: 29px;
			line-height: 1.2;
		}
	}

	.quick-view-sub-title {
		width: 100px;
    	display: inline-block;
	}

	.quick-view-price {
		margin-top: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid #b9bbc0;
		margin-bottom: 20px;

		.search-price {
			font-size: 29px;
			font-weight: bold;
			line-height: 1.2;
		}

		.availability-section {
			padding-top: 14px;
		}
	}

	.line-item {
		border-bottom-width: 0;
	}
}

/** sm size (tablet) **/
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	#catalogTreeNew {
		height: 350px;
	}
	.my-catalogs {
		margin-top: 30px;

		.my-catalogs-left-content {
			width: 186px;

			.my-catalogs-title-row {
				padding-left: 15px;
			}

			.no-catalogs-row {
				padding-left: 15px;
			}

			.my-catalogs-filter-row {
				padding: 15px;
			}

			#create-new-catalog {
				float: none !important;
			}

			.my-catalogs-tree-row {
				height: 350px;
			}
		}

		.my-catalogs-right-content {
			padding-left: 15px;
			padding-right: 15px;

			.filters {
				> div.col-sm-4 {
					padding-left: 7px;
					padding-right: 7px;
				}
				> div.col-sm-4:first-of-type {
					padding-left: 14px;
				}
				> div.col-sm-4:last-of-type {
					padding-right: 14px;
				}
			}

			.my-catalogs-items {
				.my-catalogs-item-row {
					.my-catalogs-item-details {

						.image-section {
							text-align: center;
							width: 108px;

							img {
								max-width: 108px;
								max-height: 108px;
							}
						}

						.product-detail-section {
							.product-name {
								a {
									font-size: 18px;
									font-weight: bold;
									line-height: 1.4;
								}
							}
						}
					}			
				}

				.tablet-right {
					padding-left: 138px;
					margin-top: 10px;
					width: 100%;
    				position: relative;

					.quantity-button-content {
						margin-top: 0;
					    position: absolute;
					    top: 0;
					    right: 15px;
					}
				}
			}
		}
	}
}

/* xs size (mobile) */
@media only screen and (max-width: $mobile-max-width) {
	#catalogTreeNew {
		height: 260px;
	}
	#create-catalog.modal {
		.solar-modal-input {
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;

			> label {
				width: 100%;
			}

			> input[type="text"], .solar-modal-input-field {
				width: 100%;
				display: block;
				-ms-flex:  1 0 auto;
			}
		}

		.add-my-catalog-item-quantity {
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;

			.add-my-catalog-quantity {
				margin-top: 16px;
			}
		}
	}
	.my-catalogs {
		margin-top: 30px;
		display: block;

		.mobile-pad {
			padding-left: 15px;
			padding-right: 15px;
		}

		.my-catalogs-left-content {
			width: 100%;

			div.row {
				margin-left: 0;
				margin-right: 0;
			}

			.my-catalogs-title-row {
				margin-top: 20px;
				padding-left: 15px;

				h4 {
					margin-top: 5px;
					margin-bottom: 0;
				}	
				
				#create-new-catalog {
					display: block;
					width: 100%;
				}
			}

			.no-catalogs-row {
				padding-left: 0px;
			}

			.my-catalogs-tree-row {
				height: 260px;
			}
		}

		.my-catalogs-right-content {
			padding-left: 0;
			padding-right: 0;

			form > div.row {
				margin-left: 0;
				margin-right: 0;
			}

			.delete-catalog {
				width: 100%;
			    display: block;
			    margin-left: 0;
			    margin-top: 10px;
			}

			.catalog-number-filter {
				width: 100%;
			}

			.close-user-section-container {
				padding-left: 15px;
				padding-right: 15px;
				margin-top: 20px;
				margin-bottom: 10px;

				#close-user-section {
					width: 100%;					
				}
			}
			
			.mobile-buttons {
				button, input[type="submit"] {
					width: 100%;
					margin-left: 0;
				}
			}

			.my-catalogs-items {

				.my-catalogs-item-top {
					margin-top: 35px;

					.number-items {
						margin-top: 0px;
					}
				}

				.line-item:first-child {
				    margin-top: 20px;
				}

				.line-item {
					margin-left: 0;
					margin-right: 0;
				}

				.my-catalogs-item-row {
					.my-catalogs-item-details {

						.image-section {
							width: 65px;
							margin-left: 0;

							img {
								max-width: 65px;
								max-height: 65px;
							}
						}

						.product-detail-section {
							margin-left: 10px;

							.product-name {
								a {
									font-size: 15px;
									font-weight: bold;
									line-height: 1.7;
								}
							}
						}
					}

					.search-price {
						font-size: 19px;
						font-weight: bold;
						line-height: 1.6;
					}									
				}

				.tablet-right {
					padding-left: 95px;
					margin-top: 10px;
					width: 100%;
    				position: relative;	

    				.line-item-button {
    					margin-left: 0;
    					padding: 7px 12px;
    				}			
				}
			}
		}
	}

	#item-quick-view-my-catalogs {
		.modal-content {
			padding-left: 0;
			padding-right: 0;

			.modal-header {
				padding-right: 10px;
			}

			div.row {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.quick-view-add-cart {
			> div.row {
				> div {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.quick-view-img {
			max-width: 100%;
			text-align: center;

			img {
				max-width: 190px;
				max-height: 190px;
			}
		}

		.quick-view-title {
			a {
				font-size: 19px;
				line-height: 1.6;
			}
		}

		.quick-view-sub-title {
			width: 100px;
	    	display: inline-block;
		}

		.quick-view-price {
			margin-top: 15px;
			padding-top: 10px;
			border-top: 1px solid #b9bbc0;
			padding-bottom: 0;
			border-bottom: none;
			margin-bottom: 10px;

			.price-section {
				float: none !important;
			}

			.search-price {
				font-size: 19px;
				font-weight: bold;
				line-height: 1.6;
			}

			.availability-section {
				padding-top: 0;
				float: none !important;
			}
		}

		.quick-view-quantity {
			margin-top: 15px;
			padding-top: 10px;
			border-top: 1px solid #b9bbc0;
		}

		.line-item {
			border-bottom-width: 0;
		}

		.line-item-button {
			margin-left: 0;
		}

		.quick-view-add-to-cart {
			padding-bottom: 15px;
			border-bottom: 1px solid #b9bbc0;
		}

		.quick-view-mobile-spec-sheet {
			margin-top: 10px;
		    border-top: 1px solid #b9bbc0;
		    padding-top: 10px;
		}
	}
}